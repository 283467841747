import { createApi } from './ServerService';


export const fetchOperators = () => {
  return createApi("/api/v1/operators/?role=cashier&role=operator");
};

export const fetchCreateOperator = (operatorForm) => {
  return createApi("/api/v1/operators/", {method: "POST", body: operatorForm});
};

export const fetchUpdateOperator = (operatorForm, id) => {
  return createApi("/api/v1/operators/"+ id + "/", {method: "PATCH", body: operatorForm});
};

export const fetchOperator = (id) => {
  return createApi("/api/v1/operators/" + id + "/");
};

export const fetchDeleteOperator = (id) => {
  return createApi("/api/v1/operators/" + id + "/", {method: "DELETE"});
};
