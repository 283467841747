import React, {useState, useEffect} from "react";
import {
    CButton,
    CCard,
    CCardBody, CCardHeader, CForm, CFormGroup, CLabel, CModal, CModalBody, CModalFooter, CModalHeader, CRow
} from "@coreui/react";
import {useDispatch} from "react-redux";
import {fetchNodes, fetchRouteDetail, fetchRoutes, fetchDeleteRoute} from '../../services/RouteService';
import {setBreadcrumb} from "src/redux/actions/settingsActions";
import {useTranslation} from "react-i18next";
import {useModalState, useConfirmModal} from "../../helpers/useModal";
import RouteForm from "./RouteForm";
import CIcon from "@coreui/icons-react";
import {toastify} from "../../helpers/toast";
import {useTranslate} from "../../helpers/useTranslate";
import { useHistory } from "react-router-dom";


export default function Routes() {
    const [nodes, setNodes] = useState(null)
    const {isConfirmOpen, onConfirmToggle} = useConfirmModal();
    const dispatch = useDispatch();
   const { tr } = useTranslate()
    const [routes, setRoutes] = useState([]);
    const {t} = useTranslation();
    const [modalTitle, setModalTitle] = useState("Modal Title");
    const {isOpen, onToggle} = useModalState(false);
    const [selectedRoute, setSelectedRoute] = useState(null)

    const history = useHistory()


    const newRouteForm = () => {
        setSelectedRoute({})
        setModalTitle(t("Create Route"));
        onToggle();
    }


    const handleEdit = (route) => {
        fetchRouteDetail(route.id)
            .then((res) => {
                if (res.ok){
                    res.json().then((data) => {
                        setSelectedRoute(data)

                    })
                }
            })
            .catch(err => console.log(err, '#1 error in Editing'))

        dispatch(setBreadcrumb(["Routes", t("Edit Route")]));
        setModalTitle(t("Edit Route"));
        onToggle()
    }

    const handleCreate = () => {
        newRouteForm()
        setSelectedRoute({
            distance: "",
            duration: "",
            duration_unit: "",
            id: "",
            name_kg: "",
            name_ru: "",
        })
        dispatch(setBreadcrumb(["Routes", t("Create Route")]));
    }

    const handleDelete = (route) => {
        dispatch(setBreadcrumb(["Routes", t("Delete Route")]));
        setSelectedRoute(route)
        setModalTitle(t("Delete Route"));
        onConfirmToggle()
    }

    const getRoutes = () => {
        fetchRoutes()
            .then((res) => res.json().then((data) => {
                setRoutes(data)
            }))
            .catch((err) => console.log(err));
    }

    const onDelete = (selectedRoute) => {
        fetchDeleteRoute(selectedRoute.id)
            .then((res) => {
                if (res.ok) {
                    toastify("success", t("маршрут удален"));
                    getRoutes()
                } else {
                    toastify("error", "Произошла ошибка удаления");
                }
            })
            .catch((err) => toastify("error", "Произошла ошибка, проверьте интернет"))
            .finally(onConfirmToggle)

    }


    useEffect(() => {
        dispatch(setBreadcrumb(["Routes"]));
        getRoutes()

        fetchNodes()
            .then((res) => res.json().then((data) => setNodes(data)))
            .catch((err) => console.log(err));
    }, [dispatch]);


    return (
        <CCard className="m-2">

            <CCardHeader>
                <CButton
                    variant="outline"
                    color="info"
                    className="float-right mx-1"
                    onClick={() => history.push("/nodes")}
                >
                    {t("Nodes")}
                </CButton>
                <CButton
                    variant="outline"
                    color="success"
                    className="float-right mx-1"
                    onClick={handleCreate}
                >
                    {t("Create new route")}
                </CButton>
            </CCardHeader>

            <CCardBody>
                <table className="table table-hover table-borderles">
                    <thead>
                    <tr>
                        <th>{t("Name")}</th>
                        <th>{t("Distance")}</th>
                        <th>{t("Duration")}</th>
                        <th>{t("Actions")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {routes &&
                    routes.map((route) => (
                        <tr key={route.id}>
                            <td>{tr(route.name_ru, route.name_kg)}</td>
                            <td>{route.distance} км</td>
                            <td>
                                {route.duration} {route.duration_unit === 'h' ? tr('часов', "саат"): tr("мин", "мин")}

                            </td>
                            <td>
                                <CIcon
                                    name="cilTrash"
                                    className="text-danger mr-2"
                                    size="xl"
                                    style={{cursor: "pointer"}}
                                    onClick={() => handleDelete(route)}
                                />

                                <CIcon
                                    name="cilPencil"
                                    className="text-warning"
                                    size="xl"
                                    style={{cursor: "pointer"}}
                                    onClick={() => handleEdit(route)}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </CCardBody>

            {/*Modal for Delete Action*/}
            <CModal show={isConfirmOpen} onClose={onConfirmToggle}>
                <CModalHeader closeButton>{modalTitle}</CModalHeader>
                <CModalBody>
                    {selectedRoute && (
                        <CForm>
                            <CFormGroup>
                                <CLabel htmlFor="nf-username">
                                    {t("Delete this route?")} <b>{selectedRoute.name_kg}</b>
                                </CLabel>
                            </CFormGroup>
                        </CForm>
                    )}
                </CModalBody>
                <CModalFooter>
                    {selectedRoute && (
                        <CRow>
                            <CButton
                                onClick={() => onDelete(selectedRoute)}
                                color="danger"
                                className="mr-2"
                            >
                                {t("Delete")}
                            </CButton>
                        </CRow>
                    )}
                </CModalFooter>
            </CModal>

            {selectedRoute && <RouteForm
                setRoutes={setRoutes}
                toggle={onToggle}
                modal={isOpen}
                selectedRoute={selectedRoute}
                modalTitle={modalTitle}
                nodes={nodes}
            />}
        </CCard>
    );
}
