import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import {
  getCities,
  getDistricts,
  getLocations,
  // locationUpdate,
  deleteLocationFromRedux,
} from "src/redux/actions/locationActions";
import { useTranslation } from "react-i18next";
import { YMaps, Map, Placemark, Clusterer } from "react-yandex-maps";
import { postLocation, deleteLocation } from "src/services/CityService";
import {
  CInput,
  CRow,
  CCol,
  CCardHeader,
  CCard,
  CLabel,
  CButton,
} from "@coreui/react";

const apikey = "14b7fac2-2096-4a85-9aa1-ed9ac48250ff";

export default function NewAddressAdd() {
  const Button = ({
    selectedAddress,
    deleteLocation,
    deleteLocationFromRedux,
  }) => (
    <button
      onClick={() => {
        let del = window.confirm("Удалить?");
        if (del) {
          dispatch(deleteLocationFromRedux(selectedAddress));
          deleteLocation(selectedAddress.id).then((res) => {
            if (res.ok) {
              console.log("Адрес удален");
            } else {
              console.log("Ошибка удаления адреса");
            }
          });
        } else {
          console.log("ошибка удаления");
        }
      }}
    >
      Удалить адрес
    </button>
  );

  const BaloonContent = ({
    selectedAddress,
    deleteLocation,
    deleteLocationFromRedux,
  }) => {
    const portalRoot = document.querySelector(".portal");
    const portalContainer = document.createElement("div");
    useEffect(() => {
      portalRoot.appendChild(portalContainer);
    }, [portalContainer, portalRoot]);

    return ReactDOM.createPortal(
      <Button
        selectedAddress={selectedAddress}
        deleteLocation={deleteLocation}
        deleteLocationFromRedux={deleteLocationFromRedux}
      />,
      portalContainer
    );
  };
  const dispatch = useDispatch();
  const map = useRef(null);
  const { i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const [nameKg, setNameKg] = useState("");
  const [nameRu, setNameRu] = useState("");
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [mapApi, setMapApi] = useState(null);
  const [city, setCity] = useState({
    label: i18n.language === "ru" ? "Выбрать город" : "Шаарды тандоо",
    value: 0,
  });
  const [district, setDistrict] = useState({
    label: i18n.language === "ru" ? "Выбрать район" : "Районду тандоо",
    value: 0,
  });
  const [distForBalloon, setDistForBalloon] = useState("");
  const [selectedAddress, setSelectedAddress] = useState({});

  const cities = useSelector((state) => state.locations.cities);
  const districts = useSelector((state) => state.locations.districts);
  // const addresses = useSelector((state) => state.locations.locations);
  const [addresses, setAddresses] = useState([]);

  const onClickMap = (e) => {
    const [lat, lng] = e.get("coords");
    setLat(lat);
    setLng(lng);
    // getName(lat, lng);
  };

  const getDistrict = (lat, lng) => {
    mapApi
      .geocode([lat, lng], {
        kind: "district",
      })
      .then((res) => {
        setDistForBalloon(res.geoObjects.get(0).properties.get("name"));
      });
  };

  // const getName = (lat, lng) => {
  //   mapApi
  //     .geocode([lat, lng], {
  //       kind: "house",
  //     })
  //     .then((res) => {
  //       console.log(res.geoObjects.get(0).properties.get("name"));
  //       // setNameKg(
  //       //   res.geoObjects.get(0).properties.get("name").replace("улица", "")
  //       // );
  //       // setNameRu(res.geoObjects.get(0).properties.get("name"));
  //     });
  // };

  const createNew = () => {
    const form = {
      name_kg: nameKg,
      name_ru: nameRu,
      lat,
      lng,
      city_id: city.value,
      district_id: district.value,
      map_zoom: 12,
    };
    console.log(form);
    postLocation(form).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          // dispatch(locationUpdate(data));
          setAddresses([...addresses, data])
          setLat(0);
          setLng(0);
          setNameKg("");
          setNameRu("");

          console.log(data);
        });
      } else {
        console.log("ошибкаа");
      }
    });
  };

  useEffect(() => {
    dispatch(getCities());
    setCity({
      label: i18n.language === "ru" ? "Выбрать город" : "Шаарды тандоо",
      value: 0,
    });
    setDistrict({
      label: i18n.language === "ru" ? "Выбрать район" : "Районду тандоо",
      value: 0,
    });
  }, [dispatch, i18n.language]);

  return (
    <CCard>
      <CCardHeader className="p-0 m-0">
        <CRow style={{ width: "100%" }}>
          <CCol style={{ maxWidth: "13%" }} className="pl-4 pr-1">
            <CLabel>Город, район</CLabel>
            {city && (
              <Select
                value={city}
                options={
                  i18n.language === "ru"
                    ? cities.map((c) => {
                        return {
                          value: c.id,
                          label: c.name_ru,
                          coors: [c.lat, c.lng],
                        };
                      })
                    : cities.map((c) => {
                        return {
                          value: c.id,
                          label: c.name_kg,
                          coors: [c.lat, c.lng],
                        };
                      })
                }
                onChange={(e) => {
                  dispatch(getDistricts(e.value));
                  setCity(e);
                }}
              />
            )}
          </CCol>
          <CCol style={{ maxWidth: "13%" }} className="px-1">
            <CLabel>Мкр, село, ж/м</CLabel>
            {districts && (
              <Select
                value={district}
                options={
                  i18n.language === "ru"
                    ? districts &&
                      districts.map((d) => {
                        return {
                          value: d.id,
                          label: d.name_ru,
                          coors: [d.lat, d.lng],
                        };
                      })
                    : districts &&
                      districts.map((d) => {
                        return {
                          value: d.id,
                          label: d.name_kg,
                          coors: [d.lat, d.lng],
                        };
                      })
                }
                onChange={(e) => {
                  setDistrict(e);
                  dispatch(getLocations(city.value, e.value));
                  console.log(addresses);
                }}
              />
            )}
          </CCol>
          <CCol style={{ maxWidth: "7%" }} className="px-1">
            <CLabel>Шир</CLabel>
            <CInput
              placeholder="lat"
              value={lat}
              onChange={(e) => setLat(e.target.value)}
            />
          </CCol>
          <CCol style={{ maxWidth: "7%" }} className="px-1">
            <CLabel>Дол</CLabel>
            <CInput
              placeholder="lng"
              value={lng}
              onChange={(e) => setLng(e.target.value)}
            />
          </CCol>
          <CCol style={{ maxWidth: "26%" }} className="px-1">
            <CLabel>КГ</CLabel>
            <CInput
              placeholder="Name on Kg"
              value={nameKg}
              onChange={(e) => setNameKg(e.target.value)}
            />
          </CCol>
          <CCol style={{ maxWidth: "26%" }} className="px-1">
            <CLabel>РУ</CLabel>
            <CInput
              placeholder="Name on Ru"
              value={nameRu}
              onChange={(e) => setNameRu(e.target.value)}
            />
          </CCol>

          <CButton
            className="mt-4"
            type="submit"
            variant="outline"
            color="success"
            onClick={() => createNew()}
          >
            Создать
          </CButton>
        </CRow>
      </CCardHeader>

      <YMaps query={{ load: "package.full", apikey }}>
        <Map
          onClick={(e) => onClickMap(e)}
          instanceRef={map}
          onLoad={(ymaps) => setMapApi(ymaps)}
          width="100%"
          height="75vh"
          defaultState={{
            center: [42.873205532853866, 74.659174446212772],
            zoom: 8,
            controls: ["typeSelector"],
          }}
        >
          <Placemark geometry={[lat, lng]} />
          <Clusterer
            options={{
              preset: "islands#invertedGreenClusterIcons",
              groupByCoordinates: false,
            }}
          >
            {addresses &&
              addresses.map((adr, i) => (
                <Placemark
                  modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
                  onMouseEnter={() => {
                    setDistForBalloon(getDistrict(adr.lat, adr.lng));
                  }}
                  properties={{
                    hintContent:
                      adr.name_ru +
                      "<br/>" +
                      "Наши данные:" +
                      adr.district.name_ru +
                      "<br/>" +
                      "<br/>" +
                      "Yandex:" +
                      distForBalloon,
                    balloonContent: `<div class="portal"></div>`,
                  }}
                  onBalloonOpen={() => {
                    setOpen(true);
                    setSelectedAddress(adr);
                  }}
                  onBalloonClose={() => setOpen(false)}
                  geometry={[adr.lat, adr.lng]}
                  key={i}
                />
              ))}
          </Clusterer>
        </Map>
      </YMaps>
      {open && (
        <BaloonContent
          selectedAddress={selectedAddress}
          deleteLocation={deleteLocation}
          deleteLocationFromRedux={deleteLocationFromRedux}
        />
      )}
    </CCard>
  );
}
