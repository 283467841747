export function date(date) {
    if(date) {
        let d = new Date(Date.parse(date))
        return (d.getDate() + "/" + parseInt(d.getMonth()+1) +"/" +d.getFullYear() + " "+
        (d.getHours()>9 ? d.getHours() : "0"+ d.getHours()) +":" + (d.getMinutes()>9 ? d.getMinutes() : "0"+ d.getMinutes()));
    } else {
        return ""
    }
}

export const dateDifference = (start, end) => {
    let diff = Math.abs(
        new Date(end).getTime() - new Date(start).getTime()
    );

    let msec = diff;
    // let dd = Math.floor(msec / 1000 / 60 / 60 / 24);
    // msec -= dd * 1000 * 60 * 60 * 24;
    let hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    let mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    let ss = Math.floor(msec / 1000);
    msec -= ss * 1000;
    let result = '';
    // result += (dd > 0 ? dd + "дн. " : "")
    result += (hh > 0 ? hh + "ч. " : "")
    result += (mm > 0 ? mm + "мин." : "")
    return result
}

const today = new Date()

const monthCheck = (d) => d.getMonth() ===  today.getMonth()

const getOldDate = (diff) => {
    const d = new Date();
    if(diff===30) {
        d.setMonth(d.getMonth() - 1)
    } else {
        d.setDate(d.getDate() - diff);
    }
    return d;
}

export const dateFilters = [
    {
        name: "Today",
        rule: (date) => {
            const d = new Date(date);
            return monthCheck(d) ? d.getDate() === today.getDate() : false; 
        },
        date_from: new Date(),
        date_to: new Date(), 
    },
    {
        name: "Yesterday",
        rule: (date) => {
            const d = new Date(date);
            return monthCheck(d) ? d.getDate() + 1 === today.getDate() : false; 
        },
        date_from: getOldDate(1),
        date_to: getOldDate(1), 
    },
    {
        name: "Before yesterday",
        rule: (date) => {
            const d = new Date(date);
            return monthCheck(d) ? d.getDate() + 2 === today.getDate() : false; 
        },
        date_from: getOldDate(2),
        date_to: getOldDate(2), 
    },
    {
        name: "Earlier",
        rule: (date) => {
            const d = new Date(date);
            return monthCheck(d) ? d.getDate() + 2 < today.getDate() : true;             
        },
        date_from: new Date('2022-01-01'),
        date_to: getOldDate(3), 
    }
]

export const archiveDateFilters = [
    {
        name: "Today",
        rule: (date) => {
            const d = new Date(date);
            return monthCheck(d) ? d.getDate() === today.getDate() : false; 
        },
        date_from: new Date(),
        date_to: new Date(), 
    },
    {
        name: "Yesterday",
        rule: (date) => {
            const d = new Date(date);
            return monthCheck(d) ? d.getDate() + 1 === today.getDate() : false; 
        },
        date_from: getOldDate(1),
        date_to: getOldDate(1), 
    },
    {
        name: "Before yesterday",
        rule: (date) => {
            const d = new Date(date);
            return monthCheck(d) ? d.getDate() + 2 === today.getDate() : false; 
        },
        date_from: getOldDate(2),
        date_to: getOldDate(2), 
    },
    {
        name: "7 days",
        rule: () => false,
        date_from: getOldDate(7),
        date_to: new Date(), 
    },
    {
        name: "30 days",
        rule: () => false,
        date_from: getOldDate(30),
        date_to: new Date(), 
    }
]