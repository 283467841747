import { CButton } from '@coreui/react'
import React from 'react'
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { useTranslation } from 'react-i18next';
import { ordersFetchArchiveExport } from 'src/services/OrderService';
import { date, dateDifference } from 'src/helpers/date';
import { useTranslate } from 'src/helpers/useTranslate';

export default function ExportExcel ({filterParams}) {

    const {t} = useTranslation()
    const {tr} = useTranslate()

    const generateFileName = () => {
        let d = new Date()
        return d.getDate().toString() + "_" + (d.getMonth() + 1).toString() + "_" + d.getFullYear().toString()
    }

    const download = (orders) => {
        const sheetHeaderNames = [
            '#', 'ID', t("From Address"), t("To Address"), t("Comments"), 
            t("Created"), t("Delivered"), t("Time"), "км", 
            t("Courier"), t("Redemption"), t("Price")];
        const columnSizes = [5, 15, 15, 15, 15, 10, 10, 8, 5, 15, 10, 10];
        const fileName = generateFileName()

        const workbook = new Workbook();

        const worksheet = workbook.addWorksheet('Все заказы');

        // Add new row
        const titleRow = worksheet.addRow(sheetHeaderNames);
        // Set font, size and style in title row.
        titleRow.font = {name: 'Calibri', size: 12, bold: true};
        titleRow.height = 30;

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < orders.length; i++) {
            const order = orders[i];
            const rowData = [];
            rowData.push(i + 1);
            rowData.push(order.oid);
            rowData.push(tr(order.location_from?.name_ru, order.location_from?.name_kg));
            rowData.push(tr(order.location_to?.name_ru, order.location_to?.name_kg));
            rowData.push(order.description);
            rowData.push(date(order.created_at));
            rowData.push(date(order.delivery_end_time));
            rowData.push(dateDifference(order.created_at, order.delivery_end_time));
            rowData.push(order.distance / 1000);
            rowData.push(order.courier ? order.courier.user?.firstname : order.courier.firstname || '');
            rowData.push(order.redemption_amount);
            rowData.push(order.final_price);
            const mr = worksheet.addRow(rowData);
            mr.height = 30;
            mr.font = {size: 10}
        }

        worksheet.eachRow((row, i) => {
            row.eachCell(cell => {
                cell.alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                cell.border = {
                top: {style: 'thin'},
                left: {style: 'thin'},
                bottom: {style: 'thin'},
                right: {style: 'thin'}
                };
            });
        });

        worksheet.columns.forEach((column, i) => {
            column.width = columnSizes[i];
        });

        // Add row with current date
        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            fs.saveAs(blob, fileName + '.xlsx');
        });
    }

      const onExportClick = () => {
        ordersFetchArchiveExport(filterParams).then(res => {
            res.ok && res.json().then(d => {
                download(d);
            })
        }).catch(err => {
            console.log(err);
        })
      }

    return (
        <CButton color="primary" variant='outline' className="mx-1" size="sm" onClick={onExportClick}>{t("Export")}</CButton>
    )
}