import CIcon from "@coreui/icons-react";
import { CInput, CListGroup, CListGroupItem } from "@coreui/react";

export default function InputWithSuggestions({
    value,
    placeholder,
    invalid,
    suggests,
    suggestShow,
    itemSelect,
    setSuggestShow,
    handleChange,
}) {
    const suggestListStyle = {
        position: 'absolute', 
        zIndex: '10', 
        width: "100%", 
        backgroundColor: "white", 
        border: "Solid Gray 1px", 
        borderTop: "none", 
        borderRadius: "0 0 2% 2%",
    }
    return (
        <div>
            <CInput
                // onBlur={(e) => setSuggestShow(false)}
                value={value}
                onChange={e => handleChange(e.target.value)}
                placeholder={placeholder}
                style={{border: invalid ? "Solid Red 1px" : ""}}
            />
            <div style={{position: 'relative'}}>
                <div style={suggestListStyle} className={(suggestShow && suggests.length > 0) ? "" : "d-none"}>
                    <div className="d-flex justify-content-end">
                        <CIcon name="cilX" size="sm" className="sp-link" onClick={() => setSuggestShow(false)}/>
                    </div>
                    <CListGroup flush>
                        {suggests.map((s, i) =>
                            <CListGroupItem key={i} 
                                onClick={() => itemSelect(s)}
                                name="sp-listofsuggests"
                                className="py-1 sp-link"
                            >
                                {s.label}
                            </CListGroupItem>
                        )}
                    </CListGroup>
                </div>
            </div>
        </div>
    )
}