import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchCompanyColor, fetchCompanyStatuses } from "src/services/CompanyService";
import {CCard, CCardBody, CButton, CModal, CModalBody, CModalFooter} from "@coreui/react";

import { ColorPicker, useColor, toColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import { useModalState } from "src/helpers/useModal";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "src/redux/actions/settingsActions";
import { toastify } from "src/helpers/toast";

export default function CompanyStatuses () {

    const {i18n, t} = useTranslation();
    const [statusList, setStatusList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState({});
    const {isOpen, onToggle} = useModalState();
    const [color, setColor] = useColor("hex", "#ffffff");
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(setBreadcrumb(["Statuses", "Colors"]))
        fetchCompanyStatuses().then(res => res.json().then(data => setStatusList(data)))
    }, [dispatch])

    const updateStatusColor = () => {
        fetchCompanyColor({"status": selectedStatus.value, "color": color.hex}).then(res => {
            if(res.ok) {
                toastify("success", t("Status color changed successfully"));
                onToggle();
            } else {
                toastify("error", t("Произошла ошибка изменения цвета статуса"))
            }
        }).catch(err => toastify("error", t("Произошла ошибка, проверьте интернет")));
    }
    const changeColor = (status) =>  {
        setSelectedStatus(status); 
        setColor(toColor("hex", status.color));
        onToggle()
    }
    
    return (
        <div>
            <CCard className="m-2">
                <CCardBody>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>{t("Name")}</th>
                                <th>{t("Value")}</th>
                                <th>{t("Stage")}</th>
                                <th>{t("Color")}</th>
                                <th>{t("Actions")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {statusList.map((s,i) => 
                                <tr key={i}>
                                    <td>{i18n.language === "ru" ? s.name_ru : s.name_kg}</td>
                                    <td>{s.value}</td>
                                    <td>{s.stage}</td>
                                    <td style={{backgroundColor: s.color}}>{s.color}</td>
                                    <td>
                                        <CButton variant="outline" color="primary" onClick={() => changeColor(s)}>{t("Change Color")}</CButton>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </CCardBody>
            </CCard>
            <CModal show={isOpen} onClose={onToggle}>
            <CModalBody>
                <ColorPicker width={456} height={228} color={color} onChange={setColor} hideRGB hideHSB dark />
            </CModalBody>
            <CModalFooter>
                <CButton outline="outline" color="success" onClick={() => updateStatusColor()}>{t("Save")}</CButton>
                <CButton outline="outline" color="secondary" onClick={() => onToggle()}>{t("Cancel")}</CButton>
            </CModalFooter>
        </CModal>
        </div>
    )
}