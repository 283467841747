import { CCol, CInput, CLabel, CRow, CSelect } from '@coreui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'


export default function PaymentFilters({ filterChange, organizations, offices, params }) {

    const {t} = useTranslation()
    return (
        <CRow className="mb-2">
            <CCol sm="6">
                <CLabel>{t("Search")}</CLabel>
                <CInput placeholder="Search" onChange={e => filterChange(e, "search_key")} value={params?.searchKey}/>
            </CCol>
            <CCol sm="6">
                <CRow>
                    <CCol>
                        <CLabel>{t("From")}</CLabel>
                        <CInput type="date" onChange={e => filterChange(e, "date_from")} value={params?.date_from}/>
                    </CCol>
                    <CCol>
                        <CLabel>{t("To")}</CLabel>
                        <CInput type="date" onChange={e => filterChange(e, "date_to")} value={params?.date_to}/>
                    </CCol>
                </CRow>
            </CCol>
            <CCol sm="2" className="float-right">
                <CLabel>{t("Redemption type")}</CLabel>
                <CSelect onChange={e => filterChange(e, "payment_type")} value={params?.payment_type}>
                    <option value="">{t("All")}</option>
                    <option value="redemption">{t("From cash register")}</option>
                    <option value="imposed">{t("Imposed")}</option>
                </CSelect>    
            </CCol>
            <CCol sm="2" className="float-right">
                <CLabel>{t("Redemption payment status")}</CLabel>
                <CSelect onChange={e => filterChange(e, "payment_status")} value={params?.payment_status}>
                <option value="">{t("All")}</option>
                    <option value="paid">{t("Paid")}</option>
                    <option value="not_paid">{t("Not paid")}</option>
                </CSelect>    
            </CCol>
            <CCol sm="2" className="float-right">
                <CLabel>{t("Redemption payment method")}</CLabel>
                <CSelect onChange={e => filterChange(e, "payment_method")} value={params?.payment_method}>
                    <option value="">{t("All")}</option>
                    <option value="cash">{t("Cash")}</option>
                    <option value="invoice">{t("Банковский перевод")}</option>
                    <option value="elsom">{t("Элсом")}</option>
                    <option value="mbank">{t("Мбанк")}</option>
                    <option value="optima">{t("Оптима")}</option>
                    <option value="o_dengi">{t("O! деньги")}</option>
                    <option value="megapay">{t("Мега Pay")}</option>
                    <option value="balance_kg">{t("Balance KG")}</option>
                    <option value="other">{t("Other")}</option>
                </CSelect>    
            </CCol>
            <CCol sm="4" className="float-right d-none">
                <CLabel>{t("Organizations")}</CLabel>
                <CSelect onChange={e => filterChange(e, "organization")} value={params?.organization}>
                    <option value="">{t("All")}</option>
                        {organizations.map(o => 
                            <option value={o.id} key={o.id}>{o.name}</option>
                        )}
                </CSelect>    
            </CCol>
            <CCol sm="3" className="float-right">
                <CLabel>{t("Sender office")} </CLabel>
                <CSelect onChange={e => filterChange(e, "from_office_id")} value={params?.from_office_id}>
                    <option value="">{t("All")}</option>
                        {offices.map(o => 
                            <option value={o.id} key={o.id}>{o.name}</option>
                        )}
                </CSelect>    
            </CCol>
            <CCol sm="3" className="float-right">
                <CLabel>{t("Receiver office")}</CLabel>
                <CSelect onChange={e => filterChange(e, "to_office_id")} value={params?.to_office_id}>
                    <option value="">{t("All")}</option>
                        {offices.map(o => 
                            <option value={o.id} key={o.id}>{o.name}</option>
                        )}
                </CSelect>
            </CCol>
        </CRow>
    )
}