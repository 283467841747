import React, {useCallback, useState} from 'react';
import {CRow, CCol, CInput} from "@coreui/react"
import {useSelector} from 'react-redux'
import { useTranslation } from 'react-i18next';
import { dateFilters } from 'src/helpers/date';
import { useTranslate } from 'src/helpers/useTranslate';
import FilterButton from '../../../components/FilterButton';
import Select from 'react-select';
import Search from 'src/components/Search';

export default function FilterBar({statuses, applyFilters, filterParams}){
    const [selectedStatusList, setSelectedStatusList] = useState([])
    const [selectedCourierList, setSelectedCourierList] = useState([])
    const [selectedAuthorList, setSelectedAuthorList] = useState([])
    const [searchValue, setSearchValue] = useState('')

    const { t } = useTranslation();
    const { tr } = useTranslate();
    
    const couriers = useSelector(state => state.courier.couriers);

    const statusSelectChange = useCallback((list) => {
        setSelectedStatusList(list)
        const newParams = {...filterParams, status: list.map(l => l.value)}
        applyFilters(newParams)
    }, [filterParams, applyFilters])

    const courierSelectChange = useCallback((list) => {
        setSelectedCourierList(list)
        const newParams = {...filterParams, courier_id: list.map(l => l.value)}
        applyFilters(newParams)
    }, [filterParams, applyFilters])

    const authorSelectChange = useCallback((list) => {
        setSelectedAuthorList(list)
        const newParams = {...filterParams, author_id: list.map(l => l.value)}
        applyFilters(newParams)
    }, [filterParams, applyFilters])

    const filterOrdersByDate = useCallback((date_from, date_to, name) => {
        const newParams = {...filterParams, date_from: date_from, date_to: date_to, dateName: name}
        applyFilters(newParams);
    }, [filterParams, applyFilters]);

    const searchSubmit = useCallback((e) => {
        e.preventDefault();
        const newParams = {...filterParams, search: searchValue}
        applyFilters(newParams);
    }, [filterParams, applyFilters, searchValue]);

    const statusListCompose = useCallback((list) => {
        return list.map(l => ({value: l.value, label: tr(l.name_ru, l.name_kg), ...l}))
    }, [tr])
    
    const courierListCompose = useCallback((list) => {
        return list.map(l => ({value: l.id, label: l.firstname, ...l}))
    }, [])

    const authorListCompose = useCallback((list) => {
        return list.map(l => ({value: l.id, label: l.firstname, ...l}))
    }, [])


    return (
        <div className='px-2'>
            <CRow>
                <CCol sm="4">
                    <div style={{display: "inline"}}>
                        <FilterButton
                            onClick={() => filterOrdersByDate(null, null, null)}
                            borderColor={'dark'}
                            isActive={!filterParams.dateName}
                            text={'Все'}
                        />
                        {dateFilters.map((df, i) => 
                            <FilterButton
                                key={'dat' + i}
                                onClick={() => 
                                    filterOrdersByDate(df.date_from.toISOString().slice(0, 10), df.date_to.toISOString().slice(0, 10), df.name)}
                                borderColor={'dark'}
                                isActive={filterParams.dateName === df.name}
                                text={t(df.name)}
                            />
                        )}
                    </div>
                </CCol>
                <CCol sm="4">
                    <CRow>
                        <CCol>
                            <CInput type="date" 
                                onChange={e => filterOrdersByDate(e.target.value, filterParams.date_to)} 
                                value={filterParams?.date_from}
                            />
                        </CCol>
                        <CCol>
                            <CInput type="date" 
                                onChange={e => filterOrdersByDate(filterParams.date_from, e.target.value)} 
                                value={filterParams?.date_to}
                            />
                        </CCol>
                    </CRow>
                </CCol>
                <CCol sm="4">
                    <Search 
                        placeholder={t("Search order...")} 
                        searchSubmit={searchSubmit}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol sm='4'>
                    <Select 
                        isMulti
                        isClearable
                        options={courierListCompose(couriers)}  
                        onChange={res => courierSelectChange(res)} 
                        value={courierListCompose(selectedCourierList)}
                        placeholder={t("Courier")}
                    />
                </CCol>
                <CCol sm='4'>
                    <Select 
                        isMulti
                        isClearable
                        options={authorListCompose([])}  
                        onChange={res => authorSelectChange(res)} 
                        value={authorListCompose(selectedAuthorList)}
                        placeholder={t("Author")}
                    />
                </CCol>
                <CCol sm='4'>
                    <Select 
                        isMulti
                        isClearable
                        options={statusListCompose(statuses)}  
                        onChange={res => statusSelectChange(res)} 
                        value={statusListCompose(selectedStatusList)}
                        placeholder={t("Status")}
                    />
                </CCol>
            </CRow>
        </div>
    )
}

