import { toastify } from "src/helpers/toast";
import { fetchDistrictSearch, fetchSearchedCities } from "src/services/CityService";
import { fetchCreateImg } from "src/services/ServerService";

export const getDataHelper = (fetch, setData) => {
  fetch()
    .then((res) => res.ok && res.json().then((data) => setData(data)))
    .catch((err) => console.log(err));
};

export const promiseCityOptions = (inputValue, tr) => {
  return new Promise((resolve) => {
    fetchSearchedCities(inputValue).then(
      (res) =>
        res.ok &&
        res.json().then((d) => {
          resolve(
            d.map((dd) => {
              return {
                label: tr(dd.name_ru, dd.name_kg),
                value: dd.id,
                ...dd,
              };
            })
          );
        })
    );
  });
};

export const promiseDistrictOptions = (inputValue, tr, city) => {
  let params = inputValue;
  params += city ? "&city_id=" + city.id : "";
  return new Promise((resolve) => {
    params.includes("&city_id=")
      ? fetchDistrictSearch(params).then(
          (res) =>
            res.ok &&
            res.json().then((d) => {
              resolve(
                d.map((dd) => {
                  return {
                    label: tr(dd.name_ru, dd.name_kg),
                    value: dd.id,
                    ...dd,
                  };
                })
              );
            })
        )
      : resolve([]);
  });
};

export const onUploadPhoto = (e, imgName, onSuccess) => {
  let img = e.target.files[0];
  checkSize(img) &&
    imgToString(img)
      .then((res) => {
        if (res.status === 200) {
          onSuccess(res.data, imgName)
          // setInitForm((state) => {
          //   if (imgName === "transport_images") {
          //     return { ...state, [imgName]: [res.data] };
          //   } else {
          //     return { ...state, [imgName]: res.data };
          //   }
          // });
        } else {
          console.log(res.status);
        }
      })
      .catch((err) => console.log(err));
};

const checkSize = (img) => {
  if (img?.size > 1085760) {
    toastify("error", "Слишком большой размер картинки, выберите другую");
    return false;
  }
  return true;
};

const imgToString = (img) => {
  let formData = new FormData();
  formData.append("image", img);
  return fetchCreateImg(formData);
};