import {createApi } from "./ServerService";

export const fetchAllUsers = () => {
    return createApi(`/api/v1/users/company/all/`);
};
export const fetchUsers = (currentPage) => {
    return createApi(`/api/v1/users/?page=${currentPage}`);
};

export const fetchUsersByRole = (role) => {
    return createApi(`/api/v1/users/${role}/`);
};