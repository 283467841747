import order_mark_orange from "src/assets/icons/order_mark_orange.svg";
import order_mark_red from "src/assets/icons/order_mark_red.svg";
import order_mark_gray from "src/assets/icons/order_mark_gray.svg";
import order_mark_blue from "src/assets/icons/order_mark_blue.svg";

export const statusInfo = {
    'created': {
      status: 'created',
      icon: order_mark_red,
      definition: 'Надо забрать'
    },
    'approved': {
      status: 'approved',
      icon: order_mark_red,
      definition: 'Надо забрать'
    },
    'appointed': {
      status: 'appointed',
      icon: order_mark_orange,
      definition: 'Курьер назначен или в пути'
    },
    'on pick-up': {
      status: 'on pick-up',
      icon: order_mark_orange,
      definition: 'Курьер назначен или в пути'
    },
    'arrived at sender': {
      status: 'arrived at sender',
      icon: order_mark_orange,
      definition: 'Курьер назначен или в пути'
    },
    'at destination sorting center': {
      status: 'at destination sorting center',
      icon: order_mark_blue,
      definition: 'Надо доставить'
    },
    'on final courier': {
      status: 'on final courier',
      icon: order_mark_blue,
      definition: 'Надо доставить'
    },
    'arrived at receiver': {
      status: 'arrived at receiver',
      icon: order_mark_blue,
      definition: 'Надо доставить'
    },
    'on wait': {
      status: 'on wait',
      icon: order_mark_gray,
      definition: 'В ожидании'
    },
  }
  
  export const newStatuses = ['created', 'approved'];
  export const onpickupStatuses = ['appointed', 'on pick-up', 'arrived at sender'];
  export const oncourierStatuses = ['at destination sorting center', 'on final courier'];
  export const onwaitStatuses = ['on wait'];