import React, {useCallback, useEffect, useState} from 'react';
import {CCard, CCardBody, CInput,CButton, CRow, CCol} from "@coreui/react"
import OrdersTable from './OrdersTable';
import {useSelector, useDispatch} from 'react-redux'
import { setBreadcrumb } from 'src/redux/actions/settingsActions';
import { fetchCompanyStatuses } from 'src/services/CompanyService';
import { useTranslation } from 'react-i18next';
import MiniSpinner from 'src/components/spinners/MiniSpinner';
import { dateFilters as filters } from 'src/helpers/date';


export default function CurrentOrders(){
    
    const [searchKey, setSearchKey] = useState("")
    const [statuses, setStatuses] = useState([]);
    const [filterStatus, setFilterStatus] = useState(null);
    const [filterDate, setFilterDate] = useState("Today");
    const [filterOffice, setFilterOffice] = useState(null);

    const {t, i18n} = useTranslation();
    
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setBreadcrumb(["Current Orders"]));
        fetchCompanyStatuses().then(res => {
            if(res.ok) {
                res.json().then(data => {
                    let statusList = data.map((d, i) => {
                        if(d.value==="created") {
                            return {...d, percent: 4}
                        } else if(i < data.length - 2) {
                            return {...d, percent: parseInt(i * 13)}
                        } else {
                            return {...d, percent: 100}
                        }
                    })
                    setStatuses(statusList);
                })
            }
        }).catch(err => console.log(err));
    }, [dispatch])

    const orders = useSelector(state => state.order.orders);
    const profile = useSelector(state => state.auth.profile);
    const isLoading = useSelector(state => state.order.isLoading);

    const filterOrders = useCallback((allOrders) => {
        return allOrders
        .filter(o => filterDate ? filters.find(f => f.name === filterDate).rule(o.created_at) : true)
        .filter(o => filterStatus ? o.status === filterStatus : true)
        .filter(o => filterOffice ? (
                        filterOffice === "sent" ? 
                            o.office_id === profile?.delivery_office?.id 
                        : 
                            o.office_id !== profile?.delivery_office?.id) 
                        : true)
        .filter(o => {
            if(o.sender?.fullname?.toUpperCase()?.includes(searchKey.toUpperCase()) 
                || o.sender?.phone?.includes(searchKey.toUpperCase()) 
                || o.sender?.username?.includes(searchKey.toUpperCase())) {
                    return o
            } else if(o.receiver?.fullname?.toUpperCase()?.includes(searchKey.toUpperCase()) 
                || o.receiver?.phone?.includes(searchKey.toUpperCase()) 
                || o.receiver?.username?.includes(searchKey.toUpperCase())) {
                    return o
            } else if(o.location_from?.name_kg?.toUpperCase()?.includes(searchKey.toUpperCase()) 
                || o.location_from?.name_ru?.toUpperCase()?.includes(searchKey.toUpperCase())) {
                return o
            } else if(o.location_to?.name_kg?.toUpperCase()?.includes(searchKey.toUpperCase()) 
                || o.location_to?.name_ru?.toUpperCase()?.includes(searchKey.toUpperCase())) {
                return o
            } else if(o.package_type?.name_kg?.toUpperCase()?.includes(searchKey.toUpperCase()) 
                || o.package_type?.name_ru?.toUpperCase()?.includes(searchKey.toUpperCase())) {
                return o
            } else if(o.tariff?.name?.toUpperCase()?.includes(searchKey.toUpperCase())) {
                return o
            } else if(o.oid?.toUpperCase()?.includes(searchKey.toUpperCase())) {
                return o
            } else if(o.description?.toUpperCase()?.includes(searchKey.toUpperCase())) {
                return o
            } else {
                return false
            }
        })
    }, [searchKey, filterStatus, filterDate, filterOffice, profile])

    return (
        <div>
            <CCard className="m-2">
                <CCardBody className="p-0">
                    <CRow>
                        <CCol>
                            <div className="p-1 m-1" style={{display: "inline"}}>
                                <CButton className="btn-outline-light btn-sm m-1 text-dark" 
                                    onClick={() => setFilterDate(null)} 
                                    style={{backgroundColor: !filterDate && "aqua"}}>Все({orders.length})</CButton>
                                {filters.map((df, i) => 
                                    <CButton key={"day" + i}
                                        className="btn-outline-light btn-sm m-1 text-capitalize text-dark"
                                        onClick={() => setFilterDate(df.name)} style={{border: "Solid 1px dark", backgroundColor: filterDate === df.name && "aqua"}}>
                                            {t(df.name)} ({orders.filter(o => df.rule(o.created_at)).length})
                                    </CButton>
                                )}
                            </div>
                        </CCol>
                        <CCol className="text-right">
                            <div className="p-1 m-1" style={{display: "inline"}}>
                                <CButton className="btn-outline-light btn-sm m-1 text-dark" 
                                    onClick={() => setFilterOffice(null)} 
                                    style={{backgroundColor: !filterDate && "aqua"}}>Все({orders.length})</CButton>
                                <CButton className="btn-outline-light btn-sm m-1 text-dark" 
                                    onClick={() => setFilterOffice("sent")} 
                                    style={{backgroundColor: filterOffice === "sent" && "aqua"}}>
                                        Отправленные({orders.filter(order => order.office_id === profile?.delivery_office?.id).length})</CButton>
                                <CButton className="btn-outline-light btn-sm m-1 text-dark" 
                                    onClick={() => setFilterOffice("received")} 
                                    style={{backgroundColor: filterOffice === "received" && "aqua"}}>
                                        Приходящие({orders.filter(order => order.office_id !== profile?.delivery_office?.id).length})</CButton>
                            </div>
                        </CCol>
                    </CRow>
                    <hr className='my-1'/>
                    <div className="p-1 m-1" style={{display: "inline"}}>
                        <CButton className="btn-outline-light btn-sm m-1 text-dark" 
                        onClick={() => setFilterStatus(null)} 
                        style={{backgroundColor: !filterStatus && "aqua"}}>Все({orders.filter(o => filterDate ? filters.find(f => f.name === filterDate).rule(o.created_at) : true).length})</CButton>
                        
                        {statuses.map((st, i) => 
                        <CButton key={i} 
                            className="btn-outline-light btn-sm m-1 text-capitalize text-dark"
                            onClick={() => setFilterStatus(st.value)} style={{border: "Solid 1px "+ st.color, backgroundColor: filterStatus === st.value && "aqua"}}>
                                {i18n.language === "ru" ? st.name_ru : st.name_kg} ({orders.filter(o => filterDate ? filters.find(f => f.name === filterDate).rule(o.created_at) : true).filter(o => o.status === st.value).length})
                        </CButton>)}
                        <CInput size="sm" placeholder="Search..." style={{width: "250px", display: "inline-flex", marginLeft: "1rem"}} onChange={(e) => setSearchKey(e.target.value)} value={searchKey}/>
                    </div>
                    <div className="mt-1">
                        {isLoading ? 
                            <div className="text-center">
                                <MiniSpinner />
                            </div>
                        :
                            <OrdersTable currentOrders={filterOrders(orders)} statusList={statuses}/> 
                        }
                    </div>

                </CCardBody>
            </CCard>
        </div>
    )
}

