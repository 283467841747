import {SET, SETBREADCRUMB, SET_CONNECTION_STATUS, SET_ATS_CARD, SET_CALLING_CLIENT, SET_SOUND, SET_SEARCH} from "../types/settingsTypes"

const initialState = {
  sidebarShow: 'responsive',
  breadCrumbTitle: [],
  status: "",
  atsCard: false,
  callingClient: null,
  isSoundOn: false,
  searchText: ''
}

export default function settingsReducer(state = initialState, action){
  switch (action.type) {
    case SET:
      return {...state, sidebarShow: action.payload }
    case SETBREADCRUMB:
      return {
        ...state, 
        breadCrumbTitle: action.payload
      }
      case SET_CONNECTION_STATUS:
        return {
          ...state, 
          status: action.payload
        }
      case SET_ATS_CARD:
        return {
          ...state, 
          atsCard: action.payload
        }
      case SET_CALLING_CLIENT:
        return {
          ...state, 
          callingClient: action.payload
        }
      case SET_SOUND:
        return {
          ...state, 
          isSoundOn: action.payload
        }
      case SET_SEARCH:
        return {
          ...state, 
          searchText: action.payload
        }
    default:
      return state
  }
}
