import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCouriers } from "../../redux/actions/courierActions";
import { setBreadcrumb } from "src/redux/actions/settingsActions";
import { useModalState } from "../../helpers/useModal";
import { useTranslation } from "react-i18next";
import { fetchCourier } from "src/services/CourierService";
import { toastify } from "src/helpers/toast";
import { fetchOffices } from "../../services/CompanyService";
import { useHistory } from "react-router-dom";
import { getDataHelper } from "./helpers/helpers";
import { blockRequest, getAll } from "./helpers/api";

export default function useCourierList({ isAll }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [courierList, setCourierList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [offices, setOffices] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const couriers = useSelector((state) => state.courier.couriers);
  const isLoading = useSelector((state) => state.courier.isLoading);
  const reFetchCouriers = async () => dispatch(getCouriers());
  const { isOpen, onToggle } = useModalState();
  const [modalTitle, setModalTitle] = useState("Modal Title");
  const [selectedCourier, setSelectedCourier] = useState({});
  const [modalForm, setModalForm] = useState(null);

  useEffect(() => {
    dispatch(setBreadcrumb([t("All Couriers")]));
    if (!isAll) {
      setCourierList(null);
      dispatch(getCouriers());
    } else {
      setLoading(true);
      const onSuccess = (d) => {
        setCourierList(d);
        setLoading(false);
      };
      getAll(
        onSuccess,
        () => console.log("err"),
        () => console.log("err")
      );
    }
    getDataHelper(fetchOffices, setOffices);
  }, [dispatch, isAll, t]);

  const verifyForm = () => {
    setModalForm("verify");
    setModalTitle(t("Verify Courier"));
    onToggle();
  };

  const blockCourier = (id, block) => {
    const onSuccess = () => {
      toastify("success", t("Operation succeeded"));
      dispatch(getCouriers());
      onToggle();
    };
    const onError = () => {
      toastify("error", "Error occured");
    };
    const onFetchError = () => {
      toastify("error", "Error occured. Check network connecton");
    };

    blockRequest(id, block, onSuccess, onError, onFetchError);
  };

  const selectCourier = (courier) => {
    fetchCourier(courier.id).then((res) => {
      res.ok && res.json().then((d) => setSelectedCourier(d));
    });
  };

  const onOfficeSelect = (office) => setSelectedOffice(office);

  const onStatusSelect = (status) => setSelectedStatus(status);

  const filterList = useCallback(
    (list) => {
      let currentList = list;
      if (selectedOffice) {
        currentList = currentList.filter(
          (c) => c.delivery_office?.name === selectedOffice?.label
        );
      }
      if (selectedStatus) {
        currentList = currentList.filter(
          (c) => c.status === selectedStatus?.label
        );
      }
      return currentList;
    },
    [selectedOffice, selectedStatus]
  );

  const list = isAll ? courierList : couriers;
  const loadingState = isAll ? loading : isLoading;

  const onCreateClick = useCallback(() => {
    history.push("/create-courier");
  }, [history]);

  const onEditClick = useCallback(
    (id) => {
      history.push("/edit-courier/" + id);
    },
    [history]
  );

  return {
    couriers: list,
    onStatusSelect,
    onOfficeSelect,
    selectCourier,
    blockCourier,
    verifyForm,
    modalForm,
    setModalForm,
    onToggle,
    selectedCourier,
    setSelectedCourier,
    modalTitle,
    setModalTitle,
    reFetchCouriers,
    isOpen,
    isLoading: loadingState,
    offices,
    search,
    setSearch,
    filterList,
    onCreateClick,
    onEditClick,
  };
}
