import { createApi } from "./ServerService";
import { DELETE, PATCH, POST } from "./types";

/* Requisite Types start */
export const fetchRequisiteTypes = () => {
  return createApi("/api/v1/requisite_types/");
};

export const fetchSubmitRequisiteType = (body) => {
  return createApi("/api/v1/requisite_types/", {method: POST, body: body});
};

export const fetchUpdateRequisiteType = (body, id) => {
  return createApi("/api/v1/requisite_types/" + id + "/", {method: PATCH, body: body});
};

export const fetchDeleteRequisiteType = (id) => {
  return createApi("/api/v1/requisite_types/" + id + "/", {method: DELETE});
};
/* Requisite Types end */

/* Client Requisites start */
export const fetchClientRequisites = (id) => {
  return createApi(`/api/v1/clients/${id}/requisites/`);
};

export const fetchSubmitClientRequisite = (body) => {
  return createApi("/api/v1/requisites/", {method: POST, body: body});
};

export const fetchUpdateClientRequisite = (body, id) => {
  return createApi("/api/v1/requisites/" + id + "/", {method: PATCH, body: body});
};

export const fetchDeleteClientRequisite = (id) => {
  return createApi("/api/v1/requisites/" + id + "/", {method: DELETE});
};
/* Client Requisites end */
