import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";


export default function ImageGallery({ photos, role }) {
    const {t} = useTranslation();

    const [selectedPhoto, setSelectedPhoto] = useState(photos[0]);

    return (
        <CCard>
            <CCardBody>
                {photos.length > 0 ? 
                <>
                    <h6><b>{t(role)}</b></h6>
                    <div className="text-center my-2">
                        <img alt="signature" className="img-thumbnail" src={selectedPhoto || photos[0]}/>
                    </div>
                    <div>
                        <CRow>
                            {photos.map(photo =>
                                <CCol sm="2">
                                    <img alt="ph" src={photo} style={{maxWidth: "100%", opacity: (selectedPhoto === photo) ? "" : "0.5"}}
                                        onClick={() => setSelectedPhoto(photo)}
                                    />
                                </CCol>
                            )}
                        </CRow>
                    </div>
                </> 
                : 
                <div>Нет фотографий</div>}
            </CCardBody>
        </CCard>
    )
}