import { createApi } from './ServerService';
import { DELETE, PATCH, POST } from './types';


// /api/v1/tariffs/
export const fetchTariffs = () => {
    return createApi("/api/v1/tariffs/");
}
export const fetchCreateTariff = (tariffForm) => {
    return createApi("/api/v1/tariffs/", {method: POST, body: tariffForm});
}

// /api/v1/tariffs/{tariff_id}
export const fetchTariff = (id) => {
    return createApi("/api/v1/tariffs/" + id + "/");
}

export const fetchDeleteTariff = (id) => {
    return createApi("/api/v1/tariffs/" + id + "/", {method: DELETE});
}
export const fetchUpdateTariff = (tariffForm, id) => {
    return createApi("/api/v1/tariffs/" + id + "/", {method: PATCH, body: tariffForm});
}