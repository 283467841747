export const paymentList = [
    {
        value: "cash",
        label: "Cash"
    },
    {
        value: "bank invoice",
        label: "Банковский перевод"
    },
    {
        value: "elsom",
        label: "Элсом"
    },
    {
        value: "mbank",
        label: "Мбанк"
    },
    {
        value: "optima",
        label: "Оптима"
    },
    {
        value: "o_dengi",
        label: "O! деньги"
    },
    {
        value: "megapay",
        label: "Мега Pay"
    },
    {
        value: "balance_kg",
        label: "Balance KG"
    },
    {
        value: "other",
        label: "Other"
    },
]