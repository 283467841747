export function authHeader(accessToken) {
    // return authorization header with jwt token
    if (accessToken) {
        return { 'Authorization': 'Bearer ' + accessToken };
    } else {
        return {};
    }
}

export function authHeaderPost(accessToken) {
    // return authorization header with jwt token
    if (accessToken) {
        return { 
            'Content-Type':'application/json', 
            'Authorization': 'Bearer ' + accessToken 
        };
    } else {
        return {};
    }
}