import React, {useEffect, useState} from 'react';
import {CButton, CCard, CCardBody, CCardHeader, CCol, CPagination, CRow, CSelect} from "@coreui/react";
import {setBreadcrumb} from "../../redux/actions/settingsActions";
import CIcon from "@coreui/icons-react";
import {useTranslation} from "react-i18next";
import {fetchUsers} from "../../services/UsersService";
import FullSpinner from "../../components/spinners/FullSpinner";
import {date} from "../../helpers/date";
import { useDispatch } from 'react-redux';
import {fetchCompanies} from "../../services/CompanyService";

const Users = () => {
    const { t } = useTranslation();
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [companies, setCompanies] = useState([])
    const dispatch = useDispatch()

    const [selectedCompanyName, setSelectedCompanyName] = useState(null)

    const roles = ["Super_Admin", "Client", "Admin", "Operator", "Courier", "Center_Staff", "Content_Editor", "Partner", "Accountant"];


    const filterByCompanyName = (e) => {
        const name = e.target.value
        setSelectedCompanyName(name)
    }



    useEffect(() => {

        dispatch(setBreadcrumb(["Users"]))
        fetchUsers(currentPage)
            .then((res) => {
                console.log(res, 'res')
                if (res.ok){
                    res.json().then((data) =>{
                        setUsers(data)
                        setTotalPages(Math.ceil(data.length/50))
                        console.log(data, 'all users')
                        setIsLoading(false)
                    })
                }
            })
            .catch(err => {
                console.log(err, 'error fetch')
                setIsLoading(false)
            })

        fetchCompanies()
            .then((res) => {
                if(res.ok){
                    res.json().then((data) => {
                        setCompanies(data)

                    })
                }
            })
            .catch((err) => console.log(err,"#1 error"))

    }, [currentPage, dispatch])


    if(isLoading) return <FullSpinner/>
    return (
        <CCard className="m-2">
            <CCardHeader>
                <CRow>
                    <CCol sm="2">
                        <CSelect>
                            <option value="">{t("All")}</option>
                            {roles.map((role, i) => (
                                <option value={role} key={i}>{role}</option>
                            ))}
                        </CSelect>
                    </CCol>
                    <CCol sm="2">
                        <CSelect  onChange={(e) => filterByCompanyName(e)} value={selectedCompanyName?.name}>
                            <option value="">{t("All")}</option>
                            {companies && companies.map((company) => {
                                return <option value={company.name} key={company.id}>{company.name}</option>
                            })}
                        </CSelect>
                    </CCol>

                </CRow>

                <CButton
                    variant="outline"
                    color="success"
                    className="float-right"
                >
                    {t("Create new user")}
                </CButton>
            </CCardHeader>
            <CCardBody>
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th>{t("Firstname")}</th>
                        <th>{t("Lastname")}</th>
                        <th>{t("Phone number")}</th>
                        <th>{t("Delivery Service")}</th>
                        <th>{t("Balance")}</th>
                        <th>{t("Last Activity")}</th>
                        <th>{t("Active/Blocked")}</th>
                        <th>{t("Actions")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        users &&
                        (selectedCompanyName ?
                            users.filter(u => u.courier_profile?.delivery_service?.name === selectedCompanyName) : users)
                            .map((user) => (
                            <tr key={user.id}>
                                <td>{user.firstname}</td>
                                <td>{user.lastname}</td>
                                <td>{user.username}</td>
                                <td>{user.courier_profile?.delivery_service?.name}</td>
                                <td>{user.courier_profile?.balance}</td>
                                <td>
                                    {date(user.courier_profile?.last_updated)}
                                </td>
                                {/*<td>{user.courier_profile?.last_updated}</td>*/}
                                <td>{user.courier_profile?.is_blocked ? t('Blocked') : t("Active")}</td>
                                <td>
                                    <CIcon
                                        name="cilTrash"
                                        className="text-danger mr-2"
                                        size="xl"
                                        style={{ cursor: "pointer" }}
                                    />
                                    <CIcon
                                        name="cilPencil"
                                        className="text-warning"
                                        size="xl"
                                        style={{ cursor: "pointer" }}
                                    />
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
                <CPagination
                    activePage={currentPage}
                    pages={totalPages}
                    onActivePageChange={(i) => setCurrentPage(i)}
                />
            </CCardBody>
        </CCard>
            );
};

export default Users;