import React from 'react';
import AsyncCreatableSelect from "react-select/async-creatable/dist/react-select.esm";
import {fetchCompanyAddressesSearch} from "../services/CompanyService";
import {useTranslation} from "react-i18next";
import {useTranslate} from "../helpers/useTranslate";

const AddressSearchInput = ({value, onChange, isClearable=true, selectedCity, selectedDistrict}) => {
    const {t} = useTranslation();
    const {tr} = useTranslate();


    const promiseAddressOptions = (inputValue) => {
        let params = inputValue
        if(selectedCity){
            params = inputValue + "&city_id=" + selectedCity.id
            if(selectedDistrict){
                params = params + "&district_id=" + selectedDistrict.id
            }
        }

        return new Promise(resolve => {
            fetchCompanyAddressesSearch(params).then(res => res.ok && res.json().then(d => {
                resolve(d.map(dd => {return {
                    label: tr(dd.name_ru, dd.name_kg) +
                        (dd.city ? ", " + tr(dd.city.name_ru, dd.city.name_kg) : "") +
                        (dd.district ? ", " + tr(dd.district.name_ru, dd.district.name_kg) : "") ,
                    value: dd.id,
                    ...dd}}))
            }))
        });
    }


    return (

            <AsyncCreatableSelect
                className="mt-2"
                loadOptions={promiseAddressOptions}
                formatCreateLabel={res => t("Create") + " " + res}
                isClearable={isClearable}
                placeholder={t("Address")}
                onChange={onChange}
                value={value}
            />

    );
};

export default AddressSearchInput;