import React, {useCallback, useEffect, useState} from 'react';
import {CCard, CCardBody, CSelect} from "@coreui/react"
import OrdersTable from './OrdersTable';
import {useSelector, useDispatch} from 'react-redux'
import { setBreadcrumb } from 'src/redux/actions/settingsActions';
import { fetchCompanyStatuses } from 'src/services/CompanyService';
import { useTranslation } from 'react-i18next';
import MiniSpinner from 'src/components/spinners/MiniSpinner';
import { getOrders } from 'src/redux/actions/orderActions';
import Pagination from 'src/components/Pagination';
import CIcon from '@coreui/icons-react';
import FilterBar from './FilterBar';
// import { useBottomScrollListener } from 'react-bottom-scroll-listener';

const initalParams = {
    status: [],
    date_from: undefined,
    date_to: undefined,
    search: '',
    size: 100,
    page: 1,
}
export default function Current(){
    
    const [showFilterBar, setShowFilterBar] = useState(true);
    const [statuses, setStatuses] = useState([]);
    const [filterParams, setFilterParams] = useState(initalParams)

    const { t } = useTranslation();
    
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setBreadcrumb(["Current Orders"]));
        fetchCompanyStatuses().then(res => {
            if(res.ok) {
                res.json().then(data => {
                    let statusList = data.map((d, i) => {
                        if(d.value==="created") {
                            return {...d, percent: 5}
                        } else if(i < data.length - 2) {
                            return {...d, percent: parseInt(i * 10)}
                        } else {
                            return {...d, percent: 100}
                        }
                    })
                    setStatuses(statusList);
                })
            }
        }).catch(err => console.log(err));
    }, [dispatch])

    const orders = useSelector(state => state.order.orders);
    const isLoading = useSelector(state => state.order.isLoading);

    const applyFilters = useCallback((params) => {
        dispatch(getOrders(params))
        setFilterParams(params)
    }, [dispatch])

    // const onBottomGained = useCallback(() => {
    //     const newParams = {...filterParams, size: filterParams.size * 2}
    //     applyFilters(newParams);
    // }, [applyFilters, filterParams])

    // useBottomScrollListener(() => console.log("IS bottom?"));

    const onNext = useCallback(() => {
        applyFilters({...filterParams, page: filterParams.page + 1});
    }, [applyFilters, filterParams])

    const onPrev = useCallback(() => {
        applyFilters({...filterParams, page: filterParams.page - 1});
    }, [applyFilters, filterParams])

    return (
        <div>
            <CCard className="m-2">
                <CCardBody className="pt-1 px-0">
                    <div className="px-2 my-1 d-flex justify-content-end">
                        <div className='sp-link bg-secondary rounded px-1' onClick={() => setShowFilterBar(!showFilterBar)}>
                            {t((showFilterBar ? "Hide" : "Show") + " filters")} 
                            <CIcon name={showFilterBar ? "cilX" : "cilListFilter"}/> 
                        </div>
                    </div>
                    <div className={showFilterBar ? '' : 'd-none'}>
                        <FilterBar 
                            statuses={statuses} 
                            applyFilters={applyFilters} 
                            filterParams={filterParams}
                        />
                    </div>
                    <div className="mt-2">
                        {isLoading ? 
                            <div className="text-center">
                                <MiniSpinner />
                            </div>
                        :
                            <div>
                                <div className='my-1 mx-2 d-flex justify-content-between'>
                                    <div>
                                        {t("Orders amount")}: {orders?.total}
                                    {/* . На странице: {orders.items?.length} */}
                                    </div>
                                    <div>
                                        <CSelect size="sm" value={filterParams.size}
                                            onChange={e => applyFilters({
                                                    ...filterParams, 
                                                    size: parseInt(e.target.value)
                                                })}>
                                            <option>50</option>
                                            <option>100</option>
                                            <option>200</option>
                                            <option>300</option>
                                        </CSelect>
                                    </div>
                                </div>
                                <OrdersTable currentOrders={orders?.items || []} statusList={statuses}/>
                                <Pagination 
                                    currentPage={filterParams.page}
                                    nextPage={onNext}
                                    prevPage={onPrev}
                                    limit={filterParams.size}
                                    currentListLength={orders?.items?.length}
                                    isLastPage={(orders?.total / (orders?.page * filterParams.size)) <= 1}/>
                            </div>
                        }
                    </div>

                </CCardBody>
            </CCard>
        </div>
    )
}

