import React, {useEffect, useState} from 'react';
import {CButton, CInput, CLabel, CModal, CModalHeader, CRow} from "@coreui/react";
import MiniSpinner from "../../components/spinners/MiniSpinner";
import {Map, Placemark, YMaps} from "react-yandex-maps";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import { fetchCreateRegion, fetchUpdateRegion} from "../../services/CityService";
import {toastify} from "../../helpers/toast";
import { getAllRegions} from "../../redux/actions/locationActions";

const apikey = "14b7fac2-2096-4a85-9aa1-ed9ac48250ff";

const RegionForm = ({
   toggle,
   modal,
   modalTitle,
   selectedRegion,
}) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [btnState, setBtnState] = useState(false);
    const [nameKg, setNameKg] = useState("");
    const [nameRu, setNameRu] = useState("");
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const [center, setCenter] = useState([42.876054043715826, 74.60139339774767]);
    const [zoom, setZoom] = useState(8);
// regions

    const submitRegion = () => {
        const regionForm = {
            name_kg: nameKg,
            name_ru: nameRu,
            lat,
            lng,
            map_zoom: 11,
        };
        console.log(regionForm, 'region');
        fetchCreateRegion(regionForm)
            .then((res) => {
              if (res.ok) {
                  onSuccess()
              } else {
                toastify("error", t("Operation failed"));
                setBtnState(false);
              }
            })
            .catch((err) => {
              toastify("error", t("Operation failed"));
              setBtnState(false);
            });
    };

    const updateRegion = () => {
        const region = {
            name_kg: nameKg,
            name_ru: nameRu,
            lat,
            lng,
            map_zoom: 10,
        };

        fetchUpdateRegion(region, selectedRegion.id)
            .then((res) => {
                if (res.ok) {
                    onSuccess()
                } else {
                    toastify("error", t("Operation failed"));
                    setBtnState(false);
                }
            })
            .catch((err) => {
                toastify("error", t("Operation failed"));
                setBtnState(false);
            });
    };

    const onSuccess = () => {
        toastify("success", t("Operation succeeded"));
        setBtnState(false);
        toggle();
        setNameKg("");
        setNameRu("");
        setLat(null);
        setLng(null);
        dispatch(getAllRegions());
    }

    const onClickMap = (e) => {
        const [lat, lng] = e.get("coords");
        setLat(lat);
        setLng(lng);

    };



    useEffect(() => {
        setCenter(
            selectedRegion.lat && selectedRegion.lng
                ? [selectedRegion.lat, selectedRegion.lng]
                : [42.876054043715826, 74.60139339774767]
        );

        setZoom(selectedRegion.lat && selectedRegion.lng ? 7 : 8);

        setNameKg(selectedRegion?.name_kg || "");
        setNameRu(selectedRegion?.name_ru || "");
        setLat(selectedRegion.lat || "");
        setLng(selectedRegion.lng || "");

    }, [selectedRegion, i18n.language]);


    return (
        <CModal show={modal} onClose={toggle} size="xl">
            <CModalHeader closeButton>{modalTitle}</CModalHeader>
            <div className="d-flex justify-content-around">
                <div style={{ width: "20%"}}>
                    <CLabel>{t("Name on kg")}</CLabel>
                    <CInput
                        type="text"
                        placeholder={t("Name on kg")}
                        name="nameKg"
                        value={nameKg}
                        onChange={(e) => setNameKg(e.target.value)}
                    />
                </div>
                <div style={{ width: "20%"}}>
                    <CLabel>{t("Name on ru")}</CLabel>
                    <CInput
                        type="text"
                        placeholder={t("Name on ru")}
                        name="nameRu"
                        value={nameRu}
                        onChange={(e) => setNameRu(e.target.value)}
                    />
                </div>
                <div style={{ width: "20%"}}>
                    <CLabel>{t("Latitude")}</CLabel>
                    <CInput
                        type="text"
                        placeholder={t("Latitude")}
                        name="lat"
                        value={lat}
                        onChange={(e) => setLat(e.target.value)}
                    />
                </div>
                <div style={{ width: "20%" }}>
                    <CLabel>{t("Longitude")}</CLabel>
                    <CInput
                        type="text"
                        placeholder={t("Longitude")}
                        name="lng"
                        value={lng}
                        onChange={(e) => setLng(e.target.value)}
                    />
                </div>

                {selectedRegion.id ? (
                    <CButton
                        type="submit"
                        variant="outline"
                        color="success"
                        className="my-3"
                        disabled={btnState}
                        onClick={() => {
                            setBtnState(true);
                            updateRegion();

                        }}
                    >
                        {btnState ? <MiniSpinner /> : t("Update")}
                    </CButton>
                ) : (
                    <CButton
                        type="submit"
                        className="my-3"
                        variant="outline"
                        color="success"
                        disabled={btnState}
                        onClick={() => {
                            setBtnState(true);
                                submitRegion();
                        }}
                    >
                        {btnState ? <MiniSpinner /> : t("Create")}
                    </CButton>
                )}

                <CButton
                    className="my-3"
                    variant="outline"
                    color="danger"
                    onClick={() => {
                        setBtnState(false);
                        toggle();
                    }}
                >
                    {t("Cancel")}
                </CButton>
            </div>
            <CRow className="mx-1 px-1">
                <YMaps query={{ load: "package.full", apikey }}>
                    <Map
                        onClick={(e) => {
                            console.log(e.get("zoom"))
                            onClickMap(e)
                        }}
                        width="100%"
                        height="80vh"
                        state={{
                            center: center,
                            zoom: zoom,
                            controls: [],
                        }}
                    >
                        <Placemark
                            geometry={[lat, lng]}
                            options={{
                                preset: "islands#redDotIcons",
                                iconColor: "red",
                            }}
                        />
                    </Map>
                </YMaps>
            </CRow>
        </CModal>
    );
};

export default RegionForm;