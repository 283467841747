import {
    CForm,
    CButton,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CLabel,
    CInput,
    CSelect
  } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toastify } from "src/helpers/toast";
import { fetchCreatePartnerOperator, fetchUpdatePartnerOperator } from "src/services/PartnerService";

export default function PartnerOperatorForm(props) {
   
    const { t } = useTranslation();

    const [partnerOperatorForm, setPartnerOperatorForm] = useState(
        {
            "username": "",
            "firstname": "",
            "lastname": "",
            "middlename": "",
            "partner_id": "",
            "password": "",
          }
    );

    useEffect(() => {
        if(props.selectedPartnerOperator) {
            setPartnerOperatorForm({...props.selectedPartnerOperator, 
                "partner_id": props.selectedPartnerOperator.partner?.id
            })
        }
    }, [props.selectedPartnerOperator])

    const handleChange = (e) => {
        setPartnerOperatorForm({...partnerOperatorForm, [e.target.name]: e.target.value})
    }

    const submitForm = () => {
        console.log(partnerOperatorForm);
        let formData = partnerOperatorForm;
        if(formData.partner_id === "None") {
            formData.partner_id = null
        }
        if(props.selectedPartnerOperator) {
            fetchUpdatePartnerOperator(props.selectedPartnerOperator.id, partnerOperatorForm).then(res => {
                if(res.ok) {
                    props.onSuccess();
                } else {
                    toastify("error", "Operation failed")
                }
            })
        } else {
            fetchCreatePartnerOperator(partnerOperatorForm).then(res => {
                if(res.ok) {
                    props.onSuccess();
                } else {
                    toastify("error", "Operation failed")
                }
            })
        }
    }
  
    return (
        <CModal show={props.isOpen} onClose={props.onToggle} size="lg">
          <CModalHeader closeButton>{props.modalTitle}</CModalHeader>
          <CModalBody>
            <CForm>
                <CLabel>{t("Username")}</CLabel>
                <CInput value={partnerOperatorForm.username} onChange={e => handleChange(e)} name="username" placeholder="username"/>
                <CLabel>{t("Firstname")}</CLabel>
                <CInput value={partnerOperatorForm.firstname} onChange={e => handleChange(e)} name="firstname" placeholder="firstname"/>
                <CLabel>{t("Lastname")}</CLabel>
                <CInput value={partnerOperatorForm.lastname} onChange={e => handleChange(e)} name="lastname" placeholder="lastname"/>
                <CLabel>{t("Middlename")}</CLabel>
                <CInput value={partnerOperatorForm.middlename} onChange={e => handleChange(e)} name="middlename" placeholder="middlename"/>
                <CLabel>{t("Password")}</CLabel>
                <CInput value={partnerOperatorForm.password} onChange={e => handleChange(e)} name="password" placeholder="password"/>
                <CLabel>{t("Parnter")}</CLabel>
                <CSelect value={partnerOperatorForm.partner_id} onChange={e => handleChange(e)} name="partner_id">
                    {props?.partners?.map((p, i) => 
                        <option key={i} value={p.id}>{p.name}</option>
                    )}
                </CSelect>
            </CForm>
          </CModalBody>
          <CModalFooter>
            <CButton
                color="success"
                onClick={() => submitForm()}
                className="mr-2"
            >
                {!props.selectedPartnerOperator ? t("Create") : t("Update")} 
            </CButton>
          </CModalFooter>
        </CModal>
    );
  }
  