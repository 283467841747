import React, { useState } from 'react';
import {CModal, CModalHeader, CModalBody, CModalFooter, CForm, CFormGroup, CInput, CRow, CButton, CLabel} from "@coreui/react"
import { useTranslation } from 'react-i18next';
import { fetchCourierPassword } from 'src/services/CourierService';
import { toastify } from 'src/helpers/toast';
import { useDispatch } from 'react-redux';
import { getCouriers } from 'src/redux/actions/courierActions';

export default function CourierPassword (props) {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [password, setPassword] = useState("");

    const submitChangePassword = () => {
        if(parseInt(password) > 0 && password.length === 6) {
            fetchCourierPassword(props.selectedCourier.id, {password}).then(res => {
                if(res.ok) {
                    console.log(res);
                    toastify("success", t("Courier password changed successfully"));
                    dispatch(getCouriers());
                    props.onToggle();
                    setPassword("")
                } else {
                    console.log("error");
                    toastify("error", t("Courier password change failed"));
                }
            }).catch(err => {
              console.log(err);
              toastify("error", t("Courier password change failed"));
            })
        } else {
            toastify("error", t("Password must be 6 digits"))
        }
    }

    return(
        <CModal show={props.isOpen} onClose={props.onToggle}>
          <CModalHeader closeButton>
              {/* {t(props.modalTitle)}<br /> */}
            <h5>
                {t("Change password of ")} {props.selectedCourier?.firstname}
            </h5>
          </CModalHeader>
          <CModalBody>
            {props.selectedCourier && (
              <CForm>

                <CFormGroup>
                    <CLabel>{t("New password") + " " + t("(only digits and 6 symbols)")}</CLabel>
                    <CInput placeholder="_ _ _ _ _ _" type="number" value={password} onChange={(e) => setPassword(e.target.value)}/>
                </CFormGroup>
              </CForm>
            )}
          </CModalBody>
          <CModalFooter>
            {props.selectedCourier && (
              <CRow>
                <CButton
                  color="info"
                  onClick={() => submitChangePassword()}
                  className="mr-2"
                >
                  {t("Update")}
                </CButton>
              </CRow>
            )}
          </CModalFooter>
        </CModal>
    )
}