import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { YMaps, Map, Placemark } from "react-yandex-maps";
import { CButton, CCard, CCardBody, CModal, CModalBody, CModalFooter, CModalHeader } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { useModalState } from "src/helpers/useModal";
import man_default from "src/assets/icons/man_default.svg";
import { date } from "src/helpers/date";
import Select from 'react-select';
import { ordersFetchForMap, updateOrderFetch } from "src/services/OrderService";
import { toastify } from "src/helpers/toast";
import { useTranslate } from "src/helpers/useTranslate";
import { statusInfo } from "./Dict";
import FilterBar from "./FilterBar";

export default function OrdersAndCouriersMap() {

  const map = useRef(null);

  const {t} = useTranslation()
  const {tr} = useTranslate()
  const [open, setOpen] = useState()

  const {isOpen, onOpen, onToggle} = useModalState()
  
  const [orders, setOrders] = useState([]);
  const [couriers, setCouriers] = useState([]);
  useEffect(() => {
    ordersFetchForMap().then(res => {
      res.ok && res.json().then(d => {
        setOrders(d.orders)
        setCouriers(d.couriers)
      })
    }).catch(err => console.log(err))
  }, [])

  const [filters, setFilters] = useState({
    newOrders: true,
    onpickup: true,
    oncourier: true,
    onwait: true,
    courierShow: true
  })
  const [filteredStatuses, setFilteredStatuses] = useState(Object.keys(statusInfo))

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [newSelectedCourier, setNewSelectedCourier] = useState(null)

  const changeCourier = () => {
    if(newSelectedCourier?.id) {
      updateOrderFetch(selectedOrder.id, {courier_id: newSelectedCourier.id}).then(res => {
        if(res.ok) {
          toastify("success", "Operation succeded")
          onToggle()
          setSelectedOrder(null)
          setNewSelectedCourier(null)
        } else {
          toastify("error", "Operation failed")
          res.json(d => {
            console.log(d)
          })
        }
      }).catch(err => {
        toastify("error", "Operation failed")
        console.log(err)
      })
    }
  }

  const closeModal = () => {
    setNewSelectedCourier(null); 
    onToggle();
  }

  const Button = (props) => (
    <div>
      <div className="text-left">
        <b>{t("Order")}: {props.selectedItem?.oid}</b><br />
        {props.selectedItem?.courier && <div>{t("Courier")}: {props.selectedItem?.courier?.user?.firstname}</div>}
      </div>
      <div className="text-center">
      <button onClick={() => {onOpen(); setSelectedOrder(props.selectedItem)}} className="btn btn-primary btn-sm">
        {t("Change courier")}
        </button>
      </div>
    </div>
  );

  const BaloonContent = (props) => {
    const portalRoot = document.querySelector(".portal");
    const portalContainer = document.createElement("div");
    useEffect(() => {
      portalRoot.appendChild(portalContainer);
    }, [portalContainer, portalRoot]);

    return ReactDOM.createPortal(
      <Button selectedItem={props.selectedItem}/>,
      portalContainer
    );
  };

  return (
    <>
    <CCard className="mb-0">
      <CCardBody>
        <FilterBar 
          setFilters={setFilters}
          setFilteredStatuses={setFilteredStatuses}
          filters={filters}
          filteredStatuses={filteredStatuses}
        />
      <YMaps>
        <Map
            instanceRef={map}
            width="100%"
            height="90vh"
            defaultState={{ 
                center: [42.88, 74.58], 
                zoom: 12
            }}
        >

          {filters.courierShow && couriers.map((c,i) => <Placemark key={i} properties={{
                            hintContent: c.firstname,
                            balloonContent: `<div>${t("Courier")}: ${c.firstname}<br/>Статус: ${c.status}<br/>${date(c.last_updated)}</div>`
                        }}
                        geometry={[c.lat, c.lng]}
                        options={{  iconLayout: 'default#image', 
                        iconImageHref: man_default }}
                        modules = {
                            ['geoObject.addon.balloon', 'geoObject.addon.hint']
                        }/>)}
          
          {orders?.filter(o => filteredStatuses.includes(o.status))
            .map((o, i) => (
              <Placemark
                properties={{
                  hintContent: o.oid,
                  balloonContent: `<div class="portal" style="height: 80px; width: 200px"></div>`
                }}
                onBalloonOpen={() => {
                  setOpen(true)
                  setSelectedItem(o)
                }}
                onBalloonClose={() => setOpen(false)}
                geometry={[o.location_from?.lat, o.location_from?.lng]}
                options={{iconLayout: 'default#image', iconImageHref: statusInfo[o.status]?.icon
            }}
                modules = {
                  ['geoObject.addon.balloon', 'geoObject.addon.hint']
                }
                key={i}
              />
          ))}
        </Map>
      </YMaps>
      {open && (
        <BaloonContent selectedItem={selectedItem}/>
      )}
      </CCardBody>
    </CCard>

    {selectedOrder && 
      <CModal show={isOpen} onClose={closeModal} closeOnBackdrop={false}>
        <CModalHeader closeButton>
            {t("Select new courier")}
        </CModalHeader>
        <CModalBody>
          <Select className="my-2" 
            isClearable
            placeholder={t("Courier")}
            options={couriers.sort((a, b) => a.firstname.localeCompare(b.firstname)).map(c => ({ 
                    value: c.id,
                    label: c.city ? `${c.firstname} (${tr(c.city.name_ru, c.city.name_kg)})` : c.firstname, 
                    ...c}
                    ))}  
            onChange={res => setNewSelectedCourier(res)} 
            value={newSelectedCourier || 
              (selectedOrder?.courier && 
                { value: selectedOrder.courier.id,label: selectedOrder.courier.user.firstname, ...selectedOrder.courier}
              )}/>
        </CModalBody>
        <CModalFooter>
            <CButton className="btn btn-outline-success float-right" onClick={changeCourier}>
              {t("Save")}
            </CButton>
            <CButton className="btn btn-outline-danger float-right" onClick={closeModal}>
              {t("Cancel")}
            </CButton>
        </CModalFooter>
      </CModal>
    }
    </>
  );
}
