import CIcon from '@coreui/icons-react'
import { CButton, CCard, CCardBody, CCol, CInput, CLabel, CRow, CSelect, CTextarea } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { toastify } from 'src/helpers/toast'
import { setBreadcrumb } from 'src/redux/actions/settingsActions'
import { fetchPayment, fetchPaymentUpdate } from 'src/services/ReportsService'
import { fetchCreateImg } from 'src/services/ServerService'
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { fetchClientsSearh } from 'src/services/ClientService'
import { fetchAllUsers } from 'src/services/UsersService'

export default function UpdatePayment() {

    const {t} = useTranslation()
    const dispatch = useDispatch()
    const params = useParams()
    const history = useHistory()
    const [payment, setPayment] = useState({})
    const [users, setUsers] = useState([])
    const [btnDisabled, setBtnDisabled] = useState(false)

    useEffect(() => {
        dispatch(setBreadcrumb(["Payment edit"]))
        fetchPayment(params.id).then(res => {
            res.ok && res.json().then(d => {
                setPayment(d)
                console.log(d)
            })
        }).catch(err => console.log(err))
        
        fetchAllUsers().then(res => {
            res.ok && res.json().then(d => {
                console.log("u", d)
                setUsers(d)
            })
        }).catch(err => console.log(err))
    }, [dispatch, params])

    const submitUpdate = () => {
        fetchPaymentUpdate(payment?.id, payment).then(res => {
            if(res.ok) {
                history.push("/redemption-payments")
                toastify("success", t("Operation succeeded"))
            } else {
                toastify("error", t("Operation failed"))
            }
        })
    }

    const uploadImage = (e) => {
        let formData = new FormData();
        setBtnDisabled(true)
        formData.append("image", e.target.files[0]);
        fetchCreateImg(formData).then(data => {
            setBtnDisabled(false)
            setPayment(payment => {
                return {...payment, image: data.data}
            })
        }).catch(err => {
            console.log(err)
            toastify("error", "Image upload failed")
            setBtnDisabled(false)
        })
    }

    const handleChange = (e) => {
        setPayment({...payment, [e.target.name]: e.target.value})
    }

    const promiseClientOptions = (inputValue) => {
        return new Promise((resolve) => {
          fetchClientsSearh(inputValue).then(
            (res) =>
              res.ok &&
              res.json().then((d) => {
                resolve(
                  d.map((cl) => {
                    return {
                      value: cl.id,
                      label: cl.fullname,
                      ...cl,
                    };
                  })
                );
              })
          );
        });
      };
      
    return <div>
        <CCard style={{maxWidth: "500px"}} className="mx-auto">
            <CCardBody>
                <CRow>
                    <CCol xs="6">
                       <div>{t("Sum")}</div>
                    </CCol>
                    <CCol xs="6" className="mb-2">
                       <CInput placeholder='2500' value={payment.amount || ""} onChange={handleChange} name="amount"/>
                    </CCol>
                    <CCol xs="6">
                       <div>{t("Redemption type")}</div>
                    </CCol>
                    <CCol xs="6" className="mb-2">
                       <CSelect value={payment.type}  onChange={handleChange} name="type">
                           <option value="redemption">{t("From cash register")}</option>
                           <option value="imposed">{t("Imposed")}</option>
                       </CSelect>
                    </CCol>
                    <CCol xs="6">
                       <div>{t("Payment method")}</div>
                    </CCol>
                    <CCol xs="6" className="mb-2">
                       <CSelect value={payment.method}  onChange={handleChange} name="method">
                            <option value="cash">{t("Cash")}</option>
                            <option value="invoice">{t("Банковский перевод")}</option>
                            <option value="elsom">{t("Элсом")}</option>
                            <option value="mbank">{t("Мбанк")}</option>
                            <option value="optima">{t("Оптима")}</option>
                            <option value="o_dengi">{t("O! деньги")}</option>
                            <option value="megapay">{t("Мега Pay")}</option>
                            <option value="balance_kg">{t("Balance KG")}</option>
                            <option value="other">{t("Other")}</option>
                       </CSelect>
                    </CCol>
                    <CCol xs="6">
                       <div>{t("Payment status")}</div>
                    </CCol>
                    <CCol xs="6" className="mb-2">
                       <CSelect value={payment.is_paid ? "paid" : "not_paid"} 
                            onChange={e => setPayment({...payment, is_paid: e.target.value==="paid" ? true : false})}>
                           <option value="paid">{t("Paid")}</option>
                           <option value="not_paid">{t("Not paid")}</option>
                       </CSelect>
                    </CCol>
                    <CCol xs="6">
                       <CLabel>{t("Payer")}</CLabel>
                        {payment.ordering === 2 ? 
                            <AsyncSelect
                                loadOptions={promiseClientOptions}
                                isClearable
                                placeholder={t("Payer")}
                                value={payment.client ? {label: payment.client.fullname, value: payment.client.id, ...payment.client} : null}
                                onChange={(res) => {
                                setPayment({...payment, client_id: res?.id, client: res?.id ? res : null})
                                }}
                            /> : 
                            <Select
                                options={users.map(u => {return {label: u.firstname, value: u.id, ...u}})}
                                isClearable
                                placeholder={t("Pay Receiver")}
                                value={payment.payer ? {label: payment.payer.firstname, value: payment.payer.id, ...payment.payer} : null}
                                onChange={(res) => {
                                    setPayment({...payment, payer_id: res?.id, payer: res?.id ? res : null})
                                }}
                            />
                        }
                    </CCol>
                    <CCol xs="6" className="mb-2">
                       <CLabel>{t("Pay receiver")}</CLabel>
                       {payment.ordering === 1 ? 
                            <AsyncSelect
                                loadOptions={promiseClientOptions}
                                isClearable
                                placeholder={t("Pay Receiver")}
                                value={payment.client ? {label: payment.client.fullname, value: payment.client.id, ...payment.client} : null}
                                onChange={(res) => {
                                    setPayment({...payment, client_id: res?.id, client: res?.id ? res : null})
                                }}
                            /> : 
                            <Select
                                options={users.map(u => {return {label: u.firstname, value: u.id, ...u}})}
                                isClearable
                                placeholder={t("Pay Receiver")}
                                value={payment.receiver ? {label: payment.receiver.firstname, value: payment.receiver.id, ...payment.receiver} : null}
                                onChange={(res) => {
                                    setPayment({...payment, receiver_id: res?.id, receiver: res?.id ? res : null})
                                }}
                            />
                        }
                    </CCol>
                    <CCol xs="12" className="mb-2">
                       <CLabel>{t("Notes")}</CLabel>
                        <CTextarea placeholder='кошумча маалымат' value={payment.comment || ""}  onChange={handleChange} name="comment"/>
                    </CCol>
                    <CCol xs="6">
                        <CLabel>Чек</CLabel>
                    </CCol>
                    <CCol xs="6" className="mb-2">
                        <CInput type='file' onChange={uploadImage}/>
                    </CCol>
                    <CCol xs="6">
                        <CLabel>{t("Preview")}</CLabel>
                    </CCol>
                    <CCol xs="6" className="mb-2">
                        <img src={payment.image} 
                            alt="check" style={{maxWidth: "75%"}}/>
                    </CCol>
                    <CCol xs="6">
                    </CCol>
                    <CCol xs="6" className="mb-2">
                        <CButton className="btn-block" variant="outline" color="dark" disabled={btnDisabled} onClick={submitUpdate}>
                            <CIcon name="cilCheck"/>
                            {t("Save")}
                        </CButton>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    </div>
}