import React, {useCallback, useEffect, useState} from 'react';
import {CCard, CCardBody,
    CCardHeader,
    CRow,
    CCol} from "@coreui/react"
import {useDispatch, useSelector} from 'react-redux'
import { setBreadcrumb } from 'src/redux/actions/settingsActions';
import { useTranslation } from 'react-i18next';
import MiniSpinner from 'src/components/spinners/MiniSpinner';
import ExportAct from './../ExportAct';
import { fetchRoutes } from 'src/services/ServerService';
import { date } from 'src/helpers/date';
import CIcon from '@coreui/icons-react';
import { useTranslate } from 'src/helpers/useTranslate';
import { useHistory } from 'react-router-dom';
import { toastify } from 'src/helpers/toast';
import { ordersFetchForRevision } from 'src/services/OrderService';
import Select from 'react-select'
import { fetchRegions } from 'src/services/CityService';
import { dateFilters as filters } from 'src/helpers/date';
import FilterButton from 'src/components/FilterButton';
import Pagination from 'src/components/Pagination';

const notExistingRegion = {
    id: "emptyRegion",
    name_kg: "Жеткирүү региону жок",
    name_ru: "Нет региона назначения",
}
const limit = 20;
export default function RegionalOrders(){

    const {t} = useTranslation();
    const {tr} = useTranslate()
    const history = useHistory()
    const dispatch = useDispatch();

    const couriers = useSelector(state => state.courier.couriers)

    const [orders, setOrders] = useState([])
    const [routes, setRoutes] = useState([])
    const [regions, setRegions] = useState([])
    const [loading, setLoading] = useState(false)
    // const [filterByRoutes, setFilterByRoutes] = useState([])
    const [filterParams, setFilterParams] = useState({
        dateFrom: null,
        dateTo: null,
        size: limit,
        page: 1,
        courier_id: null,
        region_id: null,
        route_id: null
    })

    const fetchReq = useCallback((params) => {
        ordersFetchForRevision(params).then(res => 
            res.ok ? res.json().then(d => {
                setOrders(d)
                console.log(d)
                setLoading(false)
            }) : setLoading(false))
            .catch(err => setLoading(false))
        
    }, [])

    useEffect(() => {
        setLoading(true)
        fetchReq({});
        fetchRoutes().then(res => res.ok && res.json().then(d => setRoutes(d)))
        fetchRegions().then(res => res.ok && res.json().then(d => setRegions([...d, {...notExistingRegion}])))
        dispatch(setBreadcrumb(["Regional Orders"]));
    }, [dispatch, fetchReq])

    const onFilterChanged = useCallback((params) => {
        setFilterParams(params);
        fetchReq(params)
    }, [fetchReq])

    const filterRegion = useCallback((id) => {
        const newParams = {...filterParams, region_id: id}
        onFilterChanged(newParams)
    }, [filterParams, onFilterChanged])

    // const filterRoute = useCallback((id) => {
    //     const newParams = {...filterParams, route_id: id}
    //     onFilterChanged(newParams)
    // }, [filterParams, onFilterChanged])

    const filterCourier = useCallback((id) => {
        const newParams = {...filterParams, courier_id: id}
        onFilterChanged(newParams)
    }, [filterParams, onFilterChanged])

    const filterOrdersByDate = useCallback((dateFrom, dateTo, name) => {
        const newParams = {...filterParams, dateFrom: dateFrom, dateTo: dateTo, dateName: name}
        onFilterChanged(newParams);
    }, [filterParams, onFilterChanged]);

    const onNext = () => {
        const newParams ={...filterParams, page: filterParams.page + 1}
        onFilterChanged(newParams);
    }

    const onPrev = () => {
        const newParams ={...filterParams, page: filterParams.page - 1}
        onFilterChanged(newParams);
    }

    const setLabel = (obj) => {
        return obj && `${tr(obj.name_ru, obj.name_kg)}, `
    }

    return (
        <div>
            <CCard className="m-2">
                <CCardHeader>
                    <CRow>
                        <CCol sm="6" className="m-0 px-1">
                        <div style={{display: "inline"}}>
                            <FilterButton
                                onClick={() => filterOrdersByDate(null, null, null)}
                                borderColor={'dark'}
                                isActive={!filterParams.dateName}
                                text={'Все'}
                            />
                            {filters.map((df, i) => 
                                <FilterButton
                                    key={'dat' + i}
                                    onClick={() => filterOrdersByDate(df.date_from, df.date_to, df.name)}
                                    borderColor={'dark'}
                                    isActive={filterParams.dateName === df.name}
                                    text={t(df.name)}
                                />
                            )}
                        </div>
                        </CCol>
                        <CCol sm="4">
                            <Select 
                                isClearable
                                placeholder={t("Courier")}
                                options={couriers.map(c => {return {label: c.firstname, value: c.id, ...c}})}
                                onChange={res => filterCourier(res.id)}
                                />
                        </CCol>
                        <CCol sm="2">
                            <div className='float-right'>
                                <ExportAct orders={orders?.items || []} 
                                    regions={regions} filterByRegions={filterParams.region_id} 
                                    routes={routes} filterByRoutes={[]} 
                                    filterByCourier={filterParams.courier_id}/>
                            </div>
                        </CCol>
                    </CRow>
                </CCardHeader>
                <CCardBody className="p-0">
                    <div className="p-1 m-1" style={{display: "inline"}}>
                            <FilterButton
                                onClick={() => filterRegion(null)}
                                borderColor={'dark'}
                                isActive={filterParams.region_id === null}
                                text={'Все'}
                            />
                        {regions.map((r, i) => 
                            <FilterButton
                                key={i}
                                onClick={() => filterRegion(r.id)}
                                borderColor={'dark'}
                                isActive={filterParams.region_id === r.id}
                                text={tr(r.name_ru, r.name_kg)}
                            /> 
                            // <CButton key={i} 
                            //     className="btn-outline-light btn-sm m-1 text-capitalize text-dark"
                            //     onClick={() => 
                            //             filterRegion(r.id)
                            //             // setFilterByRegions((filters) => 
                            //             // filters.includes(r.id) ? [...filters].filter(f => f !== r.id) : [...filters, r.id])
                            //         }                                    
                            //         style={{border: "Solid 1px "+ r.color, backgroundColor: filterByRegions.includes(r.id) && "aqua"}}>
                            //             {tr(r.name_ru, r.name_kg)} 
                            //             ({filterOrders(orders?.items || []).filter(o => o.route?.to_node?.region_id ? 
                            //                     r.id === o.route?.to_node?.region_id : r.id==="emptyRegion").length})
                            // </CButton>
                            )}
                    </div>
                    <hr className='my-1'/>
                    {/* <div className="p-1 m-1" style={{display: "inline"}}>
                        <FilterButton
                            onClick={() => filterRoute(null)}
                            borderColor={'dark'}
                            isActive={!filterParams.route_id}
                            text={'Все'}
                        />
                        {routes
                            .filter(r => filterByRegions.includes(r.to_node?.region_id) || filterByRegions.length === 0).map((r, i) => 
                            filterOrders(orders?.items || [])
                            .filter(o => o.route?.id === r.id).length > 0 
                        && 
                            <CButton key={i} 
                                className="btn-outline-light btn-sm m-1 text-capitalize text-dark"
                                onClick={() => setFilterByRoutes((filters) => 
                                        filters.includes(r.id) ? [...filters].filter(f => f !== r.id) : [...filters, r.id])} 
                                    
                                    style={{border: "Solid 1px "+ r.color, backgroundColor: filterByRoutes.includes(r.id) && "aqua"}}>
                                        {tr(r.name_ru, r.name_kg)} 
                                        ({filterOrders(orders?.items || []).filter(o => o.route?.id === r.id).length})
                            </CButton>)}
                    </div> */}
                    <div className="mt-1">
                        {loading ? 
                            <div className="text-center">
                                <MiniSpinner />
                            </div>
                        : <div>
                            <table className="table table-responsive-lg">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{t("Sender")}</th>
                                        <th>{t("Receiver")}</th>
                                        <th>{t("Courier")}</th>
                                        <th>{t("Status")}</th>
                                        <th>{t("Date")}</th>
                                        <th>{t("Tariff")}</th>
                                        <th>{t("Redemption")}</th>
                                        <th>{t("Price")}</th>
                                        <th>{t("Actions")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orders?.items?.map(o => 
                                        <tr key={o.id}>
                                            <td>{o.oid}</td>
                                            <td>
                                                {o.sender?.phone}
                                                <br/>
                                                {setLabel(o.city_from || o.location_from?.city)}
                                                {o.location_from?.name_kg}
                                            </td>
                                            <td>
                                                {o.receiver?.phone}
                                                <br/>
                                                {setLabel(o.city_to || o.location_to?.city)}
                                                {o.location_to?.name_kg}
                                            </td>
                                            <td>{o.courier?.user?.firstname}<br/></td>
                                            <td>{t(o.status)}</td>
                                            <td>{date(o.created_at)}</td>
                                            <td>{o.tariff?.name}</td>
                                            <td>{o.redemption_amount}</td>
                                            <td>{o.final_price}</td>
                                            <td>
                                                <CIcon name="cilCopy" color="success" className='mx-1' size="lg" 
                                                    style={{cursor: "pointer"}}
                                                onClick={() => {
                                                    let order = o
                                                    if (order){
                                                        const orderId = order.id
                                                        const sender = order.sender
                                                        const locationFrom = order.location_from
                                                        const locationFromFlat = order.location_from_flat
                                                        const locationFromFloor = order.location_from_floor
                                                        const redemptionAmount = order.redemption_amount
                                                        const receiver = order.receiver
                                                        const locationTo = order.location_to
                                                        const locationToFlat = order.location_to_flat
                                                        const locationToFloor = order.location_to_floor
                                                        const paying_side = 
                                                            order.paying_side === "receiver" ? "Получатель" : 
                                                            order.paying_side === "sender" ? "Отправитель" : "Вместе"
                                                        const finalPrice = order.final_price
                                                        const packageType = tr(order.package_type?.name_ru,order.package_type?.name_ru)
                                                        const description = order.description

                                                        navigator.clipboard.writeText(`*Заказ №${orderId}* \nОтправитель: ${sender?.fullname}, ${sender?.phone},\n🅰️Откуда: ${locationFrom?.city && (tr(locationFrom?.city?.name_ru, locationFrom.city?.name_kg) +",")} ${locationFrom?.district ? tr(locationFrom?.district?.name_ru, locationFrom?.district?.name_kg) + ", " : ""}${tr(locationFrom?.name_ru, locationFrom?.name_kg)} ${locationFromFlat ? "\nКвартира: " + locationFromFlat : ""} ${locationFromFloor ? " \nЭтаж: " + locationFromFloor : ""} \n${order.package_type ? "\n📦Тип посылки: " + packageType : ""} \nВыкуп: *${redemptionAmount} ⊆* \n\nПолучатель: ${(receiver ? receiver?.fullname  + ',' + receiver?.phone : "нужно уточнить, ")} \n🅱️Куда: ${locationTo ? (tr(locationTo?.city?.name_ru, locationTo?.city?.name_kg) + ", " + (locationTo?.district ?
                                                            tr(locationTo?.district?.name_ru, locationTo?.district?.name_kg) + ", " : "") + tr(locationTo?.name_ru, locationTo?.name_kg)) : "нужно уточнить"}  \n${locationToFlat ? "Квартира: " + locationToFlat : ""} ${locationToFloor ? " Этаж: " + locationToFloor : ""} \n\nОплачивает: ${paying_side} \n💰 За доставку: *${finalPrice} ⊆*  \n\nКомментарий : ${description ? description : ""}
                                                        `)
                                                        toastify("info", "Copied to cipboard")
                                                    }
                                                }}/>
                                                <CIcon name="cilPencil" color="success" className='mx-1' size="lg" onClick={() => {
                                                    history.push("/update-order/" + o.id)
                                                }}/>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <Pagination 
                                currentPage={filterParams.page}
                                nextPage={onNext}
                                prevPage={onPrev}
                                limit={limit}
                                currentListLength={orders?.items?.length}
                                isLastPage={(orders?.total / (orders?.page * limit)) <= 1}/>
                        </div>
                        }
                    </div>

                </CCardBody>
            </CCard>
        </div>
    )
}

