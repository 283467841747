import {
  CITIES_SUCCESS,
  CITIES_FAIL,
  CITIES_LOADING,
  DISTRICTS_SUCCESS,
  DISTRICTS_FAIL,
  DISTRICTS_LOADING,
  REGIONS_SUCCESS,
  REGIONS_FAIL,
  REGIONS_LOADING,
  LOCATIONS_LOADING,
  LOCATIONS_SUCCESS,
  LOCATIONS_FAIL,
} from "../types/locationTypes";

const initialState = {
  cities: [],
  districts: [],
  regions: [],
  locations: [],
  errors: null,
  isCitiesLoading: true,
  isDistrictsLoading: true,
  isRegionsLoading: true,
  isLocationsLoading: true,
};

export default function locationsReducer(state = initialState, action) {
  switch (action.type) {
    case CITIES_SUCCESS:
      return {
        ...state,
        cities: action.cities,
        isCitiesLoading: false,
        error: null,
      };
    case CITIES_FAIL:
      return {
        ...state,
        isCitiesLoading: false,
        error: action.payload || null,
      };
    case CITIES_LOADING:
      return {
        ...state,
        isCitiesLoading: true,
        error: null,
      };

    case DISTRICTS_SUCCESS:
      return {
        ...state,
        districts: action.districts,
        isDistrictsLoading: false,
        error: null,
      };
    case DISTRICTS_FAIL:
      return {
        ...state,
        isDistrictsLoading: false,
        error: action.payload || null,
      };
    case DISTRICTS_LOADING:
      return {
        ...state,
        isDistrictsLoading: true,
        error: null,
      };
    case REGIONS_SUCCESS:
      return {
        ...state,
        regions: action.regions,
        isRegionsLoading: false,
        error: null,
      };
    case REGIONS_FAIL:
      return {
        ...state,
        isRegionsLoading: false,
        error: action.payload || null,
      };
    case REGIONS_LOADING:
      return {
        ...state,
        isRegionsLoading: true,
        error: null,
      };
    case LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.locations,
        isLocationsLoading: false,
        error: null,
      };
    case LOCATIONS_FAIL:
      return {
        ...state,
        isLocationsLoading: false,
        error: action.payload || null,
      };
    case LOCATIONS_LOADING:
      return {
        ...state,
        isLocationsLoading: true,
        error: null,
      };
    default:
      return {
        ...state,
      };
  }
}
