import React, { lazy } from "react";

import CurrentOrders from "./orders/CurrentOrders";
import Current from "./orders/Current2/Current";
import CurrentOrdersForPartners from "./partnerPanel/CurrentOrdersForPartners";
import CompletedOrders from "./orders/OrdersArchive";
import Tariff from "./tariffs/Tariffs";
import Clients from "./clients/Clients";
import { CompanyAddress } from "./company/CompanyAddresses";
import CourierCompanyAddresses from "./company/CourierCompanyAddresses";
import { CompanyPackageTypes } from "./company/CompanyPackageTypes";
import Operators from "./operators/Operators";
import Cities from "./cities/Cities";
import Transports from "./transports/Transports";
import Extra from "./extra/Extra";
import CompanyStatuses from "./company/CompanyStatuses";
import Routes from "./routes/Routes";
import Organizations from "./organizations/Organizations";
import Polygons from "./polygons/Polygons";
import NewAddressAdd from "./newAddresses/NewAddressAdd";
import CourierCompanies from "./courier-services/CourierCompanies";
import NewCompanyAddress from "./company/NewCompanyAddress";
import Reports from "./reports/Reports";
import CourierPayments from "./reports/CourierPayments";
import CitiesDistrictsOnMap from "./cities/CitiesDistrictsOnMap";
import AddressesOnMap from "./cities/AddressesOnMap";
import {Addresses} from "./newAddresses/Addresses";
import { OrderForm } from "src/pages/orders/New-Order/OrderForm";
import { GroupOrders } from "src/pages/orders/GroupOrders";
import Schedules from "./Schedules/Schedules";
import OrdersAndCouriersMap from "./orders/Map/OrdersAndCouriersMap";
import CompanyNotificationSettings from "./company/CompanyNotificationSettings";
import Partners from "./partners/Partners";
import Users from "./users/Users";
import RedemptionPayments from "./reports/redemption-payments/RedemptionPayments";
import AllCompaniesOrders from "../module-superadmin/AllCompaniesOrders";
import Offices from "./offices/Offices";
import RegionalOrders from "./orders/Order-List/RegionalOrders";
import Carriers from "./carriers/Carriers";
import CourierDetails from "./couriers/details-page/CourierDetails";
import Graphics from "./reports/Graphics";
import { OrderFormForPartner } from "src/module-partner/OrderFormForPartner";
import ReportsForPartners from "src/module-partner/ReportsForParnters";
import Nodes from "./routes/Nodes";
import UpdatePayment from "./reports/redemption-payments/UpdatePayment";
import SearchResult from "./orders/Current2/SearchResult";
import ActOrders from "./orders/ActOrders/ActOrders";
import Archive from "./orders/Archive/Archive";
import CouriersContainer from "./couriers/CouriersContainer";
import CourierFormPage from "./couriers/form-page/CourierFormPage";

const MainPage = lazy(() => import("./MainPage"));

export const routelist = [
    { component: <OrderForm />, path: "/new-order-alt", exact: true },
    { component: <MainPage />, path: "/", exact: true },
    { component: <OrderForm />, path: "/new-order" },
    { component: <OrderFormForPartner />, path: "/new-order-partner" },
    { component: <GroupOrders />, path: "/group-order" },
    { component: <CurrentOrders />, path: "/current" },
    { component: <Current />, path: "/current-orders" },
    { component: <SearchResult />, path: "/search-orders/" },
    { component: <RegionalOrders />, path: "/regional-orders" },
    { component: <ActOrders />, path: "/act-orders" },
    { component: <CurrentOrdersForPartners />, path: "/current-orders-partners" },
    { component: <OrdersAndCouriersMap />, path: "/couriers-map" },
    { component: <OrdersAndCouriersMap />, path: "/orders-map" },
    { component: <Schedules />, path: "/courier-shifts" },
    { component: <Archive />, path: "/orders-archive", exact: true },
    // { component: <CompletedOrders />, path: "/orders-archive", exact: true },
    { component: <CompletedOrders />, path: "/orders-archive/:p", exact: true },
    { component: <OrderForm />, path: "/update-order/:orderId" },
    { component: <OrderForm confirm />, path: "/confirm-order/:orderId" },
    { component: <Tariff />, path: "/tariffs" },
    { component: <Clients />, path: "/clients" },
    { component: <Organizations />, path: "/organizations" },
    { component: <CompanyAddress />, exact: true, path: "/company-addresses" },
    { component: <CompanyAddress />, path: "/company-addresses/:p" },
    { component: <Addresses />, exact: true, path: "/addresses-base" },
    { component: <Addresses />, path: "/addresses-base/:p" },
    {
      component: <CourierCompanyAddresses />,
      path: "/courier-company-addresses",
    },
    { component: <CompanyPackageTypes />, path: "/company-package-types" },
    { component: <Operators />, path: "/operators" },
    { component: <Users />, path: "/users" },
    { component: <Cities />, path: "/cities" },
    { component: <CitiesDistrictsOnMap />, path: "/cities-districts-on-map" },
    { component: <AddressesOnMap />, path: "/addresses-on-map" },
    { component: <Transports />, path: "/transports" },
    { component: <Extra />, path: "/extra" },
    { component: <CompanyStatuses />, path: "/company-statuses" },
    {
      component: <CompanyNotificationSettings />,
      path: "/company-sms-settings",
    },
    { component: <Routes />, path: "/routes" },
    { component: <Nodes />, path: "/nodes" },
    { component: <Polygons />, path: "/polygons" },
    { component: <NewAddressAdd />, path: "/newaddress" },
    { component: <CourierCompanies />, path: "/courier-companies" },
    { component: <Offices />, path: "/offices" },
    { component: <Partners />, path: "/partners" },
    { component: <NewCompanyAddress />, path: "/newcompanyaddress" },
    { component: <NewCompanyAddress />, path: "/address/edit/:id" },
    { component: <Reports />, path: "/reports" },
    { component: <ReportsForPartners />, path: "/reports-for-partners" },
    { component: <Graphics />, path: "/graphics" },
    { component: <CourierPayments />, path: "/courier-payments" },
    { component: <UpdatePayment />, path: "/redemption-payments/edit/:id" },
    { component: <RedemptionPayments />, path: "/redemption-payments" },

    { component: <AllCompaniesOrders />, path: "/all-orders", exact: true },
    { component: <AllCompaniesOrders />, path: "/all-orders/:p", exact: true },

    { component: <Carriers />, path: "/carriers" },
    { component: <CourierDetails />, path: "/courier-details/:id" },

    { component: <CouriersContainer all={false} />, path: "/couriers" },
    { component: <CouriersContainer all={true} />, path: "/all-couriers" },
    { component: <CourierFormPage />, path: "/create-courier" },
    { component: <CourierFormPage />, path: "/edit-courier/:id" },
  ]
