import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { YMaps, Map, Polygon, Placemark } from "react-yandex-maps";
import man_online from "src/assets/icons/man_online.svg";

const apikey = "14b7fac2-2096-4a85-9aa1-ed9ac48250ff";

export function FormMap({
  orderData,
  setPointSender,
  setPointReceiver,
  updateOrderData,
  handleSenderAddress,
  handleReceiverAddress,
  courierList,
  sidebarShow,
  setMapApi,
  pointSender,
  pointReceiver,
  center,
  zoom,
  allFees,
  open,
  setOpen,
  cCourier,
  setCCourier,
  map
}) {
  const { t } = useTranslation();

  const Button = (props) => (
    <div className="text-center">
      <img
        alt="profile avatar"
        style={{
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          objectFit: "cover",
        }}
        src="https://www.pngfind.com/pngs/m/676-6764065_default-profile-picture-transparent-hd-png-download.png"
      />
      {props.cCourier?.firstname}
      <br />
      {props.cCourier?.status}
      <button
        onClick={() => {
          updateOrderData("selectedCourier", {
            value: props.cCourier?.id,
            label: props.cCourier?.firstname,
          });
        }}
        className="btn btn-primary btn-sm"
      >
        {t("Assign")}
      </button>
    </div>
  );

  const BaloonContent = (props) => {
    const portalRoot = document.querySelector(".portal");
    const portalContainer = document.createElement("div");
    useEffect(() => {
      portalRoot.appendChild(portalContainer);
    }, [portalContainer, portalRoot]);

    return ReactDOM.createPortal(
      <Button cCourier={props.cCourier} />,
      portalContainer
    );
  };

  return (
    <>
      {/* map component */}
      <YMaps className={!sidebarShow && "w-100vw"} query={{ apikey }}>
        <Map
          onLoad={(ymaps) => {
            setMapApi(ymaps);
          }}
          onClick={(e) => {
            if (pointSender) {
              handleSenderAddress({
                ...orderData.selectedAddressSender,
                lat: e.get("coords")[0],
                lng: e.get("coords")[1],
              });
              setPointSender(false);
            }
            if (pointReceiver) {
              handleReceiverAddress({
                ...orderData.selectedAddressReceiver,
                lat: e.get("coords")[0],
                lng: e.get("coords")[1],
              });
              setPointReceiver(false);
            }
          }}
          instanceRef={map}
          modules={["multiRouter.MultiRoute"]}
          width="100%"
          height="70vh"
          state={{ center: center, zoom: zoom }}
          defaultState={{ center: center, zoom: zoom }}
        >
          {orderData.selectedAddressSender && !orderData.currentRoute && (
            <Placemark
              onDragEnd={(res) => {
                let coords = res.originalEvent.target.geometry._coordinates;
                !orderData.selectedAddressSender.id &&
                  handleSenderAddress({
                    ...orderData.selectedAddressSender,
                    lat: coords[0],
                    lng: coords[1],
                  });
              }}
              geometry={[
                orderData.selectedAddressSender.lat,
                orderData.selectedAddressSender.lng,
              ]}
              options={{
                iconColor: "red",
                draggable: !orderData.selectedAddressSender.id && true,
              }}
            />
          )}
          {orderData.selectedAddressReceiver && !orderData.currentRoute && (
            <Placemark
              onDragEnd={(res) => {
                let coords = res.originalEvent.target.geometry._coordinates;
                !orderData.selectedAddressSender.id &&
                  handleReceiverAddress({
                    ...orderData.selectedAddressReceiver,
                    lat: coords[0],
                    lng: coords[1],
                  });
              }}
              geometry={[
                orderData.selectedAddressReceiver.lat,
                orderData.selectedAddressReceiver.lng,
              ]}
              options={{
                iconColor: "SteelBlue",
                draggable: !orderData.selectedAddressSender?.id && true,
              }}
            />
          )}

          {courierList.map((c, i) => (
            <Placemark
              properties={{
                hintContent: c.firstname,
                balloonContent: `<div class="portal" style="height: 100px;"></div>`,
              }}
              onBalloonOpen={() => {
                setOpen(true);
                setCCourier(c);
              }}
              onBalloonClose={() => setOpen(false)}
              geometry={[c.lat, c.lng]}
              options={{
                iconLayout: "default#image",
                iconImageHref: c.transport?.photo || man_online,
              }}
              modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
              key={i}
            />
          ))}
          <Polygon
            geometry={allFees}
            options={{ visible: false, opacity: 1 }}
          />
        </Map>
      </YMaps>
      {open && <BaloonContent cCourier={cCourier} />}
    </>
  );
}
