import {
  CCard,
  CCardBody,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
} from "@coreui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import OrdersTab from "./OrdersTab";
import ProfileTab from "./ProfileTab";
import useDetailsPage from "./useDetailsPage";

export default function CourierDetails() {
  const { t } = useTranslation();

  const {
    activeTab,
    setActiveTab,
    courier,
    orders,
    handleOrderSelect,
    selectAll,
  } = useDetailsPage();

  return (
    <CCard>
      <CCardBody>
        <h4>
          {t("Courier")}: {courier?.firstname}
        </h4>
        <CTabs activeTab={activeTab}>
          <CNav variant="tabs">
            <CNavItem>
              <CNavLink
                data-tab="profile"
                onClick={() => setActiveTab("profile")}
              >
                {t("Профиль")}
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                data-tab="orders"
                onClick={() => setActiveTab("orders")}
              >
                {t("Current Orders")}
              </CNavLink>
            </CNavItem>
            {/* <CNavItem>
              <CNavLink
                data-tab="history"
                onClick={() => setActiveTab("history")}
              >
                {t("History")}
              </CNavLink>
            </CNavItem> */}
          </CNav>

          <CTabContent>
            <CTabPane data-tab="orders">
              <OrdersTab
                orders={orders}
                selectAll={selectAll}
                handleOrderSelect={handleOrderSelect}
              />
            </CTabPane>
            <CTabPane data-tab="profile">
              <ProfileTab courier={courier}/>
            </CTabPane>
            {/* <CTabPane data-tab="history">History (Coming soon...)</CTabPane> */}
          </CTabContent>
        </CTabs>
      </CCardBody>
    </CCard>
  );
}
