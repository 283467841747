import CIcon from "@coreui/icons-react";
import { CForm, CInput, CInputGroup, CInputGroupAppend, CInputGroupText } from "@coreui/react";

export default function Search ({searchSubmit, setSearchValue, searchValue, placeholder}) {
    return (
        <CForm onSubmit={searchSubmit}>
            <CInputGroup>
              <CInput placeholder={placeholder} 
                onChange={e => setSearchValue(e.target.value)}
                value={searchValue}  
              />
              <CInputGroupAppend>
                <CInputGroupText className="bg-primary" onClick={searchSubmit}>
                  <CIcon name="cilSearch" style={{color: "white"}}/>
                </CInputGroupText>
              </CInputGroupAppend>
            </CInputGroup>
          </CForm>
    )
}