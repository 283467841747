import React from 'react';
import union from "src/assets/union.svg"
import calling from "src/assets/calling.svg"
import organizations from "src/assets/Organizations.svg"
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { newOrderData } from 'src/redux/actions/orderActions';
import { useTranslate } from 'src/helpers/useTranslate';
import { date } from 'src/helpers/date';
import CIcon from '@coreui/icons-react';
import { useTranslation } from 'react-i18next';
import { setAtsCard } from 'src/redux/actions/settingsActions';

export default function ATSCard({client}) {
    const history = useHistory()
    const dispatch = useDispatch()
    const {tr} = useTranslate()
    const {t} = useTranslation()

    const goToOrderForm = () => {
        let selectedClient = client?.client_data ? {label: client.phone, value: client.client_data.id, ...client.client_data} : {label: client.phone}
        client && dispatch(newOrderData({selectedClientSender: selectedClient, senderFullname: selectedClient.fullname}))
        history.push("/new-order")
        dispatch(setAtsCard(false))
    }
    return (
        <table className="w-100 table table-bordered text-center ats-table mb-0">
            <tbody>
                <tr>
                    <td style={{backgroundColor: "#3C4B64", width: "5%"}}>
                        <img alt="call" src={calling} className="m-1"/>
                    </td>
                    <td colSpan="2" style={{width: "65%"}}>{client?.phone || "Phone number"}</td>
                    <td rowSpan="2" style={{width: "30%", cursor: "pointer"}} className="bg-success pt-3" onClick={goToOrderForm}>
                        Оформить новый заказ
                    </td>
                </tr>
                <tr>
                    <td style={{backgroundColor: "#3C4B64", width: "5%"}}>
                        {client?.client_data?.organization ? <img alt="user" src={organizations} className="m-1"/> : <img alt="user" src={union} className="m-1"/>}
                    </td>
                    <td colSpan="2">{client?.client_data?.fullname || "Unknown client"}</td>
                </tr>
                    <tr>
                        <td rowSpan="2" style={{backgroundColor: "#3C4B64", width: "5%", color: "white"}}>
                            № {client?.order_data?.id}
                        </td>
                        <td colSpan="3">{t("From where")} - {t("To where")}</td>
                    </tr>
                    <tr className="font-weight-bold">
                        <td colSpan="3">{tr(client.order_data?.location_from?.name_ru, client.order_data?.location_from?.name_kg)} 
                        - {tr(client.order_data?.location_from?.name_ru, client.order_data?.location_from?.name_kg)} </td>
                    </tr>
                    <tr>
                        <td style={{cursor: "pointer"}} className="bg-danger pt-3" rowSpan="2" onClick={() => dispatch(setAtsCard(false))}>
                            <CIcon name="cilX"/>
                        </td>
                        <td>Статус</td>
                        <td>Дата</td>
                        <td>Цена</td>
                    </tr>
                    <tr className="font-weight-bold">
                        <td>{client.order_data?.status}</td>
                        <td>{date(client.order_data?.created_at)}</td>
                        <td>{client.order_data?.final_price}c</td>
                    </tr>
            </tbody>
        </table>
    )
}