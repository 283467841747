import { authHeader } from "../helpers/authHeader";
import store from "../redux/store";
import { createApi, SERVER_URL } from "./ServerService";
import { DELETE, PATCH, POST } from "./types";

// /api/v1/clients/
export const fetchClients = () => {
  return createApi("/api/v1/clients/");
};

export const fetchClientsByType = (type) => {
  if (type === 1) {
    return fetch(SERVER_URL + "/api/v1/clients/?in_organization=" + true, {
      method: "get",
      headers: new Headers(authHeader(store.getState().auth.accessToken)),
    });
  } else if (type === 2) {
    return fetch(SERVER_URL + "/api/v1/clients/?in_organization=" + false, {
      method: "get",
      headers: new Headers(authHeader(store.getState().auth.accessToken)),
    });
  } else {
    return fetch(SERVER_URL + "/api/v1/clients/", {
      method: "get",
      headers: new Headers(authHeader(store.getState().auth.accessToken)),
    });
  }
};

export const fetchClientsSearh = (key) => {
  let sKey = key;
  if (parseInt(key) > 0) {
    sKey.toString().split(" ").join("");
  }
  return createApi(`/api/v1/clients/?search=${key}`)
};

export const fetchCreateClient = (clientForm) => {
  return createApi("/api/v1/clients/", {method: POST, body: clientForm})
};

export const fetchClient = (id) => {
  return createApi("/api/v1/clients/" + id + "/")
};

export const fetchUpdateClient = (clientForm, id) => {
  console.log(clientForm);
  return createApi("/api/v1/clients/" + id + "/", {method: PATCH, body: clientForm})
};

export const fetchDeleteClient = (id) => {
  return createApi("/api/v1/clients/" + id + "/", {method: DELETE})
};

export const fetchAddClientAddress = (id, address) => {
  return createApi("/api/v1/clients/" + id + "/address/", {method: POST, body: address})
};

export const fetchUpdateClientAddress = (id, address) => {
  return createApi("/api/v1/clients/" + id + "/address/", {method: PATCH, body: address})
};

export const fetchDeleteClientAddress = (clientId, addressId) => {
  return createApi("/api/v1/clients/" + clientId + "/address/" + addressId + "/", {method: DELETE})
};

export const fetchClientOrders = (id, type) => {
  return createApi("/api/v1/clients/" + id + "/orders/?client_type=" + type)
};

export const fetchClientOrganizations = () => {
  return createApi("/api/v1/organizations/")
};

export const fetchClientOrganizationDetails = (id) => {
  return createApi("/api/v1/organizations/" + id + "/")
};

export const fetchCreateOrganization = (organizationForm) => {
  return createApi("/api/v1/organizations/", {method: POST, body: organizationForm})
};

export const fetchUpdateOrganization = (organizationForm, id) => {
  return createApi("/api/v1/organizations/" + id + "/", {method: PATCH, body: organizationForm})
};

export const fetchDeleteOrganization = (id) => {
  return createApi("/api/v1/organizations/" + id + "/", {method: DELETE})
};

export const fetchClientLastOrders = (id) => {
  return createApi("/api/v1/clients/" + id + "/orders/")
};
