import {
  ORGANIZATION_SUCCESS,
  ORGANIZATION_LOADING,
  ORGANIZATION_FAIL,
} from "../types/organizationTypes";

import { fetchClientOrganizations } from "../../services/ClientService";

export const getOrganizations = () => async (dispatch, getState) => {
  // console.log(getState().auth.accessToken)
  dispatch(organizationLoading());
  fetchClientOrganizations()
    .then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          dispatch(organizationSuccess(data));
          console.log(data);
        });
      } else {
        dispatch(organizationFail("response status is not ok"));
      }
    })
    .catch((err) => {
      dispatch(organizationFail(err));
    });
};

export const organizationLoading = () => ({ type: ORGANIZATION_LOADING });
export const organizationSuccess = (organization) => ({
  type: ORGANIZATION_SUCCESS,
  payload: organization,
});
export const organizationFail = (error) => ({
  type: ORGANIZATION_FAIL,
  payload: error,
});
