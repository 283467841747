import React, { useState, useEffect } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CInput,
  CLabel,
  CModalFooter,
  CModalHeader,
  CRow,
  CCol,
} from "@coreui/react";
import { fetchRoutes } from "src/services/ServerService";
import Select from "react-select";
import MiniSpinner from "src/components/spinners/MiniSpinner";
import { useTranslation } from "react-i18next";
import { fetchCourierCompleteRegistration } from "src/services/CourierService";
import { toastify } from "src/helpers/toast";

export default function VerifyCourierForm({
  modal,
  toggle,
  modalTitle,
  selectedCourier,
  reFetchCouriers,
}) {
  const lang = localStorage.getItem("lang");
  const { t } = useTranslation();
  const [btnState, setBtnState] = useState(false);
  const [personal_number, setPersonal_number] = useState("");
  // const [auto_number, setAuto_number] = useState("");
  const [max_weight, setMax_weight] = useState("");
  const [max_volume, setMax_volume] = useState("");
  const [courierRoutes, setCourierRoutes] = useState([]);

  const [routes, setRoutes] = useState([]);
  const [route, setRoute] = useState("");

  const takeRoute = (e) => {
    const arr = [];
    e.map((r) => arr.push(r.value));
    setCourierRoutes(arr);
  };

  useEffect(() => {
    fetchRoutes()
      .then((res) => res.json().then((data) => setRoutes(data)))
      .catch((err) => console.log(err));
  }, []);

  console.log(selectedCourier)
  const verifyCourier = () => {
    const data = {
      personal_number,
      // auto_number,
      max_weight,
      max_volume,
      routes: courierRoutes,
    };
    console.log(data, selectedCourier.id);
    fetchCourierCompleteRegistration(data, selectedCourier.id)
      .then((res) => {
        if (res.ok) {
          toastify("success", "Operation succeeded");
          reFetchCouriers();
          setBtnState(false);
          toggle();
        } else {
          toastify("error", "Error occured");
          setBtnState(false);
        }
      })
      .catch((err) => {
        toastify("error", "Произошла ошибка, проверьте интернет");
        setBtnState(false);
      });
  };


  const [selectedImg, setSelectedImg] = useState(null) ;
  const [rotate, setRotate] = useState(null);
  // const [angle, setAngle] = useState(0)

  const turnLeft = () => {
    if(rotate?.transform==="rotate(90deg)") {
      setRotate(null)
    } else {
      setRotate({transform:"rotate(270deg)"})
    }
  }
  const turnRight = () => {
    if(rotate?.transform === "rotate(270deg)") {
      setRotate(null)
    } else {
      setRotate({transform: "rotate(90deg)"})
    }
  }

  return (
    <CModal show={modal} onClose={toggle} size="xl">
      <CModalHeader closeButton>{modalTitle}</CModalHeader>
      <CModalBody>
        <CRow>
          <CCol className="text-center" sm="6">
            <div>
              <div>
                <img style={{...rotate, marginTop: "80px", marginBottom: "80px"}} className="img img-thumbnail" width="400" height="400" src={selectedImg || selectedCourier.passport_front} alt="passport front"/>
              </div>
              <div className="d-inline text-center px-auto">
                <CButton variant="outline" color="primary" onClick={turnLeft} className="mr-2">Left</CButton>
                <CButton variant="outline" color="primary" onClick={turnRight}>Right</CButton>
              </div>
            </div>
            <div className="text-center mt-2">
              <img onClick={() => setSelectedImg(selectedCourier.passport_front)} className="img img-thumbnail mx-2" width="80" height="80" src={selectedCourier.passport_front} alt="passport front"/>
              <img onClick={() => setSelectedImg(selectedCourier.passport_back)} className="img img-thumbnail mx-2" width="80" height="80" src={selectedCourier.passport_back} alt="passport back"/>
              <img onClick={() => setSelectedImg(selectedCourier.photo)} className="img img-thumbnail mx-2" width="80" height="80" src={selectedCourier.photo} alt="profile avatar"/>
            </div>
          </CCol>
          <CCol sm="6">
            <CLabel>{t("Personal Number")}</CLabel>
              <CInput
                type="text"
                placeholder={t("Personal Number")}
                value={personal_number}
                onChange={(e) => setPersonal_number(e.target.value)}
              />

              {/* <CLabel>Auto Number</CLabel>
              <CInput
                type="text"
                placeholder="Auto Number"
                value={auto_number}
                onChange={(e) => setAuto_number(e.target.value)}
              /> */}
              <CLabel>{t("Max Weight")}(кг)</CLabel>
              <CInput
                type="number"
                placeholder={t("Max Weight")}
                value={max_weight}
                onChange={(e) => setMax_weight(e.target.value)}
              />
              <CLabel>{t("Max Volume")}(м<sup>3</sup>)</CLabel>
              <CInput
                type="number"
                placeholder={t("Max Volume")}
                value={max_volume}
                onChange={(e) => setMax_volume(e.target.value)}
              />
              <CLabel>{t("Routes")}</CLabel>
              <Select
                isMulti
                onChange={(e) => {
                  takeRoute(e);
                  setRoute(e);
                }}
                value={route}
                options={routes.map((r) => {
                  return {
                    value: r.id,
                    label: lang === "ru" ? r.name_ru : r.name_kg,
                  };
                })}
              />
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton
          className="btn-outline-success"
          disabled={btnState}
          onClick={() => {
            setBtnState(true);
            verifyCourier();
            toggle();
          }}
        >
          {btnState ? <MiniSpinner /> : t("Verify")}
        </CButton>
        <CButton
          className="btn-outline-danger"
          onClick={() => {
            toggle();
          }}
        >
          {t("Cancel")}
        </CButton>
      </CModalFooter>
    </CModal>
  );
}
