import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setBreadcrumb } from 'src/redux/actions/settingsActions'
import {CChart} from '@coreui/react-chartjs'
import { useTranslation } from 'react-i18next'
import { CCard, CCardBody, CCol, CLabel, CRow, CSelect } from '@coreui/react'
import { months, yearsList } from './constants'
import { fetchOffices } from 'src/services/CompanyService'

export default function Graphics () {

    const {t} = useTranslation()

    const dispatch = useDispatch()

    const [offices, setOffices] = useState([])

    useEffect(() => {
        dispatch(setBreadcrumb(["Graphics"]))

        fetchOffices().then(res => res.ok && res.json().then(d => {
            setOffices(d)
        }))
    }, [dispatch])

    return (
        <div>
            <CCard>
                <CCardBody>
                    <CRow>
                        <CCol sm="3">
                            <CLabel>{t("Year")}</CLabel>
                            <CSelect>
                                {yearsList.map(y =>
                                    <option>{y.name}</option>    
                                )}
                            </CSelect>
                        </CCol>
                        <CCol sm="3">
                            <CLabel>{t("Month")}</CLabel>
                            <CSelect>
                                {months.map(m => 
                                    <option>{m.name}</option>    
                                )}
                            </CSelect>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="3">
                            <CLabel>{t("Office")}</CLabel>
                            <CSelect>
                                <option>{t("All")}</option>
                                {offices.map(off => 
                                    <option>{off.name}</option>
                                    )}
                            </CSelect>
                        </CCol>
                        <CCol sm="3">
                            <CLabel>Регион</CLabel>
                            <CSelect>
                                <option>{t("All")}</option>
                                <option>Бишкек</option>
                                <option>Ош</option>
                            </CSelect>
                        </CCol>
                        <CCol sm="3">
                            <CLabel>Региональный?</CLabel>
                            <CSelect>
                                <option>{t("All")}</option>
                                <option>Региональный</option>
                                <option>Городской</option>
                            </CSelect>
                        </CCol>
                    </CRow>
                <CChart 
                    type="line"
                    labels={months.map(m => m.name)}
                    datasets={[
                        {
                            label: t("Completed Orders"),
                            backgroundColor: "rgba(220, 220, 220, 0.2)",
                            borderColor: "rgba(220, 220, 220, 1)",
                            pointBackgroundColor: "rgba(220, 220, 220, 1)",
                            pointBorderColor: "#fff",
                            data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 40, 39, 80]
                        },
                        {
                            label: t("Canceled Orders"),
                            backgroundColor: "rgba(151, 187, 205, 0.2)",
                            borderColor: "rgba(151, 187, 205, 1)",
                            pointBackgroundColor: "rgba(151, 187, 205, 1)",
                            pointBorderColor: "#fff",
                            data: [50, 12, 28, 29, 7, 25, 12, 70, 60, 25, 12, 70]
                        },
                        {
                            label: t("Rejected Orders"),
                            backgroundColor: "rgba(151, 187, 205, 0.2)",
                            borderColor: "rgba(151, 187, 205, 1)",
                            pointBackgroundColor: "rgba(151, 187, 205, 1)",
                            pointBorderColor: "#fff",
                            data: [38, 11, 22, 20, 17, 5, 15, 10, 20, 5, 15, 10]
                        },
                        ]}
                    />
                </CCardBody>
            </CCard>
        </div>
            
    )
}