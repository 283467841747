import CIcon from "@coreui/icons-react";
import { CButton } from "@coreui/react";
import {useTranslation} from "react-i18next"

export default function Pagination({ 
    currentListLength, 
    limit, 
    currentPage, 
    nextPage, 
    prevPage, 
    isLastPage }) 
{

    const {t} = useTranslation();

    return (
        <div className="d-flex float-right align-items-center">
            {currentPage > 1 && 
                <CButton
                    className='mb-3'
                    variant="outline"
                    color="primary"
                    onClick={prevPage}>
                        <CIcon name="cilArrowLeft"></CIcon>
                </CButton>
            }
            <span className='mb-3 px-2' color="success"> 
                {t("Page")}: {currentPage} 
            </span>
            {(currentListLength === limit && !isLastPage) && 
                <CButton
                    className='mb-3'
                    variant="outline"
                    color="primary"
                    onClick={nextPage}>
                        <CIcon name="cilArrowRight"></CIcon>
                </CButton>
            }
        </div>
    )
}