let today = new Date().toJSON().slice(0, 10);
let yesterday = new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000)
  .toJSON()
  .slice(0, 10);
let daysInMonth = new Date(
  new Date().getFullYear(),
  new Date().getMonth() + 1,
  0
).getDate();
let aWeekAgo = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  .toJSON()
  .slice(0, 10);
let aMonthAgo = new Date(
  new Date().getTime() - daysInMonth * 24 * 60 * 60 * 1000
)
  .toJSON()
  .slice(0, 10);
export const getDates = (year) => {
  return [
    { id: "first", date_from: `${year}-01-01`, date_to: `${year}-03-31` },
    { id: "second", date_from: `${year}-04-01`, date_to: `${year}-06-30` },
    { id: "third", date_from: `${year}-07-01`, date_to: `${year}-08-31` },
    { id: "fourth", date_from: `${year}-10-01`, date_to: `${year}-12-31` },
    { id: "month", date_from: aMonthAgo, date_to: today },
    { id: "week", date_from: aWeekAgo, date_to: today },
    { id: "yesterday", date_from: yesterday, date_to: yesterday },
    { id: "today", date_from: today, date_to: today },
    { id: "january", date_from: `${year}-01-01`, date_to: `${year}-01-31` },
    {
      id: "february",
      date_from: `${year}-02-01`,
      date_to: `${year}-02-${year % 4 === 0 ? 29 : 28}`,
    },
    { id: "march", date_from: `${year}-03-01`, date_to: `${year}-03-31` },
    { id: "april", date_from: `${year}-04-01`, date_to: `${year}-04-30` },
    { id: "may", date_from: `${year}-05-01`, date_to: `${year}-05-31` },
    { id: "june", date_from: `${year}-06-01`, date_to: `${year}-06-30` },
    { id: "july", date_from: `${year}-07-01`, date_to: `${year}-07-31` },
    { id: "august", date_from: `${year}-08-01`, date_to: `${year}-08-31` },
    { id: "september", date_from: `${year}-09-01`, date_to: `${year}-09-30` },
    { id: "october", date_from: `${year}-10-01`, date_to: `${year}-10-31` },
    { id: "november", date_from: `${year}-11-01`, date_to: `${year}-11-30` },
    { id: "december", date_from: `${year}-12-01`, date_to: `${year}-12-31` },

    { id: "x20", date_from: `${year}-01-01`, date_to: `${year}-12-31` },
    { id: "x21", date_from: `${year}-01-01`, date_to: `${year}-12-31` },
    { id: "x22", date_from: `${year}-01-01`, date_to: `${year}-12-31` },
    { id: "x23", date_from: `${year}-01-01`, date_to: `${year}-12-31` },
    { id: "x24", date_from: `${year}-01-01`, date_to: `${year}-12-31` },
    { id: "x25", date_from: `${year}-01-01`, date_to: `${year}-12-31` },
    { id: "x26", date_from: `${year}-01-01`, date_to: `${year}-12-31` },
    { id: "x27", date_from: `${year}-01-01`, date_to: `${year}-12-31` },
    { id: "x28", date_from: `${year}-01-01`, date_to: `${year}-12-31` },
    { id: "x29", date_from: `${year}-01-01`, date_to: `${year}-12-31` },
    { id: "x30", date_from: `${year}-01-01`, date_to: `${year}-12-31` },
  ];
};

export const months = [
  { id: "january", name: "Январь" },
  { id: "february", name: "Февраль" },
  { id: "march", name: "Март" },
  { id: "april", name: "Апрель" },
  { id: "may", name: "Май" },
  { id: "june", name: "Июнь" },
  { id: "july", name: "Июль" },
  { id: "august", name: "Август" },
  { id: "september", name: "Сентябрь" },
  { id: "october", name: "Октябрь" },
  { id: "november", name: "Ноябрь" },
  { id: "december", name: "Декабрь" },
];

export const yearsList = [
  { id: "x20", name: 2020 },
  { id: "x21", name: 2021 },
  { id: "x22", name: 2022 },
  { id: "x23", name: 2023 },
  { id: "x24", name: 2024 },
  { id: "x25", name: 2025 },
  { id: "x26", name: 2026 },
  { id: "x27", name: 2027 },
  { id: "x28", name: 2028 },
  { id: "x29", name: 2029 },
  { id: "x30", name: 2030 },
];

export const redemptionTypes = [
  { type: "Все", typekg: "Баары", id: 'all' },
  { type: "C выкупом", typekg: "Сатып алуу бар", id: 'with' },
  { type: "Без выкупа", typekg: "Сатып алуу жок", id: 'without' },
];
export const orderTypes = [
  { type: "Все", typekg: "Баары", id: 'all' },
  { type: "Город", typekg: "Шаар", id: 'city' },
  { type: "Регион", typekg: "Регион", id: 'regional' },
];
