import {
  CCard,
  CCardHeader,
  CCardBody,
  CButton,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { setBreadcrumb } from "src/redux/actions/settingsActions";
import { useDispatch } from "react-redux";
import { fetchCompanies } from "src/services/CompanyService";
import { useModalState } from "src/helpers/useModal";
import { toastify } from "src/helpers/toast";
import CompanyForm from "./CompanyForm";
import MiniSpinner from "src/components/spinners/MiniSpinner";

export default function CourierCompanies() {

  const dispatch = useDispatch();

  const { isOpen, onToggle } = useModalState();

  const { t } = useTranslation();

  const [companies, setCompanies] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    dispatch(setBreadcrumb(["Courier Companies"]));
    setLoading(true)
    fetchCompanies().then(res => res.ok && res.json().then(data => {
      console.log(data)
      setLoading(false)
      setCompanies(data)
    })).catch(err => setLoading(err))
  }, [dispatch]);
  
  const onSuccess = () => {
    fetchCompanies().then(res => res.json().then(data => {
      setCompanies(data)
    }))
    onToggle();
  }

  const editCompany = (com) => {
    setSelectedCompany(com);
    onToggle();
    setModalTitle(t("Edit Company"));
  }
  const detailsOf = (com) => {
    toastify("", "Details")
  }

  return (
    <CCard className="m-2">
      <CCardHeader>
        <CButton
          variant="outline"
          color="success"
          className="float-right"
          onClick={() => {
            setModalTitle("Create company");
            setSelectedCompany(null)
            onToggle();
          }}
        >
          {t("Create new company")}
        </CButton>
      </CCardHeader>
      <CCardBody>
        {loading ? <div className="text-center">
          <MiniSpinner/>
          </div> : 
        <table className="table table-hover">
          <thead>
            <tr>
              <th>{t("ID")}</th>
              <th>{t("Name")}</th>
              <th>{t("Status")}</th>
              <th>{t("City")}</th>
              <th>{t("District")}</th>
              <th>{t("Balance")}</th>
              <th>{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {companies &&
              companies.map((com) => (
                <tr key={com.id}>
                  <td>{com.id}</td>
                  <td>{com.name}</td>
                  <td>{t(com.status)}</td>
                  <td>{com.city?.name_kg}</td>
                  <td>{com.district?.name_kg}</td>
                  <td>{com.balance}</td>
                  <td>
                    <CDropdown>
                      <CDropdownToggle
                          color="secondary" size="sm" >
                              <CIcon name="cil-menu"/>
                      </CDropdownToggle>
                      <CDropdownMenu>
                          <CDropdownItem onClick={() => detailsOf(com)}>{t("Details")}</CDropdownItem>
                          <CDropdownItem onClick={() => editCompany(com)}>{t("Edit")}</CDropdownItem>
                      </CDropdownMenu>
                  </CDropdown>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        }
      </CCardBody>
      
      <CompanyForm isOpen={isOpen} onToggle={onToggle} modalTitle={modalTitle} onSuccess={onSuccess} selectedCompany={selectedCompany}/>
      

    </CCard>
  );
}
