import {
    CITIES_SUCCESS,
    CITIES_FAIL,
    CITIES_LOADING,
    DISTRICTS_SUCCESS,
    DISTRICTS_FAIL,
    DISTRICTS_LOADING,
    REGIONS_SUCCESS,
    REGIONS_FAIL,
    REGIONS_LOADING,
    LOCATIONS_LOADING,
    LOCATIONS_SUCCESS,
    LOCATIONS_FAIL,
} from "../types/locationTypes";

import {
    fetchCities,
    fetchDistricts,
    fetchLocations,
    fetchAllDistricts,
    fetchSearchedCities,
    fetchSearchDistricts, fetchRegions
} from "../../services/CityService";

export const getCities = () => async (dispatch, getState) => {
  dispatch(citiesLoading());

  fetchCities()
    .then((res) => res.ok && res.json())
    .then((data) => {
      dispatch(citiesSuccess(data));
    })
    .catch((err) => dispatch(citiesFail(err)));
};

export const getSearchedCities = (key) => async (dispatch, getState) => {
  dispatch(citiesLoading());

  fetchSearchedCities(key)
    .then((res) => res.ok && res.json())
    .then((data) => {
      dispatch(citiesSuccess(data));
    })
    .catch((err) => dispatch(citiesFail(err)));
};

export const getAllDistricts = () => async (dispatch, getState) => {
  dispatch(districtsLoading());

  fetchAllDistricts()
    .then((res) => res.ok && res.json())
    .then((data) => {
      dispatch(districtsSuccess(data));
    })
    .catch((err) => dispatch(districtsFail(err)));
};

export const getSearchedDistricts = (key) => async (dispatch, getState) => {
  dispatch(districtsLoading());
  fetchSearchDistricts(key)
    .then((res) => res.ok && res.json())
    .then((data) => {
      dispatch(districtsSuccess(data));
    })
    .catch((err) => dispatch(districtsFail(err)));
};

export const getDistricts = (cityId) => async (dispatch, getState) => {
  dispatch(districtsLoading());

  fetchDistricts(cityId)
    .then((res) => res.ok && res.json())
    .then((data) => {
      dispatch(districtsSuccess(data));
    })
    .catch((err) => dispatch(districtsFail(err)));
};

export const getAllRegions = () => async (dispatch, getState) => {
    dispatch(regionsLoading());

    fetchRegions()
        .then((res) => res.ok && res.json())
        .then((data) => {
            dispatch(regionsSuccess(data));
        })
        .catch((err) => dispatch(regionsFail(err)));
};


export const getLocations =
  (cityId, districtId) => async (dispatch, getState) => {
    dispatch(locationsLoading());

    fetchLocations(cityId, districtId)
      .then((res) => res.json())
      .then((data) => {
        dispatch(locationsSuccess(data));
      })
      .catch((err) => dispatch(locationsFail(err)));
  };

export const citiesLoading = () => ({ type: CITIES_LOADING });
export const citiesSuccess = (cities) => ({
  type: CITIES_SUCCESS,
  cities,
});
export const citiesFail = (err) => ({ type: CITIES_FAIL, payload: err });

export const districtsLoading = () => ({ type: DISTRICTS_LOADING });
export const districtsSuccess = (districts) => ({
  type: DISTRICTS_SUCCESS,
  districts,
});
export const districtsFail = (err) => ({ type: DISTRICTS_FAIL, payload: err });

export const regionsLoading = () => ({ type: REGIONS_LOADING });
export const regionsSuccess = (regions) => ({
    type: REGIONS_SUCCESS,
    regions,
});
export const regionsFail = (err) => ({ type: REGIONS_FAIL, payload: err });

export const locationsLoading = () => ({ type: LOCATIONS_LOADING });
export const locationsSuccess = (locations) => ({
  type: LOCATIONS_SUCCESS,
  locations,
});
export const locationsFail = (err) => ({ type: LOCATIONS_FAIL, payload: err });

//location delete
export const locationUpdate = (location) => async (dispatch, getState) => {
  let locationList = getState().locations.locations;
  locationList.push(location);
  dispatch(locationsSuccess(locationList));
};

//location update
export const deleteLocationFromRedux = (location) => async (dispatch, getState) => {
  let locationList = getState().locations.locations.filter(
    (o) => o.id !== location.id
  );
  dispatch(locationsSuccess(locationList));
};
