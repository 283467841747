import { fetchCompanyExtraFees } from "src/services/CompanyService";
import {
  EXTRA_FEE_SUCCESS,
  EXTRA_FEE_LOADING,
  EXTRA_FEE_FAIL
} from "../types/extraFeeTypes";


export const getExtraFees = () => async (dispatch, getState) => {
  dispatch(extraFeesLoading());

  fetchCompanyExtraFees()
    .then((res) =>
      {
        if(res.ok) {
          res.json().then((data) => {
            dispatch(extraFeesSuccess(data));
          })
        } else {
          dispatch(extraFeesFail(res.statusText))
        }
      }
    )
    .catch((err) => {
      dispatch(extraFeesFail(err));
    });
};

export const extraFeesLoading = () => ({ type: EXTRA_FEE_LOADING });

export const extraFeesSuccess = (operators) => ({
  type: EXTRA_FEE_SUCCESS,
  payload: operators,
});
export const extraFeesFail = (error) => ({ type: EXTRA_FEE_FAIL, payload: error });

export const resetExtraFee = (id) => async (dispatch, getState) => {
  let restFees = getState().extraFees.fees.filter(f => f.id !== id);
  dispatch(extraFeesSuccess(restFees));
}