import { CButton, CCard, CCardBody, CCardHeader, CCardTitle } from '@coreui/react';
import React, { useState } from 'react';
import {dateDifference} from "src/helpers/date";
import HistoryContainer from './HistoryContainer';

export default function HistoryDetails(props) {
    const [filter, setFilter] = useState(null);

    const detectStatus = (arr, statusName) => {
        let startDate, endDate
        if(statusName === "on courier"){
            startDate = arr.filter(item => item?.new_value?.state === "approved")[0]?.date  ||  arr.filter(item => item?.new_value?.state === "appointed")[0]?.date
            endDate = arr.filter(item => item?.new_value?.state === "arrived at sender")[0]?.date
        }else{
             startDate = arr.filter(item => item?.new_value?.state === "approved")[0]?.date  ||  arr.filter(item => item?.new_value?.state === "appointed")[0]?.date
             endDate = arr.filter(item => item?.new_value?.state === "completed")[0]?.date
        }

            return (startDate && endDate) && dateDifference(startDate, endDate)
    }

    return (
        <CCard>
            <CCardHeader>
                <CCardTitle>
                    История
                    <div className="float-right">
                        <span className="mr-4">Время подачи: {detectStatus(props.orderDetails.order_history, "on courier")}</span>
                        <span>Общее время доставки: {detectStatus(props.orderDetails.order_history)}</span>
                    </div>
                    <div className="float-right d-none">
                    <CButton variant="outline" color="success" size="sm" className="m-1" onClick={() => setFilter(null)}>
                        Все
                    </CButton>
                    <CButton variant="outline" color="success" size="sm" className="m-1" onClick={() => setFilter("status change")}>
                        Статусы
                    </CButton>
                    <CButton variant="outline" color="success" size="sm" className="m-1" onClick={() => setFilter("transfer")}>
                        Трансфер
                    </CButton>
                    <CButton variant="outline" color="success" size="sm" className="m-1" onClick={() => setFilter("media")}>
                        Медиа
                    </CButton>
                    <CButton variant="outline" color="success" size="sm" className="m-1" onClick={() => setFilter("payments")}>
                        Платежи
                    </CButton>
                    <CButton variant="outline" color="success" size="sm" className="m-1" onClick={() => setFilter("details change")}>
                        Детали
                    </CButton>
                </div>
                </CCardTitle>
                
            </CCardHeader>
            <CCardBody className="p-0">
                <HistoryContainer order_history={props.orderDetails.order_history.filter(oh => {
                    if(filter) {
                        if(filter === "media") {
                            return oh.action_type === "sender signature" || oh.action_type === "receiver signature";
                        } else if(filter === "payments") {
                            return oh.action_type === "payment payment" || oh.action_type === "client redemption payment";
                        } else {
                            return oh.action_type === filter
                        }
                    } else {
                        return true;
                    }
                })} filter={filter}/>
            </CCardBody>
        </CCard>
    )
}