// import React from "react";
// import { YMaps, Map, Polygon } from "react-yandex-maps";

// // import "./styles.css";

// const mapState = {
//   center: [55.73, 37.9],
//   zoom: 10
// };

// const draw = ref => {
//   ref.editor.startDrawing();

//   ref.editor.events.add("statechange", event => {
//     console.log(event);
//   });
// };

// export default function YMapPolygon() {
//   return (
//     <div className="App">
//       <h1>Hello CodeSandbox</h1>
//       <YMaps>
//         <Map defaultState={mapState} modules={["geoObject.addon.editor"]} width="100%" height="60vh">
//           <Polygon
//             instanceRef={ref => ref && draw(ref)}
//             geometry={[]}
//             options={{
//               editorDrawingCursor: "crosshair",
//               editorMaxPoints: 5,

//               fillColor: "#00FF00",
//               // Цвет обводки.
//               strokeColor: "#0000FF",
//               // Ширина обводки.
//               strokeWidth: 5
//             }}
//           />
//         </Map>
//       </YMaps>
//     </div>
//   );
// }

import React, { useState, useRef, useEffect } from "react";
import { YMaps, Map, Polygon } from "react-yandex-maps";
import { CButton, CInput, CRow, CCol } from "@coreui/react";
import { toastify } from "src/helpers/toast";
import {
  fetchCompanyExtraFeePatch,
  fetchCompanyExtraFeePost,
} from "src/services/CompanyService";
import { useDispatch, useSelector } from "react-redux";
import {
  getExtraFees,
  resetExtraFee,
} from "src/redux/actions/extraFeesActions";
import { useTranslation } from "react-i18next";

export default function YMapPolygon(props) {
  const getCenter = function (arr) {
    return arr.reduce(
      function (x, y) {
        return [x[0] + y[0] / arr.length, x[1] + y[1] / arr.length];
      },
      [0, 0]
    );
  };

  const { t } = useTranslation();
  const map = useRef(null);
  const dispatch = useDispatch();

  const [selectedId, setSelectedId] = useState(null);
  const [coors, setCoors] = useState([[]]);
  const [center, setCenter] = useState([42.88, 74.58]);
  const [mapApi, setMapApi] = useState(null);
  const [name, setName] = useState("");
  const [amount, setAmount] = useState(0);
  // console.log('aaa', coors)

  const allFees = useSelector((state) => state.extraFees.fees);

  // console.log(allFees && allFees);
  useEffect(() => {
    dispatch(getExtraFees());
  }, [dispatch]);

  useEffect(() => {
    if (props.selectedFee) {
      setCoors([props.selectedFee.fee.coors] || [[]]);
      setName(props.selectedFee.name);
      setSelectedId(props.selectedFee.id);
      setAmount(props.selectedFee.amount);
      setCenter(getCenter(props?.selectedFee?.fee?.coors));
      map.current && map.current.setZoom(12);
    } else {
      setCoors([[]]);
      setCenter([42.88, 74.58]);
      setName("");
      setAmount(0);
      map.current && map.current.setZoom(11);
    }
  }, [props]);

  // console.log(mapApi && mapApi);

  const addToCoors = (e) => {
    let clickedCoors = e.get("coords");
    // console.log(clickedCoors);
    let polygonLines = coors[0];
    polygonLines.push(clickedCoors);
    setCoors([polygonLines]);
  };

  const checkCoors = () => {
    console.log(mapApi);
    var myPolygon = new mapApi.Polygon(coors);

    map.current.geoObjects.add(myPolygon);
    console.log(myPolygon.geometry.contains([42.88, 74.58]) ? "Hit!" : "Miss!");
  };

  const createZone = () => {
    // let center = map.current.getCenter();
    // console.log(map.current);
    // console.log(center);
    let zoneBody = { name, amount, fee_type: "zone", fee: { coors: coors[0] } };
    console.log(zoneBody);

    fetchCompanyExtraFeePost(zoneBody)
      .then((res) => {
        if (res.ok) {
          toastify("success", t("Created new zone successfully"));
          props.reFetchFees();
          setCoors([[]]);
          dispatch(getExtraFees());
          props.onToggle();
        } else {
          toastify("error", t("Произошла ошибка создания зоны"));
        }
      })
      .catch((err) =>
        toastify("error", t("Произошла ошибка создания, проверьте интернет"))
      );
  };
  // console.log(coors);

  const updateZone = () => {
    let zoneBody = { name, amount, fee: { coors: coors[0] } };
    console.log(zoneBody);

    fetchCompanyExtraFeePatch(zoneBody, props.selectedFee.id)
      .then((res) => {
        if (res.ok) {
          toastify("success", t("Update zone successfully"));
          props.reFetchFees();
          dispatch(getExtraFees());
          setCoors([[]]);
          props.onToggle();
        } else {
          toastify("error", t("Произошла ошибка обновления зоны"));
        }
      })
      .catch((err) =>
        toastify("error", t("Произошла ошибка создания, проверьте интернет"))
      );
  };
  // console.log(center);
  return (
    <>
      <div className="my-2 text-center">
        <CRow>
          <CCol sm="4">
            <CInput
              placeholder={t("Zone name")}
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </CCol>
          <CCol sm="4">
            <CInput
              placeholder={t("Fee amount")}
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </CCol>
          <CCol sm="4">
            {props.selectedFee && props.selectedFee ? (
              <CButton
                variant="outline"
                color="primary"
                className="mx-2"
                onClick={() => updateZone()}
              >
                {t("Update Zone")}
              </CButton>
            ) : (
              <CButton
                variant="outline"
                color="success"
                className="mx-2"
                onClick={() => createZone()}
              >
                {t("Create Zone")}
              </CButton>
            )}
            <CButton
              variant="outline"
              color="primary"
              className="mx-2 d-none"
              onClick={() => checkCoors()}
            >
              {t("Check Coors")}
            </CButton>
            <CButton
              variant="outline"
              color="danger"
              className="mx-2"
              onClick={() => {
                setCoors([[]]);
                dispatch(resetExtraFee(selectedId));
              }}
            >
              {t("Reset")}
            </CButton>
            <CButton
              variant="outline"
              color="dark"
              onClick={() => {
                props.onToggle();
                setCoors([[]]);
                setName("");
                setAmount(0);
              }}
            >
              {t("Cancel")}
            </CButton>
          </CCol>
        </CRow>
      </div>
      <YMaps>
        <Map
          width="100%"
          height="80vh"
          onLoad={(ymaps) => setMapApi(ymaps)}
          state={{
            center: center,
            zoom: 12,
          }}
          instanceRef={map}
          onClick={(e) => addToCoors(e)}
        >
          <Polygon
            geometry={coors}
            options={{
              fillColor: "#00FF00",
              strokeColor: "#0000FF",
              opacity: 0.5,
              strokeWidth: 5,
              strokeStyle: "shortdash",
            }}
          />
          {allFees &&
            allFees
              .filter((fee) => fee.fee_type === "zone")
              .map((zone) => (
                <Polygon
                  modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
                  key={zone.id}
                  geometry={[zone.fee.coors]}
                  options={{
                    fillColor: `${
                      zone.amount < 20
                        ? "#8ed38b"
                        : zone.amount < 40
                        ? "#aad369"
                        : zone.amount < 60
                        ? "#71d326"
                        : zone.amount < 80
                        ? "#b2d300"
                        : zone.amount < 100
                        ? "#b2d300"
                        : zone.amount < 140
                        ? "#d39900"
                        : zone.amount < 200
                        ? "#d36200"
                        : zone.amount < 220
                        ? "#d32100"
                        : zone.amount < 240
                        ? "#d30000"
                        : zone.amount < 260
                        ? "#7a0000"
                        : zone.amount < 280
                        ? "#3a0000"
                        : zone.amount < 300
                        ? "#4a1738"
                        : zone.amount < 350
                        ? "#210000"
                        : "#210b19"
                    }`,
                    strokeColor: "#004B35",
                    opacity: 0.7,
                    strokeWidth: 1,
                    strokeStyle: "shortdash",
                  }}
                  properties={{
                    hintContent:
                      "Название: " +
                      zone.name +
                      "</br>" +
                      "Доплата: " +
                      zone.amount +
                      "c",
                  }}
                />
              ))}
        </Map>
      </YMaps>
    </>
  );
}
