
import React, { useEffect, useRef, useState } from 'react';
import { CModal, CModalBody,
  CNavLink, CTabs, CTabContent, CTabPane, CRow,
  CContainer,
  CCard,
  CCardBody,
  CCol,
  CButton,
  CModalHeader,
  CModalFooter} from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { orderDeleteFetch, orderFetch } from 'src/services/OrderService';
import MainDetails from './MainDetails';
import HistoryDetails from './HistoryDetails';
import Rectangle from '../../../assets/Rectangle.png'
import { YMaps, Map } from "react-yandex-maps";
import { Link} from 'react-router-dom';
import { toastify } from 'src/helpers/toast';
import { useDispatch, useSelector } from 'react-redux';
import { removeOrder } from 'src/redux/actions/orderActions';
import { useReactToPrint } from 'react-to-print';
import { useConfirmModal } from 'src/helpers/useModal';
import {useTranslate} from "../../../helpers/useTranslate";
import PrintStickerAmanat from "./PrintStickerAmanat";
import PhotoDetails from './PhotoDetails';
import { SERVER_URL } from 'src/services/ServerService';

const apikey = "14b7fac2-2096-4a85-9aa1-ed9ac48250ff";





export default function OrderDetails(props) {

  const {t} = useTranslation();
  const {tr} = useTranslate();
  const [orderDetails, setOrderDetails] = useState(null);
  const [activeTab, setActiveTab] = useState("main");

  const { isConfirmOpen, onConfirmToggle } = useConfirmModal();

  const [distanceBetween, setDistanceBetween] = useState(0);
  const [estimation, setEstimation] = useState(0);
  const profile = useSelector(state => state.auth.profile)
  const company = useSelector(state => state.auth.company)

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const addOrderDetailToClipboard = () => {
    console.log(orderDetails, "orderDetails")
    if (orderDetails) {
      const orderOid = orderDetails.oid
      const orderId = orderDetails.id
      const orderPid = orderDetails.pid
      const sender = orderDetails.sender
      const cityFrom = tr(orderDetails.city_from?.name_ru, orderDetails.city_from?.name_kg)
      const districtFrom =
          orderDetails.district_from === null ? "" : tr(orderDetails.district_from?.name_ru, orderDetails.district_from?.name_kg)
      const locationFrom = tr(orderDetails.location_from?.name_ru, orderDetails.location_from?.name_kg)
      const locationFromFlat = orderDetails.location_from_flat
      const locationFromFloor = orderDetails.location_from_floor
      const redemptionAmount = orderDetails.redemption_amount
      const receiver = orderDetails.receiver

      const cityTo = tr(orderDetails.city_to?.name_ru, orderDetails.city_to?.name_kg)
      const districtTo =
          orderDetails.district_to === null ? "" : tr(orderDetails.district_to?.name_ru, orderDetails.district_to?.name_kg)
      const locationTo = orderDetails.location_to === null ? "" : tr(orderDetails.location_to?.name_ru, orderDetails.location_to?.name_kg)
      const locationToFlat = orderDetails.location_to_flat
      const locationToFloor = orderDetails.location_to_floor
      const paying_side =
          orderDetails.paying_side === "receiver" ? "Получатель" :
              orderDetails.paying_side === "sender" ? "Отправитель" : "Вместе"
      const finalPrice = orderDetails.final_price
      const packageType = tr(orderDetails.package_type?.name_ru, orderDetails.package_type?.name_ru)
      const description = orderDetails.description
      const senderPaymentAmount = orderDetails.sender_payment_amount
      const receiverPaymentAmount = orderDetails.receiver_payment_amount
      const senderPaymentAmountIsPaid = orderDetails.sender_payment_is_paid
      const receiverPaymentAmountIsPaid = orderDetails.receiver_payment_is_paid


      navigator.clipboard.writeText(`*Заказ №: ${orderOid ? orderOid : orderId}* ${SERVER_URL}/orders/${orderPid}  \n\nОтправитель: ${sender?.fullname}, ${sender?.phone},\n🅰️Откуда: ${cityFrom && (cityFrom + ",")} ${districtFrom && (districtFrom + ", ")} ${locationFrom && (locationFrom)} ${locationFromFlat ? "\nКвартира: " + locationFromFlat : ""} ${locationFromFloor ? " Этаж: " + locationFromFloor : ""} \n${orderDetails.package_type ? "\n📦Тип посылки: " + packageType : ""} \nВыкуп: *${redemptionAmount} ⊆* \n\nПолучатель: ${(receiver ? receiver?.fullname + ',' + receiver?.phone : "нужно уточнить, ")} \n🅱️Куда: ${cityTo && (cityTo + ",")} ${districtTo && (districtTo + ", ")} ${locationTo && (locationTo)}  \n${locationToFlat ? "Квартира: " + locationToFlat : ""} ${locationToFloor ? " Этаж: " + locationToFloor : ""} \nОплачивает: ${paying_side === "Вместе" ? `*Совместно* \nОтправитель: *${senderPaymentAmount} ⊆* (${senderPaymentAmountIsPaid ? "Оплачено" : "Неоплачено"}) \nПолучатель: *${receiverPaymentAmount} ⊆* (${receiverPaymentAmountIsPaid ? "Оплачено" : "Неоплачено"})` : `${paying_side} \n💰 За доставку: *${finalPrice} ⊆* (${paying_side === "Получатель" ? receiverPaymentAmountIsPaid ? "Оплачено" : "Неоплачено" : paying_side === "Отправитель" ? senderPaymentAmountIsPaid ? "Оплачено" : "Неоплачено" : ""})`} 
          \nКомментарий : ${description ? description : ""}
         `)
    }
  }

  useEffect(() => {
    if(props.selectedOrder?.id) {
      orderFetch(props.selectedOrder.id).then(res => res.ok && res.json()).then(data => {
        setOrderDetails(data);
      }).catch(err => console.log(err));
    }
  }, [props.selectedOrder])

  const closeModal = () => {
    props.onToggle();
    props.setSelectedOrder(null);
  }

  const map = useRef(null);
  const [mapApi, setMapApi] = useState(null);
  const [currentRoute, setCurrentRoute] = useState(null)

  const addRoute = () => {
    if(mapApi && orderDetails.location_from.lat && orderDetails.location_to.lat){
      const multiRoute = new mapApi.multiRouter.MultiRoute(
          {
            referencePoints: [
              [orderDetails.location_from.lat, orderDetails.location_from.lng],
              [orderDetails.location_to.lat, orderDetails.location_to.lng]
            ]
          },
          {
            boundsAutoApply: true
          }
      );

      currentRoute && map.current.geoObjects.remove(currentRoute);
      setCurrentRoute(multiRoute);

      map.current.geoObjects.add(multiRoute);

      multiRoute.model.events.add('requestsuccess', function() {
        var activeRoute = multiRoute.getActiveRoute();
        if(activeRoute) {
          setDistanceBetween(activeRoute.properties.get("distance").text);
          setEstimation(activeRoute.properties.get("duration").text);
        }
      });
    }
  }

  const dispatch = useDispatch();
  const deleteOrder = () => {
    orderDeleteFetch(orderDetails.id).then(res =>
    {
      if(res.ok) {
        toastify("success", "Order deleted successfully");
        dispatch(removeOrder({order_ids:[orderDetails.id]}));
        closeModal();
      } else {
        toastify("error", "Something goes wrong")
        res.json().then(d => console.log(d));
      }
    }).catch(err => {
      console.log(err)
      toastify("error", "Network error");
    })
  }

  return (
      <CModal show={props.isOpen} onClose={() => closeModal()} size="xl" style={{backgroundColor: "#556785"}}>
        {orderDetails &&
        <CModalBody>
          <CContainer>
            <CRow>
              <CCol sm="6">
                    <span className="mx-2 text-white">
                      <span style={{color: "#B5B5B5"}}>ID: </span>
                      {orderDetails?.oid}
                      </span>
                <span className="mx-2 text-white">
                      <span style={{color: "#B5B5B5"}}>{t("Status")}: </span>
                  {t(orderDetails?.status)}
                      </span>
                <span className="mx-2 text-white">
                      <span style={{color: "#B5B5B5"}}>{t("Courier")}: </span>
                  {orderDetails.courier && orderDetails.courier.user.firstname}
                      </span>
              </CCol>
              <CCol sm="6" className="text-right">

                <div className="float-right d-flex">
                  <CButton className="btn btn-sm btn-outline-light mx-1" onClick={() => addOrderDetailToClipboard()}>{t("Copy")}</CButton>
                  {props.allowedToUpdate && 
                  <Link to={"/update-order/"+orderDetails.id} className="btn btn-sm btn-outline-light mx-1">{t("Edit")}</Link>
                  }
                  {profile?.roles.includes("super_admin") &&
                  <>
                    <CButton className="btn btn-sm btn-outline-warning mx-1" onClick={() => onConfirmToggle()}>{t("Delete")}</CButton>
                  </>
                  }
                  <CButton className="btn btn-sm btn-outline-success mx-1" onClick={handlePrint}>{t("Print")}</CButton>
                </div>
              </CCol>
            </CRow>
            <div>
              <CTabs activeTab={activeTab}>
                <CRow>
                  <CNavLink data-tab="main" style={{color: activeTab==="main" ? "#FF8518" :"#CC6000"}} onClick={() => setActiveTab("main")}>
                    {t("Main")}
                  </CNavLink>
                  <CNavLink data-tab="history" style={{color: activeTab==="history" ? "#FF8518" :"#CC6000"}} onClick={() => setActiveTab("history")}>
                    {t("History")}
                  </CNavLink>
                  <CNavLink data-tab="map" style={{color: activeTab==="map" ? "#FF8518" :"#CC6000"}} onClick={() => {setActiveTab("map"); addRoute();}}>
                    {t("Map")}
                  </CNavLink>
                  <CNavLink data-tab="signature" style={{color: activeTab==="signature" ? "#FF8518" :"#CC6000"}} onClick={() => setActiveTab("signature")}>
                    {t("Signature")}
                  </CNavLink>
                  <CNavLink data-tab="photo" style={{color: activeTab==="photo" ? "#FF8518" :"#CC6000"}} onClick={() => setActiveTab("photo")}>
                    {t("Photo")}
                  </CNavLink>
                </CRow>

                <CTabContent>
                  <CTabPane data-tab="main">
                    <MainDetails orderDetails={orderDetails}/>
                    <div className="d-none">
                      <div ref={componentRef} >
                        <PrintStickerAmanat
                            qrCode={`http://api.qrserver.com/v1/create-qr-code/?data=${SERVER_URL}/orders/` + orderDetails.pid}
                            courierCompanyLogo={company?.navbar_logo} courierCompanyPhone={""} orderId={orderDetails.id} oid={orderDetails.oid}
                            smartPost={""} sender={orderDetails.sender?.fullname} receiver={orderDetails.receiver?.fullname}
                            senderPhone={orderDetails.sender.phone} senderAddress={orderDetails.location_from}
                            receiverPhone={orderDetails.receiver?.phone} receiverAddress={orderDetails.location_to}
                            comment={orderDetails.description} redemption={orderDetails.redemption_amount}
                            price={orderDetails.final_price} isPaid={orderDetails.is_paid} redemptionPaymentType={orderDetails.redemption_payment_type} payingSide={orderDetails.paying_side} senderPaymentType={orderDetails.sender_payment_type} receiverPaymentType={orderDetails.receiver_payment_type}
                        />
                      </div>
                    </div>
                  </CTabPane>
                  <CTabPane data-tab="history">
                    <HistoryDetails orderDetails={orderDetails}/>
                  </CTabPane>
                  <CTabPane data-tab="map">
                    <CRow>
                      <p className="mx-2 text-white">
                        <span style={{color: "#B5B5B5"}}>{t("Distance")}: </span> 
                        {distanceBetween}
                        </p>
                      <p className="mx-2 text-white">
                        <span style={{color: "#B5B5B5"}}>{t("Duration")}: </span> 
                        {estimation}
                        </p>
                    </CRow>
                    <YMaps query={{ apikey }}>
                      <Map
                        onLoad={(ymaps) => {setMapApi(ymaps)}}
                        instanceRef={map}
                        modules={["multiRouter.MultiRoute"]}
                        width="100%"
                        height="70vh"
                        defaultState={{ center: [42.8623946,74.5962726], zoom: 12 }}>
                      </Map>
                    </YMaps>
                  </CTabPane>
                  <CTabPane data-tab="signature">
                    <CRow>
                      <CCol>
                        <CCard>
                          <CCardBody>
                            <h6><b>{t("Sender")}</b></h6>
                            <div className="text-center mt-2">
                              <img alt="signature" className="img-thumbnail" src={orderDetails.sender_signature || Rectangle}/>
                            </div>
                          </CCardBody>
                        </CCard>
                      </CCol>
                      <CCol>
                        <CCard>
                          <CCardBody>
                            <h6><b>{t("Receiver")}</b></h6>
                            <div className="text-center mt-2">
                              <img alt="signature" className="img-thumbnail" src={orderDetails.receiver_signature || Rectangle}/>
                            </div>
                          </CCardBody>
                        </CCard>
                      </CCol>
                    </CRow>
                  </CTabPane>
                  <CTabPane data-tab="photo">
                    <PhotoDetails orderDetails={orderDetails}/>
                  </CTabPane>
                </CTabContent>
              </CTabs>
            </div>
          </CContainer>
        </CModalBody>
        }

        <CModal show={isConfirmOpen} onClose={onConfirmToggle}>
          <CModalHeader closeButton>{t("Confirm")}</CModalHeader>
          <CModalBody>
            <p>{t("Are you sure?")}</p>
          </CModalBody>
          <CModalFooter>
            <CButton
                color="danger"
                onClick={() => deleteOrder()}
                className="mr-2"
            >
              {t("Confirm")}
            </CButton>
          </CModalFooter>
        </CModal>
      </CModal>
  )
}