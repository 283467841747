import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import {CRow, CCol, CButton, CLabel, CSelect, CInput} from '@coreui/react';
import Select from 'react-select';
import chevronUp from "src/assets/chevron-up.svg"
import chevronDown from "src/assets/chevron-down.svg"
import { toastify } from 'src/helpers/toast';
import { fetchClientOrganizations, fetchCreateOrganization } from 'src/services/ClientService';
import CreatableSelect from 'react-select/creatable';
import { paymentList } from 'src/helpers/paymentsDict';


export function TariffFields({
    tariffs, tariffSelect, orderData, updateOrderData, setButtonClickable
}) {

    const {t} = useTranslation();
    const [showExtraFees, setShowExtraFees] = useState(true)
    const [organizations, setOrganizations] = useState([])
    const [showPaymentDetails, setShowPaymentDetails] = useState(false)


    useEffect(() => {
        fetchClientOrganizations().then(res => res.ok && res.json().then(d => setOrganizations(d)))
    }, [])

    const createOrg = (res, field) => {
        setButtonClickable(false)
        fetchCreateOrganization({name: res}).then(res => {
            if(res.ok) {
                res.json().then(d => {
                    updateOrderData(field, {label: d.name, value: d.id, ...d})
                    setButtonClickable(true)
                })
            } else {
                toastify("warning", "Не удалось создать организацию")
                setButtonClickable(true)
            }
        }).catch(err => {
            toastify("warning", "Не удалось создать организацию")
            setButtonClickable(true)
        })
    }

    return (
        <div>
            <h6 className="text-dark text-uppercase my-n1 font-xs">{t('Tariff')}</h6>
            <hr className="mt-0 my-2" />
           
            <Select 
                options={tariffs.map(tariff => { return { value: tariff.id,label: tariff.name, ...tariff}})}  
                onChange={res => tariffSelect(res)} 
                value={orderData.selectedTariff || { value: tariffs[0]?.id,label: tariffs[0]?.name, ...tariffs[0]}}/>
            {(orderData.redemptionFee || orderData.weightFee || orderData.zoneFee) ? 
                <div>
                    <CLabel className="mt-1 w-100 rounded py-1 border-bottom" onClick={() => setShowExtraFees(!showExtraFees)}>
                        <span>{t("Extra fee")}</span>
                        <span className="float-right">
                            <img src={showExtraFees ? chevronUp : chevronDown} alt="toggle"/>
                        </span>
                    </CLabel>
                {showExtraFees && <div>
                    <CRow>
                        <CCol className="font-xs" sm="auto">{t("redemption fee")}</CCol>
                        <CCol className="text-right">{orderData.redemptionFee && "+" + orderData.redemptionFee}<u>с</u>
                        <input type="checkbox" className="ml-1" 
                            checked={orderData.redemptionFeeCheck || false} 
                            onChange={(e) => {
                                const checked = e.target.checked
                                updateOrderData("redemptionFeeCheck", checked)
                                updateOrderData("final_price", checked ? 
                                parseInt(orderData.final_price) + parseInt(orderData.redemptionFee || 0) :
                                parseInt(orderData.final_price) - parseInt(orderData.redemptionFee || 0));
                        }}/>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol className="font-xs" sm="auto">{t("weight fee")}</CCol>
                        <CCol className="text-right">{orderData.weightFee && "+" + orderData.weightFee}<u>с</u>
                        <input type="checkbox" className="ml-1"
                            checked={orderData.weightFeeCheck || false} 
                            onChange={(e) => {
                                const checked = e.target.checked
                                updateOrderData("weightFeeCheck", checked)
                                updateOrderData("final_price", checked ? 
                                parseInt(orderData.final_price) + parseInt(orderData.weightFee || 0) :
                                parseInt(orderData.final_price) - parseInt(orderData.weightFee || 0));
                        }}/>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol className="font-xs" sm="auto">{t("zone fee")}</CCol>
                        <CCol className="text-right">{orderData.zoneFee && "+" + orderData.zoneFee}<u>с</u>
                        <input type="checkbox" className="ml-1"
                            checked={orderData.zoneFeeCheck || false} 
                            onChange={(e) => {
                                const checked = e.target.checked
                                updateOrderData("zoneFeeCheck", checked)
                                updateOrderData("final_price", checked ? 
                                parseInt(orderData.final_price) + parseInt(orderData.zoneFee || 0) :
                                parseInt(orderData.final_price) - parseInt(orderData.zoneFee || 0));
                        }}/>
                        </CCol>
                    </CRow>
                </div>}
            </div> : ""}
            <CRow className="my-2">
                <CCol className="my-auto"><b>{t("Overall")}</b></CCol>
                <CCol>
                    <CInput value={orderData.final_price || ""} onChange={e => {
                        updateOrderData("final_price", e.target.value);
                    }}/>
                </CCol>
            </CRow>
            <b>{t('Pays')}:</b><br />
            <CRow>
                <CCol className="pr-0">
                    <CButton style={{borderRadius: "5px 0 0 5px"}} className={"btn text-truncate d-flex font-xs p-1 btn-block btn-sm " 
                        + (orderData.paying_side==="sender" ? "btn-success" : "btn-outline-success")}
                        onClick={() => updateOrderData("paying_side", "sender")}>
                        {t("Sender")}
                    </CButton>
                </CCol>
                <CCol className="px-0">
                    <CButton style={{borderRadius: "0 0 0 0"}} className={"btn text-truncate mx-auto font-xs p-1 btn-block btn-sm " 
                        + (orderData.paying_side==="jointly" ? "btn-success" : "btn-outline-success")}
                        onClick={() => updateOrderData("paying_side", "jointly")}>
                        {t("Together")}
                    </CButton>
                </CCol>
                <CCol className="pl-0">
                    <CButton style={{borderRadius: "0 5px 5px 0"}} className={"btn text-truncate d-flex font-xs p-1 btn-block btn-sm " 
                        + ((orderData.paying_side!=="sender" && orderData.paying_side!=="jointly") ? "btn-success" : "btn-outline-success")}
                        onClick={() => updateOrderData("paying_side", "receiver")}>
                        {t("Receiver")}
                    </CButton>
                </CCol>
            </CRow>
            
            <CLabel className="mt-2 w-100 border-bottom rounded py-1" onClick={() => setShowPaymentDetails(!showPaymentDetails)}>
                <span>{t("Payment details")}</span>
                <span className="float-right">
                    <img src={showPaymentDetails ? chevronUp : chevronDown} alt="toggle"/>
                </span>    
            </CLabel>
            {showPaymentDetails && <div>

{/* sender */}
                {(orderData.paying_side === "sender" || orderData.paying_side === "jointly") && <div>
                {orderData.paying_side === "jointly" && <>
                    <CLabel size="sm" className="my-0">
                        {t("Sender amount")}
                    </CLabel>
                    <CInput size="sm" value={orderData.sender_payment_amount} placeholder={t("Sender amount")} type="number"
                    onChange={e => {
                        let amount = parseInt(e.target.value)
                        if(!amount || amount <= parseInt(orderData.final_price)) {
                            updateOrderData("sender_payment_amount", amount)
                            updateOrderData("receiver_payment_amount", parseInt(orderData.final_price) - amount)
                        } else {
                            toastify("info", t("Amount must be less than final price"))
                        }
                        }}/>
                </>}
                <CLabel className="my-0">{t("Payment type")}</CLabel>
                <CRow>
                    <CCol className="pr-0">
                        <CButton style={{borderRadius: "5px 0 0 5px"}} 
                        className={"btn d-flex text-truncate font-xs p-1 btn-block btn-sm " + (orderData.sender_payment_type==="invoice" ? "btn-success" : "btn-outline-success")}
                            onClick={() => updateOrderData("sender_payment_type", "invoice")}>
                            {t("Invoice")}
                        </CButton>
                    </CCol>
                    <CCol className="pl-0">
                        <CButton style={{borderRadius: "0 5px 5px 0"}} 
                        className={"btn d-flex text-truncate  font-xs p-1 btn-block btn-sm " + (orderData.sender_payment_type!=="invoice" ? "btn-success" : "btn-outline-success")}
                            onClick={() => updateOrderData("sender_payment_type", "service")}>
                            {t("Instant")}
                        </CButton>
                    </CCol>
                </CRow>
                {orderData.sender_payment_type === "invoice" && <>
                    <CLabel className="my-0">{t("Organization")}</CLabel>
                    <CreatableSelect 
                        styles={{
                            control: base => ({
                                ...base,
                                height: 30,
                                minHeight: 30
                            })
                        }}
                        formatCreateLabel={res => t("Create") + " " + res}
                        options={organizations.map(or => {return { value: or.id, label: or.name, ...or}})}  
                        placeholder={t("Organization")}
                        onChange={res => updateOrderData("invoice_organization_sender", res)} 
                        value={orderData.invoice_organization_sender}
                        onCreateOption={res => createOrg(res, "invoice_organization_sender")}
                    />
                </>}
                <CLabel className="my-0">{t("Payment method")}</CLabel>
                <CSelect size="sm" className="mt-1" value={orderData.sender_payment_method} onChange={e => updateOrderData("sender_payment_method", e.target.value)}>
                    {paymentList.map(payment => 
                        <option value={payment.value}>{t(payment.label)}</option>
                    )}
                </CSelect>
                <CLabel className="my-0">{t("Payment status")}</CLabel>
                <CRow>
                    <CCol className="pr-0">
                        <CButton style={{borderRadius: "5px 0 0 5px"}} 
                        className={"btn d-flex font-xs p-1 btn-block btn-sm " + (orderData.sender_payment_is_paid===true ? "btn-success" : "btn-outline-success")}
                            onClick={() => updateOrderData("sender_payment_is_paid", true)}>
                            {t("Paid")}
                        </CButton>
                    </CCol>
                    <CCol className="pl-0">
                        <CButton style={{borderRadius: "0 5px 5px 0"}} 
                        className={"btn d-flex font-xs p-1 btn-block btn-sm " + (orderData.sender_payment_is_paid!==true ? "btn-dark" : "btn-outline-dark")}
                            onClick={() => updateOrderData("sender_payment_is_paid", false)}>
                            {t("Not paid")}
                        </CButton>
                    </CCol>
                </CRow>
                </div>}

{/* receiver */}
                {(!orderData.paying_side || orderData.paying_side === "receiver" || orderData.paying_side === "jointly") && <div>
                    {orderData.paying_side === "jointly" && <>
                        <CLabel size="sm" className="my-0">
                            {t("Receiver amount")}
                        </CLabel>
                        <CInput size="sm" value={orderData.receiver_payment_amount} placeholder={t("Receiver amount")} type="number"
                        onChange={e => {
                            let amount = parseInt(e.target.value)
                            if(!amount || amount <= parseInt(orderData.final_price)) {
                                updateOrderData("receiver_payment_amount", amount)
                                updateOrderData("sender_payment_amount", parseInt(orderData.final_price) - amount)
                            } else {
                                toastify("info", t("Amount must be less than final price"))
                            }
                            }}/>
                    </>}
                    <CLabel className="my-0">{t("Payment type")}</CLabel>
                    <CRow>
                        <CCol className="pr-0">
                            <CButton style={{borderRadius: "5px 0 0 5px"}} 
                            className={"btn d-flex text-truncate font-xs p-1 btn-block btn-sm " + (orderData.receiver_payment_type==="invoice" ? "btn-success" : "btn-outline-success")}
                                onClick={() => updateOrderData("receiver_payment_type", "invoice")}>
                                {t("Invoice")}
                            </CButton>
                        </CCol>
                        <CCol className="pl-0">
                            <CButton style={{borderRadius: "0 5px 5px 0"}} 
                            className={"btn d-flex text-truncate font-xs p-1 btn-block btn-sm " + (orderData.receiver_payment_type!=="invoice" ? "btn-success" : "btn-outline-success")}
                                onClick={() => updateOrderData("receiver_payment_type", "service")}>
                                {t("Instant")}
                            </CButton>
                        </CCol>
                    </CRow>
                    {orderData.receiver_payment_type === "invoice" && <>
                        <CLabel className="my-0">{t("Organization")}</CLabel>
                        <CreatableSelect 
                            styles={{
                                control: base => ({
                                  ...base,
                                  height: 30,
                                  minHeight: 30
                                })
                              }}
                            formatCreateLabel={res => t("Create") + " " + res}
                            options={organizations.map(or => {return { value: or.id, label: or.name, ...or}})}  
                            placeholder={t("Organization")}
                            onChange={res => updateOrderData("invoice_organization_receiver", res)} 
                            value={orderData.invoice_organization_receiver}
                            onCreateOption={res => createOrg(res, "invoice_organization_receiver")}
                        />
                    </>}
                    <CLabel className="my-0">{t("Payment method")}</CLabel>
                    <CSelect size="sm" className="mt-1" value={orderData.receiver_payment_method} onChange={e => updateOrderData("receiver_payment_method", e.target.value)}>
                        {paymentList.map(payment => 
                            <option value={payment.value}>{t(payment.label)}</option>
                        )}
                    </CSelect>
                    <CLabel className="my-0">{t("Payment status")}</CLabel>
                    <CRow>
                        <CCol className="pr-0">
                            <CButton style={{borderRadius: "5px 0 0 5px"}} 
                            className={"btn d-flex font-xs p-1 btn-block btn-sm " + (orderData.receiver_payment_is_paid===true ? "btn-success" : "btn-outline-success")}
                                onClick={() => updateOrderData("receiver_payment_is_paid", true)}>
                                {t("Paid")}
                            </CButton>
                        </CCol>
                        <CCol className="pl-0">
                            <CButton style={{borderRadius: "0 5px 5px 0"}} 
                            className={"btn d-flex font-xs p-1 btn-block btn-sm " + (orderData.receiver_payment_is_paid!==true ? "btn-dark" : "btn-outline-dark")}
                                onClick={() => updateOrderData("receiver_payment_is_paid", false)}>
                                {t("Not paid")}
                            </CButton>
                        </CCol>
                    </CRow>
                </div>}
            </div>}
        </div>
    )
}
