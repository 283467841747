import {
    COURIER_SUCCESS,
    COURIER_LOADING,
    COURIER_FAIL
  } from '../types/courierTypes'
  
  const initialState = {
    couriers: [],
    error: null,
    isLoading: true
  }
  
  export default function courierReducer(state = initialState, action) {
    switch (action.type) {
      case COURIER_LOADING:
        return {
          ...state,
          isLoading: true,
          error: null
        }
      case COURIER_SUCCESS:
        return {
          ...state,
          couriers: action.payload,
          isLoading: false,
          error: null
        }
      case COURIER_FAIL:
        return {
          ...state,
          isLoading: false,
          error: action.payload || null
        }
      default:
        return {
          ...state
        }
    }
  }
  