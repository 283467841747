import {
    CCard,
    CCardBody,
    CTabs,
    CNav,
    CNavItem,
    CNavLink,
    CTabContent,
    CTabPane
  } from "@coreui/react";
  import React, { useEffect, useState } from "react";
  import { useTranslation } from "react-i18next";
  import { setBreadcrumb } from "src/redux/actions/settingsActions";
  import { useDispatch } from "react-redux";
//   import { toastify } from "src/helpers/toast";
import { fetchPartnerOperators, fetchPartners } from "src/services/PartnerService";
import PartnersTable from "./PartnersTable";
import PartnerOperators from "./PartnerOperators";
  
  export default function Partners() {
  
    const dispatch = useDispatch();
  
    const { t } = useTranslation();
  
    const [partners, setPartners] = useState([]);
    const [operators, setOperators] = useState([]);
    const [activeTab, setActiveTab] = useState("partners");

  
    useEffect(() => {
      dispatch(setBreadcrumb(["Partners"]));
      fetchPartners().then(res => res.ok && res.json().then(data => {
        setPartners(data)
      }))
      fetchPartnerOperators().then(res => res.ok && res.json().then(data => {
        console.log(data)
        setOperators(data)
      }))
    }, [dispatch]);
    
    
    return (
      <CCard className="m-2">
        <CCardBody>
        <CTabs activeTab={activeTab}>
          <CNav variant="tabs">
            <CNavItem>
              <CNavLink
                data-tab="partners"
                onClick={() => setActiveTab("partners")}
              >
                {t("Partners")}
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                data-tab="operators"
                onClick={() => setActiveTab("operators")}
              >
                {t("Partner operators")}
              </CNavLink>
            </CNavItem>
          </CNav>

          <CTabContent>
            <CTabPane data-tab="partners">
              <PartnersTable partners={partners} setPartners={setPartners}/>
            </CTabPane>
            <CTabPane data-tab="operators">
              <PartnerOperators partners={partners} operators={operators} setOperators={setOperators}/>
            </CTabPane>
          </CTabContent>
          </CTabs>

          
        </CCardBody> 
        
       
      
      </CCard>
    );
  }
  