import {
  CCard,
  CCardHeader,
  CCardBody,
  CForm,
  CFormGroup,
  CLabel,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CRow,
  CBadge,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDeleteOperator } from "src/services/OperatorService";

import { getOperators } from "../../redux/actions/operatorActions";
import { useModalState, useConfirmModal } from "../../helpers/useModal";
import { useTranslation } from "react-i18next";
import OperatorForm from "./OperatorForm";
import { setBreadcrumb } from "src/redux/actions/settingsActions";
import { toastify } from "src/helpers/toast";

export default function Operators() {
  const dispatch = useDispatch();

  const operators = useSelector((state) => state.operator.operators);

  const reFetchOperators = async () => dispatch(getOperators());
  const { t } = useTranslation();

  const { isOpen, onToggle } = useModalState();
  const { isConfirmOpen, onConfirmToggle } = useConfirmModal();

  const [modalTitle, setModalTitle] = useState("Modal Title");
  const [selectedOperator, setSelectedOperator] = useState({});

  const newOperatorForm = () => {
    setSelectedOperator({});
    setModalTitle(t("Create Operator"));
    onToggle();
  };

  const showEdit = (operator) => {
    setSelectedOperator(operator);
    setModalTitle(t("Edit operator"));
    reFetchOperators();
    onToggle();
  };

  const showDelete = (operator) => {
    setSelectedOperator(operator);
    setModalTitle(t("Delete operator"));
    reFetchOperators();
    onConfirmToggle();
  };

  const deleteOperator = (id) => {
    console.log(id);
    fetchDeleteOperator(id)
      .then((res) => {
        if (res.ok) {
          toastify("success", t("Оператор удален"));
          reFetchOperators();
        } else {
          toastify("error", "Произошла ошибка удаления");
        }
      })
      .catch((err) => toastify("error", "Произошла ошибка, проверьте интернет"))
      .finally(onConfirmToggle);
  };

  useEffect(() => {
    dispatch(getOperators());
    dispatch(setBreadcrumb(["Operators"]));
  }, [dispatch]);

  return (
    <CCard className="m-2">
      <CCardHeader>
        <CButton
          variant="outline"
          color="success"
          className="float-right"
          onClick={() => {
            newOperatorForm();
            setSelectedOperator({});
          }}
        >
          {t("Create new operator")}
        </CButton>
      </CCardHeader>
      <CCardBody>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>{t("Photo")}</th>
              <th>{t("Username")}</th>
              <th>{t("Firstname")}</th>
              <th>{t("Lastname")}</th>
              <th>{t("Roles")}</th>
              <th>{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {operators &&
              operators.map((oper) => (
                <tr key={oper.id}>
                  <td>
                    <img
                      className="sp-package-mini"
                      src={oper.photo}
                      alt={oper.username}
                    />
                  </td>
                  <td>{oper.username}</td>
                  <td>{oper.firstname}</td>
                  <td>{oper.lastname}</td>
                  <td>{oper.roles.map(r => 
                    <CBadge color="primary" className="mr-1">{r}</CBadge>
                    )}</td>
                  <td>
                    <CIcon
                      name="cilTrash"
                      className="text-danger mr-2"
                      size="xl"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        showDelete(oper);
                      }}
                    />
                    <CIcon
                      name="cilPencil"
                      className="text-warning"
                      size="xl"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        showEdit(oper);
                      }}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </CCardBody>

      <CModal show={isConfirmOpen} onClose={onConfirmToggle}>
        <CModalHeader closeButton>{modalTitle}</CModalHeader>
        <CModalBody>
          {selectedOperator && (
            <CForm>
              <CFormGroup>
                <CLabel htmlFor="nf-username">
                  {t("Delete this operator? ")} {selectedOperator.username}
                </CLabel>
              </CFormGroup>
            </CForm>
          )}
        </CModalBody>
        <CModalFooter>
          {selectedOperator && (
            <CRow>
              <CButton
                color="danger"
                onClick={() => deleteOperator(selectedOperator.id)}
                className="mr-2"
              >
                {t("Delete")}
              </CButton>
            </CRow>
          )}
        </CModalFooter>
      </CModal>

      <OperatorForm
        toggle={onToggle}
        modal={isOpen}
        reFetchOperators={reFetchOperators}
        modalTitle={modalTitle}
        selectedOperator={selectedOperator}
      />
    </CCard>
  );
}
