import {
    CForm,
    CButton,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CRow,
    CCol,
    CLabel,
    CInput,
    CSelect,
    CSwitch,
  } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toastify } from "src/helpers/toast";
import { fetchCompanies, fetchCompanyPackageTypes, fetchCompanyStatuses } from "src/services/CompanyService";
import { fetchCreatePartner, fetchUpdatePartner } from "src/services/PartnerService";
import { fetchTariffs } from "src/services/TariffService";
import { fetchUsersByRole } from "src/services/UsersService";


export default function PartnerForm(props) {
   
    const { t } = useTranslation();

    const [partnerForm, setPartnerForm] = useState(
        {
            "name": "",
            "source": "",
            "tariff_id": null,
            "delivery_service_id": null,
            "package_type_id": null,
            "take_fee": false,
            "author_id": null,
            "login": "",
            "password": "",
            "status": "created"
          }
    );
    const [deliveryServices, setDeliveryServices] = useState([])
    const [tariffs, setTariffs] = useState([])
    const [packageTypes, setPackageTypes] = useState([])
    const [statuses, setStatuses] = useState([])

    const [partnerUsers, setPartnerUsers] = useState([])

    useEffect(() => {
        fetchUsersByRole("partner").then(res => res.ok && res.json().then(d => setPartnerUsers(d)))

        if(props.selectedPartner) {
            setPartnerForm({...props.selectedPartner, 
                "delivery_service_id": props.selectedPartner.delivery_service?.id,
                "package_type_id": props.selectedPartner.package_type?.id,
                "tariff_id": props.selectedPartner.tariff?.id
            })
        }
    }, [props.selectedPartner])

    useEffect(() => {
        fetchCompanies().then(res => res.ok && res.json().then(d => {
            setDeliveryServices(d)
        }))
        fetchTariffs().then(res => res.ok && res.json().then(d => {
            setTariffs(d)
        }))
        fetchCompanyPackageTypes().then(res => res.ok && res.json().then(d => {
            setPackageTypes(d)
        }))
        fetchCompanyStatuses().then(res => res.ok && res.json().then(d => {
            setStatuses(d);
        }))

    }, [])

    const handleChange = (e) => {
        setPartnerForm({...partnerForm, [e.target.name]: e.target.value})
    }

    const handleSwitch = (value) => {
        setPartnerForm({...partnerForm, "take_fee": value})
    }

    const submitForm = () => {
        console.log(partnerForm);
        let formData = partnerForm;
        if(formData.delivery_service_id === "None") {
            formData.delivery_service_id = null
        }
        if(props.selectedPartner) {
            fetchUpdatePartner(props.selectedPartner.id, partnerForm).then(res => {
                if(res.ok) {
                    props.onSuccess();
                } else {
                    toastify("error", "Operation failed")
                }
            })
        } else {
            fetchCreatePartner(partnerForm).then(res => {
                if(res.ok) {
                    props.onSuccess();
                } else {
                    toastify("error", "Operation failed")
                }
            })
        }
    }
  
    return (
        <CModal show={props.isOpen} onClose={props.onToggle} size="lg">
          <CModalHeader closeButton>{props.modalTitle}</CModalHeader>
          <CModalBody>
            <CForm>
                <CRow>
                    <CCol sm="6">
                        <CLabel>{t("Name")}</CLabel>
                        <CInput value={partnerForm.name} onChange={e => handleChange(e)} name="name" placeholder="name"/>
                        <CLabel>{t("Source")}</CLabel>
                        <CInput value={partnerForm.source} onChange={e => handleChange(e)} name="source" placeholder="source"/>
                        <CLabel>{t("Author")}</CLabel>
                        <CSelect value={partnerForm.author_id} onChange={e => handleChange(e)} name="author_id">
                            {partnerUsers.map((p, i) => 
                                <option key={i} value={p.id}>{p.firstname}</option>
                            )}
                        </CSelect>
                        <CLabel>{t("Login")}</CLabel>
                        <CInput value={partnerForm.login} onChange={e => handleChange(e)} name="login" placeholder="login"/>
                        <CLabel>{t("Password")}</CLabel>
                        <CInput value={partnerForm.password} onChange={e => handleChange(e)} name="password" placeholder="password"/>
                    </CCol>
                    <CCol sm="6">
                        <CLabel>{t("Tariff")}</CLabel>
                        <CSelect value={partnerForm.tariff_id} name="tariff_id" onChange={e => handleChange(e)}>
                            <option disabled>None</option>
                            {tariffs.map((t, i) => 
                                <option key={i} value={t.id}>{t.name}</option>)}
                        </CSelect>
                        <CLabel>{t("Delivery service")}</CLabel>
                        <CSelect value={partnerForm.delivery_service_id} name="delivery_service_id" onChange={e => handleChange(e)}>
                            <option>None</option>
                            {deliveryServices.map((d,i) => 
                                <option key={i} value={d.id}>{d.name}</option>)}
                        </CSelect>
                        <CLabel>{t("Package type")}</CLabel>
                        <CSelect value={partnerForm.package_type_id} name="package_type_id" onChange={e => handleChange(e)}>
                            <option disabled>None</option>
                            {packageTypes.map((p, i) => 
                                <option key={i} value={p.id}>{p.name_ru}</option>)}
                        </CSelect>
                        <CLabel>{t("Status")}</CLabel>
                        <CSelect value={partnerForm.status} name="status" onChange={e => handleChange(e)}>
                            {statuses.map((s, i) => <option key={i} value={s.value}>{s.name_ru}</option>)}
                        </CSelect>
                        <CLabel>{t("Take fee")}</CLabel>
                        <div>
                            <CSwitch className="float-right" color="primary" checked={partnerForm.take_fee} name="take_fee" onChange={e=> handleSwitch(e.target.checked)}/>
                        </div>
                    </CCol>
                </CRow>
            </CForm>
          </CModalBody>
          <CModalFooter>
            <CButton
                color="success"
                onClick={() => submitForm()}
                className="mr-2"
            >
                {!props.selectedPartner ? t("Create") : t("Update")} 
            </CButton>
          </CModalFooter>
        </CModal>
    );
  }
  