import { useState } from "react";
import { sideBarPages } from "../helpers/constants";

export default function useTabs({ onLastPage }) {
  const [activePage, setActivePage] = useState(1);

  const onGoToNextPage = (e) => {
    const pagesNumber = sideBarPages.length;
    if (activePage < pagesNumber) {
      setActivePage((activePage) => (activePage += 1));
    }
    if (activePage === 4) {
      onLastPage();
    }
  };

  return {
    onGoToNextPage,
    activePage,
    setActivePage,
  };
}
