import {
  CCol,
  CFormText,
  CInput,
  CInputGroup,
  CLabel,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import React from "react";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import { onUploadPhoto } from "../helpers/helpers";

export default function PersonalDataTab({
  initForm,
  handleUserNameChange,
  handleInputChange,
  fieldChecked,
  onUploadPhotoSuccess,
}) {
  const { t } = useTranslation();
  return (
    <>
      <CRow className="ml-2">
        <CCol xs={10}>
          <CRow>
            <CCol xs={4}>
              <div
                style={{
                  width: "95px",
                  height: "95px",
                  borderRadius: "50%",
                }}
              >
                <img
                  src={initForm.photo}
                  alt="phot"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              </div>
            </CCol>

            <CCol xs={8}>
              <CInput
                type="file"
                className="mt-2"
                placeholder={t("Upload a photo")}
                style={{ width: "87%" }}
                onChange={(e) =>
                  onUploadPhoto(e, "photo", onUploadPhotoSuccess)
                }
              />
              <CFormText
                style={{ fontSize: "10px" }}
                className="mt-2 help-block"
              >
                Разрешается png, jpg. Макс размер файла 800к.
              </CFormText>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <CRow
        className="ml-2 mt-2"
      >
        <CCol xs={10}>
          <CLabel>{t("Логин")}*</CLabel>
          <CInputGroup>
            <div
              className="d-flex align-items-center"
              style={{ width: "100%" }}
            >
              <PhoneInput
                onlyCountries={["kg"]}
                country={"kg"}
                value={initForm.username}
                name="username"
                onChange={(e) => handleUserNameChange(e)}
                placeholder="+996 xxx xxx xxx"
                masks={{ kg: "(...) ..-..-.." }}
                inputStyle={{ width: "100%" }}
              />

              <CIcon
                name={fieldChecked.username ? "cilCheckCircle" : "cilWarning"}
                className={
                  "ml-2 " +
                  (fieldChecked.username ? "text-success" : "text-danger")
                }
                size="xl"
              />
            </div>
            {!initForm.username && (
              <span style={{ fontSize: "10px", color: "red" }}>
                Введите номер
              </span>
            )}
          </CInputGroup>

          <CLabel className="mt-3">{t("Password")}</CLabel>
          <CInput
            style={{ width: "91%" }}
            value={initForm.password}
            name="password"
            type="text"
            placeholder={t("Password")}
            onChange={(e) => handleInputChange(e)}
          />

          <CLabel className="mt-3">{t("Firstname")}</CLabel>
          <CInputGroup>
            <div
              className="d-flex align-items-center"
              style={{ width: "100%" }}
            >
              <CInput
                value={initForm.firstname}
                name="firstname"
                type="text"
                onChange={(e) => handleInputChange(e)}
                placeholder={t("Firstname")}
              />
              <CIcon
                name={fieldChecked.firstname ? "cilCheckCircle" : "cilWarning"}
                className={
                  "ml-2 " +
                  (fieldChecked.firstname ? "text-success" : "text-danger")
                }
                size="xl"
              />
            </div>
          </CInputGroup>

          {!initForm.firstname && (
            <span
              style={{ fontSize: "10px", color: "red" }}
              className="d-block"
            >
              Вы не указали имя
            </span>
          )}

          <CLabel className="mt-3">{t("Lastname")}</CLabel>
          <CInputGroup>
            <div
              className="d-flex align-items-center"
              style={{ width: "100%" }}
            >
              <CInput
                value={initForm.lastname}
                name="lastname"
                type="text"
                placeholder={t("Lastname")}
                onChange={(e) => handleInputChange(e)}
              />
              <CIcon
                name={fieldChecked.lastname ? "cilCheckCircle" : "cilWarning"}
                className={
                  "ml-2 " +
                  (fieldChecked.lastname ? "text-success" : "text-danger")
                }
                size="xl"
              />
            </div>
          </CInputGroup>

          <CLabel className="mt-3">{t("Middlename")}</CLabel>
          <CInput
            style={{ width: "91%" }}
            value={initForm.middlename}
            name="middlename"
            type="text"
            placeholder={t("Middlename")}
            onChange={(e) => handleInputChange(e)}
          />
        </CCol>
      </CRow>
    </>
  );
}
