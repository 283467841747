import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import {CRow, CCol, CInput, CSelect, CTextarea, CLabel, CButton} from '@coreui/react';
import chevronUp from "src/assets/chevron-up.svg"
import chevronDown from "src/assets/chevron-down.svg"

export function CommentFields({orderData, updateOrderData, profile, detectRedemptionFee}) {

    const {t} = useTranslation();

    const [showRedemptionDetails, setShowRedemptionDetails] = useState(true)

    return (
        <div>
            <h6 className="text-dark text-uppercase my-n1 font-xs">{t('Comments')}</h6>
            <hr className="mt-0 my-2" />            
            <CRow className="my-2">
                <CCol xs="5" className="my-auto"><b>{t("Redemption")}:</b></CCol>
                <CCol xs="7">
                    <CInput placeholder="Сумма выкупа" className="my-2" value={orderData.redemption_amount || ""} onChange={e => {
                        updateOrderData("redemption_amount", e.target.value); 
                        detectRedemptionFee(e.target.value)}
                        }
                    />
                </CCol>
            </CRow>
            {(orderData.redemption_amount && orderData.redemption_amount > 0 && !profile?.roles?.includes("partner")) ? <div>
                <CLabel className="mt-1 w-100 border-bottom rounded py-1" onClick={() => setShowRedemptionDetails(!showRedemptionDetails)}>
                    <span>{t("Redemption details")}</span>
                    <span className="float-right">
                        <img src={showRedemptionDetails ? chevronUp : chevronDown} alt="toggle"/>
                    </span>    
                </CLabel>
                {showRedemptionDetails && <div>
                    <CLabel className="my-0">{t("Redemption type")}</CLabel>
                    <CRow>
                        <CCol className="pr-0">
                            <CButton style={{borderRadius: "5px 0 0 5px"}} 
                            className={"btn d-flex font-xs p-1 btn-block btn-sm " + (orderData.redemption_payment_type!=="imposed" ? "btn-success" : "btn-outline-success")}
                                onClick={() => updateOrderData("redemption_payment_type", "redemption")}>
                                {t("From cash register")}
                            </CButton>
                        </CCol>
                        <CCol className="pl-0">
                            <CButton style={{borderRadius: "0 5px 5px 0"}} 
                            className={"btn d-flex font-xs p-1 btn-block btn-sm " + (orderData.redemption_payment_type==="imposed" ? "btn-dark" : "btn-outline-dark")}
                                onClick={() => updateOrderData("redemption_payment_type", "imposed")}>
                                {t("Imposed")}
                            </CButton>
                        </CCol>
                    </CRow>
                    <CLabel className="my-0">{t("Redemption method")}</CLabel>
                    <CSelect size="sm" className="mt-1" onChange={e => updateOrderData("redemption_payment_method", e.target.value)} value={orderData.redemption_payment_method}>
                        <option value="cash">{t("Cash")}</option>
                        <option value="invoice">{t("Банковский перевод")}</option>
                        <option value="elsom">{t("Элсом")}</option>
                        <option value="mbank">{t("Мбанк")}</option>
                        <option value="optima">{t("Оптима")}</option>
                        <option value="o_dengi">{t("O! деньги")}</option>
                        <option value="megapay">{t("Мега Pay")}</option>
                        <option value="balance_kg">{t("Balance KG")}</option>
                        <option value="other">{t("Other")}</option>
                    </CSelect>
                    {/* <CLabel className="my-0">{t("Redemption status")}</CLabel>
                    <CRow>
                        <CCol className="pr-0">
                            <CButton style={{borderRadius: "5px 0 0 5px"}} 
                            className={"btn d-flex font-xs p-1 btn-block btn-sm " + (orderData.redemption_is_paid===true ? "btn-success" : "btn-outline-success")}
                                onClick={() => updateOrderData("redemption_is_paid", true)}>
                                {t("Paid")}
                            </CButton>
                        </CCol>
                        <CCol className="pl-0">
                            <CButton style={{borderRadius: "0 5px 5px 0"}} 
                            className={"btn d-flex font-xs p-1 btn-block btn-sm " + (orderData.redemption_is_paid!==true ? "btn-dark" : "btn-outline-dark")}
                                onClick={() => updateOrderData("redemption_is_paid", false)}>
                                {t("Not paid")}
                            </CButton>
                        </CCol>
                    </CRow> */}
                </div>}
            </div> : ""}
            <CTextarea rows="7" placeholder={t("Comments")} className="mb-2" value={orderData.description || ""} onChange={e => updateOrderData("description", e.target.value)}/>
        </div>
    )
}
