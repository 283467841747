//2ver
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from 'src/redux/actions/settingsActions';
import { fetchCities, fetchAllDistrictsWithPar } from 'src/services/CityService';
import { YMaps, Map, Placemark, Clusterer } from 'react-yandex-maps';

import redPoint from '../../assets/icons/redPoint.png';

const apikey = '14b7fac2-2096-4a85-9aa1-ed9ac48250ff';

export default function CitiesDistrictsOnMap() {
  const dispatch = useDispatch();
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [key, setKey] = useState(0);

  useEffect(() => {
    fetchCities().then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          setCities(data);
        });
      } else {
        console.log(res.statusText);
      }
    });
    dispatch(setBreadcrumb(['Geography On Map']));
  }, [dispatch]);

  const reFetch = useCallback(() => {
    if (key < 2300) {
      setTimeout(
        () =>
          fetchAllDistrictsWithPar(key).then((res) => {
            if (res.ok) {
              res.json().then((data) => {
                setDistricts([...districts, ...data]);
                setKey(key + 150);
              });
            } else {
              console.log(res.statusText);
            }
          }),
        50,
      );
    }
  }, [districts, key]);

  useEffect(() => {
    reFetch();
  }, [reFetch]);

  return (
    <YMaps query={{ load: 'package.full', apikey }}>
      <Map
        // onLoad={(ymaps) => {
        //   setMapApi(ymaps);
        // }}
        width="100%"
        height="100vh"
        modules={['layout.ImageWithContent', 'geoObject.addon.balloon', 'geoObject.addon.hint']}
        defaultState={{
          center: [41.302045596380175, 74.32246890840692],
          zoom: 7,
          controls: ['typeSelector'],
        }}>
        {cities?.map((c) => (
          <Placemark
            key={c.id}
            geometry={[c.lat, c.lng]}
            properties={{
              hintContent: c.name_ru,
            }}
            options={{
              iconLayout: 'default#image',
              iconImageHref: redPoint,
              iconImageSize: [15, 15],
              iconImageOffset: [0, 0],
            }}
          />
        ))}

        <Clusterer
          options={{
            preset: 'islands#invertedRedClusterIcons',
            groupByCoordinates: false,
          }}>
          {districts?.map((d, i) => (
            <Placemark
              key={i}
              properties={{
                hintContent: d.name_ru,
              }}
              options={{
                iconLayout: 'default#image',
                iconImageHref: redPoint,
                iconImageSize: [10, 10],
                iconImageOffset: [0, 0],
              }}
              geometry={[d.lat, d.lng]}
            />
          ))}
        </Clusterer>
      </Map>
    </YMaps>
  );
}

//1ver
// import React, { useEffect, useState, useCallback } from "react";
// import { useDispatch } from "react-redux";
// import { setBreadcrumb } from "src/redux/actions/settingsActions";
// import {
//   fetchCities,
//   fetchAllDistricts,
// } from "src/services/CityService";
// import { YMaps, Map, Placemark, Clusterer } from "react-yandex-maps";
// // import { useTranslation } from "react-i18next";

// import redPoint from "../../assets/icons/redPoint.png";
// // import redPoint from "../../assets/icons/redPoint";

// const apikey = "14b7fac2-2096-4a85-9aa1-ed9ac48250ff";

// export default function CitiesDistrictsOnMap() {
//   // const { i18n } = useTranslation();

//   const dispatch = useDispatch();
//   const [cities, setCities] = useState(null);
//   const [districts, setDistricts] = useState(null);
//   const [mapApi, setMapApi] = useState(null);

//   useEffect(() => {
//     fetchCities().then((res) => {
//       if (res.ok) {
//         res.json().then((data) => {
//           setCities(data);
//           console.log(data);
//         });
//       } else {
//         console.log(res.statusText);
//       }
//     });
//     fetchAllDistricts().then((res) => {
//       if (res.ok) {
//         res.json().then((data) => {
//           setDistricts(data);
//           console.log(data);
//         });
//       } else {
//         console.log(res.statusText);
//       }
//     });
//     dispatch(setBreadcrumb(["Geography On Map"]));
//   }, [dispatch]);

//   return (
//     <>
//       <YMaps query={{ load: "package.full", apikey }}>
//         <Map
//           // instanceRef={map}
//           onLoad={(ymaps) => {
//             setMapApi(ymaps);
//             // console.log(ymaps);
//           }}
//           width="100%"
//           height="100vh"
//           modules={[
//             "layout.ImageWithContent",
//             "geoObject.addon.balloon",
//             "geoObject.addon.hint",
//           ]}
//           defaultState={{
//             center: [41.302045596380175, 74.32246890840692],
//             zoom: 7,
//             controls: ["typeSelector"],
//           }}
//         >
//           {mapApi && (
//             <>
//               <Clusterer
//                 options={{
//                   preset: "islands#invertedRedClusterIcons",
//                   groupByCoordinates: false,
//                 }}
//               >
//                 {cities &&
//                   cities.map((c) => (
//                     <Placemark
//                       key={c.id}
//                       geometry={[c.lat, c.lng]}
//                       properties={{
//                         hintContent: c.name_ru,
//                       }}
//                       options={{
//                         iconLayout: "default#image",
//                         iconImageHref: redPoint,
//                         iconImageSize: [20, 20],
//                         iconImageOffset: [0, 0],
//                       }}
//                     />
//                   ))}
//               </Clusterer>

//               <Clusterer
//                 options={{
//                   preset: "islands#invertedRedClusterIcons",
//                   groupByCoordinates: false,
//                 }}
//               >
//                 {districts &&
//                   districts.map((d) => (
//                     <Placemark
//                       key={d.id}
//                       properties={{
//                         hintContent: d.name_ru,
//                       }}
//                       options={{
//                         iconLayout: "default#image",
//                         iconImageHref: redPoint,
//                         iconImageSize: [15, 15],
//                         iconImageOffset: [0, 0],
//                       }}
//                       geometry={[d.lat, d.lng]}
//                     />
//                   ))}
//               </Clusterer>
//             </>
//           )}
//         </Map>
//       </YMaps>
//     </>
//   );
// }
