import {
  CLIENT_SUCCESS,
  CLIENT_LOADING,
  CLIENT_FAIL,
  CLIENT_ORDERS_LOADING,
  CLIENT_ORDERS_SUCCESS,
  CLIENT_ORDERS_FAIL
} from "../types/clientTypes";

import { fetchClientOrders, fetchClients } from "../../services/ClientService";

export const getClients = () => async (dispatch, getState) => {
  dispatch(clientsLoading());

  fetchClients()
    .then((res) =>
     {
      if(res.ok) {
        res.json().then((data) => {
          dispatch(clientsSuccess(data));
        })
      } else {
        dispatch(clientsFail(res.statusText));
      }
    })
    .catch((err) => {
      dispatch(clientsFail(err));
    });
};

export const getClientOrders = (id, type) => async (dispatch, getState) => {
  dispatch(clientOrdersLoading());


  fetchClientOrders(id, type)
    .then((res) =>
     {
      if(res.ok) {
        res.json().then((data) => {
          dispatch(clientOrdersSuccess(data));
        })
      } else {
        dispatch(clientOrdersFail(res.statusText));
      }
    })
    .catch((err) => {
      dispatch(clientOrdersFail(err));
    });
};

export const clientsLoading = () => ({ type: CLIENT_LOADING });
export const clientsSuccess = (clients) => ({
  type: CLIENT_SUCCESS,
  payload: clients,
});
export const clientsFail = (error) => ({ type: CLIENT_FAIL, payload: error });

export const clientOrdersLoading = () => ({ type: CLIENT_ORDERS_LOADING });
export const clientOrdersSuccess = (orders) => ({
  type: CLIENT_ORDERS_SUCCESS,
  payload: orders,
});
export const clientOrdersFail = (error) => ({ type: CLIENT_ORDERS_FAIL, payload: error });