import React, { useEffect, useState } from "react";
import {
    CAlert,
    CModal,
    CModalBody,
    CButton,
    CSelect,
    CTooltip, CModalHeader, CModalFooter,
} from "@coreui/react";
import { useConfirmModal, useModalState } from "src/helpers/useModal";
import OrderDetails from "src/pages/orders/Order-Details/OrderDetails";
import { useTranslation } from "react-i18next";
import {
    orderDeleteFetch,
} from "src/services/OrderService";
import { toastify } from "src/helpers/toast";
import { useSelector } from "react-redux";
import man_online from "src/assets/icons/man_online.svg"
import man_offline from "src/assets/icons/man_offline.svg"
import man_break from "src/assets/icons/man_break.svg"
import man_default from "src/assets/icons/man_default.svg"
import { date } from "src/helpers/date";
import CIcon from "@coreui/icons-react";
import { useTranslate } from "src/helpers/useTranslate";

const AllCompaniesOrderTable = (props) => {

    const { isOpen, onToggle } = useModalState();
    const [selectedOrder, setSelectedOrder] = useState(null);
    // const [newStatus, setNewStatus] = useState(null);
    const [showInfoId, setShowInfoId] = useState(null)
    const [showInfo, setShowInfo] = useState(false)

    const profile = useSelector(state => state.auth.profile)

    const hexToRgb = (hex) => {
        let arr = hex
            .replace(
                /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
                (m, r, g, b) => "#" + r + r + g + g + b + b
            )
            .substring(1)
            .match(/.{2}/g)
            .map((x) => parseInt(x, 16));
        return `rgb(${arr[0] + 40}, ${arr[1] + 40}, ${arr[2] + 40})`;
    };

    const { onConfirmOpen, onConfirmClose, isConfirmOpen, onConfirmToggle } =
        useConfirmModal();

    const { t } = useTranslation();
    const {tr} = useTranslate();

    const openDetails = (order) => {
        setSelectedOrder(order);
        onToggle();
    };

    const handleShowCourierInfo = (order) => {
        console.log(order, "show order")
        setShowInfoId(order.id)
        setShowInfo(!showInfo)
    }

    const onDeleteOrder = (order) => {
        setSelectedOrder(order);
        onConfirmOpen();
    }

    const onConfirmDeleteOrder = () => {
        orderDeleteFetch(selectedOrder.id).then(res =>
        {
            if(res.ok) {
                toastify("success", "Order deleted successfully");
                props.onDeleteOrder(selectedOrder.id)
                onConfirmClose()
            } else {
                toastify("error", "Something goes wrong")
                res.json().then(d => console.log(d));
            }
        }).catch(err => {
            console.log(err)
            toastify("error", "Network error");
        })

    }

    const changeStatus = (order, status) => {
        // setNewStatus(status);
        setSelectedOrder(order);
        // onConfirmOpen();
    };

    // const confirmStatusChange = () => {
    //     orderStatusChangeFetch(selectedOrder.id, newStatus)
    //         .then((res) => {
    //             if (res.ok) {
    //                 toastify("success", t("Operation succeeded"));
    //                 onConfirmClose();
    //             } else {
    //                 toastify("error", t("Operation failed"));
    //             }
    //         })
    //         .catch((err) => toastify("error", "Error occured"));
    // };



    const chooseIcon = (c) => {
        switch(c.status) {
            case "online":
                return man_online;
            case "on_delivery":
                return man_online;
            case "offline":
                return man_offline;
            case "emergency":
                return man_break;
            default:
                return man_default;
        }
    }

    useEffect(() => {
        if(props.selectedOrderId) {
            setSelectedOrder({id: props.selectedOrderId})
        }
    }, [props.selectedOrderId])

    const p5px = {padding: "5px"}


    return (
        <div>
            <table className="table table-responsive-lg">
                <thead>
                <tr>
                    <th>#</th>
                    <th></th>
                    <th>{t("OID")}</th>
                    <th>{t("Sender")} <CIcon name="cilArrowRight"/> {t("Receiver")}</th>
                    <th>{t("Parcel")}</th>
                    <th>{t("Courier")}</th>
                    <th>{t("Status")}</th>
                    <th>{t("Date")}</th>
                    <th>{t("Tariff")}</th>
                    <th>{t("Redemption")}</th>
                    <th>{t("Price")}</th>
                    {!props.archive &&
                    <th>{t("Actions")}</th>
                    }
                </tr>
                </thead>
                <tbody>
                {props.allOrders.length > 0 &&
                props.allOrders.map((order) => (
                    <tr
                        className="order_table_row"
                        key={order.id}
                        style={{
                            background:
                                order.status === "created" && order.source !== "operator web" ? "linear-gradient(to top, yellow, white)"
                                    :
                                    (                    props.statusList &&
                                        props.statusList.length &&
                                        "linear-gradient(to right, " +
                                        props.statusList.filter(
                                            (s) => s.value === order.status
                                        )[0].color +
                                        " " +
                                        props.statusList.filter(
                                            (s) => s.value === order.status
                                        )[0].percent +
                                        `%, ${hexToRgb(props.statusList.filter(
                                            (s) => s.value === order.status
                                        )[0].color)} 0%)`),
                        }}
                    >
                        <td style={p5px}><b>{order.id}</b></td>

                        <td style={{maxWidth:'45px'}}>
                            {order.id &&
                            <>
                                <CTooltip trigger='click' content={order.delivery_service?.name} placement="bottom">
                                        <img src={order.delivery_service?.mini_logo} alt="courier company icon" style={{width:'30px', height:'30px'}}/>
                                </CTooltip>
                            </>
                            }

                        </td>
                        <td>
                            {order.oid}
                        </td>
                        <td
                            onClick={() => openDetails(order)}
                            style={{ cursor: "pointer", padding: "5px", width: "30%" }}
                        >
                  <span style={{color: "black"}}>
                  {order.location_from?.city && order.location_from?.city?.id !== order.location_to?.city?.id &&
                  tr(order.location_from?.city?.name_ru, order.location_from?.city?.name_kg) + ", "}
                      {tr(order.location_from?.name_ru, order.location_from?.name_kg)}
                      {" "}<CIcon name="cilArrowRight"/>{" "}
                      {order.location_to?.city && order.location_from?.city?.id !== order.location_to?.city?.id &&
                      tr(order.location_to?.city?.name_ru, order.location_to?.city?.name_kg) + ", "}
                      {tr(order.location_to?.name_ru, order.location_to?.name_kg)}
                  </span>
                            <br/>
                            {order.description.substring(0, 40)}{order.description.length > 40 && "..."}
                        </td>
                        <td
                            onClick={() => openDetails(order)}
                            style={{ cursor: "pointer", padding: "5px" }}
                            className="text-center"
                        >
                            <img alt="pack" src={order.package_type?.photo} className="rounded-circle mr-1" width="15"/>
                            {tr(order.package_type?.name_ru, order.package_type?.name_kg)}

                        </td>
                        <td style={p5px} className="text-center">
                            {order.courier &&
                            <>
                                    <div onClick={() => handleShowCourierInfo(order)}>
                                        <img src={chooseIcon(order.courier)} alt="courier icon" className="rounded-circle mr-1" width="15"/>
                                        {order.courier.user?.firstname}
                                    </div>
                                {showInfoId === order.id &&
                                    showInfo ? (
                                    <div style={{position: "relative"}}>
                                        <CAlert style={{ position: "absolute", bottom: "10px"}} color="info">
                                            {order.courier.user.firstname}<br/>
                                            {order.courier.user.username}<br/>
                                            {order.delivery_service.name}
                                        </CAlert>
                                    </div>
                                )
                                    :
                                    null
                                }


                            </>
                            }
                        </td>


                        <td style={{width: "10%", padding: "5px"}}>
                            {props.statusList ? (
                                <CSelect size="sm"
                                    onChange={(e) => changeStatus(order, e.target.value)}
                                    value={order.status} disabled={order.in_pool || props.statusDisabled}
                                >
                                    {props.statusList
                                        .slice(0, 2)
                                        .filter((st) => st.value === order.status)[0]
                                        ? props.statusList &&
                                        props.statusList.map((s, i) => (
                                            <option
                                                key={i}
                                                value={s.value}
                                                style={{
                                                    display:
                                                        (i > 2 || i === 0) &&
                                                        s.value !== "cancelled" &&
                                                        "none",
                                                }}
                                            >
                                                {tr(s?.name_ru, s?.name_kg)}
                                            </option>
                                        ))
                                        : props.statusList &&
                                        props.statusList.map((s, i) => (
                                            <option
                                                key={i}
                                                value={s.value}
                                                style={{
                                                    display: s.value !== "cancelled" && "none",
                                                }}
                                            >
                                                {tr(s?.name_ru, s?.name_kg)}
                                            </option>
                                        ))}
                                    {profile?.roles?.includes("super_admin") && <option value="completed">{tr("завершить", "бүтүрүү")}</option>}
                                </CSelect>
                            ) : (
                                order.status
                            )}
                        </td>
                        <td style={p5px} className="text-right">
                            {date(order.created_at)}
                        </td>
                        <td style={p5px}>{order.tariff?.name} </td>
                        <td style={p5px} className="text-center">{order.redemption_amount} <u>c</u></td>
                        <td style={p5px} className="text-center">{order.final_price} <u>c</u></td>
                        {!props.archive &&
                        <td style={p5px} className="text-center">
                            <CIcon
                                style={{cursor: "pointer"}}
                                className="text-danger"
                                name="cilTrash"
                                onClick={() => onDeleteOrder(order)}
                            />
                        </td>
                        }
                    </tr>
                ))}
                </tbody>
            </table>
            {selectedOrder && <OrderDetails
                isOpen={props.selectedOrderId ? true : isOpen}
                onToggle={onToggle}
                selectedOrder={selectedOrder}
                setSelectedOrder={setSelectedOrder}
            />}

            <CModal show={isConfirmOpen} onClose={onConfirmToggle}>
                <CModalHeader closeButton>{t("Confirm")}</CModalHeader>
                <CModalBody>
                    <p>{t("Are you sure?")}</p>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color="danger"
                        onClick={() => onConfirmDeleteOrder()}
                        className="mr-2"
                    >
                        {t("Confirm")}
                    </CButton>
                </CModalFooter>
            </CModal>
        </div>
    );
};

export default AllCompaniesOrderTable;