import { CButton } from "@coreui/react";
import React from "react";
import { Workbook } from "exceljs";
import * as fs from "file-saver";
import { date } from "src/helpers/date";
import { useTranslate } from "src/helpers/useTranslate";
import { useTranslation } from "react-i18next";
import { paymentList } from "src/helpers/paymentsDict";
import { ordersFetchExportForRevision } from "src/services/OrderService";

export default function ExportAct({
  regions,
  filterParams,
  filterByRegions,
  filterByRoutes,
  filterByCourier,
  redemptionTypeLabel,
}) {
  const { tr } = useTranslate();
  const { t } = useTranslation();

  // change langagues and placements add client details configure sizes sheet name change

  const generateActName = () => {
    let d = new Date();
    return (
      d.getDate().toString() +
      "_" +
      (d.getMonth() + 1).toString() +
      "_" +
      d.getFullYear().toString()
    );
  };

  const paymentStatus = (o) => {
    const exportingLabels = (side) => {
      if (o[`${side}_payment_type`] === "invoice") {
        return "Счет фактура";
      } else {
        const paymethod = paymentList.filter(
          (payment) => payment.value === o[`${side}_payment_method`]
        )[0]?.label;
        return (
          t(paymethod) +
          " (" +
          (o[`${side}_payment_is_paid`] ? "Опл." : "Не опл.") +
          ")"
        );
      }
    };
    if (o.paying_side === "jointly") {
      return exportingLabels("sender") + "/" + exportingLabels("receiver");
    } else {
      return exportingLabels(o.paying_side);
    }
  };

  const downloadAct = (orders) => {
    const sheetHeaderNames = [
      "#",
      "ID",
      "Дата",
      "Откуда",
      "Куда",
      "Адрес отправителя",
      "Контакт отправителя",
      "Адрес получателя",
      "Контакт получателя",
      "Тип посылки",
      "Коментарий",
      "Выкуп",
      "Статус выкупа",
      "Стоимость",
      "Оплата",
      "Курьер",
      "Статус",
    ];
    const columnSizes = [
      5, 15, 15, 13, 13, 20, 15, 20, 15, 12, 15, 10, 10, 12, 13, 12, 15,
    ];
    const regColumnSizes = [
      5, 15, 15, 13, 13, 20, 15, 20, 15, 12, 15, 10, 10, 12, 13, 12, 15,
    ];
    const selectedRegion = regions.filter(
      (r) => r.id === filterByRegions[0]
    )[0];
    const fileName =
      "Акт_" +
      (filterByCourier ? filterByCourier?.firstname + "_" : "") +
      generateActName() +
      "_" +
      (filterByRegions.length > 0
        ? tr(selectedRegion.name_ru, selectedRegion.name_kg)
        : "All");

    const workbook = new Workbook();

    if (filterByRegions.length === 0) {
      const worksheet = workbook.addWorksheet("Все заказы");

      // Add new row
      const titleRow = worksheet.addRow(sheetHeaderNames);
      // Set font, size and style in title row.
      titleRow.font = { name: "Calibri", size: 12, bold: true };
      titleRow.height = 30;
      const orderlist = orders.filter((o) =>
        filterByRoutes.length > 0 ? filterByRoutes.includes(o.route?.id) : true
      );

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < orderlist.length; i++) {
        const order = orderlist[i];
        const rowData = [];
        rowData.push(i + 1);
        rowData.push(order.oid);
        rowData.push(date(order.created_at) || "");
        rowData.push(order.city_from ? order.city_from?.name_ru : "");
        rowData.push(
          (order.district_to ? `${order.district_to.name_ru}, ` : "") +
            (order.city_to ? order.city_to?.name_ru : "")
        );
        rowData.push(order.location_from ? order.location_from.name_ru : "");
        rowData.push(order.sender ? order.sender?.phone : "");
        rowData.push(order.location_to ? order.location_to.name_ru : "");
        rowData.push(order.receiver ? order.receiver?.phone : "");
        rowData.push(order.package_type?.name_ru || "");
        rowData.push(order.description || "");
        rowData.push(order.redemption_amount || "");
        rowData.push(redemptionTypeLabel(order.redemption_payment_type));
        rowData.push(order.final_price || "");
        rowData.push(paymentStatus(order));
        rowData.push(order.courier ? order.courier.user?.firstname : "");
        rowData.push(t(order.status));
        const mr = worksheet.addRow(rowData);
        mr.height = 30;
        mr.font = { size: 10 };
      }

      worksheet.eachRow((row, i) => {
        row.eachCell((cell) => {
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      });

      worksheet.columns.forEach((column, i) => {
        column.width = columnSizes[i];
      });
    }

    regions
      .filter((r) =>
        filterByRegions.length > 0 ? filterByRegions.includes(r.id) : true
      )
      .filter(
        (r) =>
          orders?.filter((o) => o.route?.to_node?.region_id === r.id).length > 0
      )
      .forEach((r) => {
        const regWs = workbook.addWorksheet(r.name_ru);
        generateRegionWsData(orders, regWs, r, sheetHeaderNames);
        regWs.columns.forEach((column, i) => {
          column.width = regColumnSizes[i];
        });
        regWs.eachRow((row, i) => {
          row.eachCell((cell) => {
            cell.alignment = {
              vertical: "middle",
              horizontal: "center",
              wrapText: true,
            };
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          });
        });
      });

    // Add row with current date
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, fileName + ".xlsx");
    });
  };

  const generateRegionWsData = (
    orders,
    worksheet,
    region,
    sheetHeaderNames
  ) => {
    const headerRow = worksheet.addRow([
      "",
      "Дата:",
      date(new Date()),
      `Акт приема передачи ${tr(region.name_ru, region.name_kg)}`,
      "",
      "",
      "",
    ]);
    const titleRow = worksheet.addRow(sheetHeaderNames);

    headerRow.font = { name: "Calibri", size: 12, bold: true };
    headerRow.height = 35;

    // make date smaller
    headerRow.getCell(3).font = { size: 10, bold: true };

    titleRow.font = { size: 12, bold: true };
    titleRow.height = 30;

    const orderList = orders
      .filter((o) => o.route?.to_node?.region_id === region?.id)
      .filter(
        (o) =>
          filterByRoutes.includes(o.route?.id) || filterByRoutes.length === 0
      );

    let sumDeliveryCost = 0;
    let sumPurchaseCost = 0;
    const emptyRowData = [];
    for (let i = 0; i < orderList.length; i++) {
      const order = orderList[i];

      const rowData = [];
      rowData.push(i + 1);
      rowData.push(order.oid);
      rowData.push(date(order.created_at) || "");
      rowData.push(order.city_from ? order.city_from?.name_ru : "");
      rowData.push(
        (order.district_to ? `${order.district_to.name_ru}, ` : "") +
          (order.city_to ? order.city_to?.name_ru : "")
      );
      rowData.push(order.location_from ? order.location_from.name_ru : "");
      rowData.push(order.sender ? order.sender?.phone : "");
      rowData.push(order.location_to ? order.location_to.name_ru : "");
      rowData.push(order.receiver ? order.receiver?.phone : "");
      rowData.push(order.package_type?.name_ru || "");
      rowData.push(order.description || "");
      rowData.push(order.redemption_amount || "");
      rowData.push(order.final_price || "");
      rowData.push(paymentStatus(order));
      rowData.push(order.courier ? order.courier.user?.firstname : "");
      rowData.push(t(order.status));
      sumDeliveryCost = sumDeliveryCost + (order.final_price || 0);
      sumPurchaseCost = sumPurchaseCost + (order.redemption_amount || 0);

      const rr = worksheet.addRow(rowData);
      rr.height = 30;
      rr.font = { size: 10 };
    }

    sheetHeaderNames.forEach((re) => {
      emptyRowData.push("");
    });
    worksheet.addRow(emptyRowData);

    const usluga = [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "За услугу:",
      sumDeliveryCost,
    ];
    const vykup = [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "За выкуп:",
      sumPurchaseCost,
    ];
    const itogo = [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "Итого:",
      sumDeliveryCost + sumPurchaseCost,
    ];
    worksheet.addRow(usluga);
    worksheet.addRow(vykup);
    worksheet.addRow(itogo);

    worksheet.mergeCells("D1:N1");
    return worksheet;
  };

  const onExportClick = () => {
    ordersFetchExportForRevision(filterParams)
      .then((res) => {
        res.ok &&
          res.json().then((d) => {
            downloadAct(d);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <CButton
      color="primary"
      variant="outline"
      className="mx-1"
      onClick={onExportClick}
    >
      {t("Download Act")}
    </CButton>
  );
}
