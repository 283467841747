import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { fetchCourier } from "src/services/CourierService";
import { ordersFetchCurrent } from "src/services/OrderService";

export default function useDetailsPage() {
  const params = useParams();

  const [activeTab, setActiveTab] = useState("profile");
  const [orders, setOrders] = useState([]);
  const [courier, setCourier] = useState([]);

  useEffect(() => {
    if (params?.id) {
      ordersFetchCurrent({ courier_id: params.id, size: 100 }).then(
        (res) =>
          res.ok &&
          res.json().then((d) => {
            setOrders(d?.items);
          })
      );
      fetchCourier(params.id).then(
        (res) =>
          res.ok &&
          res.json().then((d) => {
            setCourier(d);
          })
      );
    }
  }, [params]);

  const multiHandlePrint = useReactToPrint({
    content: () => multiComponentRef.current,
  });

  const singleComponentRef = useRef();
  const multiComponentRef = useRef();

  const handleOrderSelect = (i, value) => {
    let orderList = [...orders];
    orderList[i].selected = value;
    setOrders([...orderList]);
  };

  const selectAll = (value) => {
    let orderList = [...orders].map((o) => {
      return { ...o, selected: value };
    });
    setOrders([...orderList]);
  };
  return {
    activeTab,
    setActiveTab,
    courier,
    orders,
    multiHandlePrint,
    singleComponentRef,
    handleOrderSelect,
    selectAll,
  };
}
