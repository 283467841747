import {
    OPERATOR_SUCCESS,
    OPERATOR_LOADING,
    OPERATOR_FAIL
  } from '../types/operatorTypes'
  
  const initialState = {
    operators: [],
    error: null,
    isLoading: true
  }
  
  export default function operatorReducer(state = initialState, action) {
    switch (action.type) {
      case OPERATOR_LOADING:
        return {
          ...state,
          isLoading: true,
          error: null
        }
      case OPERATOR_SUCCESS:
        return {
          ...state,
          operators: action.payload,
          isLoading: false,
          error: null
        }
      case OPERATOR_FAIL:
        return {
          ...state,
          operators: null,
          isLoading: false,
          error: action.payload || null
        }
      default:
        return {
          ...state
        }
    }
  }
  