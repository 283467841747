import {
  USER_SUCCESS,
  USER_LOADING,
  USER_FAIL,
  LOGOUT,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGIN_LOADING,
  CLEAR_ERROR,
  STAFF_PROFILE,
  COMPANY_PROFILE
} from "../types/authTypes"

import {signInFetch} from '../../services/AuthService'
import { fetchCompanyProfile, fetchStaffProfile } from "src/services/CompanyService";


export const loadUser = () => async (dispatch, getState) => {
  dispatch(userLoading());
  fetchStaffProfile().then(res => {
    dispatch(userSuccess(getState().auth.user, getState().auth.accessToken));
  }).catch(err => {
    dispatch(userFail(err));
  })
}

export const getProfile = () => (dispatch, getState) => {
  fetchStaffProfile().then(res => {
      if(res.ok) {
        res.json().then(data => {
          dispatch({type: STAFF_PROFILE, payload: data})
        })
      } else {
        dispatch(userFail(res))
      }
  }).catch(err => console.log("connection error"))
}

export const getCompany = () => (dispatch, getState) => {
  fetchCompanyProfile().then(res => {
    if(res.ok) {
      res.json().then(data => {
        dispatch({type: COMPANY_PROFILE, payload: data})
      })
    } else {
      console.log(res)
    }
  }).catch(err => console.log("connection error"))
}

export const login = (username, password, redirectAfterLoginSuccess) => (dispatch, getState) => {
  dispatch(loginLoading());
  signInFetch(username, password)
  .then((res) => {
    if(res.ok) {
      res.json().then(data=>{
        console.log('aaa', data);
        dispatch(loginSuccess({username, password}, data.access_token));
        redirectAfterLoginSuccess();
      })
    } else {
      res.json().then(data=>{
        dispatch(loginFail(data));
        console.log(data);
      })
    }
  })
  .catch((err) => dispatch(loginFail(err)))
}

export const loginLoading = () => ({ type: LOGIN_LOADING })
export const loginSuccess = (user, accessToken, refreshToken) => ({ type: LOGIN_SUCCESS, payload: {user, accessToken, refreshToken} })
export const loginFail = error => ({ type: LOGIN_FAIL, payload: error })

export const userLoading = () => ({ type: USER_LOADING })
export const userSuccess = (user, accessToken) => ({ type: USER_SUCCESS, payload: {user, accessToken} })
export const userFail = error => ({ type: USER_FAIL, payload: error })

export const logout = () => ({ type: LOGOUT })

export const clearError = () => ({ type: CLEAR_ERROR })
