import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "src/redux/actions/settingsActions";
import ymaps from "ymaps";
import { fetchAllBishkekAdr } from "src/services/CityService";
import FullSpinner from "src/components/spinners/FullSpinner";
import Redpoint from "../../assets/icons/redPoint.svg";

export default function CitiesDistrictsOnMap() {
  const dispatch = useDispatch();
  const [addresses, setAddresses] = useState(null);
  useEffect(() => {
    dispatch(setBreadcrumb(["Geography On Map"]));
    fetchAllBishkekAdr().then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          show(data);
          setAddresses(data);
        });
      } else {
        console.log(res.statusText);
      }
    });
  }, [dispatch]);

  console.log(addresses);

  const show = (addresses) => {
    ymaps
      .load(
        "https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=14b7fac2-2096-4a85-9aa1-ed9ac48250ff"
      )
      .then((maps) => {
        const map = new maps.Map("mape", {
            center: [42.88, 74.58],
            zoom: 7,
          }),
          objectManager = new maps.ObjectManager({
            clusterize: true,
            gridSize: 32,
          });

        objectManager.objects.options.set({
          iconLayout: "default#image",
          iconImageHref: Redpoint,
          iconImageSize: [20, 20],
          // iconImageOffset: [0, 0],
        });

        // objectManager.objects.options.set(
        //   "preset",
        //   "islands#redIcon"
        // );

        objectManager.clusters.options.set(
          "preset",
          "islands#invertedRedClusterIcons"
        );
        map.geoObjects.add(objectManager);

        let data = {
          type: "FeatureCollection",
          features: [],
        };
        addresses &&
          addresses.forEach((address) => {
            let feature = {
              type: "Feature",
              id: 0,
              geometry: { type: "Point", coordinates: [] },
              properties: {
                hintContent: "",
              },
            };
            feature.geometry.coordinates = [address.lat, address.lng];
            feature.id = address.id;
            feature.properties.hintContent = `kg: ${address.name_kg} // ${address.district.name_kg} <br/> ru: ${address.name_ru} // ${address.district.name_ru} <br/> `;
            data.features.push(feature);
          });
        objectManager.add(data && data);
      })
      .catch((error) => console.log("Failed to load Yandex Maps", error));
  };

  console.log(addresses);
  return addresses ? (
    <div id="mape" style={{ width: "100%", height: "90vh" }}></div>
  ) : (
    <FullSpinner />
  );
}
