import React, { useEffect, useState } from 'react';
import {CButton, CCard, CCardBody, CCol, CInput, CLabel, CRow, CSelect} from "@coreui/react"
import OrdersTable from './OrdersTable';
import { ordersArchiveFetch } from 'src/services/OrderService';
import { useDispatch} from 'react-redux';
import { setBreadcrumb } from 'src/redux/actions/settingsActions';
import MiniSpinner from 'src/components/spinners/MiniSpinner';
import { useParams, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { fetchCouriers } from 'src/services/CourierService';
import Pagination from 'src/components/Pagination';

const limit = 25
export default function OrdersArchive() {
    const {t} = useTranslation();

    const [ordersArchive, setOrdersArchive] = useState([])
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true)
    const [queryParams, setQueryParams] = useState(
        {search: "", courier_id: "", order_type: "", date_from: "", date_to: "", skip: 0, limit: limit})
    const [couriers, setCouriers] = useState([])
    const params = useParams();
    const history = useHistory();

    useEffect(() => {
        dispatch(setBreadcrumb(["Completed Orders"]))
        fetchCouriers().then(res => res.ok && res.json().then(d => {
            setCouriers(d)
        }))
    }, [dispatch])

    useEffect(() => {
        if(params.p) {
            const par = params.p
            let q = JSON.parse('{"' + 
                decodeURI(par.replace(/&/g, "\",\"")
                    .replace(/=/g,"\":\"")
                    .replace(/spsl/g, "/")
                    .replace(/bsps/g, '/')
                ) + '"}')
            console.log(q)
            setQueryParams({...q})
        }
        ordersArchiveFetch(params.p || "limit="+limit).then(res =>res.ok && res.json()).then(data => {
            setOrdersArchive(data);
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false);
            console.log(err)}
        ).finally(() => setIsLoading(false))
    }, [params])

    const filterChange = (value, field) => {
        console.log(value)
        let qParams = ""
        if(field)  
        {
            const newState = {...queryParams, [field]: value}
            Object.keys(newState).forEach((qKey) => {
                if(newState[qKey]) {
                    qParams = qParams ? qParams + "&" : qParams
                    qParams = qParams+qKey+"="+newState[qKey]
                }
            })
        }
        console.log(qParams)
        history.push("/orders-archive/" + qParams)
    }

    const onNext = () => {
        filterChange(parseInt(queryParams.skip || 0) + limit, "skip")
    }

    const onPrev = () => {
        filterChange(parseInt(queryParams.skip || 0) - limit, "skip")
    }

    return (
        <div>
            <CCard className="m-2">
                <CCardBody className="p-1">
                <CRow className="my-2">
                    <CCol sm="3">
                        <CLabel>{t("Search")}</CLabel>
                        <CInput placeholder='search' value={queryParams.search} onChange={e => 
                            filterChange(e.target.value
                                .replace(/[/\\\\]/g, "spsl"), "search")}/>
                    </CCol>
                    <CCol sm="2">
                        <CLabel>{t("Courier")}</CLabel>
                        <CSelect onChange={e => filterChange(e.target.value, "courier_id")} value={queryParams?.courier_id}>
                        <option value="">{t("All")}</option>
                            {couriers.map((c,i) =>
                                <option value={c.id} key={i}>{c.firstname + " " + c.username}</option>
                            )}
                        </CSelect>
                    </CCol>
                    <CCol sm="2">
                        <CLabel>{t("Type")}</CLabel>
                        <CSelect onChange={e => filterChange(e.target.value, "order_type")} value={queryParams?.order_type}>
                            <option value="">{t("All")}</option>
                            <option value="regional">{t("regional")}</option>
                            <option value="intra_city">{t("intra_city")}</option>
                        </CSelect>
                    </CCol>
                    <CCol sm="3">
                        <CRow>
                            <CCol>
                                <CLabel>{t("Since")}</CLabel>
                                <CInput type="date" onChange={e => filterChange(e.target.value, "date_from")} value={queryParams?.date_from}/>
                            </CCol>
                            <CCol>
                                <CLabel>{t("Until")}</CLabel>
                                <CInput type="date" onChange={e => filterChange(e.target.value, "date_to")} value={queryParams?.date_to}/>
                            </CCol>
                        </CRow>
                    </CCol>
                    <CCol sm="2" className="pt-4">
                        <CButton className="btn btn-outline-danger mx-1" onClick={() => filterChange("")}>{t("Clear")}</CButton>   
                    </CCol>
                </CRow>
                    {isLoading ? 
                        <div className="text-center">
                            <MiniSpinner />
                        </div> 
                        :
                        <OrdersTable archive={true} currentOrders={ordersArchive}/> 
                    }
                <Pagination 
                    currentPage={((queryParams.skip / limit) || 0) + 1}
                    nextPage={onNext}
                    prevPage={onPrev}
                    limit={limit}
                    currentListLength={ordersArchive.length}/>
                </CCardBody>
            </CCard>
        </div>
    )
}