import Cookies from 'js-cookie';
import { SERVER_URL } from './ServerService';

export const userFetch = () => {
  const userPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
          let user = {username: "admin", password: "admin"}
          resolve({user, accessToken: Cookies.get("access_token_smartpost2")})
    }, 3000);
    });
    return userPromise;
}

// /api/v1/auth/courier/sign-up/
export const signUpFetch = (user_data, transpot_image, passport_front, passport_back) => {
  return fetch(SERVER_URL + "/api/v1/auth/courier/sign-up/", {
    "method": "post",
    body: {user_data, transpot_image,passport_front, passport_back}
  })
}

// /api/v1/auth/sign-in/
export const signInFetch = (username, password) => {
  return fetch(SERVER_URL + "/api/v1/auth/sign-in/", {
    method: "post",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      "username": username,
      "password": password
  })
  })
}

// /api/v1/auth/sign-up/check/
export const signUpCheckFetch = (phone_number) => {
  return fetch("/api/v1/auth/sign-up/check/", {
    method: "post",
    body: new Headers({phone_number})
  })
}