import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { CCard, CCardBody, CCardHeader, CCol, CListGroup, CListGroupItem, CRow, CTooltip } from '@coreui/react';
import { SERVER_URL } from "../services/ServerService";
import { dateDifference } from "../helpers/date";
import { useTranslate } from "../helpers/useTranslate";
import HistoryContainer from "./orders/Order-Details/HistoryContainer";
import MiniSpinner from "src/components/spinners/MiniSpinner";
import geoloc from "src/assets/geoloc.svg";
import { useTranslation } from "react-i18next";

export default function PublicOrderPage() {
  const {tr} = useTranslate();
  const { t } = useTranslation()
  const params = useParams();

  const [order, setOrder] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  // const [error, setError] = useState(false)

  async function getOrderHistory(pid){
    setIsLoading(true)
    try{
      const response = await fetch(SERVER_URL + `/api/v1/tracking/${pid}/`)
      const data = await response.json()
      await setOrder(data)
      console.log(data)
      setIsLoading(false)
    }
    catch (err){
      // setError(err)
      console.log(err)
    }
    setIsLoading(false)

  }

  useEffect(() => {
    setIsLoading(true)
    getOrderHistory(params.id)
  },[params.id])

  const detectStatus = (arr, statusName) => {
    let startDate, endDate
    if(statusName === "on courier"){
      startDate = arr.filter(item => item?.new_value?.state === "approved")[0]?.date  ||  arr.filter(item => item?.new_value?.state === "appointed")[0]?.date
      endDate = arr.filter(item => item?.new_value?.state === "arrived at sender")[0]?.date
    }else{
      startDate = arr.filter(item => item?.new_value?.state === "approved")[0]?.date  ||  arr.filter(item => item?.new_value?.state === "appointed")[0]?.date
      endDate = arr.filter(item => item?.new_value?.state === "completed")[0]?.date
    }return (startDate && endDate) && dateDifference(startDate, endDate)
  }
  const exportStatus = (h) => {
    switch(h) {
      case "created":
        return tr("Заказ создан"," Буйрутма түзүлдү");
      case "in processing":
        return tr("В обработке","Текшерүүдө");
      case "approved":
        return tr(" Подтвержден","Тастыкталды");
      case "appointed":
        return tr(" Назначен", " Дайындалды");
      case "on pick-up":
        return tr(" Принят ", " Кабыл алынды");
      case "arrived at sender":
        return tr(" Прибыл к отправителю"," Жөнөтүүчүгө келди ");
      case "on courier":
        return tr(" Курьер забрал", " Курьер аманатты алды");
      case "in sorting center":
        return tr(" В сортировочном центре"," Курьер аманатты өткөрүп берди");
      case "arrived at receiver":
        return tr(" Прибыл к получателю.","Алуучуга келди ");
      case "on wait":
        return tr("","");
      case "cancelled":
        return tr("","");
      case "completed":
        return tr(" Завершен."," Аяктады ");
      case "rejected":
        return tr(" Отказ."," Алуучу аманатты алуу баш тартты");
      case "on carrier":
        return tr(" упаковщик передал посылку. [CourierName2] магистральный перевозчик взял посылку.", " таңгакчы аманатты өткөрүп берди. [CourierName2] магистралдык ташуучу алды.");
      default:
        return ""
    }
  }
    return (
      <CCard >
        { isLoading ? 
        <div className="text-center">
          <MiniSpinner />
        </div> 
          :
        order && <>
            <CCardHeader style={{backgroundColor: "#556785"}}>
                <div className={"d-sm-flex d-xl-flex d-md-flex"}  style={{fontSize:"1rem"}}>
                    <div className={"ml-4 mr-4"}><span style={{color: "#B5B5B5"}}>ID заказа: </span><span style={{color:"#ffffff"}}>{order.oid}</span></div>
                    <div className={"ml-4 mr-4"}><span style={{color: "#B5B5B5"}}>Статус: </span><span style={{color:"#ffffff"}}>{exportStatus(order.status)} </span></div>
                    <div className={"ml-4 mr-4"}><span style={{color: "#B5B5B5"}}>Курьер: </span><span style={{color:"#ffffff"}}>{order.courier?.user.firstname} </span></div>
                </div>
            </CCardHeader>
            <CCardHeader style={{backgroundColor: "#ffffff", fontSize:"1rem"}}>
                <div className={"d-sm-flex d-xl-flex d-md-flex"} style={{fontSize:"1rem"}}>
                    <div className={"ml-4 mr-4"}>История</div>
                    <div className={"ml-4 mr-4"}>Время подачи: <span style={{color:"#000"}}>{detectStatus(order.order_history,"on courier" )}</span></div>
                    <div className={"ml-4 mr-4"}>Общее время доставки: <span style={{color:"#000"}}>{detectStatus(order.order_history)}</span></div>
                </div>
            </CCardHeader>
            <CCardBody className="p-0">
                <CRow>
                  <CCol sm="7">
                    <HistoryContainer order_history={order.order_history} />
                  </CCol>
                  <CCol sm="5" className="mt-4">
                    <CListGroup flush>
                      <CListGroupItem>
                          <img style={{cursor: "pointer"}} src={geoloc} alt="geoloc"/>
                          {order.location_from?.city && 
                          (tr(order.location_from?.city?.name_ru, order.location_from.city?.name_kg) + ", ")}
                          {tr(order.location_from?.name_ru, order.location_from?.name_kg)}
                      </CListGroupItem>
                      <CListGroupItem>
                          <img style={{cursor: "pointer"}} src={geoloc} alt="geoloc"/>
                          {order.location_to?.city && 
                          (tr(order.location_to?.city?.name_ru, order.location_to.city?.name_kg) + ", ")}
                          {tr(order.location_to?.name_ru, order.location_to?.name_kg)}
                      </CListGroupItem>
                      <CListGroupItem className="d-flex justify-content-between">
                        <h5>{t("Overall")}:</h5>
                        <b>{order.final_price}⊆</b>
                      </CListGroupItem>
                      <CListGroupItem className="d-flex justify-content-between">
                        <h5>{t("Redemption")}:</h5>
                        <b>{order.redemption_amount}⊆</b>
                      </CListGroupItem>
                      <CListGroupItem className="d-flex justify-content-between">
                        <h5>{t("Courier")}:</h5>
                          <CTooltip content={order.courier?.user?.username}>
                            <b>
                                {order.courier?.user?.firstname}
                            </b>
                          </CTooltip>
                      </CListGroupItem>
                      <CListGroupItem className="d-flex justify-content-between">
                        <h5>{t("Courier Company")}:</h5>
                        <b>{order.delivery_service?.name}</b>
                      </CListGroupItem>
                    </CListGroup>
                  </CCol>
                </CRow>
            </CCardBody>
          </>}
        </CCard>
    )
}
