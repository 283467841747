import React, {useEffect, useState} from 'react';
// import DataTable from './DataTable';
import {CCard, CCardBody, CInput,CButton} from "@coreui/react"
import OrdersTableForPartners from '../partnerPanel/OrdersTableForPartners';
import {useSelector, useDispatch} from 'react-redux'
import { setBreadcrumb } from 'src/redux/actions/settingsActions';
import { fetchCompanyStatuses } from 'src/services/CompanyService';
import { useTranslation } from 'react-i18next';
import MiniSpinner from 'src/components/spinners/MiniSpinner';
// import { fetchRequest } from 'src/services/ServerService';

export default function CurrentOrders(){

    const [searchKey, setSearchKey] = useState("")
    const [statuses, setStatuses] = useState([]);
    const [filterStatus, setFilterStatus] = useState(null);
    const [filteredOrders, setFilteredOrders] = useState([]);

    const {i18n} = useTranslation();

    const dispatch = useDispatch();

    useEffect(() => {
        // fetchRequest("/api/v1/routes/", {method: "GET"}).then(res => console.log(res)).catch(err => console.error("Network Error"))
        dispatch(setBreadcrumb(["Current Orders"]));
        fetchCompanyStatuses().then(res => {
            if(res.ok) {
                res.json().then(data => {
                    // console.log(data);
                    let statusList = data.map((d, i) => {
                        // if(d.value === "on courier" || d.value === "in sorting center") {
                        //     return {...d, percent: 65}
                        // } else
                        if(d.value==="created") {
                            return {...d, percent: 5}
                        } else if(i < data.length - 2) {
                            return {...d, percent: parseInt(i * 10)}
                        } else {
                            return {...d, percent: 100}
                        }
                    })
                    setStatuses(statusList);
                })
            }
        }).catch(err => console.log(err));
    }, [dispatch])

    const orders = useSelector(state => state.order.orders);
    const isLoading = useSelector(state => state.order.isLoading);

    const filterStatuses = (value) => {
        dispatch(setBreadcrumb(["Current Orders", "Statuses", value.toUpperCase()]))
        if(value === "all") {
            setFilterStatus(null);
            setFilteredOrders(orders);
        } else {
            setFilterStatus(value)
            setFilteredOrders(orders.filter(a => a.status === value))
        }
    }

    const searchFor = (key) => {
        setSearchKey(key);
        setFilterStatus(key);
        let searchResult = [];
        orders.forEach(o => {
            if(o.sender?.fullname?.includes(key) || o.sender?.phone?.includes(key)) {
                searchResult.push(o)
            } else if(o.receiver?.fullname?.includes(key) || o.receiver?.phone?.includes(key)) {
                searchResult.push(o)
            } else if(o.location_from?.name_kg.includes(key) || o.location_from?.name_ru.includes(key)) {
                searchResult.push(o)
            } else if(o.location_to?.name_kg.includes(key) || o.location_to?.name_ru.includes(key)) {
                searchResult.push(o)
            } else if(o.package_type?.name_kg.includes(key) || o.package_type?.name_ru.includes(key)) {
                searchResult.push(o)
            } else if(o.tariff?.name.includes(key)) {
                searchResult.push(o)
            }
        })
        setFilteredOrders(searchResult)
    }

    return (
        <div>
            <CCard className="m-2">
                <CCardBody className="p-0">
                    <div className="p-1 m-1" style={{display: "inline"}}>
                        <CButton className="btn-outline-light btn-sm m-1 text-dark"
                                 onClick={() => filterStatuses("all")}
                                 style={{backgroundColor: !filterStatus && "aqua"}}>Все({orders.length})</CButton>

                        {statuses.map((st, i) =>
                            <CButton key={i}
                                     className="btn-outline-light btn-sm m-1 text-capitalize text-dark"
                                     onClick={() => filterStatuses(st.value)} style={{border: "Solid 1px "+ st.color, backgroundColor: filterStatus === st.value && "aqua"}}>
                                {i18n.language === "ru" ? st.name_ru : st.name_kg} ({orders.filter(o => o.status === st.value).length})
                            </CButton>)}
                        <CInput size="sm" placeholder="Search..." style={{width: "250px", display: "inline-flex"}} onChange={(e) => searchFor(e.target.value)} value={searchKey}/>
                    </div>
                    <div className="mt-1">
                        {isLoading ?
                            <div className="text-center">
                                <MiniSpinner />
                            </div>
                            : filterStatus ?
                                <OrdersTableForPartners currentOrders={filteredOrders} statusList={statuses}/>
                                :
                                <OrdersTableForPartners currentOrders={orders} statusList={statuses}/>
                        }
                    </div>

                </CCardBody>
            </CCard>
        </div>
    )
}

