import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { getCities, getDistricts } from "src/redux/actions/locationActions";
import {
  fetchCompanyAddressesOwn,
  fetchCompanyAddressCreate,
  fetchCompanyAddressUpdate,
} from "src/services/CompanyService";
import { useTranslation } from "react-i18next";
import { YMaps, Map, Placemark, Clusterer } from "react-yandex-maps";
import {
  CInput,
  CRow,
  CCol,
  CCardHeader,
  CCard,
  CCardBody,
  CLabel,
  CButton,
} from "@coreui/react";
import { toastify } from "src/helpers/toast";
import { useParams } from "react-router-dom";
import { fetchLocationDetails } from "src/services/CityService";

const apikey = "14b7fac2-2096-4a85-9aa1-ed9ac48250ff";

export default function NewCompanyAddress(props) {
  const dispatch = useDispatch();
  const map = useRef(null);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [nameKg, setNameKg] = useState("");
  const [nameRu, setNameRu] = useState("");
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [mapApi, setMapApi] = useState(null);
  const [id, setId] = useState(null);
  const [errorEl, setErrorEl] = useState("none");
  const [names, setNames] = useState({})
  const [city, setCity] = useState({
    label: i18n.language === "ru" ? "Выбрать город" : "Шаарды тандоо",
    value: 0,
  });
  const [district, setDistrict] = useState({
    label: i18n.language === "ru" ? "Выбрать район" : "Районду тандоо",
    value: 0,
  });
  const cities = useSelector((state) => state.locations.cities);
  const districts = useSelector((state) => state.locations.districts);
  const [addresses, setAddresses] = useState([]);
  const [distForBalloon, setDistForBalloon] = useState("");

  const params = useParams()

  const onOptionalNameClick = (chooseNames) => {
    setNameKg(chooseNames.nameKg)
    setNameRu(chooseNames.nameRu)
    setNames({})
  }

  const onClickMap = (e) => {
    console.log(e.get("coords"), "clicked point")
    const [lat, lng] = e.get("coords");
    setLat(lat);
    setLng(lng);
    getName(lat, lng);
  };

  const getName = (lat, lng) => {
    if (id){
      mapApi
          .geocode([lat, lng], {
            kind: "house",
          })
          .then((res) => {
            const name = res.geoObjects.get(0).properties.get("name")
            setNames({
              optionalName: {nameKg: name, nameRu: name}
            })
          });
    }else{
      mapApi
          .geocode([lat, lng], {
            kind: "house",
          })
          .then((res) => {
            setNameKg(res.geoObjects.get(0).properties.get("name"));
            setNameRu(res.geoObjects.get(0).properties.get("name"));
          });
    }
  };

  const getDistrict = (lat, lng) => {
    mapApi
      .geocode([lat, lng], {
        kind: "district",
      })
      .then((res) => {
        setDistForBalloon(res.geoObjects.get(0).properties.get("name"));
      });
  };

  const submitAddress = () => {
    const newAddress = {
      name_kg: nameKg,
      name_ru: nameRu,
      lat,
      lng,
      map_zoom: 12,
      city_id: city.value,
      district_id: district.value,
    };
    if (city.value === 0) {
      setErrorEl("city");
      toastify("error", t("Выберите город"));
    }
    if (district.value === 0) {
      newAddress.district_id = null
      // setErrorEl("district");
      // toastify("error", t("Выберите район"));
    }
    if (nameKg.length === 0) {
      setErrorEl("nameKg");
      toastify("error", t("Напишите адрес"));
    }
    if (nameRu.length === 0) {
      setErrorEl("nameRu");
      toastify("error", t("Напишите адрес"));
    } else {
      fetchCompanyAddressCreate(newAddress)
        .then((res) => {
          if (res.ok) {
            res.json().then((data) => setAddresses(addresses.concat(data)));
            toastify("success", t("Новый адрес создан"));
            setNameKg("");
            setNameRu("");
            setLat(0);
            setLng(0);
            setId(null);
            history.push("/company-addresses");
          } else {
            toastify("error", t("Произошла ошибка создания адреса"));
          }
        })
        // .catch((err) => {
        //   toastify("error", t("Произошла ошибка, проверьте интернет"));
        // });
    }
  };


  useEffect(() => {
    dispatch(getCities());
  }, [dispatch])

  const prepareForEdit = useCallback((stateData) => {
    if (stateData.city) {
      dispatch(getDistricts(stateData.city && stateData.city.id));
    }
    fetchCompanyAddressesOwn().then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          setAddresses(data.filter((a) => a.id !== stateData.id) || data);
        });
      } else {
        console.log(res.statusText);
      }
    });
    setCity(
      (stateData.city && {
        label:
          i18n.language === "ru" ? stateData.city.name_ru : stateData.city.name_kg,
        value: stateData.city.id,
      }) || {
        label: i18n.language === "ru" ? "Выбрать город" : "Шаарды тандоо",
        value: 0,
      }
    );
    setDistrict(
      (stateData.district && {
        label:
          i18n.language === "ru"
            ? stateData.district.name_ru
            : stateData.district.name_kg,
        value: stateData.district.id,
      }) || {
        label: i18n.language === "ru" ? "Выбрать район" : "Районду тандоо",
        value: 0,
      }
    );
    setNameKg((stateData.name_kg && stateData.name_kg) || "");
    setNameRu((stateData.name_ru && stateData.name_ru) || "");
    setLat((stateData.lat && stateData.lat) || 0);
    setLng((stateData.lng && stateData.lng) || 0);
    setId(stateData.id && stateData.id);
  
  }, [dispatch, i18n])


  useEffect(() => {
    if(params.id) {
      fetchLocationDetails(params.id).then(res => {
        res.json().then(d => {
          prepareForEdit(d)
        })
      })
    } else {
      setCity({
        label: i18n.language === "ru" ? "Выбрать город" : "Шаарды тандоо",
        value: 0,
      });
      setDistrict({
        label: i18n.language === "ru" ? "Выбрать район" : "Районду тандоо",
        value: 0,
      });
      fetchCompanyAddressesOwn().then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            setAddresses(data);
          });
        } else {
          console.log(res.statusText);
        }
      });
    }
  }, [dispatch, params, i18n.language, prepareForEdit]);

  const updateAddress = () => {
    // console.log("updated");
    const form = {
      name_kg: nameKg,
      name_ru: nameRu,
      lat,
      lng,
      map_zoom: 13,
      city_id: city.value,
      district_id: district.value,
    };
    // console.log("form", form, "id", id);
    if (city.value === 0) {
      setErrorEl("city");
      toastify("error", t("Выберите город"));
    }
    if (district.value === 0) {
      // setErrorEl("district");
      // toastify("error", t("Выберите район"));
      form.district_id = null
    }
    if (nameKg.length === 0) {
      setErrorEl("nameKg");
      toastify("error", t("Напишите адрес"));
    }
    if (nameRu.length === 0) {
      setErrorEl("nameRu");
      toastify("error", t("Напишите адрес"));
    } else {
      fetchCompanyAddressUpdate(form, id)
        .then((res) => {
          if (res.ok) {
            toastify("success", t("Адрес обновлен"));
            setNameKg("");
            setNameRu("");
            setLat(0);
            setLng(0);
            setId(null);
            res.json().then((data) => setAddresses(addresses.concat(data)));
            history.push("/company-addresses");
          } else {
            toastify("error", t("Произошла ошибка обновления адреса"));
          }
        })
        // .catch((err) => {
        //   toastify("error", t("Произошла ошибка, проверьте интернет"));
        // });
    }
  };

  return (
    <CCard>
      <CCardHeader className="pl-2 pr-0">
        <CRow>
          <CCol
            style={{ maxWidth: "15%" }}
            className={`${errorEl === "city" ? "sp_error" : ""} pl-3 pr-1`}
          >
            <CLabel>{t("Choose city")}</CLabel>
            {city && (
              <Select
                value={city}
                options={
                  i18n.language === "ru"
                    ? cities.map((c) => {
                        return {
                          value: c.id,
                          label: c.name_ru,
                          coors: [c.lat, c.lng],
                        };
                      })
                    : cities.map((c) => {
                        return {
                          value: c.id,
                          label: c.name_kg,
                          coors: [c.lat, c.lng],
                        };
                      })
                }
                onChange={(e) => {
                  dispatch(getDistricts(e.value));
                  setCity(e);
                }}
              />
            )}
          </CCol>
          <CCol
            style={{ maxWidth: "15%" }}
            className={`${errorEl === "district" ? "sp_error" : ""} px-1`}
          >
            <CLabel>{t("Choose district")}</CLabel>
            {districts && (
              <Select
                value={district}
                options={
                  i18n.language === "ru"
                    ? districts &&
                      districts.map((d) => {
                        return {
                          value: d.id,
                          label: d.name_ru,
                          coors: [d.lat, d.lng],
                        };
                      })
                    : districts &&
                      districts.map((d) => {
                        return {
                          value: d.id,
                          label: d.name_kg,
                          coors: [d.lat, d.lng],
                        };
                      })
                }
                onChange={(e) => {
                  setDistrict(e);
                }}
              />
            )}
          </CCol>
          <CCol style={{ maxWidth: "10%" }} className="px-1">
            <CLabel>{t("Latitude")}</CLabel>
            <CInput
              placeholder="lat"
              value={lat}
              onChange={(e) => setLat(e.target.value)}
            />
          </CCol>
          <CCol style={{ maxWidth: "10%" }} className="px-1">
            <CLabel>{t("Longitude")}</CLabel>
            <CInput
              placeholder="lng"
              value={lng}
              onChange={(e) => setLng(e.target.value)}
            />
          </CCol>
          <CCol
            style={{ maxWidth: "20%" }}
            className={`${errorEl === "nameKg" ? "sp_error" : ""} px-1`}
          >
            <CLabel>{t("Name on kg")}</CLabel>
            <CInput
              placeholder={t("Name on kg")}
              value={nameKg}
              onChange={(e) => setNameKg(e.target.value)}
            />
            { Object.keys(names).map(key => (
              <CLabel onClick={() => onOptionalNameClick(names[key])} variant="outlined" color='success'>{names[key].nameKg}</CLabel>
            )) }
          </CCol>
          <CCol
            style={{ maxWidth: "20%" }}
            className={`${errorEl === "nameRu" ? "sp_error" : ""} px-1`}
          >
            <CLabel>{t("Name on ru")}</CLabel>
            <CInput
              placeholder={t("Name on ru")}
              value={nameRu}
              onChange={(e) => setNameRu(e.target.value)}
            />
            { Object.keys(names).map(key => (
                <CLabel onClick={() => onOptionalNameClick(names[key])} variant="outlined" color='success'>{names[key].nameRu}</CLabel>
            )) }
          </CCol>
          <CCol
            style={{ maxWidth: "10%", display: "flex", alignItems: "center" }}
            className="mt-4 p-0 pl-2"
          >
            {id ? (
              <CButton
                type="submit"
                variant="outline"
                color="success"
                onClick={() => {
                  updateAddress()
                }}
              >
                {t("Update")}
              </CButton>
            ) : (
              <CButton
                type="submit"
                variant="outline"
                color="success"
                onClick={() => submitAddress()}
              >
                {t("Create")}
              </CButton>
            )}
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody className="p-1">
        <YMaps query={{ load: "package.full", apikey }}>
          <Map
            onClick={(e) => onClickMap(e)}
            onLoad={(ymaps) => setMapApi(ymaps)}
            instanceRef={map}
            width="100%"
            height="75vh"
            defaultState={{
              center: [42.88, 74.58],
              zoom: 9,
              controls: [],
            }}
          >
            <Placemark
              modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
              onMouseEnter={() => {
                setDistForBalloon(getDistrict(lat, lng));
                // console.log(distForBalloon);
              }}
              geometry={[lat, lng]}
              options={{
                preset: "islands#GreenClusterIcons",
                iconColor: "green",
              }}
              properties={{
                // hintContent: distForBalloon,
                balloonContent: distForBalloon,
              }}
            />
            {id && lat && lng && (
              <Placemark
                geometry={[lat, lng]}
                options={{
                  preset: "islands#GreenClusterIcons",
                  iconColor: "red",
                }}
              />
            )}
            <Clusterer
              options={{
                preset: "islands#invertedGreenClusterIcons",
                groupByCoordinates: false,
              }}
            >
              {addresses.length > 0 &&
                addresses.map((adr, i) => (
                  <Placemark
                    modules={[
                      "geoObject.addon.balloon",
                      "geoObject.addon.hint",
                    ]}
                    properties={{
                      hintContent: adr.name_kg,
                    }}
                    geometry={[adr.lat, adr.lng]}
                    key={i}
                  />
                ))}
            </Clusterer>
          </Map>
        </YMaps>
      </CCardBody>
    </CCard>
  );
}
