import { CCol, CRow } from "@coreui/react";
import ImageGallery from "./ImageGallery";
// import Rectangle from '../../../assets/Rectangle.png'

export default function PhotoDetails({orderDetails}) {
    return (
        <CRow>
            <CCol>
                <ImageGallery role="Sender" photos={orderDetails.sender_photos || []}/>
            </CCol>
            <CCol>
                <ImageGallery role="Sender" photos={orderDetails.receiver_photos || []}/>
            </CCol>
        </CRow>
    )
}