import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import { ToastContainer } from "react-toastify";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { icons } from "./assets/icons";
import Providers from "./Providers";

import { I18nextProvider } from "react-i18next";
import i18next from "./internationalize/internationalize";
import ErrorBoundary from "./error-boundary/ErrorBoundary";

React.icons = icons;

ReactDOM.render(
  <ErrorBoundary>
    <Providers>
      <I18nextProvider i18n={i18next}>
        <ToastContainer />
        <App />
      </I18nextProvider>
    </Providers>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
