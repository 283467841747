import { CCol, CInput, CRow, CSelect } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useTranslate } from 'src/helpers/useTranslate';
import { fetchCompanies, fetchCompanyStatuses } from 'src/services/CompanyService';

export function CourierFields({
    transports, transportSelect, 
    orderData, updateOrderData,
    sortedCourierList, courierList,
}) {

    const {t} = useTranslation();
    const {tr} = useTranslate();

    const [deliveryService, setDeliveryService] = useState([])
    const [statusList, setStatusList] = useState([])
    
    const profile = useSelector(state => state.auth.profile)


    useEffect(() => {
        fetchCompanyStatuses().then(res => {
            if(res.ok) {
                res.json().then(data => {
                    setStatusList(data)
                })
            }})
    }, [])
    useEffect(() => {
        if(profile) {
            profile.roles?.includes("superadmin") && fetchCompanies().then(res => res.ok && res.json().then(d => {
                setDeliveryService(d)
            }))
        }
    }, [profile])

    const setTime = (e, type) => {
        let d = new Date();
        d.setHours(e.target.value.substring(0, 2))
        d.setMinutes(e.target.value.substring(3, 5))
        updateOrderData(type, d)
    }

    const currentStatus = statusList?.find(s => s.value === orderData.status)
    return (
        <div>
            <h6 className="text-dark text-uppercase my-n1 font-xs">{t('Courier')}</h6>
            <hr className="mt-0 my-2" />

            {profile?.roles.includes("super_admin") && 
                <Select className="my-2" 
                    isClearable
                    placeholder={t("Delivery service")}
                    options={deliveryService.map(c => { return { value: c.id,label: c.name, ...c}})}  
                    onChange={res => updateOrderData("deliveryService", res)} 
                    value={orderData.deliverService}/>}

            <Select 
                isMulti
                options={[{label: t("All")}, ...transports.map(t => { return { value: t.id,label: tr(t.name_ru, t.name_kg), ...t}})]}  
                onChange={res => transportSelect(res)} 
                defaultValue={orderData.selectedTransport && orderData.selectedTransport.length > 0 ? orderData.selectedTransport : [{label: t("All")}]}
                value={orderData.selectedTransport && orderData.selectedTransport.length > 0 ? orderData.selectedTransport : [{label: t("All")}]}
                className="basic-multi-select d-none"
                classNamePrefix="select"/>
            <Select className="my-2" 
                isClearable
                placeholder={t("Courier")}
                options={(sortedCourierList || courierList)
                    .filter(c => orderData.selectedTransport && orderData.selectedTransport.length>0 ? orderData.selectedTransport.some(st => st && st.id===c.transport?.id) : true)
                    .map(c => { return { value: c.id,label: c.firstname + " (" +t(c.roles[0] === "courier" ? "Courier" : "Carrier") + ")", ...c}})}  
                onChange={res => updateOrderData("selectedCourier", res)} 
                value={orderData?.selectedCourier}/>
            <Select className="my-2" 
                isClearable
                placeholder={t("Status")}
                options={statusList.map(s => ({value: s.value, label: tr(s.name_ru, s.name_kg)}))}
                onChange={res => updateOrderData("status", res)} 
                value={currentStatus && ({label: tr(currentStatus?.name_ru, currentStatus?.name_kg), ...currentStatus})}/>
            <div className='my-2'>
                <span className="font-xs">
                    {t("Pickup time")}
                </span>
                <CRow>
                    <CCol className="pr-1">
                        <CSelect size="sm" onChange={e => updateOrderData("pickUpDay", e.target.value)}>
                            <option value="today">{t("Today")}</option>
                            <option value="tomorrow">{t("Tomorrow")}</option>
                        </CSelect>
                    </CCol>
                    <CCol className="pl-1">
                        <CInput size="sm" type="time" id="appt" name="appt" min="00:00" max="23:59" onChange={e => setTime(e, "pickupTimeStamp")}/>
                    </CCol>
                </CRow>
                <span className="font-xs">
                    {t("Delivery time")}
                </span>
                <CRow>
                    <CCol className="pr-1">
                        <CSelect size="sm" onChange={e => updateOrderData("deliveryDay", e.target.value)}>
                            <option value="today">{t("Today")}</option>
                            <option value="tomorrow">{t("Tomorrow")}</option>
                        </CSelect>
                    </CCol>
                    <CCol className="pl-1">
                        <CInput size="sm" type="time" id="appt" name="appt" min="00:00" max="23:59" onChange={e => setTime(e, "deliveryTimeStamp")}/>
                    </CCol>
                </CRow>
            </div>
        </div>
    )
}
