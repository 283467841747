import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CForm,
  CFormGroup,
  CLabel,
  CRow,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import { useModalState, useConfirmModal } from "src/helpers/useModal";
import { setBreadcrumb } from "src/redux/actions/settingsActions";
import { getOrganizations } from "src/redux/actions/organizationActions";
import OrganizationForm from "./OrganizationForm";
import CIcon from "@coreui/icons-react";
import {
  fetchDeleteOrganization,
  fetchClientOrganizationDetails,
} from "src/services/ClientService";
import { toastify } from "src/helpers/toast";

export default function Organizations() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isOpen, onToggle } = useModalState();
  const { isConfirmOpen, onConfirmToggle } = useConfirmModal();
  const [modalTitle, setModalTitle] = useState("Modal Title");

  const organizations = useSelector(
    (state) => state.organizations.organizations
  );
  const [selectedOrg, setSelectedOrg] = useState({});

  const newOrgForm = () => {
    setSelectedOrg({});
    setModalTitle(t("Create Organization"));
    onToggle();
  };

  const deleteOrg = (id) => {
    fetchDeleteOrganization(id)
      .then((res) => {
        if (res.ok) {
          toastify("success", t("Организация удалена"));
          dispatch(getOrganizations());
        } else {
          toastify("error", t("Произошла ошибка удаления"));
        }
      })
      .catch((err) =>
        toastify("error", t("Произошла ошибка, проверьте интернет"))
      )
      .finally(onConfirmToggle);
  };

  const showDelete = (org) => {
    setModalTitle(t("Delete organization"));
    setSelectedOrg(org);
    onConfirmToggle();
  };

  const showEdit = (org) => {
    setModalTitle(t("Edit organization"));
    fetchClientOrganizationDetails(org.id).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          setSelectedOrg(data);
          // console.log(data);
        });
      } else {
        console.log(res.statusText);
      }
    });
    onToggle();
  };

  useEffect(() => {
    dispatch(getOrganizations());
    if (isOpen === false && isConfirmOpen === false) {
      dispatch(setBreadcrumb(["Organizations"]));
    }
  }, [dispatch, isOpen, isConfirmOpen]);

  return (
    <CCard className="m-2">
      <CCardHeader>
        <CButton
          variant="outline"
          color="success"
          className="float-right"
          onClick={() => {
            newOrgForm();
            setSelectedOrg({});
            dispatch(setBreadcrumb(["Organizations", "Create Organization"]));
          }}
        >
          {t("Create organization")}
        </CButton>
      </CCardHeader>
      <CCardBody>
        <table className="table table-hover" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>{t("Organization name")}</th>
              <th style={{ width: "10%" }}>{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {organizations &&
              organizations.map((o) => (
                <tr key={o.id}>
                  <td>{o.name}</td>
                  <td>
                    <CIcon
                      name="cilTrash"
                      className="text-danger mr-2"
                      size="xl"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        // console.log(e);
                        e.preventDefault();
                        showDelete(o);
                        dispatch(
                          setBreadcrumb([
                            "Organizations",
                            "Delete organization",
                          ])
                        );
                      }}
                    />
                    <CIcon
                      name="cilPencil"
                      className="text-warning"
                      size="xl"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        // console.log(e);
                        e.preventDefault();
                        showEdit(o);
                        dispatch(
                          setBreadcrumb(["Organizations", "Edit organization"])
                        );
                      }}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </CCardBody>

      <CModal show={isConfirmOpen} onClose={onConfirmToggle}>
        <CModalHeader closeButton>{modalTitle}</CModalHeader>
        <CModalBody>
          {selectedOrg && (
            <CForm>
              <CFormGroup>
                <CLabel htmlFor="nf-username">
                  {t("Delete this organization")} {selectedOrg.name}
                </CLabel>
              </CFormGroup>
            </CForm>
          )}
        </CModalBody>
        <CModalFooter>
          {selectedOrg && (
            <CRow>
              <CButton
                color="danger"
                onClick={() => deleteOrg(selectedOrg.id)}
                className="mr-2"
              >
                {t("Delete")}
              </CButton>
            </CRow>
          )}
        </CModalFooter>
      </CModal>

      <OrganizationForm
        toggle={onToggle}
        modal={isOpen}
        modalTitle={modalTitle}
        selectedOrg={selectedOrg}
      />
    </CCard>
  );
}
