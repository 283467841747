import React, { useEffect } from "react";
import { CCol, CInput, CLabel, CRow } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { onUploadPhoto } from "../helpers/helpers";
import { useTranslate } from "src/helpers/useTranslate";

const TransportAndRoutesTab = (props) => {
  const {
    updateField,
    updateFieldCheck,
    fieldChecked,
    selectedCourier,
    routes,
    transports,
    initForm,
    selectedTransport,
    setSelectedTransport,
    setRoute,
    route,
    onUploadPhotoSuccess,
  } = props;

  const { tr } = useTranslate();
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedCourier) {
      updateFieldCheck("routes", selectedCourier?.routes?.length > 0);
      updateFieldCheck("transport", selectedCourier?.transport?.id);
    }
  }, [selectedCourier, updateFieldCheck]);

  const onRouteSelect = (route) => {
    updateField(
      "routes",
      route.map((r) => r.value)
    );
    setRoute(route);
    updateFieldCheck("routes", route.length > 0);
  };

  const onAutoTypeSelect = (autoType) => {
    setSelectedTransport(autoType);
    updateField("transport_id", autoType?.value);
    updateFieldCheck("transport", autoType);
  };

  return (
    <CRow className="ml-2">
      <CCol>
        <CLabel>{t("Routes")}*</CLabel>
      </CCol>

      <div style={{ width: "100%" }} className="d-flex align-items-center">
        <CCol xs={10}>
          <Select
            isMulti
            isClearable
            placeholder="Выберите маршрут"
            onChange={(route) => {
              onRouteSelect(route);
            }}
            value={route}
            options={routes.map((route) => {
              return {
                value: route.id,
                label: tr(route.name_ru, route.name_kg),
                ...route,
              };
            })}
          />
        </CCol>
        <CIcon
          name={fieldChecked.routes ? "cilCheckCircle" : "cilWarning"}
          className={
            "ml-2 " + (fieldChecked.routes ? "text-success" : "text-danger")
          }
          size="xl"
        />
      </div>

      <CCol className="mt-2">
        <CLabel>{t("Transport type")}*</CLabel>
      </CCol>
      <div style={{ width: "100%" }} className="d-flex align-items-center">
        <CCol xs={10}>
          <Select
            placeholder="Выберите транспорт"
            isClearable
            value={selectedTransport}
            options={transports.map((transport) => ({
              value: transport.id,
              label: tr(transport.name_ru, transport.name_kg),
            }))}
            onChange={(autoType) => onAutoTypeSelect(autoType)}
          />
        </CCol>
        <CIcon
          name={fieldChecked.transport ? "cilCheckCircle" : "cilWarning"}
          className={
            "ml-2 " +
            (fieldChecked.transport ? "text-success" : "text-danger")
          }
          size="xl"
        />
      </div>

      <CCol className="mt-2" xs={10}>
        <CLabel>{t("Transport Model")}</CLabel>
        <CInput
          type="text"
          placeholder={t("Transport Model")}
          name="transport_model"
          value={initForm.transport_model}
          onChange={(e) => updateField(e.target.name, e.target.value)}
        />
      </CCol>

      <CCol className="mt-2" xs={10}>
        <CLabel>{t("Auto Number")}</CLabel>
        <CInput
          type="text"
          placeholder={t("Auto Number")}
          name="auto_number"
          value={initForm.auto_number}
          onChange={(e) => updateField(e.target.name, e.target.value)}
        />
      </CCol>

      <CCol className="mt-2" xs={10}>
        <CLabel>{t("Transport image")}*</CLabel>
        <CInput
          type="file"
          onChange={(e) =>
            onUploadPhoto(e, "transport_images", onUploadPhotoSuccess)
          }
        />
      </CCol>

      <CCol className="mt-3" xs={10}>
        <CRow>
          <CCol>
            <CLabel>{t("Max Weight")}</CLabel>
            <CInput
              type="text"
              placeholder={t("0 кг")}
              name="max_weight"
              value={initForm.max_weight}
              onChange={(e) => updateField(e.target.name, e.target.value)}
            />
          </CCol>
          <CCol>
            <CLabel>{t("Max Volume")}</CLabel>
            <CInput
              type="text"
              placeholder={t("0 куб.м.")}
              name="max_volume"
              value={initForm.max_volume}
              onChange={(e) => updateField(e.target.name, e.target.value)}
            />
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  );
};

export default TransportAndRoutesTab;
