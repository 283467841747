import { useState, useEffect } from "react";
import { CButton, CRow, CCol, CInput } from "@coreui/react";
import {
  MapContainer,
  TileLayer,
  //  Marker, Popup,
  FeatureGroup,
  Polygon,
  Tooltip,
  useMap,
  // useMapEvents
} from "react-leaflet";
// import L from "leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { toastify } from "src/helpers/toast";
import {
  fetchCompanyExtraFeePost,
  fetchCompanyExtraFeePatch,
} from "src/services/CompanyService";
import { useDispatch, useSelector } from "react-redux";
import {
  getExtraFees,
  resetExtraFee,
} from "src/redux/actions/extraFeesActions";
import { useTranslation } from "react-i18next";

function ChangeView({ center }) {
  const map = useMap();
  map.setView(center, 12);
  return null;
}

function Leaflet(props) {
  const getCenter = function (arr) {
    return arr.reduce(
      function (x, y) {
        return [x[0] + y[0] / arr.length, x[1] + y[1] / arr.length];
      },
      [0, 0]
    );
  };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // L.Icon.Default.imagePath = "https://unpkg.com/leaflet@1.5.0/dist/images/";
  const polygones = useSelector((state) => state.extraFees.fees);
  // console.log(polygones.filter((fee) => fee.fee_type === "zone"));
  const [center, setCenter] = useState([42.88, 74.58]);
  const [coors, setCoors] = useState([]);
  const [name, setName] = useState("");
  const [amount, setAmount] = useState(0);
  const [leafletMap, setLeafletMap] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  // console.log(coors);
  // console.log('aa', props);
  useEffect(() => {
    setTimeout(() => leafletMap && leafletMap._onResize(), 500);
  }, [leafletMap, props]);

  useEffect(() => {
    dispatch(getExtraFees());
  }, [dispatch]);

  useEffect(() => {
    if (props.selectedFee) {
      setCoors([props.selectedFee.fee.coors]);
      setName(props.selectedFee.name);
      // console.log('aaaa', props.selectedFee);
      setSelectedId(props.selectedFee.id);
      setAmount(props.selectedFee.amount);
      setCenter(
        props.selectedFee
          ? getCenter(props.selectedFee.fee.coors)
          : [42.88, 74.58]
      );
    } else {
      setCoors([[]]);
      setName("");
      setAmount(0);
      setCenter([42.88, 74.58]);
    }
  }, [dispatch, props]);

  const onCreate = ({ layer }) => {
    const arr = [];
    arr.push(layer.getLatLngs()[0].map((coors) => [coors.lat, coors.lng]));
    return setCoors(arr);
  };

  // const onEdited = (e) => {
  //   console.log(e);
  //   // const {
  //   //   layers: { _layers },
  //   // } = e;

  //   // return Object.values(_layers).map(({ _leaflet_id, editing }) =>
  //   //   setCoors((layers) =>
  //   //     layers.map((lay) =>
  //   //       lay.id === _leaflet_id
  //   //         ? { ...lay, latlngs: { ...editing.latlngs[0] } }
  //   //         : lay
  //   //     )
  //   //   )
  //   // );
  // };

  const onDeleted = (e) => {
    console.log(e);
    const {
      layers: { _layers },
    } = e;
    return Object.values(_layers).map(({ _leaflet_id }) =>
      setCoors((layers) => layers.filter((lay) => lay.id !== _leaflet_id))
    );
  };

  const createZone = () => {
    let zoneBody = { name, amount, fee_type: "zone", fee: { coors: coors[0] } };
    console.log(zoneBody);

    fetchCompanyExtraFeePost(zoneBody)
      .then((res) => {
        if (res.ok) {
          toastify("success", t("Created new zone successfully"));
          props.reFetchFees();
          setCoors([[]]);
          dispatch(getExtraFees());
          props.onToggle();
          setName("");
          setAmount(0);
        } else {
          toastify("error", t("Произошла ошибка создания зоны"));
        }
      })
      .catch((err) =>
        toastify("error", t("Произошла ошибка, проверьте интернет"))
      );
  };

  const updateZone = () => {
    let zoneBody = { name, amount, fee: { coors: coors[0] } };
    // console.log(zoneBody);

    fetchCompanyExtraFeePatch(zoneBody, props.selectedFee.id)
      .then((res) => {
        if (res.ok) {
          setCoors([[]]);
          toastify("success", t("Update zone successfully"));
          props.reFetchFees();
          dispatch(getExtraFees());
          props.onToggle();
          setName("");
          setAmount(0);
        } else {
          toastify("error", t("Something goes wrong. Try Again!"));
        }
      })
      .catch((err) =>
        toastify("error", t("Произошла ошибка, проверьте интернет"))
      );
  };

  return (
    <>
      <div className="my-2 text-center">
        <CRow>
          <CCol sm="4">
            <CInput
              placeholder={t("Zone name")}
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </CCol>
          <CCol sm="4">
            <CInput
              placeholder={t("Fee amount")}
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </CCol>
          <CCol sm="4">
            {props.selectedFee ? (
              <CButton
                variant="outline"
                color="primary"
                className="mx-2"
                onClick={() => updateZone()}
              >
                {t("Update Zone")}
              </CButton>
            ) : (
              <CButton
                variant="outline"
                color="success"
                className="mx-2"
                onClick={() => createZone()}
              >
                {t("Create Zone")}
              </CButton>
            )}
            <CButton
              variant="outline"
              color="danger"
              className="mx-2"
              onClick={() => {
                setCoors([[]]);
                // onDeleted()
                // console.log(selectedId);
                dispatch(resetExtraFee(selectedId));
              }}
            >
              {t("Reset")}
            </CButton>
            <CButton
              variant="outline"
              color="dark"
              onClick={() => {
                props.onToggle();
                setCoors([[]]);
                setName("");
                setAmount(0);
              }}
            >
              {t("Cancel")}
            </CButton>
          </CCol>
        </CRow>
      </div>
      <MapContainer
        whenCreated={setLeafletMap}
        center={center}
        zoom={12}
        scrollWheelZoom={true}
        style={{
          height: "85vh",
          width: "100%",
        }}
      >
        <FeatureGroup>
          <EditControl
            // onClick={(e) => console.log(e)}
            position="topright"
            onCreated={onCreate}
            // onEdited={onEdited}
            onDeleted={onDeleted}
            draw={{
              marker: false,
              rectangle: false,
              circle: false,
              circlemarker: false,
              polyline: false,
            }}
          />
        </FeatureGroup>
        {props.selectedFee && (
          <Polygon pathOptions={{ color: "purple" }} positions={coors} />
        )}
        {polygones &&
          polygones
            .filter((fee) => fee.fee_type === "zone")
            .map((zone) => {
              // console.log(zone);
              return (
                <Polygon
                  key={zone.id}
                  positions={zone.fee.coors}
                  pathOptions={{
                    color: `${
                      zone.amount < 20
                        ? "#8ed38b"
                        : zone.amount < 40
                        ? "#aad369"
                        : zone.amount < 60
                        ? "#71d326"
                        : zone.amount < 80
                        ? "#b2d300"
                        : zone.amount < 100
                        ? "#b2d300"
                        : zone.amount < 140
                        ? "#d39900"
                        : zone.amount < 200
                        ? "#d36200"
                        : zone.amount < 220
                        ? "#d32100"
                        : zone.amount < 240
                        ? "#d30000"
                        : zone.amount < 260
                        ? "#7a0000"
                        : zone.amount < 280
                        ? "#3a0000"
                        : zone.amount < 300
                        ? "#4a1738"
                        : zone.amount < 350
                        ? "#210000"
                        : "#210b19"
                    }`,
                    stroke: "#004B35",
                  }}
                >
                  <Tooltip>{zone.name + ", " + zone.amount + "c"}</Tooltip>
                </Polygon>
              );
            })}
        <ChangeView center={center} />
        <TileLayer
          // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          attribution="SmartPost"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    </>
  );
}

export default Leaflet;
