import {
  CModalHeader,
  CInput,
  CButton,
  CModal,
  CModalBody,
  CForm,
  CFormGroup,
  CModalFooter,
  CRow,
  CCol,
  CLabel,
  CSelect,
} from "@coreui/react";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toastify } from "src/helpers/toast";
import { fetchCreateImg } from "src/services/ServerService";
import {
  fetchCompanyPackageTypeCreate,
  fetchCompanyPackageTypeUpdate,
} from "src/services/CompanyService";
import MiniSpinner from "src/components/spinners/MiniSpinner";

const PackageTypeForm = ({
  toggle,
  modal,
  modalTitle,
  selectedPackageType,
  reFetchPt,
}) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");

  const takePhoto = (e) => {
    // console.log(e.target.files[0]);
    if (e.target.files[0].size > 1085760) {
      toastify("error", t("Слишком большой размер картинки, выберите другую"));
    } else {
      let formData = new FormData();
      formData.append("image", e.target.files[0]);
      fetchCreateImg(formData).then((data) => {
        setPhoto(data.data);
      });
    }
  };

  const [btnState, setBtnState] = useState(false);
  const [id, setId] = useState("");
  const [photo, setPhoto] = useState("");
  const [nameRu, setNameRu] = useState("");
  const [nameKg, setNameKg] = useState("");
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [unit, setUnit] = useState("cm/kg");
  const [maxVolume, setMaxVolume] = useState("");
  const [maxWeight, setMaxWeight] = useState("");

  const submitPackageType = () => {
    let ptForm = {
      length,
      width,
      height,
      unit,
      name_kg: nameKg,
      name_ru: nameRu,
      max_volume: +maxVolume,
      max_weight: +maxWeight,
      photo,
    };
    if (nameRu.length < 1) {
      ptForm.name_ru = nameKg;
    } else if (nameKg.length < 1) {
      ptForm.name_kg = nameRu;
    }

    // console.log(ptForm);
    fetchCompanyPackageTypeCreate(ptForm)
      .then((res) => {
        if (res.ok) {
          toastify("success", t("New package type created"));
          reFetchPt();
          setBtnState(false);
          toggle();
          setId("");
          setPhoto("");
          setNameRu("");
          setNameKg("");
          setLength("");
          setWidth("");
          setHeight("");
          setUnit("");
          setMaxVolume("");
          setMaxWeight("");
        } else {
          toastify("error", t("Произошла ошибка создания типа посылок"));
          setBtnState(false);
        }
      })
      .catch((err) => {
        toastify("error", t("Произошла ошибка, проверьте интернет"));
        setBtnState(false);
      });
  };

  const updatePackageType = () => {
    if (nameRu.length < 1) {
      setNameRu(nameKg);
    } else if (nameKg.length < 1) {
      setNameKg(nameRu);
    }
    let ptForm = {
      length,
      width,
      height,
      unit,
      name_kg: nameKg,
      name_ru: nameRu,
      max_volume: +maxVolume,
      max_weight: +maxWeight,
      photo,
    };
    fetchCompanyPackageTypeUpdate(ptForm, id)
      .then((res) => {
        if (res.ok) {
          toastify("success", t("Package type changed"));
          reFetchPt();
          setBtnState(false);
          toggle();
        } else {
          toastify("error", t("Произошла ошибка изменения типа посылок"));
          setBtnState(false);
        }
      })
      .catch((err) => {
        toastify("error", t("Произошла ошибка, проверьте интернет"));
        setBtnState(false);
      });
  };

  useEffect(() => {
    setNameKg(selectedPackageType.name_kg || "");
    setNameRu(selectedPackageType.name_ru || "");
    setId(selectedPackageType.id || "");
    setPhoto(selectedPackageType.photo || "");
    setMaxWeight(selectedPackageType.max_weight || "");
    setMaxVolume(selectedPackageType.max_volume || "");
    setWidth(selectedPackageType.width || "");
    setLength(selectedPackageType.length || "");
    setHeight(selectedPackageType.height || "");
    setUnit(selectedPackageType.unit || "cm/kg");
  }, [selectedPackageType, lang]);

  const calcVolume = (l, w, h) => {
    if (unit === "cm/kg") {
      return (l * w * h) / 1000000;
    } else {
      return l * w * h;
    }
  };

  // useEffect(() => {
  //   setMaxVolume(calcVolume(length, width, height));
  // }, [length, width, height]);

  return (
    <>
      <CModal show={modal} onClose={toggle} size="m">
        <CModalHeader closeButton>{modalTitle}</CModalHeader>
        <CModalBody>
          <CRow>
            <CCol>
              <CForm>
                <CFormGroup>
                  <CLabel>{t("Name on kg")}</CLabel>
                  <CInput
                    type="text"
                    placeholder={t("Name on kg")}
                    name="NameKg"
                    value={nameKg}
                    onChange={(e) => setNameKg(e.target.value)}
                  />
                </CFormGroup>
              </CForm>

              <CForm>
                <CFormGroup>
                  <CLabel>{t("Name on ru")}</CLabel>
                  <CInput
                    type="text"
                    placeholder={t("Name on ru")}
                    name="NameRu"
                    value={nameRu}
                    onChange={(e) => setNameRu(e.target.value)}
                  />
                </CFormGroup>
              </CForm>

              <CForm>
                <CFormGroup>
                  <CLabel>{t("Width")}</CLabel>
                  <CInput
                    name="width"
                    placeholder={t("Width")}
                    value={width}
                    onChange={(e) => setWidth(e.target.value)}
                  />
                </CFormGroup>
              </CForm>

              <CForm>
                <CFormGroup>
                  <CLabel>{t("Height")}</CLabel>
                  <CInput
                    name="height"
                    placeholder={t("Height")}
                    value={height}
                    onChange={(e) => setHeight(e.target.value)}
                  />
                </CFormGroup>
              </CForm>
            </CCol>
            <CCol>
              <CForm>
                <CFormGroup>
                  <CLabel>{t("Length")}</CLabel>
                  <CInput
                    name="length"
                    placeholder={t("Length")}
                    value={length}
                    onChange={(e) => setLength(e.target.value)}
                  />
                </CFormGroup>
              </CForm>

              <CForm>
                <CFormGroup>
                  <CLabel>{t("Max Weight")}</CLabel>
                  <CInput
                    name="Max Weight"
                    placeholder={t("Max Weight")}
                    value={maxWeight}
                    onChange={(e) => setMaxWeight(e.target.value)}
                  />
                </CFormGroup>
              </CForm>
              <CForm>
                <CRow>
                  <CCol>
                    <CFormGroup>
                      <CLabel>{t("Max Volume")}</CLabel>
                      <CInput
                        disabled
                        name={t("Max Volume")}
                        value={
                          selectedPackageType.maxVolume
                            ? maxVolume + " м³"
                            : calcVolume(length, width, height) + " м³"
                        }
                        // value={maxVolume + " м³"}
                      />
                    </CFormGroup>
                  </CCol>
                  <CCol>
                    <CLabel>{t("Unit")}</CLabel>
                    <CSelect
                      onChange={(e) => setUnit(e.target.value)}
                      value={unit}
                    >
                      <option value="cm/kg">см/кг</option>
                      <option value="m/t">м/т</option>
                    </CSelect>
                  </CCol>
                </CRow>
              </CForm>

              <CForm>
                <CFormGroup>
                  <CLabel>{t("Photo")}</CLabel>
                  <CInput
                    type="file"
                    name="photo"
                    onChange={(e) => takePhoto(e)}
                  />
                </CFormGroup>
              </CForm>
            </CCol>
          </CRow>
        </CModalBody>

        <CModalFooter>
          {id ? (
            <CButton
              type="submit"
              variant="outline"
              color="success"
              disabled={btnState}
              onClick={() => {
                setBtnState(true);
                updatePackageType({});
              }}
            >
              {btnState ? <MiniSpinner /> : t("Update")}
            </CButton>
          ) : (
            <CButton
              type="submit"
              variant="outline"
              color="success"
              disabled={btnState}
              onClick={() => {
                setBtnState(true);
                submitPackageType({});
              }}
            >
              {btnState ? <MiniSpinner /> : t("Create")}
            </CButton>
          )}
          <CButton
            variant="outline"
            color="danger"
            onClick={() => {
              toggle();
              setBtnState(false);
            }}
          >
            {t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default PackageTypeForm;
