import React, { useState, useEffect } from "react";
import { toastify } from "src/helpers/toast";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  CModalHeader,
  CInput,
  CButton,
  CModal,
  CModalBody,
  CForm,
  CFormGroup,
  CModalFooter,
  CLabel,
  CCol,
  CRow,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import { fetchCreateOrganization, fetchUpdateOrganization } from "src/services/ClientService";
import { getOrganizations } from "src/redux/actions/organizationActions";
import MiniSpinner from "src/components/spinners/MiniSpinner";

export default function OrganizationForm({
  toggle,
  modal,
  modalTitle,
  selectedOrg,
}) {
  const dispatch = useDispatch();

  const [id, setId] = useState(null);
  const [orgName, setOrgName] = useState("");
  const [account, setAccount] = useState("");
  const [bank, setBank] = useState("");
  const [bank_code, setBank_code] = useState("");
  const [contract_date, setContract_date] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhone_number] = useState("");

  const [btnState, setBtnState] = useState(false);
  const { t } = useTranslation();

  const submitOrg = () => {
    const form = {
      name: orgName,
      account,
      bank,
      bank_code,
      contract_date,
      address,
      email,
      phone_number: `+${phone_number}`,
    };
    // console.log(form);
    fetchCreateOrganization(form)
      .then((res) => {
        if (res.ok) {
          toastify("success", t("Новая организация успешно создана"));
          setBtnState(false);
          toggle();
          dispatch(getOrganizations());
          setOrgName("");
          setAccount("");
          setBank("");
          setBank_code("");
          setContract_date("");
          setAddress("");
          setEmail("");
          setPhone_number("");
        } else {
          toastify("error", t("Произошла ошибка создания организации"));
          setBtnState(false);
        }
      })
      .catch((err) => {
        toastify("error", "Произошла ошибка, проверьте интернет");
        setBtnState(false);
      });
  };

  const updateOrg = () => {
    const form = {
      name: orgName,
      account,
      bank,
      bank_code,
      contract_date,
      address,
      email,
      phone_number,
    };
    console.log(form, "id", id);
    fetchUpdateOrganization(form, id)
      .then((res) => {
        if (res.ok) {
          toastify("success", t("Организация успешно обновлена"));
          setBtnState(false);
          toggle();
          dispatch(getOrganizations());
          setOrgName("");
          setAccount("");
          setBank("");
          setBank_code("");
          setContract_date("");
          setAddress("");
          setEmail("");
          setPhone_number("");
        } else {
          toastify("error", t("Произошла ошибка обновлении организации"));
          setBtnState(false);
        }
      })
      .catch((err) => {
        toastify("error", "Произошла ошибка, проверьте интернет");
        setBtnState(false);
      });
  };

  useEffect(() => {
    setId(selectedOrg.id || "");
    setOrgName(selectedOrg.name || "");
    setAccount(selectedOrg.account || "");
    setAddress(selectedOrg.address || "");
    setBank(selectedOrg.bank || "");
    setBank_code(selectedOrg.bank_code || "");
    setEmail(selectedOrg.email || "");
    setPhone_number(selectedOrg.phone_number || "");
    setContract_date(selectedOrg.contract_date || "");
  }, [selectedOrg]);

  return (
    <CModal show={modal} onClose={toggle} size="lg">
      <CModalHeader closeButton>{modalTitle}</CModalHeader>
      <CModalBody>
        <CRow>
          <CCol>
            <CForm>
              <CFormGroup>
                <CLabel>{t("Organization name")}</CLabel>
                <CInput
                  type="text"
                  placeholder={t("Organization name")}
                  name="orgName"
                  value={orgName}
                  onChange={(e) => setOrgName(e.target.value)}
                />
              </CFormGroup>
            </CForm>
            <CForm>
              <CFormGroup>
                <CLabel>{t("Account")}</CLabel>
                <CInput
                  type="text"
                  placeholder={t("Account")}
                  name="account"
                  value={account}
                  onChange={(e) => setAccount(e.target.value)}
                />
              </CFormGroup>
            </CForm>
            <CForm>
              <CFormGroup>
                <CLabel>{t("Bank")}</CLabel>
                <CInput
                  type="text"
                  placeholder={t("Bank")}
                  name="bank"
                  value={bank}
                  onChange={(e) => setBank(e.target.value)}
                />
              </CFormGroup>
            </CForm>
            <CForm>
              <CFormGroup>
                <CLabel>{t("Bank Code")}</CLabel>
                <CInput
                  type="text"
                  placeholder={t("Bank Code")}
                  name="bank_code"
                  value={bank_code}
                  onChange={(e) => setBank_code(e.target.value)}
                />
              </CFormGroup>
            </CForm>
          </CCol>
          <CCol>
            <CForm>
              <CFormGroup>
                <CLabel>{t("Contract Date")}</CLabel>
                <CInput
                  type="date"
                  placeholder={t("Contract Date")}
                  name="contract_date"
                  value={contract_date}
                  onChange={(e) => setContract_date(e.target.value)}
                />
              </CFormGroup>
            </CForm>
            <CForm>
              <CFormGroup>
                <CLabel>{t("Address")}</CLabel>
                <CInput
                  type="text"
                  placeholder={t("Address")}
                  name="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </CFormGroup>
            </CForm>
            <CForm>
              <CFormGroup>
                <CLabel>{t("Phone number")}</CLabel>
                <PhoneInput
                  inputStyle={{ width: "100%" }}
                  containerStyle={{ width: "100%" }}
                  onlyCountries={["kg"]}
                  country={"kg"}
                  inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                  value={phone_number}
                  onChange={setPhone_number}
                  masks={{ kg: "(...) ..-..-.." }}
                />
              </CFormGroup>
            </CForm>
            <CForm>
              <CFormGroup>
                <CLabel>{t("Email")}</CLabel>
                <CInput
                  type="text"
                  placeholder={t("Email")}
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </CFormGroup>
            </CForm>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        {id ? (
          <CButton
            type="submit"
            variant="outline"
            color="success"
            disabled={btnState}
            onClick={() => {
              updateOrg();
              setBtnState(true);
            }}
          >
            {btnState ? <MiniSpinner /> : t("Update")}
          </CButton>
        ) : (
          <CButton
            type="submit"
            variant="outline"
            color="success"
            disabled={btnState}
            onClick={() => {
              submitOrg();
              setBtnState(true);
            }}
          >
            {btnState ? <MiniSpinner /> : t("Create")}
          </CButton>
        )}
        <CButton
          variant="outline"
          color="danger"
          onClick={() => {
            toggle();
            setBtnState(false);
          }}
        >
          {t('Cancel')}
        </CButton>
      </CModalFooter>
    </CModal>
  );
}
