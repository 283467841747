import React, { useEffect, useState } from "react";
import {
    CModal,
    CModalBody,
    CButton,
    CTooltip,
} from "@coreui/react";
import { useConfirmModal, useModalState } from "src/helpers/useModal";
import OrderDetails from "../orders/Order-Details/OrderDetails";
import { useTranslation } from "react-i18next";
import { orderDeleteFetchForPartners, orderStatusChangeFetch} from "src/services/OrderService";
import { toastify } from "src/helpers/toast";
import { date } from "src/helpers/date";

import CIcon from "@coreui/icons-react";
import { useTranslate } from "src/helpers/useTranslate";
import { SERVER_URL } from "src/services/ServerService";


export default function OrdersTable(props) {

    const hexToRgb = (hex) => {
        let arr = hex
            .replace(
                /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
                (m, r, g, b) => "#" + r + r + g + g + b + b
            )
            .substring(1)
            .match(/.{2}/g)
            .map((x) => parseInt(x, 16));
        return `rgb(${arr[0] + 40}, ${arr[1] + 40}, ${arr[2] + 40})`;
    };

    const { t } = useTranslation();
    const {tr} = useTranslate();

    const { isOpen, onToggle } = useModalState();
    const { onConfirmOpen, onConfirmClose, isConfirmOpen, onConfirmToggle } =
        useConfirmModal();

    const [selectedOrder, setSelectedOrder] = useState(null);
    const [newOrderValue, setNewOrderValue] = useState(null);
    const [field, setField] = useState(null);

    const addOrderDetailToClipboard = (order) => {
        if (order){
            const orderOid = order.oid
            const orderId = order.id
            const orderPid = order.pid
            const sender = order.sender
            const locationFrom = order.location_from
            const locationFromFlat = order.location_from_flat
            const locationFromFloor = order.location_from_floor
            const redemptionAmount = order.redemption_amount
            const receiver = order.receiver
            const locationTo = order.location_to
            const locationToFlat = order.location_to_flat
            const locationToFloor = order.location_to_floor
            const paying_side = 
                order.paying_side === "receiver" ? "Получатель" : 
                order.paying_side === "sender" ? "Отправитель" : "Вместе"
            const finalPrice = order.final_price
            const packageType = tr(order.package_type?.name_ru,order.package_type?.name_ru)
            const description = order.description

            navigator.clipboard.writeText(`*Заказ №: ${orderOid ? orderOid : orderId}*  ${SERVER_URL}/orders/${orderPid}  \n\nОтправитель: ${sender?.fullname}, ${sender?.phone},\n🅰️Откуда: ${locationFrom?.city && (tr(locationFrom?.city?.name_ru, locationFrom.city?.name_kg) +",")} ${locationFrom?.district ? tr(locationFrom?.district?.name_ru, locationFrom?.district?.name_kg) + ", " : ""}${tr(locationFrom?.name_ru, locationFrom?.name_kg)} ${locationFromFlat ? "\nКвартира: " + locationFromFlat : ""} ${locationFromFloor ? " \nЭтаж: " + locationFromFloor : ""} \n${order.package_type ? "\n📦Тип посылки: " + packageType : ""} \nВыкуп: *${redemptionAmount} ⊆* \n\nПолучатель: ${(receiver ? receiver?.fullname  + ',' + receiver?.phone : "нужно уточнить, ")} \n🅱️Куда: ${locationTo && locationTo.city ? (tr(locationTo?.city?.name_ru, locationTo?.city?.name_kg) === undefined ? "" : tr(locationTo?.city?.name_ru, locationTo?.city?.name_kg)  + ", " + (locationTo?.district ?
                tr(locationTo?.district?.name_ru, locationTo?.district?.name_kg) + ", " : "") + tr(locationTo?.name_ru, locationTo?.name_kg)) : "нужно уточнить"}  \n${locationToFlat ? "Квартира: " + locationToFlat : ""} ${locationToFloor ? " Этаж: " + locationToFloor : ""} \n\nОплачивает: ${paying_side} \n💰 За доставку: *${finalPrice} ⊆*
          \n\nКомментарий : ${description ? description : ""}
         `)
        }
    }

    useEffect(() => {
        if(props.selectedOrderId) {
            setSelectedOrder({id: props.selectedOrderId})
        }
    }, [props.selectedOrderId])

    const openDetails = (order) => {
        setSelectedOrder(order);
        onToggle();
    };

    const openConfirmChange = (order, field, newValue) => {
        setSelectedOrder(order);
        setNewOrderValue(newValue);
        setField(field)
        onConfirmOpen()
    }

    const confirmChange = () => {
        switch(field) {
            case "status":
                confirmStatusChange();
                break;
            case "deleteOrder":
                confirmDeleteOrder()
                break;
            default:
                console.log("confirmation");
        }
    }

    const confirmDeleteOrder = () => {

        orderDeleteFetchForPartners(selectedOrder.id)
            .then((res) => {
                if (res.ok) {
                    toastify("success", t("Operation succeeded"));
                    onConfirmClose();
                    setSelectedOrder(null);
                    setNewOrderValue(null);
                    setField(null)
                } else {
                    toastify("error", t("Operation failed"));
                }
            })
            .catch((err) => toastify("error", "Error occured"));
    };

    const confirmStatusChange = () => {
        orderStatusChangeFetch(selectedOrder.id, newOrderValue)
            .then((res) => {
                if (res.ok) {
                    toastify("success", t("Operation succeeded"));
                    onConfirmClose();
                    setSelectedOrder(null);
                    setNewOrderValue(null);
                    setField(null)
                } else {
                    toastify("error", t("Operation failed"));
                }
            })
            .catch((err) => toastify("error", "Error occured"));
    };



    console.log(props.currentOrders)

    return (
        <div>
            <table className="table table-responsive-lg">
                <thead>
                <tr>
                    <th>#</th>
                    <th>{t("Sender")} <CIcon name="cilArrowRight"/> {t("Receiver")}</th>
                    {/*{!profile?.roles?.includes("partner") && <th>{t("Офис")}</th>}*/}
                    <th>{t("Courier")}</th>
                    <th>{t("Status")}</th>
                    <th>{t("Date")}</th>
                    <th>{t("Tariff")}</th>
                    <th>{t("Redemption")}</th>
                    <th>{t("Price")}</th>
                    {!props.archive &&
                    <th>{t("Actions")}</th>
                    }
                </tr>
                </thead>
                <tbody>
                {props.currentOrders.length > 0 &&
                props.currentOrders.map((order) => (
                    <tr
                        className="order_table_row"
                        key={order.id}
                        style={{
                            background:
                                order.status === "created" && order.source !== "operator web" ? "linear-gradient(to top, yellow, white)"
                                    :
                                    (                    props.statusList &&
                                        props.statusList.length &&
                                        "linear-gradient(to right, " +
                                        props.statusList.filter(
                                            (s) => s.value === order.status
                                        )[0].color +
                                        " " +
                                        props.statusList.filter(
                                            (s) => s.value === order.status
                                        )[0].percent +
                                        `%, ${hexToRgb(props.statusList.filter(
                                            (s) => s.value === order.status
                                        )[0].color)} 0%)`),
                        }}
                    >
                        <td style={{padding: "5px", width: "10%"}}><b>{order.oid}</b></td>
                        <td
                            onClick={() => openDetails(order)}
                            style={{ cursor: "pointer", padding: "5px", width: "30%" }}
                        >
                  <span style={{color: "black"}}>
                    {order.location_from?.city && order.location_from?.city?.id !== order.location_to?.city?.id &&
                    tr(order.location_from?.city?.name_ru, order.location_from?.city?.name_kg) + ", "}
                      {tr(order.location_from?.name_ru, order.location_from?.name_kg)}

                      {" "}<CIcon name="cilArrowRight"/>{" "}

                      {order.location_to?.city && order.location_from?.city?.id !== order.location_to?.city?.id &&
                      tr(order.location_to?.city?.name_ru, order.location_to?.city?.name_kg) + ", "}
                      {tr(order.location_to?.name_ru, order.location_to?.name_kg)}
                  </span>
                            <br/>
                            {order.description.substring(0, 40)}{order.description.length > 40 && "..."}
                        </td>
                        <td style={{padding: "5px", width: "8%"}} className="text-center">
                            {
                                <>
                                    <span>{order.delivery_service?.name}</span> <br/>
                                    <span>{order.courier?.user?.firstname}</span> <br/>
                                    <span>{order.courier?.user?.username}</span>
                                </>
                            }
                        </td>
                        <td style={{width: "12%", padding: "5px"}}>
                            {<div className="text-center">
                                    {t(order.status)}
                                {order.status !== "cancelled" ? <CButton className="btn btn-block btn-sm btn-danger" onClick={() => openConfirmChange(order, "status", "cancelled")}>{t("Cancel")}</CButton> : null}
                                </div>
                            }
                        </td>
                        <td style={{padding: "5px"}} className="text-right">
                            {date(order.created_at)}
                        </td>
                        <td style={{padding: "5px"}}>
                            <img alt="" src={order.package_type?.photo || ""} className="rounded-circle mr-1" width="15"/>
                            {order.tariff?.name}
                        </td>
                        <td style={{padding: "5px"}} className="text-center">{order.redemption_amount} <u>c</u></td>
                        <td style={{padding: "5px"}} className="text-center">{order.final_price} <u>c</u></td>
                        {!props.archive &&
                        <td style={{padding: "5px"}} className="text-center">
                            <CTooltip
                                content={t("Copy")}
                            >
                                <CIcon
                                    style={{cursor: "pointer"}}
                                    name="cilCopy"
                                    onClick={() => addOrderDetailToClipboard(order)}
                                />
                            </CTooltip>
                            <CTooltip content={t("Copy link")} placement="bottom">
                                <CIcon name="cilLink" style={{cursor: "pointer"}} className="ml-1"
                                       onClick={() => {
                                           navigator.clipboard.writeText(`${SERVER_URL}/orders/` + order.pid)
                                           toastify("info", "Link is copied")
                                       }}/>
                            </CTooltip>
                            {(order.status === "cancelled") &&
                            <CIcon
                                style={{cursor: "pointer"}}
                                className="text-danger"
                                name="cilTrash"
                                onClick={() => openConfirmChange(order, "deleteOrder")}
                            />
                            }
                        </td>
                        }
                    </tr>
                ))}
                </tbody>
            </table>
            {selectedOrder && <OrderDetails
                isOpen={props.selectedOrderId ? true : isOpen}
                onToggle={onToggle}
                selectedOrder={selectedOrder}
                setSelectedOrder={setSelectedOrder}
            />}

            {selectedOrder && <CModal show={isConfirmOpen} onClose={onConfirmToggle}>
                <CModalBody>
                    <h5 className="text-center">
                            { field === "status" ? t("Confirm status change?") : t("Confirm delete order?")}
                        </h5>
                    <div className="text-right">
                        <CButton variant="outline" color="dark" onClick={onConfirmToggle} className="mx-2">{t("Close")}</CButton>
                        <CButton
                            variant="outline"
                            color="primary"
                            onClick={confirmChange}
                        >
                            {t("Confirm")}
                        </CButton>
                    </div>
                </CModalBody>
            </CModal>}

        </div>
    );
}
