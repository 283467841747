
import React, {useEffect, useState} from 'react';
import {CCard, CCardBody, CInput} from "@coreui/react"
import {useDispatch} from 'react-redux'
import { setBreadcrumb } from 'src/redux/actions/settingsActions';
import {fetchAllOrders, fetchCompanyStatuses} from 'src/services/CompanyService';
import MiniSpinner from 'src/components/spinners/MiniSpinner';
import AllCompaniesOrderTable from "./AllCompaniesOrderTable";
import Pagination from 'src/components/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import {useTranslation} from "react-i18next";

const limit = 25
const AllCompaniesOrders = () => {
    const { t } = useTranslation();
    const [statuses, setStatuses] = useState([]);
    const [allOrders, setAllOrders] = useState([]);
    const [isLoading, setIsLOading] = useState(false);

    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    const [queryParams, setQueryParams] = useState(
        {search: "", skip: 0, limit: limit})

    const onDeleteOrder = (id) => {
        setAllOrders((state) => state.filter(s => s.id !== id)
        )
    }
    useEffect(() => {
        dispatch(setBreadcrumb([t("All Orders")]));
        fetchCompanyStatuses().then(res => {
            if(res.ok) {
                res.json().then(data => {
                    let statusList = data.map((d, i) => {
                        if(d.value==="created") {
                            return {...d, percent: 5}
                        } else if(i < data.length - 2) {
                            return {...d, percent: parseInt(i * 10)}
                        } else {
                            return {...d, percent: 100}
                        }
                    })
                    setStatuses(statusList);
                })
            }
        }).catch(err => console.log(err));
        setIsLOading(true)
    }, [dispatch, t])

    useEffect(() => {
        if(params.p) {
            const par = params.p
            let q = JSON.parse('{"' + 
                decodeURI(par.replace(/&/g, "\",\"")
                    .replace(/=/g,"\":\"")
                    .replace(/spsl/g, "/")
                    .replace(/bsps/g, '/')
                ) + '"}')
            console.log(q)
            setQueryParams({...q})
        }
        fetchAllOrders(params.p || "limit="+limit).then(res =>res.ok && res.json()).then(data => {
            setAllOrders(data);
            setIsLOading(false)
        }).catch(err => {
            setIsLOading(false);
            console.log(err)}
        ).finally(() => setIsLOading(false))
    }, [params])

    const filterChange = (value, field) => {
        console.log(value)
        let qParams = ""
        if(field)  
        {
            const newState = {...queryParams, [field]: value}
            Object.keys(newState).forEach((qKey) => {
                if(newState[qKey]) {
                    qParams = qParams ? qParams + "&" : qParams
                    qParams = qParams+qKey+"="+newState[qKey]
                }
            })
        }
        console.log(qParams)
        history.push("/all-orders/" + qParams)
    }

    const onNext = () => {
        filterChange(parseInt(queryParams.skip || 0) + limit, "skip")
    }

    const onPrev = () => {
        filterChange(parseInt(queryParams.skip || 0) - limit, "skip")
    }

    return (
        <div>
            <CCard className="m-2">
                <CCardBody className="px-3 pt-3">
                    <div className='d-flex justify-between'>
                            <CInput placeholder="Search..." 
                                onChange={(e) => filterChange(e.target.value, "search")} value={queryParams.search}
                                style={{width: "80%"}}/>
                            <div className='mx-auto'>
                                <Pagination 
                                    currentPage={((queryParams.skip / limit) || 0) + 1}
                                    nextPage={onNext}
                                    prevPage={onPrev}
                                    limit={limit}
                                    currentListLength={allOrders.length}/>
                            </div>
                    </div>
                    <div className="mt-1">
                        {isLoading ?
                            <div className="text-center">
                                <MiniSpinner />
                            </div>
                                :
                                <div>
                                    <AllCompaniesOrderTable 
                                        onDeleteOrder={onDeleteOrder} 
                                        allOrders={allOrders} 
                                        statusList={statuses}/>
                                    <Pagination 
                                        currentPage={((queryParams.skip / limit) || 0) + 1}
                                        nextPage={onNext}
                                        prevPage={onPrev}
                                        limit={limit}
                                        currentListLength={allOrders.length}/>
                                </div>
                        }
                    </div>

                </CCardBody>
            </CCard>
        </div>
    );
};

export default AllCompaniesOrders;