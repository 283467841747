import React, { useEffect, useState } from "react";
import {
  CCard,
  CInput,
  CCardBody,
  CForm,
  CFormGroup,
  CLabel,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CRow,
  CCol,
  CCardHeader,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setBreadcrumb } from "src/redux/actions/settingsActions";
import MiniSpinner from "src/components/spinners/MiniSpinner";
import { getTransports } from "../../redux/actions/transportActions";
import { fetchCreateImg } from "src/services/ServerService";
import {
  fetchCreateTransport,
  fetchDeleteTransport,
  fetchUpdateTransport,
} from "src/services/TransportService";
import { toastify } from "src/helpers/toast";
import { useConfirmModal } from "../../helpers/useModal";

export default function Transports() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");

  const [btnState, setBtnState] = useState(false);
  const [isCreateInput, setIsCreateInput] = useState(false);
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const { isConfirmOpen, onConfirmToggle } = useConfirmModal();
  const [modalTitle, setModalTitle] = useState("Modal Title");

  const [selectedId, setSelectedId] = useState(null);
  const [nameKg, setNameKg] = useState("");
  const [nameRu, setNameRu] = useState("");
  const [maxVolume, setMaxVolume] = useState("");
  const [maxWeight, setMaxWeight] = useState("");
  const [photo, setPhoto] = useState("");

  const transports = useSelector((state) => state.transport.transports);
  const reFetchTransports = async () => dispatch(getTransports());

  const submitTransport = () => {
    const form = {
      name_kg: nameKg,
      name_ru: nameRu,
      max_volume: +maxVolume,
      max_weight: +maxWeight,
      photo: photo,
    };
    console.log(form);
    fetchCreateTransport(form)
      .then((res) => {
        if (res.ok) {
          setBtnState(false);
          toastify("success", t("Новый транспорт создан"));
          reFetchTransports();
          setSelectedId(null);
          setIsCreateInput(false);
          setNameKg("");
          setNameRu("");
          setMaxVolume("");
          setMaxVolume("");
          setPhoto("");
        } else {
          toastify("error", t("Произошла ошибка создания транспорта"));
          setBtnState(false);
        }
      })
      .catch((err) => {
        toastify("error", t("Произошла ошибка, проверьте интернет"));
        setBtnState(false);
      });
  };

  const updateTransport = () => {
    const form = {
      name_kg: nameKg,
      name_ru: nameRu,
      max_volume: +maxVolume,
      max_weight: +maxWeight,
      photo: photo,
    };
    console.log(form, selectedId);
    fetchUpdateTransport(form, selectedId)
      .then((res) => {
        if (res.ok) {
          toastify("success", t("Транспорт успешно изменен"), {
            position: "top-left",
          });
          reFetchTransports();
          setSelectedId(null);
          setBtnState(false);
        } else {
          toastify("error", t("Произошла ошибка изменения транспорта"));
          setBtnState(false);
        }
      })
      .catch((err) => {
        toastify("error", t("Произошла ошибка, проверьте интернет"));
        setBtnState(false);
      });
  };

  const showDelete = () => {
    setModalTitle(t("Delete Transport"));
    reFetchTransports();
    onConfirmToggle();
  };

  const deleteTransport = (id) => {
    fetchDeleteTransport(id)
      .then((res) => {
        if (res.ok) {
          toastify("success", t("Транспорт удалён"));
          reFetchTransports();
          setSelectedId(null);
        } else {
          toastify("error", t("Произошла ошибка удаления транспорта"));
        }
      })
      .catch((err) => toastify("error", t("Произошла ошибка, проверьте интернет")))
      .finally(onConfirmToggle);
  };

  const takePhoto = (e) => {
    let formData = new FormData();
    formData.append("image", e.target.files[0]);
    fetchCreateImg(formData).then((data) => setPhoto(data.data));
  };

  useEffect(() => {
    dispatch(getTransports());
    dispatch(setBreadcrumb(["Transports"]));
  }, [dispatch]);

  return (
    <CCard className="m-2">
      <CCardHeader>
        {isCreateInput === false ? (
          !isShowTooltip ? (
            <CIcon
              size="2xl"
              cursor="pointer"
              name="cilPlus"
              className="text-success sp-rounded"
              style={{ marginBottom: "2.5px" }}
              onMouseEnter={() => setIsShowTooltip(true)}
            />
          ) : (
            <CButton
              color="success"
              shape="pill"
              onMouseLeave={() => setIsShowTooltip(false)}
              onClick={() => {
                setIsCreateInput(true);
                setNameKg("");
                setNameRu("");
                setMaxVolume("");
                setMaxWeight("");
                dispatch(setBreadcrumb(["Transports", "Create Transport"]));
              }}
            >
              {t("+ Add new transport")}
            </CButton>
          )
        ) : (
          <CRow>
            <CCol>
              <CLabel>{t("Name on kg")}</CLabel>
              <CInput
                value={nameKg}
                onChange={(e) => setNameKg(e.target.value)}
              />
            </CCol>
            <CCol>
              <CLabel>{t("Name on ru")}</CLabel>
              <CInput
                value={nameRu}
                onChange={(e) => setNameRu(e.target.value)}
              />
            </CCol>
            <CCol>
              <CLabel>{t("Max Volume")}</CLabel>
              <CInput
                value={maxVolume}
                onChange={(e) => setMaxVolume(e.target.value)}
              />
            </CCol>
            <CCol>
              <CLabel>{t("Max Weight")}</CLabel>
              <CInput
                value={maxWeight}
                onChange={(e) => setMaxWeight(e.target.value)}
              />
            </CCol>
            <CCol>
              <CLabel>{t("Photo")}</CLabel>
              <CInput type="file" name="photo" onChange={(e) => takePhoto(e)} />
            </CCol>
            <CCol className="d-flex justify-content-center align-items-center">
              <CButton
                className="mr-1"
                variant="outline"
                color="success"
                disabled={btnState}
                onClick={() => {
                  submitTransport();
                  setBtnState(true);
                  setIsShowTooltip(false);
                  dispatch(setBreadcrumb(["Transports"]));
                }}
              >
                {btnState ? <MiniSpinner /> : t("Create")}
              </CButton>
              <CButton
                variant="outline"
                color="secondary"
                onClick={() => {
                  setIsCreateInput(false);
                  setIsShowTooltip(false);
                  dispatch(setBreadcrumb(["Transports"]));
                }}
              >
                {t("Cancel")}
              </CButton>
            </CCol>
          </CRow>
        )}
      </CCardHeader>
      <CCardBody>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>{t("Name")}</th>
              <th>{t("Photo")}</th>
              <th>{t("Max Volume")}</th>
              <th>{t("Max Weight")}</th>
              <th>{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {transports.map((tr) => {
              return selectedId === tr.id ? (
                <tr key={tr.id}>
                  <td>
                    <CLabel>{t("Name on kg")}</CLabel>
                    <CInput
                      value={nameKg}
                      onChange={(e) => setNameKg(e.target.value)}
                    />
                  </td>
                  <td>
                    <CLabel>{t("Name on ru")}</CLabel>
                    <CInput
                      value={nameRu}
                      onChange={(e) => setNameRu(e.target.value)}
                    />
                  </td>
                  <td>
                    <CLabel>{t("Max Volume")}</CLabel>
                    <CInput
                      value={maxVolume}
                      onChange={(e) => setMaxVolume(e.target.value)}
                    />
                  </td>
                  <td>
                    <CLabel>{t("Max Weight")}</CLabel>
                    <CInput
                      value={maxWeight}
                      onChange={(e) => setMaxWeight(e.target.value)}
                    />
                  </td>
                  <td>
                    <CLabel>{t("Photo")}</CLabel>
                    <CInput
                      type="file"
                      name="photo"
                      onChange={(e) => takePhoto(e)}
                    />
                  </td>
                  <td>
                    <CIcon
                      name="cilCheck"
                      className="text-success mr-3"
                      size="xl"
                      cursor="pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        updateTransport();
                        dispatch(setBreadcrumb(["Transports"]));
                      }}
                    />
                    <CIcon
                      name="cilX"
                      className="text-danger mr-3"
                      size="xl"
                      cursor="pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsCreateInput(false);
                        setSelectedId(null);
                        setNameKg("");
                        setNameRu("");
                        setMaxVolume("");
                        setMaxWeight("");
                        setPhoto("");
                        dispatch(setBreadcrumb(["Transports"]));
                      }}
                    />
                  </td>
                </tr>
              ) : (
                <tr key={tr.id}>
                  <td>{lang === "ru" ? tr.name_ru : tr.name_kg}</td>
                  <td>
                    <img
                      className="sp-package-mini"
                      alt={lang === "ru" ? tr.name_ru : tr.name_kg}
                      src={tr.photo}
                    />
                  </td>
                  <td>{tr.max_volume}</td>
                  <td>{tr.max_weight}</td>
                  <td>
                    <CIcon
                      name="cilTrash"
                      className="text-danger mr-3"
                      size="xl"
                      cursor="pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        showDelete();
                        setSelectedId(tr.id);
                        setNameKg(tr.name_kg);
                        setNameRu(tr.name_ru);
                        setMaxVolume(tr.max_volume);
                        setMaxWeight(tr.max_weight);
                        setPhoto(tr.photo);
                        dispatch(
                          setBreadcrumb(["Transports", "Delete Transport"])
                        );
                      }}
                    />
                    <CIcon
                      name="cilPencil"
                      className="text-warning"
                      size="xl"
                      cursor="pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsCreateInput(false);
                        setSelectedId(tr.id);
                        setNameKg(tr.name_kg);
                        setNameRu(tr.name_ru);
                        setMaxVolume(tr.max_volume);
                        setMaxWeight(tr.max_weight);
                        setPhoto(tr.photo);
                        dispatch(
                          setBreadcrumb(["Transports", "Change Transport"])
                        );
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <CModal show={isConfirmOpen} onClose={onConfirmToggle}>
          <CModalHeader closeButton>{modalTitle}</CModalHeader>
          <CModalBody>
            {nameRu && (
              <CForm>
                <CFormGroup>
                  <CLabel htmlFor="nf-name">
                    {t("Delete this transport? ")} {lang === "ru" ? nameRu : nameKg}
                  </CLabel>
                </CFormGroup>
              </CForm>
            )}
          </CModalBody>
          <CModalFooter>
            {selectedId && (
              <CRow>
                <CButton
                  color="danger"
                  onClick={() => deleteTransport(selectedId)}
                  className="mr-3"
                >
                  {t("Delete")}
                </CButton>
              </CRow>
            )}
          </CModalFooter>
        </CModal>
      </CCardBody>
    </CCard>
  );
}
