import {
    TARIFF_SUCCESS,
    TARIFF_LOADING,
    TARIFF_FAIL
  } from '../types/tariffTypes'
  
  const initialState = {
    tariffs: [],
    error: null,
    isLoading: true
  }
  
  export default function tariffReducer(state = initialState, action) {
    switch (action.type) {
      case TARIFF_LOADING:
        return {
          ...state,
          isLoading: true,
          error: null
        }
      case TARIFF_SUCCESS:
        return {
          ...state,
          tariffs: action.payload,
          isLoading: false,
          error: null
        }
      case TARIFF_FAIL:
        return {
          ...state,
          isLoading: false,
          error: action.payload || null
        }
      default:
        return {
          ...state
        }
    }
  }
  