import React, { useState } from "react";
import Logo_white from "../assets/Logo_white.png";
import Logotype_white_1 from "../assets/Logotype_white_1.png";
import { navv } from "./sideBarNav";

import { useSelector, useDispatch } from "react-redux";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavItem,
  CSidebarNavDropdown,
  CSidebarForm,
  CInput,
} from "@coreui/react";

import { set } from "../redux/actions/settingsActions";
import { useTranslation } from "react-i18next";

const TheSidebar = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.settings.sidebarShow);
  const orders = useSelector((state) => state.order.orders);
  const profile = useSelector((state) => state.auth.profile);
  const companyProfile = useSelector((state) => state.auth.company);

  const [key, setKey] = useState("")

  const { t } = useTranslation();
  
  
  return (
    <CSidebar show={show} onShowChange={(val) => dispatch(set(val))}>
      <CSidebarBrand className="d-md-down-none" to="/">
        <img
          alt="sidebar logo"
          src={companyProfile?.logo || Logotype_white_1}
          height="35"
          className="c-sidebar-brand-full"
        />
        <img
          alt="sidebar logo"
          src={companyProfile?.mini_logo || Logo_white}
          height="35"
          className="c-sidebar-brand-minimized"
        />
      </CSidebarBrand>
      <CSidebarNav>
        <CSidebarForm className={"m-1 " + (!profile?.roles?.includes("super_admin") && "d-none")}>
          <CInput placeholder={t("Search") + "..."} className="sidebarSearch" onChange={e => setKey(e.target.value)}/>
        </CSidebarForm>
        {navv
          .filter(n => n.permissions.some(p => profile?.roles?.includes(p)))
          .filter(n => 
            n.name.toLowerCase().includes(key.toLowerCase()) || 
            t(n.name).toLowerCase().includes(key.toLowerCase())
          )
          .map((n, i) => 
            n.type === "title" ? 
              <CSidebarNavTitle key={i}>{t(n.name)}</CSidebarNavTitle> 
              : 
            n.type === "dropdown" ? 
            <CSidebarNavDropdown name={n.name}>
                {n.children.map(n =>
                  <CSidebarNavItem 
                    key={i}
                    icon={<img src={n.icon} alt={n.name} className="c-sidebar-nav-icon"/>}
                    to={n.to}
                    name={t(n.name)}
                  />
                )}
            </CSidebarNavDropdown> 
              :
            <CSidebarNavItem 
                key={i}
                icon={<img src={n.icon} alt={n.name} className="c-sidebar-nav-icon"/>}
                to={n.to} 
                badge={n.name==="Current Orders" ? 
                  {color: "info", text: orders ? orders.total : 0 } : n.badge} 
                name={t(n.name)}/>
          )}
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
