import React, { useState, useEffect } from "react";
import {
  fetchCreateCity,
  fetchCreateDistrict,
  fetchUpdateCity,
  fetchUpdateDistrict,
} from "src/services/CityService";
import MiniSpinner from "src/components/spinners/MiniSpinner";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { YMaps, Map, Placemark } from "react-yandex-maps";
import { getCities, getAllDistricts } from "src/redux/actions/locationActions";
import Select from "react-select";
import {
  CModalHeader,
  CInput,
  CButton,
  CModal,
  CLabel,
  CRow,
} from "@coreui/react";
import { toastify } from "src/helpers/toast";
import { useTranslate } from "src/helpers/useTranslate";

const apikey = "14b7fac2-2096-4a85-9aa1-ed9ac48250ff";

export default function ObjectForm({
  toggle,
  modal,
  modalTitle,
  selectedObject,
}) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const {tr} = useTranslate();
  const cities = useSelector((state) => state.locations.cities);
  const [city, setCity] = useState({
    label: i18n.language === "ru" ? "Выбрать город" : "Шаарды тандоо",
    value: 0,
  });
  const [btnState, setBtnState] = useState(false);
  const [mapApi, setMapApi] = useState(null);
  const [type, setType] = useState("");
  const [nameKg, setNameKg] = useState("");
  const [nameRu, setNameRu] = useState("");
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [id, setId] = useState("");
  const [center, setCenter] = useState([42.876054043715826, 74.60139339774767]);
  const [zoom, setZoom] = useState(8);
  const [serialNumber, setSerialNumber] = useState(null)

  const [region, setRegion] = useState({
    label: i18n.language === "ru" ? "Выбрать регион" : "Регион тандоо"
  })

  const regions = useSelector(state => state.locations.regions)

  const submitCity = () => {
    const city = {
      name_kg: nameKg,
      name_ru: nameRu,
      lat,
      lng,
      map_zoom: 10,
      region_id: region?.value,
      ordering: serialNumber
    };
    console.log(city);
    fetchCreateCity(city)
      .then((res) => {
        if (res.ok) {
          toastify("success", "Новый город успешно создан");
          setBtnState(false);
          toggle();
          setNameKg("");
          setNameRu("");
          setLat(0);
          setLng(0);
          setSerialNumber("")
          dispatch(getCities());
        } else {
          toastify("error", "Произошла ошибка создания");
          setBtnState(false);
        }
      })
      .catch((err) => {
        toastify("error", "Произошла ошибка, проверьте интернет");
        setBtnState(false);
      });
  };

  const updateCity = () => {
    const city = {
      name_kg: nameKg,
      name_ru: nameRu,
      lat,
      lng,
      map_zoom: 10,
      region_id: region ? region.value : null,
      ordering: serialNumber
    };
    console.log("c", city, "id", id);
    fetchUpdateCity(city, id)
      .then((res) => {
        if (res.ok) {
          toastify("success", "Город успешно обновлен");
          setBtnState(false);
          toggle();
          setId("");
          setNameKg("");
          setNameRu("");
          setLat(0);
          setLng(0);
          setSerialNumber("");
          dispatch(getCities());
        } else {
          toastify("error", "Произошла ошибка обновления");
          setBtnState(false);
        }
      })
      .catch((err) => {
        toastify("error", "Произошла ошибка, проверьте интернет");
        setBtnState(false);
      });
  };
  const submitDistrict = () => {
    const district = {
      name_kg: nameKg,
      name_ru: nameRu,
      lat,
      lng,
      map_zoom: 10,
      city_id: city.value,
    };
    console.log(district);
    fetchCreateDistrict(district)
      .then((res) => {
        if (res.ok) {
          toastify("success", "Успешно создано");
          setBtnState(false);
          toggle();
          setNameKg("");
          setNameRu("");
          setLat(0);
          setLng(0);
          dispatch(getAllDistricts());
          setCity({
            label: i18n.language === "ru" ? "Выбрать город" : "Шаарды тандоо",
            value: 0,
          });
        } else {
          toastify("error", "Произошла ошибка создания");
          setBtnState(false);
        }
      })
      .catch((err) => {
        toastify("error", "Произошла ошибка, проверьте интернет");
        setBtnState(false);
      });
  };

  const updateDistrict = () => {
    const district = {
      name_kg: nameKg,
      name_ru: nameRu,
      lat,
      lng,
      map_zoom: 10,
      city_id: city.value,
    };
    console.log("d", district, "id", id);
    fetchUpdateDistrict(district, id)
      .then((res) => {
        if (res.ok) {
          toastify("success", "Успешно обновлено");
          setBtnState(false);
          toggle();
          setId("");
          setNameKg("");
          setNameRu("");
          setLat(0);
          setLng(0);
          dispatch(getAllDistricts());
          setCity({
            label: i18n.language === "ru" ? "Выбрать город" : "Шаарды тандоо",
            value: 0,
          });
        } else {
          toastify("error", "Произошла ошибка обновления");
          setBtnState(false);
        }
      })
      .catch((err) => {
        toastify("error", "Произошла ошибка, проверьте интернет");
        setBtnState(false);
      });
  };

  const onClickMap = (e) => {
    const [lat, lng] = e.get("coords");
    setLat(lat);
    setLng(lng);
    if (type === "city") {
      getCityName([lat, lng]);
    }
    if (type === "district") {
      getDistrictName([lat, lng]);
    }
  };

  const getDistrictName = (lat, lng) => {
    if (!id) {
      mapApi
        .geocode([lat, lng], {
          kind: "district",
        })
        .then((res) => {
          // console.log(res.geoObjects.get(0).properties.get("name"));
          setNameKg(res.geoObjects.get(0).properties.get("name"));
          setNameRu(res.geoObjects.get(0).properties.get("name"));
        });
    }
  };
  const getCityName = (lat, lng) => {
    if (!id) {
      mapApi
        .geocode([lat, lng], {
          kind: "locality",
        })
        .then((res) => {
          // console.log(res.geoObjects.get(0).properties.get('name'));
          setNameKg(res.geoObjects.get(0).properties.get("name"));
          setNameRu(res.geoObjects.get(0).properties.get("name"));
        });
    }
  };

  useEffect(() => {
    dispatch(getCities());
  }, [dispatch]);

  useEffect(() => {
    setCenter(
      selectedObject.lat && selectedObject.lng
        ? [selectedObject.lat, selectedObject.lng]
        : [42.876054043715826, 74.60139339774767]
    );

    setZoom(selectedObject.lat && selectedObject.lng ? 7 : 8);
    setType(selectedObject && selectedObject.type);
    setNameKg(selectedObject?.name_kg || "");
    setId(selectedObject.id || "");
    setNameRu(selectedObject?.name_ru || "");
    setLat(selectedObject.lat || "");
    setLng(selectedObject.lng || "");
    setSerialNumber(selectedObject.ordering || "");
    if (selectedObject.type === "district") {
      setCity(
        (selectedObject.city && {
          label:
            i18n.language === "ru"
              ? selectedObject.city?.name_ru
              : selectedObject.city?.name_kg,
          value: selectedObject.city.id,
        }) || {
          label: i18n.language === "ru" ? "Выбрать город" : "Шаарды тандоо",
          value: 0,
        }
      );
    } else {
      setRegion(
        (selectedObject.region && {
          label:
            i18n.language === "ru"
              ? selectedObject.region?.name_ru
              : selectedObject.region?.name_kg,
          value: selectedObject.region.id,
        }) || {
          label: i18n.language === "ru" ? "Выбрать регион" : "Регион тандоо",
        }
      );
    }
  }, [selectedObject, i18n.language]);
  // console.log(selectedObject);
  return (
    <CModal show={modal} onClose={toggle} size="xl">
      <CModalHeader closeButton>{modalTitle}</CModalHeader>
      <div className="d-flex justify-content-around">
        {type === "district" ? (
          <div style={{ width: "16%"}}>
            <CLabel>{t("Choose city")}</CLabel>
            <Select
              value={city}
              options={
                i18n.language === "ru"
                  ? cities.map((c) => {
                      return {
                        value: c.id,
                        label: c?.name_ru,
                        coors: [c.lat, c.lng],
                      };
                    })
                  : cities.map((c) => {
                      return {
                        value: c.id,
                        label: c?.name_kg,
                        coors: [c.lat, c.lng],
                      };
                    })
              }
              onChange={(res) => {
                setCity(res);
              }}
            />
          </div>
        ) : (
            <>
              <div style={{ width: "10%"}}>
                <CLabel>{t("Serial number")}</CLabel>
                <CInput
                    type="text"
                    placeholder={t("Serial number")}
                    name="serialNumber"
                    value={serialNumber}
                    onChange={(e) => setSerialNumber(e.target.value)}
                />
              </div>
          <div style={{ width: "16%"}}>
            <CLabel>{t("Choose region")}</CLabel>
            <Select
              value={region}
              isClearable
              options={
                regions.map(r => {
                  return {
                    value: r.id,
                    label: tr(r?.name_ru, r?.name_kg),
                    coors: [r.lat, r.lng]
                  }
                })
              }
              onChange={(res) => {
                setRegion(res);
              }}
            />
          </div>
            </>
        )}
        <div style={{ maxWidth: "16%"}}>
          <CLabel>{t("Name on kg")}</CLabel>
          <CInput
            type="text"
            placeholder={t("Name on kg")}
            name="nameKg"
            value={nameKg}
            onChange={(e) => setNameKg(e.target.value)}
          />
        </div>
        <div style={{ width: "16%"}}>
          <CLabel>{t("Name on ru")}</CLabel>
          <CInput
            type="text"
            placeholder={t("Name on ru")}
            name="nameRu"
            value={nameRu}
            onChange={(e) => setNameRu(e.target.value)}
          />
        </div>
        <div style={{ width: "10%"}}>
          <CLabel>{t("Latitude")}</CLabel>
          <CInput
            type="text"
            placeholder={t("Latitude")}
            name="lat"
            value={lat}
            onChange={(e) => setLat(e.target.value)}
          />
        </div>
        <div style={{ width: "10%"}}>
          <CLabel>{t("Longitude")}</CLabel>
          <CInput
            type="text"
            placeholder={t("Longitude")}
            name="lng"
            value={lng}
            onChange={(e) => setLng(e.target.value)}
          />
        </div>

        {id ? (
          <CButton
            type="submit"
            variant="outline"
            color="success"
            className="my-3"
            disabled={btnState}
            onClick={() => {
              setBtnState(true);
              if (type === "city") {
                updateCity();
              }
              if (type === "district") {
                updateDistrict();
              }
            }}
          >
            {btnState ? <MiniSpinner /> : t("Update")}
          </CButton>
        ) : (
          <CButton
            type="submit"
            className="my-3"
            variant="outline"
            color="success"
            disabled={btnState}
            onClick={() => {
              setBtnState(true);
              if (type === "city") {
                submitCity();
              }
              if (type === "district") {
                submitDistrict();
              }
            }}
          >
            {btnState ? <MiniSpinner /> : t("Create")}
          </CButton>
        )}
        <CButton
          className="my-3"
          variant="outline"
          color="danger"
          onClick={() => {
            setBtnState(false);
            toggle();
          }}
        >
          {t("Cancel")}
        </CButton>
      </div>
      <CRow className="mx-1 px-1">
        <YMaps query={{ load: "package.full", apikey }}>
          <Map
            onClick={(e) => onClickMap(e)}
            onLoad={(ymaps) => setMapApi(ymaps)}
            width="100%"
            height="80vh"
            state={{
              // center: [42.876054043715826, 74.60139339774767],
              center: center,
              zoom: zoom,
              controls: [],
            }}
          >
            <Placemark
              geometry={[lat, lng]}
              options={{
                preset: "islands#redDotIcons",
                iconColor: "red",
              }}
            />
          </Map>
        </YMaps>
      </CRow>
    </CModal>
  );
}
