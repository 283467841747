import {
  CCard,
  CCardHeader,
  CCardBody,
  CModal,
  CLabel,
  CModalFooter,
  CModalBody,
  CModalHeader,
  CFormGroup,
  CForm,
  CRow,
  CCol,
  CButton,
  CInput,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import CIcon from "@coreui/icons-react";
import React, { useState, useEffect } from "react";
import { useModalState, useConfirmModal } from "../../helpers/useModal";
import {
  fetchDeleteClient,
  fetchClientsSearh,
  fetchClient,
  fetchClients,
  fetchClientsByType,
} from "../../services/ClientService";
import { useTranslate } from "src/helpers/useTranslate";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "src/redux/actions/settingsActions";
import MiniSpinner from "src/components/spinners/MiniSpinner";
import { toastify } from "src/helpers/toast";
import ClientModals from "./ClientModals";



export default function Clients() {
  const [loading, setLoading] = useState(null);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { tr } = useTranslate();
  const [selectedClient, setSelectedClient] = useState([]);
  const { isOpen, onToggle } = useModalState();
  const { isConfirmOpen, onConfirmToggle } = useConfirmModal();
  const [modalTitle, setModalTitle] = useState("Modal Title");
  const [key, setKey] = useState("");
  const [clients, setClients] = useState([]);
  const [clientType, setClientType] = useState({
    label: tr("Все клиенты", "Баардык кардарлар"),
    value: 0,
  });
  const types = [
    { name: tr("Все клиенты", "Баардык кардарлар"), id: 3 },
    { name: tr("Организации", "Мекемелер"), id: 1 },
    { name: tr("Физические лица", "Жеке кардарлар"), id: 2 },
  ];

  useEffect(() => {
    setLoading(true);
    setClientType({
      label: i18n.language === "ru" ? "Все клиенты" : "Баардык кардарлар",
      value: 0,
    });
    fetchClients().then((res) => {
      setLoading(false);
      if (res.ok) {
        res.json().then((data) => {
          setClients(data);
        });
      } else {
        console.log(res.statusText);
      }
    });
  }, [dispatch, i18n.language]);

  const showEdit = (client) => {
    fetchClient(client.id).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          setSelectedClient(data);
        });
      } else {
        console.log(res.statusText);
      }
    });
    setModalTitle(t("Edit Client"));
    dispatch(setBreadcrumb(["Clients", "Edit Client"]));
    onToggle();
  };

  const showDelete = (client) => {
    setSelectedClient(client);
    setModalTitle(t("Delete Client"));
    // dispatch(setBreadcrumb(["Clients", "Delete Client"]));
    onConfirmToggle();
  };

  const deleteClient = (id) => {
    fetchDeleteClient(id)
      .then((res) => {
        if (res.ok) {
          toastify("success", t("Operation succeeded"));
          setClients(state => state.filter(c => c.id !== id))
        }else{
          toastify("error", t("Operation failed"));
        }
      })
      .catch((err) => console.error(err));
      onConfirmToggle()
  };

  const newClientForm = () => {
    setSelectedClient([]);
    setModalTitle(t("Create client"));
    dispatch(setBreadcrumb(["Clients", "Create client"]));
    onToggle();
  };

  return (
    <CCard className="m-2">
      <CCardHeader>
        <CRow>
          <CCol className="mb-2 col-lg-6 col-md-8 col-sm-12">
            <CInput
              value={key}
              placeholder={t("Поиск клиента")}
              onChange={(e) => {
                setKey(e.target.value);
                fetchClientsSearh(e.target.value).then((res) => {
                  if (res.ok) {
                    res.json().then((data) => {
                      setClients(data);
                    });
                  } else {
                    console.log(res.statusText);
                  }
                });
              }}
            />
          </CCol>
          <CCol className=" mb-2 col-lg-3 col-md-5 col-sm-6">
            <Select
              value={clientType}
              options={types.map((o) => {
                return { label: o.name, value: o.id };
              })}
              onChange={(e) => {
                setClientType(e);

                if (e.value === 1) {
                  fetchClientsByType(1).then((res) => {
                    if (res.ok) {
                      res.json().then((data) => {
                        setClients(data);
                      });
                    } else {
                      console.log(res.statusText);
                    }
                  });
                } else if (e.value === 2) {
                  fetchClientsByType(2).then((res) => {
                    if (res.ok) {
                      res.json().then((data) => {
                        setClients(data);
                      });
                    } else {
                      console.log(res.statusText);
                    }
                  });
                } else {
                  fetchClientsByType(3).then((res) => {
                    if (res.ok) {
                      res.json().then((data) => {
                        setClients(data);
                      });
                    } else {
                      console.log(res.statusText);
                    }
                  });
                }
              }}
            />
          </CCol>
          <CCol className="mb-2 col-lg-3 col-md-5 col-sm-6">
            <CButton
              variant="outline"
              color="success"
              onClick={() => {
                newClientForm();
                dispatch(setBreadcrumb(["Clients", t("Create client")]));
              }}
            >
              {t("Create new client")}
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        {loading ? (
          <div className="text-center">
            <MiniSpinner />
          </div>
        ) : (
          <table className="table table-hover">
            <thead>
              <tr>
                <th>{t("Fullname")}</th>
                <th>{t("Phone")}</th>
                <th>{t("Organization")}</th>
                <th>{t("Actions")}</th>
              </tr>
            </thead>
            <tbody>
              {clients &&
                clients.map((client) => (
                  <tr key={client.id}>
                    <td>{client.fullname}</td>
                    <td>{client.phone}</td>
                    <td>{client.organization?.name}</td>
                    <td>
                      <CIcon
                        name="cilTrash"
                        className="text-danger mr-2"
                        size="xl"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          showDelete(client);
                          dispatch(
                            setBreadcrumb(["Clients", "Delete Client"])
                          );
                        }}
                      />
                      <CIcon
                        name="cilPencil"
                        className="text-warning"
                        size="xl"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          showEdit(client);
                        }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        <CModal show={isConfirmOpen} onClose={onConfirmToggle}>
          <CModalHeader closeButton>{modalTitle}</CModalHeader>
          <CModalBody>
            {selectedClient && (
              <CForm>
                <CFormGroup>
                  <CLabel htmlFor="nf-client">
                    {t("Delete this client? ")} {selectedClient.fullname}
                  </CLabel>
                </CFormGroup>
              </CForm>
            )}
          </CModalBody>
          <CModalFooter>
            {selectedClient && (
              <CRow>
                <CButton
                  color="danger"
                  onClick={() => deleteClient(selectedClient.id)}
                  className="mr-2"
                >
                  {t("Delete")}
                </CButton>
              </CRow>
            )}
          </CModalFooter>
        </CModal>
        {/* edit address modal */}
        {
          selectedClient && <ClientModals
              toggle={onToggle}
              modal={isOpen}
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
          />
        }

        {/*<CreateClientModal*/}
        {/*  toggle={onToggle}*/}
        {/*  modal={isOpen}*/}
        {/*  selectedClient={selectedClient}*/}
        {/*/>*/}
      </CCardBody>
    </CCard>
  );
}
