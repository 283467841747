import CIcon from "@coreui/icons-react";
import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import { Link } from "react-router-dom";
import geoloc from "src/assets/geoloc.svg";
import { useTranslate } from "src/helpers/useTranslate";

export default function ClientCard({title, client, address, address_flat, address_floor, city}) {

    const {tr} = useTranslate();
    return (
        <CCard>
            <CCardBody>
                <h6><b>{title}</b></h6>
                <hr />
                <CRow>
                <CCol sm="4">
                    <div >
                    <img alt="profile avatar" style={{borderRadius: "50%", width: "100px", height: "100px", objectFit: "cover"}}
                    src="https://www.pngfind.com/pngs/m/676-6764065_default-profile-picture-transparent-hd-png-download.png"/>
                    </div>
                </CCol>
                <CCol sm="8">
                    <p><CIcon name="cilUser"/>{client?.fullname || <i>надо уточнить</i>}</p>
                    {client?.phone && <p><CIcon name="cilPhone"/> {client.phone}</p>}
                    <p>
                    <Link to={"/address/edit/" + address?.id} target="_blank">
                        <img style={{cursor: "pointer"}} src={geoloc} alt="geoloc"/>
                    </Link>
                    {city && 
                    (tr(city?.name_ru, city?.name_kg) + ", ")}
                    {address?.district &&
                    (tr(address?.district?.name_ru, address.district?.name_kg) + ", ")}
                    
                    {tr(address?.name_ru, address?.name_kg)}<br/>
                    {address_flat ? "кв: " + address_flat : ""}
                    {address_floor ? " этаж: " + address_floor : ""}
                    </p>
                </CCol>
                </CRow>
            </CCardBody>
            </CCard>
    )
}