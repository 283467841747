import { combineReducers } from "redux";
import authReducer from "./authReducer";
import settingsReducer from "./settingsReducer";
import orderReducer from "./orderReducer";
import operatorReducer from "./operatorsReducer";
import courierReducer from "./courierReducer";
import tariffReducer from "./tariffReducer";
import clientReducer from "./clientReducer";
import addressReducer from "./addressReducer";
import locationsReducer from "./locationsReducer";
import transportReducer from "./transportReducer";
import extraFeeReducer from "./extraFeeReducer";
import organizationReducer from "./organizationReducer";

export default combineReducers({
  settings: settingsReducer,
  auth: authReducer,
  order: orderReducer,
  operator: operatorReducer,
  courier: courierReducer,
  tariff: tariffReducer,
  locations: locationsReducer,
  clients: clientReducer,
  addresses: addressReducer,
  transport: transportReducer,
  extraFees: extraFeeReducer,
  organizations: organizationReducer,
});
