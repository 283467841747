import { CLabel } from "@coreui/react";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import { useTranslate } from "src/helpers/useTranslate";
import { promiseCityOptions } from "../helpers/helpers";

export default function CityField({ cities, city, handleCityChange }) {
  const { t } = useTranslation();
  const { tr } = useTranslate();
  return (
    <>
      <CLabel>{t("City")}*</CLabel>
      <div style={{ width: "92%" }}>
        <AsyncSelect
          defaultOptions={cities.map((city) => {
            return {
              value: city.id,
              label: tr(city.name_ru, city.name_kg),
              ...city,
            };
          })}
          loadOptions={(value) => promiseCityOptions(value, tr)}
          isClearable
          placeholder={tr("Город/Район", "Шаар/Район")}
          onChange={(res) => handleCityChange(res)}
          value={city}
        />
      </div>
    </>
  );
}
