import CIcon from '@coreui/icons-react';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useTranslate } from 'src/helpers/useTranslate';

export default function ReportsTable({forDownload, reports, setSelectedOrder, onToggle, dateParse, dateDifference}) {

    const {t} = useTranslation()
    const {tr} = useTranslate()
    return (
        <table
            className="table table-hover"
            id={forDownload && "excel-reports"}
          >
            <thead>
              <tr>
                <th className="px-1" style={{width: "10%"}}>
                  {t("Id")}
                </th>
                {forDownload ? <>
                    <th className="px-1" style={{ width: "15%" }}>
                    {t("From Address")}
                    </th>
                    <th className="px-1" style={{ width: "15%" }}>
                    {t("To Address")}
                    </th>
                    <th className="px-1" style={{ width: "10%" }}>
                    {t("Comments")}
                    </th>
                </> : 
                    <th className='px-1' style={{width: "30%"}}>
                    {t("From Address")} <CIcon name="cilArrowRight" /> {t("To Address")} 
                    </th>}
                <th className="px-1" style={{ width: "15%" }}>
                  {t("Created")}
                </th>
                <th className="px-1" style={{ width: "15%" }}>
                  {t("Delivered")}
                </th>
                <th className="px-1" style={{ width: "10%" }}>
                  <CIcon name="cilClock"/>
                </th>
                <th className="px-1" style={{ width: "7%" }}>
                  {t("км")}
                </th>
                <th className="px-1" style={{ width: "10%" }}>
                  {t("Courier")}
                </th>
                <th className="px-1" style={{ width: "10%" }}>
                  {t("Redemption")}
                </th>
                <th className="px-1" style={{ width: "10%" }}>
                  {t("Price")}
                </th>
              </tr>
            </thead>
            <tbody>
              {reports &&
                reports.map((r) => (
                  <tr
                    key={r.id}
                    onClick={() => {
                      setSelectedOrder(r);
                      onToggle();
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <td className="px-1">{r.oid}</td>
                    {forDownload ?
                        <>
                            <td className="px-1">
                            {tr(r.location_from?.name_ru, r.location_from?.name_kg)}
                            </td>
                            <td className="px-1">
                            {tr(r.location_to?.name_ru, r.location_to?.name_kg)}
                            </td>
                            <td className="px-1">
                            {r.description}
                            </td>
                        </>
                        :
                        <td>
                            <b>
                                {tr(r.location_from?.name_ru, r.location_from?.name_kg)}
                                    <CIcon name="cilArrowRight"/>
                                {tr(r.location_to?.name_ru, r.location_to?.name_kg)}
                            </b><br/>
                            {r.description}
                        </td>
                    }
                    <td className="px-1">{dateParse(r.created_at)}</td>
                    <td className="px-1">{dateParse(r.delivery_end_time)}</td>
                    <td className="px-1">{dateDifference(r.created_at, r.delivery_end_time)}</td>
                    <td className="px-1">{r.distance / 1000}</td>
                    <td className="px-1">
                      {r.courier && r.courier.user.firstname
                        ? r.courier.user.firstname
                        : ""}
                    </td>
                    <td className="px-1">{r.redemption_amount}</td>
                    <td className="px-1">{r.final_price}</td>
                  </tr>
                ))}
            </tbody>
          </table>
    )
}