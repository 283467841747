import React, { useCallback, useEffect, useState } from "react";
import { CCard, CCardBody, CCardHeader } from "@coreui/react";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "src/redux/actions/settingsActions";
import MiniSpinner from "src/components/spinners/MiniSpinner";
import ExportAct from "./../ExportAct";
import { fetchRoutes } from "src/services/ServerService";
import { ordersFetchForRevision } from "src/services/OrderService";
import { fetchCities, fetchRegions } from "src/services/CityService";
import Pagination from "src/components/Pagination";
import OrdersTable from "./OrdersTable";
import { fetchCompanyStatuses } from "src/services/CompanyService";
import FilterBar from "./FilterBar";
import { useTranslation } from "react-i18next";

const limit = 20;
export default function ActOrders() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [orders, setOrders] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [cities, setCities] = useState([]);
  const [regions, setRegions] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const [loading, setLoading] = useState(false);
  const [filterParams, setFilterParams] = useState({
    date_from: null,
    date_to: null,
    size: limit,
    page: 1,
    courier_id: null,
    city_id: [],
    region_id: [],
    route_id: [],
  });

  const fetchReq = useCallback((params) => {
    ordersFetchForRevision(params)
      .then((res) =>
        res.ok
          ? res.json().then((d) => {
              setOrders(d);
              console.log(d);
              setLoading(false);
            })
          : setLoading(false)
      )
      .catch((err) => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchReq({});
    fetchRoutes().then((res) => res.ok && res.json().then((d) => setRoutes(d)));
    fetchCities().then((res) => res.ok && res.json().then((d) => setCities(d)));
    fetchRegions().then(
      (res) => res.ok && res.json().then((d) => setRegions(d))
    );
    fetchCompanyStatuses().then(
      (res) => res.ok && res.json().then((d) => setStatuses(d))
    );
    dispatch(setBreadcrumb(["Regional Orders"]));
  }, [dispatch, fetchReq]);

  const applyFilters = useCallback(
    (params) => {
      setFilterParams(params);
      fetchReq(params);
    },
    [fetchReq]
  );

  const onNext = () => {
    applyFilters({ ...filterParams, page: filterParams.page + 1 });
  };

  const onPrev = () => {
    applyFilters({ ...filterParams, page: filterParams.page - 1 });
  };

  const redemptionTypeLabel = useCallback(
    (type) => {
      if (type === "imposed") {
        return t("Imposed");
      } else if (type === "redemption") {
        return t("From cash register");
      } else {
        return "";
      }
    },
    [t]
  );

  return (
    <div>
      <CCard className="m-2">
        <CCardHeader>
          <FilterBar
            applyFilters={applyFilters}
            filterParams={filterParams}
            cities={cities}
            statuses={statuses}
            regions={regions}
            routes={routes}
          />
          <div className="d-flex justify-content-between my-1">
            {/* <div> */}
            {t("Orders amount")}: {orders?.total}
            {/* . На странице: {orders.items?.length} */}
            {/* </div> */}
            <ExportAct
              orders={orders?.items || []}
              filterParams={filterParams}
              regions={regions}
              filterByRegions={filterParams.region_id}
              routes={routes}
              filterByRoutes={filterParams.route_id}
              filterByCourier={filterParams.courier_id}
              redemptionTypeLabel={redemptionTypeLabel}
            />
          </div>
        </CCardHeader>
        <CCardBody className="p-0">
          <div className="mt-1">
            {loading ? (
              <div className="text-center">
                <MiniSpinner />
              </div>
            ) : (
              <div>
                <OrdersTable
                  orders={orders}
                  redemptionTypeLabel={redemptionTypeLabel}
                />
                <Pagination
                  currentPage={filterParams.page}
                  nextPage={onNext}
                  prevPage={onPrev}
                  limit={limit}
                  currentListLength={orders?.items?.length}
                  isLastPage={orders?.total / (orders?.page * limit) <= 1}
                />
              </div>
            )}
          </div>
        </CCardBody>
      </CCard>
    </div>
  );
}
