export const USER_LOADING = 'USER_LOADING'
export const USER_SUCCESS = 'USER_SUCCESS'
export const USER_FAIL = 'USER_FAIL'
export const LOGIN_LOADING = 'LOGIN_LOADING'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const STAFF_PROFILE = 'STAFF_PROFILE'
export const COMPANY_PROFILE = 'COMPANY_PROFILE'
