import React from 'react';
import {CButton, CCol, CInput, CRow} from "@coreui/react";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import Select from "react-select";
import {fetchClientRequisites, fetchSubmitClientRequisite } from "../../services/Requisites";
import {toastify} from "../../helpers/toast";
import {useTranslation} from "react-i18next";

const RequisitePropertyForm = ({selectedClientId, selectedRequisite, setSelectedRequisiteType, selectedRequisiteType, requisiteTypes, setAccountNumber, accountNumber, setSelectedCurrency, selectedCurrency, currencies, setUserInitials, userInitials, setPropertyForm, setRequisiteTypes, resetPropertyFormFields }) => {


    const { t } = useTranslation();

    const onSubmitRequisiteForm = () =>{
        let requisiteForm = {
            client_id: selectedClientId,
            account_number: accountNumber,
            fullname: userInitials,
            requisite_type_title: selectedRequisiteType.label,
            currency_id: selectedCurrency.value
        }

            fetchSubmitClientRequisite(requisiteForm).then((res) => {
                if (res.ok){
                    toastify("success", t("Новый реквизит успешно создан"));
                    resetPropertyFormFields()
                    fetchClientRequisites(selectedClientId).then((res) => {
                        if (res.ok){
                            res.json().then((data) => {
                                setRequisiteTypes(data)
                            })
                        }
                    }).catch((err) => console.log(err))
                }else {
                    toastify("error", t("Произошла ошибка создания реквизита"))
                }
            }).catch((err) => {
                toastify("error", t("Произошла ошибка"));
                console.log(err, 'error')
            })


    }

    return (
        <CRow>
            <CCol className="d-flex">
                <CreatableSelect
                    styles={{
                        control: (provided, state) => ({
                            ...provided,
                            width: "250px",
                        }),
                    }}
                    value={selectedRequisiteType}
                    options={requisiteTypes && requisiteTypes.map((r) => {
                        return {
                            label: r.requisite_type?.title,
                            value: r.requisite_type?.id,
                        };
                    })}
                    onChange={(e) => {
                        console.log(e,"mye")
                        setSelectedRequisiteType(e);
                    }}
                    isClearable
                    onCreateOption={(e) => {
                        console.log(e,"myerr")
                        setSelectedRequisiteType({ label: e, value: e });
                    }}
                    cacheOptions
                    formatCreateLabel={(e) => (`Создать ${e}`)}
                />
                <CInput
                    type="text"
                    style={{
                        padding: "18px auto",
                        margin: "0 5px",
                    }}
                    value={accountNumber}
                    onChange={(e) => setAccountNumber(e.target.value)}
                    placeholder={t("Account number")}
                />
                <Select
                    styles={{
                        control: (provided, state) => ({
                            ...provided,
                            width: "100px",
                        }),
                    }}
                    value={selectedCurrency}
                    options={
                        currencies &&
                        currencies.map((c) => {
                            return { label: c.name, value: c.id };
                        })
                    }
                    onChange={(e) => {
                        setSelectedCurrency(e);
                    }}
                />
            </CCol>
            <CCol className="d-flex col-12 mt-2">
                <CInput
                    style={{ width: "75%", marginRight: "5px" }}
                    type="text"
                    value={userInitials}
                    onChange={(e)=> setUserInitials(e.target.value)}
                    placeholder={t("Initials")}
                />
                <CButton
                    style={{ width: "25%" }}
                    color="dark"
                    onClick = {onSubmitRequisiteForm}
                >
                    {t("Save")}
                </CButton>
            </CCol>
        </CRow>
    );
};

export default RequisitePropertyForm;