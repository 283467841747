import { CBadge, CCol, CRow } from '@coreui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { date } from 'src/helpers/date'

export default function PaymentDetails({selectedPayment, payer, payReceiver}) {
    
    const {t} = useTranslation()
    
    return <div>
        <div className='d-flex justify-content-between'>
            <div>{t("Created date")}</div>
            <div>{date(selectedPayment.created_at)}</div>
        </div>
        <div className='d-flex justify-content-between'>
            <div>{t("Sum")}</div>
            <div>{selectedPayment.amount} <u>с</u></div>
        </div>
        <div className='d-flex justify-content-between'>
            <div>{t("Redemption type")}</div>
            <div>{t(selectedPayment.redemption_type === "redemption" ? "From cash register" : selectedPayment.redemption_type === "imposed" ? "Imposed" : "")}</div>
        </div>
        <div className='d-flex justify-content-between'>
            <div>{t("Payment method")}</div>
            <div>{t(selectedPayment.method)}</div>
        </div>
        <div className='d-flex justify-content-between'>
            <div>{t("Payment status")}</div>
            <div><CBadge color={selectedPayment.is_paid ? "success" : "danger"}>{selectedPayment.is_paid ? t("Paid") : t("Not paid")}</CBadge></div>
        </div>
        <div className='d-flex justify-content-between'>
            <div>{t("Paid datetime")}</div>
            <div>{selectedPayment.is_paid ? date(selectedPayment.paid_at) : "__/__/____ __:__"}</div>
        </div>
        <div className='d-flex justify-content-between'>
            <div>{t("Last changes")}</div>
            <div>{date(selectedPayment.updated_at)}</div>
        </div>
        <CRow>
            <CCol xs="6" className="text-center">
                <div className='bg-secondary m-3 text-dark py-2 rounded'>
                    <div>{t("Payer")}</div>
                    <b>{payer(selectedPayment)?.fullname || payer(selectedPayment)?.firstname}</b>
                </div>
            </CCol>
            <CCol xs="6" className="text-center">
                <div className='bg-secondary m-3 text-dark py-2 rounded'>
                    <div>{t("Pay receiver")}</div>
                    <b>{payReceiver(selectedPayment)?.fullname || payReceiver(selectedPayment)?.firstname}</b>
                </div>
            </CCol>
        </CRow>
        {selectedPayment.comment &&
        <>{t("Notes")}
            <p className='p-1 bg-secondary text-dark'>
                {selectedPayment.comment}
            </p>
        </>}
        {selectedPayment.image &&
        <>Чек
            <div className='text-center'>
                <img src={selectedPayment.image} 
                    alt="check" style={{maxWidth: "75%"}}/>
            </div>
        </>}
    </div>
}