import { CContainer, CAlert, CButton } from '@coreui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const ErrorIndicator = ({message}) => {
    const history = useHistory()
    const {t} = useTranslation()
    return (
        <div>
            <CContainer>
                <div className="mt-2">
                    <CAlert color="warning">
                        <h1>Ooopsss...</h1>
                        <p>{t(message || "Unknown error")}</p>
                        <CButton variant="outline" color="info" onClick={() => history.push("/")}>{t("Go Home")}!</CButton>
                    </CAlert>
                </div>
            </CContainer>
        </div>
    );
};

export default ErrorIndicator;
