import React, { useState, useEffect } from "react";
import {
  CCard,
  CInput,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CCardBody,
  CButton,
  CModal,
  CModalBody,
  CDropdown,
  CDropdownItem,
  CDropdownToggle,
  CDropdownMenu,
} from "@coreui/react";
import { setBreadcrumb } from "src/redux/actions/settingsActions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Polygons from "./Polygons";
import {
  fetchCompanyExtraFeeDelete,
  fetchCompanyExtraFees,
  fetchCompanyExtraFeesSearch,
} from "src/services/CompanyService";
import { useModalState, useConfirmModal } from "src/helpers/useModal";
import CIcon from "@coreui/icons-react";
import ExtraFeeForm from "./ExtraFeeForm";
import { toastify } from "src/helpers/toast";
import Leaflet from "./leaflet/Leaflet";
import { YMaps, Map, Polygon } from "react-yandex-maps";

export default function Extra() {
  const [activeTab, setActiveTab] = useState("zone");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [allFees, setAllFees] = useState(null);
  const [openForm, setOpenForm] = useState(null);
  const [selectedFee, setSelectedFee] = useState(null);
  const [key, setKey] = useState("");

  const { isOpen, onToggle } = useModalState();
  const { isConfirmOpen, onConfirmToggle } = useConfirmModal();

  const reFetchFees = () => {
    fetchCompanyExtraFees().then((res) => {
      if (res.ok) {
        res.json().then((data) => setAllFees(data));
      } else {
        console.log(res.statusText);
      }
    });
  }
  useEffect(() => {
    dispatch(setBreadcrumb([t("Extra Fee")]));
    reFetchFees()
  }, [dispatch, t]);

  const createNew = (type) => {
    setSelectedFee(null);
    setOpenForm(type);
    onToggle();
    setActiveTab(type);
  };

  const deleteFee = (fee) => {
    setSelectedFee(fee);
    onConfirmToggle();
  };

  const updateFee = (fee, zone) => {
    setSelectedFee(fee);
    if (zone) {
      setOpenForm(zone);
    } else {
      setOpenForm(fee.fee_type);
    }
    onToggle();
  };
  const deleteConfirm = () => {
    // console.log(selectedFee.id);
    fetchCompanyExtraFeeDelete(selectedFee.id)
      .then((res) => {
        if (res.ok) {
          toastify("success", t("Fee deleted successfully"));
          fetchCompanyExtraFees().then((res) => {
            if (res.ok) {
              res.json().then((data) => setAllFees(data));
            } else {
              console.log(res.statusText);
            }
          });
          onConfirmToggle();
        } else {
          toastify("error", t("Произошла ошибка удаления доплаты"));
        }
      })
      .catch((err) =>
        toastify("error", t("Произошла ошибка, проверьте интернет"))
      );
  };

  return (
    <CCard className="m-2">
      <div className="text-right m-2">
        <CDropdown className="mt-2">
          <CDropdownToggle caret color="success" variant="outline">
            {t("Create New Fee")}
          </CDropdownToggle>
          <CDropdownMenu>
            <CDropdownItem onClick={() => createNew("weight")}>
              {t("Weight Fee")}
            </CDropdownItem>
            <CDropdownItem onClick={() => createNew("redemption")}>
              {t("Redemption Fee")}
            </CDropdownItem>
            <CDropdownItem onClick={() => createNew("zone")}>
              {t("Zone Fee")}
            </CDropdownItem>
            <CDropdownItem onClick={() => createNew("leaflet zone")}>
              {t("Leaflet zone")}
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      </div>

      <CCardBody>
        <CTabs activeTab={activeTab}>
          <CNav variant="tabs">
            <CNavItem>
              <CNavLink
                data-tab="weight"
                onClick={() => {
                  setActiveTab("weight");
                  dispatch(setBreadcrumb(["Extra Fee", "For Weight"]));
                }}
              >
                {t("For Weight")}
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                data-tab="redemption"
                onClick={() => {
                  setActiveTab("redemption");
                  dispatch(setBreadcrumb(["Extra Fee", "Redemption Fee"]));
                }}
              >
                {t("Redemption Fee")}
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                data-tab="zone"
                onClick={() => {
                  setActiveTab("zone");
                  dispatch(setBreadcrumb(["Extra Fee", "Zone Fee"]));
                }}
              >
                {t("Zone Fee")}
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                data-tab="leaflet zone"
                onClick={() => {
                  setActiveTab("leaflet zone");
                  dispatch(setBreadcrumb(["Extra Fee", "Leaflet zone"]));
                }}
              >
                {t("Leaflet zone")}
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                data-tab="polygons"
                onClick={() => {
                  setActiveTab("polygons");
                  dispatch(setBreadcrumb(["Extra Fee", "Polygons"]));
                }}
              >
                {t("Polygons")}
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CInput value={key} placeholder={t("Search fee")} onChange={(e) => {
                setKey(e.target.value);
                fetchCompanyExtraFeesSearch(e.target.value).then(res => {
                  if (res.ok) {
                    res.json().then(data => setAllFees(data))
                  } else {
                    console.log(res.statusText);
                  }
                })
              }} />
            </CNavItem>
          </CNav>

          <CTabContent>
            <CTabPane data-tab="weight">
              <table className="table my-2">
                <thead>
                  <tr>
                    <th>{t("Name")}</th>
                    <th>{t("Min Weight")}</th>
                    <th>{t("Max Weight")}</th>
                    <th>{t("Amount Sum")}</th>
                    <th>{t("Actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {allFees &&
                    allFees
                      .filter((fee) => fee.fee_type === "weight")
                      .map((fee, i) => (
                        <tr key={i}>
                          <td>{fee.name}</td>
                          <td>{fee.fee.min}</td>
                          <td>{fee.fee.max}</td>
                          <td>{fee.amount}</td>
                          <td>
                            <CIcon
                              name="cilTrash"
                              className="text-danger mr-2"
                              size="xl"
                              style={{ cursor: "pointer" }}
                              onClick={() => deleteFee(fee)}
                            />
                            <CIcon
                              name="cilPencil"
                              className="text-warning"
                              size="xl"
                              style={{ cursor: "pointer" }}
                              onClick={() => updateFee(fee)}
                            />
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </CTabPane>
            <CTabPane data-tab="redemption">
              <table className="table my-2">
                <thead>
                  <tr>
                    <th>{t("Name")}</th>
                    <th>{t("Min Sum")}</th>
                    <th>{t("Max Sum")}</th>
                    <th>{t("Amount Sum")}</th>
                    <th>{t("Actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {allFees &&
                    allFees
                      .filter((fee) => fee.fee_type === "redemption")
                      .map((fee, i) => (
                        <tr key={i}>
                          <td>{fee.name}</td>
                          <td>{fee.fee.min}</td>
                          <td>{fee.fee.max}</td>
                          <td>{fee.amount}</td>
                          <td>
                            <CIcon
                              name="cilTrash"
                              className="text-danger mr-2"
                              size="xl"
                              style={{ cursor: "pointer" }}
                              onClick={() => deleteFee(fee)}
                            />
                            <CIcon
                              name="cilPencil"
                              className="text-warning"
                              size="xl"
                              style={{ cursor: "pointer" }}
                              onClick={() => updateFee(fee)}
                            />
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </CTabPane>
            <CTabPane data-tab="zone">
              <table className="table my-2">
                <thead>
                  <tr>
                    <th>{t("Name")}</th>
                    <th>{t("Amount Sum")}</th>
                    <th>{t("Actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {allFees &&
                    allFees
                      .filter((fee) => fee.fee_type === "zone")
                      .map((zone, i) => (
                        <tr key={i}>
                          <td>{zone.name}</td>
                          <td>{zone.amount}</td>
                          <td>
                            <CIcon
                              name="cilTrash"
                              className="text-danger mr-2"
                              size="xl"
                              style={{ cursor: "pointer" }}
                              onClick={() => deleteFee(zone)}
                            />
                            <CIcon
                              name="cilPencil"
                              className="text-warning"
                              size="xl"
                              style={{ cursor: "pointer" }}
                              onClick={() => updateFee(zone)}
                            />
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </CTabPane>
            <CTabPane data-tab="leaflet zone">
              <table className="table my-2">
                <thead>
                  <tr>
                    <th>{t("Name")}</th>
                    <th>{t("Amount Sum")}</th>
                    <th>{t("Actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {allFees &&
                    allFees
                      .filter((fee) => fee.fee_type === "zone")
                      .map((zone, i) => (
                        <tr key={i}>
                          <td>{zone.name}</td>
                          <td>{zone.amount}</td>
                          <td>
                            <CIcon
                              name="cilTrash"
                              className="text-danger mr-2"
                              size="xl"
                              style={{ cursor: "pointer" }}
                              onClick={() => deleteFee(zone)}
                            />
                            <CIcon
                              name="cilPencil"
                              className="text-warning"
                              size="xl"
                              style={{ cursor: "pointer" }}
                              onClick={() => updateFee(zone, "leaflet zone")}
                            />
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </CTabPane>
            <CTabPane data-tab="polygons">
              <YMaps>
                <Map
                  width="100%"
                  height="80vh"
                  defaultState={{
                    center: [42.88, 74.58],
                    zoom: 10,
                  }}
                >
                  {allFees &&
                    allFees
                      .filter((fee) => fee.fee_type === "zone")
                      .map((zone, i) => (
                        <Polygon
                          modules={[
                            "geoObject.addon.balloon",
                            "geoObject.addon.hint",
                          ]}
                          key={i}
                          geometry={[zone.fee.coors]}
                          options={{
                            fillColor: `${
                              zone.amount < 20
                                ? "#8ed38b"
                                : zone.amount < 40
                                ? "#aad369"
                                : zone.amount < 60
                                ? "#71d326"
                                : zone.amount < 80
                                ? "#b2d300"
                                : zone.amount < 100
                                ? "#b2d300"
                                : zone.amount < 140
                                ? "#d39900"
                                : zone.amount < 200
                                ? "#d36200"
                                : zone.amount < 220
                                ? "#d32100"
                                : zone.amount < 240
                                ? "#d30000"
                                : zone.amount < 260
                                ? "#7a0000"
                                : zone.amount < 280
                                ? "#3a0000"
                                : zone.amount < 300
                                ? "#4a1738"
                                : zone.amount < 350
                                ? "#210000"
                                : "#210b19"
                            }`,
                            strokeColor: "#004B35",
                            opacity: 0.7,
                            strokeWidth: 1,
                            strokeStyle: "shortdash",
                          }}
                          properties={{
                            hintContent:
                              "Название: " +
                              zone.name +
                              "</br>" +
                              "Доплата: " +
                              zone.amount,
                          }}
                        />
                      ))}
                </Map>
              </YMaps>
            </CTabPane>
          </CTabContent>
        </CTabs>
        <CModal show={isOpen} onClose={onToggle} size="xl">
          <CModalBody>
            {openForm === "zone" && (
              <Polygons onToggle={onToggle} selectedFee={selectedFee} reFetchFees={reFetchFees} />
            )}
            {openForm === "leaflet zone" && (
              <Leaflet onToggle={onToggle} selectedFee={selectedFee} reFetchFees={reFetchFees}/>
            )}
            {openForm === "redemption" && (
              <ExtraFeeForm
                openForm={openForm}
                selectedFee={selectedFee}
                onToggle={onToggle}
              />
            )}
            {openForm === "weight" && (
              <ExtraFeeForm
                openForm={openForm}
                selectedFee={selectedFee}
                onToggle={onToggle}
              />
            )}
          </CModalBody>
        </CModal>

        <CModal show={isConfirmOpen} onClose={onConfirmToggle}>
          <CModalBody>
            <h5>{t("Are you sure to delete?")}</h5>
            <div className="text-right m-4">
              <CButton
                variant="outline"
                color="danger"
                onClick={() => deleteConfirm()}
              >
                {t("Delete")}
              </CButton>
              {/* <CButton variant="outline" color="dark" className="mx-2" onClick={onToggle}>Close</CButton> */}
            </div>
          </CModalBody>
        </CModal>
      </CCardBody>
    </CCard>
  );
}
