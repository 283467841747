import { CButton, CCard, CCardBody, CForm, CLabel, CModal, CModalBody } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useModalState } from 'src/helpers/useModal'
import { useTranslate } from 'src/helpers/useTranslate'
import { fetchNodes, fetchPatchNodes } from 'src/services/RouteService'
import CreatableSelect from 'react-select/creatable';
import { fetchCreateRegion, fetchRegions } from 'src/services/CityService'
import { toastify } from 'src/helpers/toast'

export default function Nodes() {

    const {t} = useTranslation()
    const {tr} = useTranslate()

    const {onOpen, isOpen, onClose} = useModalState()

    const [nodes, setNodes] = useState([])
    const [regions, setRegions] = useState([])
    const [selectedNode, setSelectedNode] = useState(null)
    const [selectedRegion, setSelectedRegion] = useState(null)

    useEffect(() => {
        fetchNodes().then(res => res.ok && res.json().then(d => {
            console.log(d)
            setNodes(d)
        }))
        fetchRegions().then(res => res.ok && res.json().then(d => {
            setRegions(d)
        }))
    }, [])

    const openEdit = (node) => {
        setSelectedNode(node);
        let selectedReg = regions.filter(r => r.id === node.region_id)[0] 
        setSelectedRegion(
            node.region_id ? {
                value: selectedReg?.id,
                label: tr(selectedReg?.name_ru, selectedReg?.name_kg)
            } : null
        )
        onOpen()
    }

    const updateNode = () => {
        if(selectedRegion?.id) {
            fetchUpdate({region_id: selectedRegion.id})
        } else if(selectedRegion?.label) {
            fetchCreateRegion({name_kg: selectedRegion?.label, name_ru: selectedRegion?.label}).then(res => {
                if(res.ok) {
                    res.json().then(d => {
                        fetchUpdate({region_id: d.id})
                    })
                } else {
                    toastify("error", t("Operation failed"))
                }
            }).catch(err => {
                toastify("error", t("Operation failed"))
            })
        }
    }   

    const fetchUpdate = (nodeBody) => {
        fetchPatchNodes(selectedNode.id, nodeBody).then(res => {
            if(res.ok) {
                toastify("success", t("Operation succeeded"))
                onClose()
                setNodes(nodes => [[...nodes].filter(n => n.id !== selectedNode.id), {...selectedNode, region_id: nodeBody?.region_id}])
                setSelectedNode(null)
                setSelectedRegion(null)
            } else {
                toastify("error", t("Operation failed"))
            }
        }).catch(err => {
            toastify("error", t("Operation succeeded"))
            console.log(err)
        })
    }

    return(
        <div>
            <CCard>
                <CCardBody>
                    <div className='table-responsive'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>{t("Name")}</th>
                                    <th>{t("Region")}</th>
                                    <th>{t("Actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {nodes.map( n =>
                                    <tr key={n.id}>
                                        <td>
                                            {tr(n.name_ru, n.name_kg)}
                                        </td>
                                        <td>
                                            {n.region_id}
                                        </td>
                                        <td>
                                            <CButton variant="outline" color="primary" 
                                                onClick={() => openEdit(n)}>{t("Edit")}</CButton>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </CCardBody>
            </CCard>
            <CModal show={isOpen} onClose={onClose}>
                <CModalBody>
                    <CForm>
                        <CLabel>{t("Region")}</CLabel>
                        <CreatableSelect 
                                formatCreateLabel={res => t("Create") + " " + res}
                                isClearable
                                options={regions.map(r => {return { value: r.id,label: tr(r.name_ru, r.name_kg), ...r}})}  
                                placeholder={t("Region")}
                                onChange={res => setSelectedRegion(res)} 
                                value={selectedRegion}/>
                            <div className='mt-2 d-flex justify-content-end'>
                                <CButton color='success' variant='outline' onClick={updateNode}>
                                    {t("Save")}
                                </CButton>
                            </div>
                    </CForm>
                </CModalBody>
            </CModal>
        </div>
    )
}