import {
  CCard,
  CInput,
  CCardBody,
  CForm,
  CFormGroup,
  CLabel,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CRow,
  CCol,
  CCardHeader,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import React, { useState, useEffect } from "react";
import {
  fetchCreateTariff,
  fetchDeleteTariff,
  fetchUpdateTariff,
} from "src/services/TariffService";
import { useDispatch, useSelector } from "react-redux";
import { useConfirmModal } from "../../helpers/useModal";
import { getTariffs } from "../../redux/actions/tariffActions";
import { setBreadcrumb } from "src/redux/actions/settingsActions";
import { toastify } from "src/helpers/toast";
import MiniSpinner from "src/components/spinners/MiniSpinner";
import { useTranslation } from "react-i18next";

export default function Tariff() {
  const { t } = useTranslation();
  const [selectedId, setSelectedId] = useState(null);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [pricePerKm, setPricePerKm] = useState("");
  
  const [btnState, setBtnState] = useState(false);
  const [isCreateInput, setIsCreateInput] = useState(false);
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const [modalTitle, setModalTitle] = useState("Modal Title");

  const { isConfirmOpen, onConfirmToggle } = useConfirmModal();
  const dispatch = useDispatch();

  const tariffs = useSelector((state) => state.tariff.tariffs);

  const reFetchTariff = async () => dispatch(getTariffs());

  const submitTariff = () => {
    const tariffForm = {
      name: name,
      price: price,
      price_per_km: pricePerKm,
    };
    fetchCreateTariff(tariffForm)
      .then((res) => {
        if (res.ok) {
          setBtnState(false);
          toastify("success", t("Новый тариф успешно создан"));
          reFetchTariff();
          setSelectedId(null);
          setName("");
          setPrice("");
          setPricePerKm("");
          setIsCreateInput(false);
        } else {
          toastify("error", t("Произошла ошибка создания тарифа"));
          setBtnState(false);
        }
      })
      .catch((err) => {
        toastify("error", t("Произошла ошибка, проверьте интернет"));
        setBtnState(false);
      });
  };

  const updateTariff = () => {
    const tariffForm = {
      name,
      price,
      price_per_km: pricePerKm,
    };
    fetchUpdateTariff(tariffForm, selectedId)
      .then((res) => {
        if (res.ok) {
          toastify("success", t("Тариф успешно изменен"), {
            position: "top-left",
          });
          reFetchTariff();
          setSelectedId(null);
          setBtnState(false);
        } else {
          toastify("error", t("Произошла ошибка изменения тарифа"));
          setBtnState(false);
        }
      })
      .catch((err) => {
        toastify("error", t("Произошла ошибка, проверьте интернет"));
        setBtnState(false);
      });
  };

  const showDelete = () => {
    setModalTitle(t("Delete Tariff"));
    reFetchTariff();
    onConfirmToggle();
  };

  const deleteTariff = (id) => {
    fetchDeleteTariff(id)
      .then((res) => {
        if (res.ok) {
          toastify("success", t("Тариф успешно удален"));
          reFetchTariff();
        } else {
          toastify("error", t("Произошла ошибка удаления"));
        }
      })
      .catch((err) => toastify("error", t("Произошла ошибка, проверьте интернет")))
      .finally(onConfirmToggle);
  };

  useEffect(() => {
    dispatch(getTariffs());
    dispatch(setBreadcrumb(["Tariffs"]));
  }, [dispatch]);

  return (
    <CCard className="m-2">
      <CCardHeader>
        {isCreateInput === false ? (
          !isShowTooltip ? (
            <CIcon
              size="2xl"
              cursor="pointer"
              name="cilPlus"
              className="text-success sp-rounded"
              style={{ marginBottom: "2.5px" }}
              onMouseEnter={() => setIsShowTooltip(true)}
            />
          ) : (
            <CButton
              color="success"
              shape="pill"
              onMouseLeave={() => setIsShowTooltip(false)}
              onClick={() => {
                setIsCreateInput(true);
                setName("");
                setPrice("");
                setPricePerKm("");
                setSelectedId(null);
                dispatch(setBreadcrumb(["Tariffs", "Create Tariff"]));
              }}
            >
              + {t("Add new tariff")}
            </CButton>
          )
        ) : (
          <CRow>
            <CCol>
              <CInput
                placeholder={t("Name")}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </CCol>
            <CCol>
              <CInput
                placeholder={t("Price")}
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </CCol>
            <CCol>
              <CInput
                placeholder={t("Price per Km")}
                value={pricePerKm}
                onChange={(e) => setPricePerKm(e.target.value)}
              />
            </CCol>
            <CCol>
              <CButton
                className="mr-1"
                variant="outline"
                color="success"
                disabled={btnState}
                onClick={() => {
                  setBtnState(true);
                  submitTariff();
                  setIsShowTooltip(false);
                  dispatch(setBreadcrumb(["Tariffs"]));
                }}
              >
                {btnState ? <MiniSpinner /> : t("Create")}
              </CButton>
              <CButton
                variant="outline"
                color="secondary"
                onClick={() => {
                  setIsCreateInput(false);
                  setIsShowTooltip(false);
                  dispatch(setBreadcrumb(["Tariffs"]));
                }}
              >
                {t("Cancel")}
              </CButton>
            </CCol>
          </CRow>
        )}
      </CCardHeader>
      <CCardBody>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>{t("Tariff Name")}</th>
              <th>{t("Price")}</th>
              <th>{t("Price per Km")}</th>
              <th>{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {tariffs &&
              tariffs.map((t) => {
                return selectedId === t.id ? (
                  <tr key={t.id}>
                    <td style={{ width: "40%" }}>
                      <CInput
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </td>
                    <td style={{ width: "20%" }}>
                      <CInput
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </td>
                    <td style={{ width: "20%" }}>
                      <CInput
                        value={pricePerKm}
                        onChange={(e) => setPricePerKm(e.target.value)}
                      />
                    </td>
                    <td style={{ width: "20%" }}>
                      <CIcon
                        onClick={() => {
                          updateTariff();
                          dispatch(setBreadcrumb(["Tariffs"]));
                        }}
                        name="cilCheck"
                        className="text-success mr-3"
                        size="xl"
                        cursor="pointer"
                      />
                      <CIcon
                        name="cilX"
                        className="text-danger mr-3"
                        size="xl"
                        cursor="pointer"
                        onClick={() => {
                          setSelectedId(null);
                          setName("");
                          setPrice("");
                          setPricePerKm("");
                          setIsCreateInput(false);
                          dispatch(setBreadcrumb(["Tariffs"]));
                        }}
                      />
                    </td>
                  </tr>
                ) : (
                  <tr key={t.id}>
                    <td style={{ width: "40%" }}>{t.name}</td>
                    <td style={{ width: "20%" }}>{t.price}</td>
                    <td style={{ width: "20%" }}>{t.price_per_km}</td>
                    <td style={{ width: "20%" }}>
                      <CIcon
                        name="cilTrash"
                        className="text-danger mr-3"
                        size="xl"
                        cursor="pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          showDelete();
                          setSelectedId(t.id);
                          setName(t.name);
                          setPrice(t.price);
                          setPricePerKm(t.price_per_km);
                          dispatch(setBreadcrumb(["Tariffs", "Delete Tariff"]));
                        }}
                      />
                      <CIcon
                        name="cilPencil"
                        className="text-warning"
                        size="xl"
                        cursor="pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsCreateInput(false);
                          setSelectedId(t.id);
                          setName(t.name);
                          setPrice(t.price);
                          setPricePerKm(t.price_per_km);
                          dispatch(setBreadcrumb(["Tariffs", "Change Tariff"]));
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        <CModal show={isConfirmOpen} onClose={onConfirmToggle}>
          <CModalHeader closeButton>{modalTitle}</CModalHeader>
          <CModalBody>
            {name && (
              <CForm>
                <CFormGroup>
                  <CLabel htmlFor="nf-name">{t("Delete this tariff?")} {name}</CLabel>
                </CFormGroup>
              </CForm>
            )}
          </CModalBody>
          <CModalFooter>
            {selectedId && (
              <CRow>
                <CButton
                  color="danger"
                  onClick={() => deleteTariff(selectedId)}
                  className="mr-3"
                >
                  {t("Delete")}
                </CButton>
              </CRow>
            )}
          </CModalFooter>
        </CModal>
      </CCardBody>
    </CCard>
  );
}
