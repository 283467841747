import { useCallback, useState } from "react";
import { fetchClientsSearh } from "src/services/ClientService";
import InputWithSuggestions from "./InputWithSuggestions";

export default function ClientInputWithSuggestions({
    value, 
    placeholder,
    invalid,
    setValue,
    onOptionSelect,
}) {
    const [suggestShow, setSuggestShow] = useState(false);
    const [suggests, setSuggests] = useState([])

    const updateSuggests = useCallback((val) => {
        fetchClientsSearh(val + '&limit=5').then(res => {
            res.ok && res.json().then(d => {
                if(d.length > 0) {
                    setSuggestShow(true)
                    setSuggests(d?.map(item => ({label: item.phone, ...item})))
                } else {
                    setSuggests([])
                }
            })
        }).catch(err => console.log(err))
    }, [])

    const handleChange = useCallback((val) => {
        setValue(val)
        updateSuggests(val);
    }, [setValue, updateSuggests])

    const itemSelect = useCallback((item) => {
        setValue(item.label);
        setSuggestShow(false)
        onOptionSelect(item)
    }, [onOptionSelect, setValue])

    return (
        <div className="mb-2">
            <InputWithSuggestions 
                value={value}
                placeholder={placeholder}
                invalid={invalid}
                suggests={suggests}
                suggestShow={suggestShow}
                itemSelect={itemSelect}
                setSuggestShow={setSuggestShow}
                handleChange={handleChange}
            />
        </div>
    )
}