import React from 'react';
import {CModal, CModalHeader, CModalBody, CModalFooter, CButton} from "@coreui/react"
import { useTranslation } from 'react-i18next';
import { fetchDeleteCourier } from 'src/services/CourierService';
import { toastify } from 'src/helpers/toast';
import { useDispatch } from 'react-redux';
import { getCouriers } from 'src/redux/actions/courierActions';

export default function ConfirmDeleteModal (props) {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const submitDelete= () => {
        fetchDeleteCourier(props.selectedCourier.id).then(res => {
            if(res.ok) {
                toastify("success", t("Operation succeded"));
                dispatch(getCouriers());
                props.onToggle();
            } else {
                console.log("error");
                toastify("error", t("Operation failed"));
            }
        }).catch(err => {
          console.log(err);
          toastify("error", t("Operatin failed"));
        })
    }

    return(
        <CModal show={props.isOpen} onClose={props.onToggle}>
          <CModalHeader closeButton>{t(props.modalTitle)}</CModalHeader>
          <CModalBody>
            {props.selectedCourier && 
                <h5>
                    {t("Confirm delete") + " "} {props.selectedCourier?.firstname} ?
                </h5>
            }
          </CModalBody>
          <CModalFooter className="text-right">
            <CButton
                color="danger"
                onClick={() => submitDelete()}
                className="mr-2"
            >
                {t("Delete")}
            </CButton>
          </CModalFooter>
        </CModal>
    )
}