import React, {useCallback, useEffect, useState} from 'react';
import {CCard, CCardBody, CSelect} from "@coreui/react"
import OrdersTable from './OrdersTable';
import { useDispatch} from 'react-redux'
import { setBreadcrumb } from 'src/redux/actions/settingsActions';
import { fetchCompanyStatuses } from 'src/services/CompanyService';
import { useTranslation } from 'react-i18next';
import MiniSpinner from 'src/components/spinners/MiniSpinner';
import Pagination from 'src/components/Pagination';
import CIcon from '@coreui/icons-react';
import FilterBar from './FilterBar';
import { ordersFetchArchive } from 'src/services/OrderService';
import { fetchRegions } from 'src/services/CityService';
import { fetchClientOrganizations } from 'src/services/ClientService';
import ExportExcel from './ExportExcel';

const initalParams = {
    size: 20,
    page: 1,
    
    date_from: undefined,
    date_to: undefined,
    
    order_type: undefined,
    redemption: undefined,
    
    courier_id: undefined,
    organization_id: undefined,
    client_id: undefined,
    region_id: undefined,
}

export default function Archive(){
    
    const [orders, setOrders] = useState([]);

    const [regions, setRegions] = useState([]);
    const [organizations, setOrganizations] = useState([]);

    const [showFilterBar, setShowFilterBar] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [filterParams, setFilterParams] = useState(initalParams)

    const { t } = useTranslation();
    
    const dispatch = useDispatch();

    const fetchReq = useCallback((params) => {
        setIsLoading(true)
        ordersFetchArchive(params).then(res => {
            res.ok && res.json().then(d => {
                setOrders(d)
                setIsLoading(false)
            })
        }).catch(err => {
            setIsLoading(false)
            console.log(err)
        })
    }, [])

    useEffect(() => {
        fetchReq();
    }, [fetchReq])

    useEffect(() => {
        fetchRegions().then(res => {
            res.ok && res.json().then(d => {
                setRegions(d)
            })
        }).catch(err => {
            console.log(err)
        })
    }, [])

    useEffect(() => {
        fetchClientOrganizations().then(res => {
            res.ok && res.json().then(d => {
                setOrganizations(d)
            })
        }).catch(err => {
            console.log(err)
        })
    }, [])

    useEffect(() => {
        dispatch(setBreadcrumb(["All Orders"]));
        fetchCompanyStatuses().then(res => {
            if(res.ok) {
                res.json().then(data => {
                    let statusList = data.map((d, i) => {
                        if(d.value==="created") {
                            return {...d, percent: 5}
                        } else if(i < data.length - 2) {
                            return {...d, percent: parseInt(i * 10)}
                        } else {
                            return {...d, percent: 100}
                        }
                    })
                    setStatuses(statusList);
                })
            }
        }).catch(err => console.log(err));
    }, [dispatch])

    const applyFilters = useCallback((params) => {
        fetchReq(params)
        setFilterParams(params)
    }, [fetchReq])

    const onNext = useCallback(() => {
        applyFilters({...filterParams, page: filterParams.page + 1});
    }, [applyFilters, filterParams])

    const onPrev = useCallback(() => {
        applyFilters({...filterParams, page: filterParams.page - 1});
    }, [applyFilters, filterParams])

    return (
        <div>
            <CCard className="m-2">
                <CCardBody className="pt-1 px-0">
                    <div className="px-2 my-1 d-flex justify-content-end">
                        <div className='sp-link bg-secondary rounded px-1' onClick={() => setShowFilterBar(!showFilterBar)}>
                            {t((showFilterBar ? "Hide" : "Show") + " filters")} 
                            <CIcon name={showFilterBar ? "cilX" : "cilListFilter"}/> 
                        </div>
                    </div>
                    <div className={showFilterBar ? '' : 'd-none'}>
                        <FilterBar 
                            statuses={statuses} 
                            applyFilters={applyFilters} 
                            filterParams={filterParams}
                            regions={regions}
                            organizations={organizations}
                        />
                    </div>
                    {/* {showFilterBar && 
                        <FilterBar 
                            statuses={statuses} 
                            applyFilters={applyFilters} 
                            filterParams={filterParams}
                            regions={regions}
                            organizations={organizations}
                        />} */}
                    <div className="mt-2">
                        {isLoading ? 
                            <div className="text-center">
                                <MiniSpinner />
                            </div>
                        :
                            <div>
                                <div className='my-1 mx-2 d-flex justify-content-between'>
                                    <div>
                                    {t("Orders amount")}: {orders.total}
                                    {/* . На странице: {orders.items?.length} */}
                                    </div>
                                    <div className='d-flex'>
                                        <ExportExcel filterParams={filterParams}/>
                                        <CSelect size="sm" value={filterParams.size}
                                            onChange={e => applyFilters({
                                                    ...filterParams, 
                                                    size: parseInt(e.target.value)
                                                })}>
                                            <option>20</option>
                                            <option>25</option>
                                            <option>50</option>
                                            <option>100</option>
                                        </CSelect>
                                    </div>
                                </div>
                                <OrdersTable currentOrders={orders?.items || []} statusList={statuses} archive/>
                                <Pagination 
                                    currentPage={filterParams.page}
                                    nextPage={onNext}
                                    prevPage={onPrev}
                                    limit={filterParams.size}
                                    currentListLength={orders?.items?.length}
                                    isLastPage={(orders?.total / (orders?.page * filterParams.size)) <= 1}/>
                            </div>
                        }
                    </div>

                </CCardBody>
            </CCard>
        </div>
    )
}

