import { CLabel } from "@coreui/react";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import { useTranslate } from "src/helpers/useTranslate";
import { promiseDistrictOptions } from "../helpers/helpers";

export default function DistrictField({
  districts,
  district,
  city,
  handleDistrictChange,
}) {
  const { t } = useTranslation();
  const { tr } = useTranslate();
  return (
    <>
      <CLabel>{t("District")}*</CLabel>
      <div style={{ width: "92%" }}>
        <AsyncSelect
          isClearable
          className="mb-2"
          placeholder={tr("Мкрн/Село", "Кичирайон/Айыл")}
          defaultOptions={districts.map((d) => {
            return { value: d.id, label: tr(d.name_ru, d.name_kg), ...d };
          })}
          loadOptions={(value) => promiseDistrictOptions(value, tr, city)}
          onChange={(res) => handleDistrictChange(res)}
          value={district}
        />
      </div>
    </>
  );
}
