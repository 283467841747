import {
  TRANSPORT_SUCCESS,
  TRANSPORT_LOADING,
  TRANSPORT_FAIL
} from '../types/transportTypes'

const initialState = {
  transports: [],
  error: null,
  isLoading: true
}

export default function transportReducer(state = initialState, action) {
  switch (action.type) {
    case TRANSPORT_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case TRANSPORT_SUCCESS:
      return {
        ...state,
        transports: action.payload,
        isLoading: false,
        error: null
      }
    case TRANSPORT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload || null
      }
    default:
      return {
        ...state
      }
  }
}