import React from 'react';
import { CRow, CCard, CCardBody, CCol} from '@coreui/react';
import { useTranslation } from 'react-i18next';
import tariff from "src/assets/tariff.svg";
import box from "src/assets/box.svg";
import web from "src/assets/web.svg";
import { useTranslate } from 'src/helpers/useTranslate';
import ClientCard from './ClientCard';
import PaymentsCard from './PaymentsCard';

export default function MainDetails({orderDetails}) {

    const {t} = useTranslation();
    const {tr} = useTranslate();

    return (
        <div>
          <CRow>
            <CCol lg="6">
              <ClientCard 
                title={t("Sender")}
                client={orderDetails.sender}
                city={orderDetails.city_from}
                address={orderDetails.location_from} 
                address_floor={orderDetails.location_from_floor}
                address_flat={orderDetails.location_from_flat}/>
            </CCol>
            <CCol lg="6">
            <ClientCard 
                title={t("Receiver")}
                client={orderDetails.receiver}
                city={orderDetails.city_to}
                address={orderDetails.location_to} 
                address_floor={orderDetails.location_to_floor}
                address_flat={orderDetails.location_to_flat}/>
            </CCol>
          </CRow>
          
          <CRow>
            <CCol lg="6">
              <CCard>
                <CCardBody>
                  <h6><b>{t("Order Details")}</b></h6>
                  <hr/>
                  <CRow>
                    <CCol sm="2">
                      <img src={box} alt="pack type" width="40"/>
                    </CCol>
                    <CCol sm="4">
                      <b>{t("Parcel")}</b>
                      <p>{tr(orderDetails.package_type?.name_ru, orderDetails.package_type?.name_kg)}</p>
                    </CCol>
                    <CCol sm="2">
                      <img src={tariff} alt="tariff icon" width="40"/>
                    </CCol>
                    <CCol sm="4">
                      <b>{t("Tariff")}:</b>
                      <p>{orderDetails.tariff?.name}</p>
                    </CCol>
                    <CCol sm="2">
                      <img src={web} alt="source" width="40"/>
                    </CCol>
                    <CCol sm="4">
                      <b>{t("Source")}:</b>
                      <p>{orderDetails.source}</p>
                    </CCol>
                    <CCol sm="2">
                      <img src={box} alt="pack type" width="40"/>
                    </CCol>
                    <CCol sm="4">
                      <p>{t("Weight")}: {orderDetails.weight}<br />
                      {t("Amount")}: {orderDetails.amount}</p>
                    </CCol>
                    <CCol sm="12">
                      <p>{t("Comments")}: {orderDetails.description}</p>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCol>
            <CCol lg="6">
              <PaymentsCard orderDetails={orderDetails}/>
            </CCol>
          </CRow>
          {orderDetails.parent_type === "group" && 
            <CCard>
              <CCardBody>
              <h6><b>{t("Nested orders")}</b></h6>
              <table className="table table-responsive-sm">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>{t("Sender")}</th>
                    <th>{t("Receiver")}</th>
                    <th>{t("Parcel")}</th>
                    <th>{t("Redemption")}</th>
                    <th>{t("Price")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                  </tr>
                </tbody>
              </table>
              </CCardBody>
            </CCard>
          }
        </div>
    )
}
