import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  fetchCompanyAddressesOwnSearch,
  fetchCompanyAddressDelete,
} from "src/services/CompanyService";
import { setBreadcrumb } from "src/redux/actions/settingsActions";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CInput,
  CModal,
  CModalHeader,
  CModalFooter,
  CModalBody,
  CLabel,
  CRow,
  CForm,
  CFormGroup,
  CButton,
  CCol,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { useConfirmModal } from "src/helpers/useModal";
import { toastify } from "src/helpers/toast";
import { useParams } from 'react-router';
import AsyncSelect from 'react-select/async';
import { fetchDistrictSearch, fetchSearchedCities } from "src/services/CityService";
import { useTranslate } from "src/helpers/useTranslate";
import { fetchCompanyAddressesByParams } from "src/services/LocationService";

export function CompanyAddress() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams()
  const { t } = useTranslation();
  const { tr } = useTranslate();

  const [key, setKey] = useState("");
  const [pars, setPars] = useState("");
  const [page, setPage] = useState(0)

  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [selectedCity, setSelectedCity] = useState(null)
  const [selectedDistrict, setSelectedDistrict] = useState(null)

  const { isConfirmOpen, onConfirmToggle } = useConfirmModal();
  const [modalTitle, setModalTitle] = useState("Modal Title");

  const limit = 50;

  useEffect(() => {
    dispatch(setBreadcrumb(["Addresses"]));
    if(params?.p) {
      let q = JSON.parse('{"' + decodeURI(params.p.replace(/&/g, "\",\"").replace(/=/g,"\":\"").replace(/spsl/g, "/")) + '"}')
      setPars(q)
      let page = q.page
      setPage(page)
      let skip = ((page || 1)-1) * limit
      let cityId = q.cityId
      let districtId = q.districtId
      let search = q.search
      fetchCompanyAddressesByParams({search, districtId, cityId, skip, limit: page ? limit : 200})
          .then((res) => {
            if(res.ok){
              res.json().then((data) => {
                setAddresses(data);
              })
            } else {
              console.log(res.statusText);
            }
          })
    } else if (params?.search) {
      fetchCompanyAddressesOwnSearch(params.search, 200)
          .then((res) => {
            if(res.ok){
              res.json().then((data) => {
                setKey(params.search)
                setAddresses(data);
              })
            } else {
              console.log(res.statusText);
            }
          })
    } else {
      history.push(`/company-addresses/page=1`)
    }
  }, [params, dispatch, history])


  const onNext = () => {
   history.push(`/company-addresses/page=${parseInt(pars.page) + 1}`)
  }

  const onPrev = () => {
    history.push(`/company-addresses/page=${parseInt(pars.page) - 1}`)
  }

  const showDelete = (adr) => {
    setSelectedAddress(adr);
    setModalTitle(t("Delete Address"));
    onConfirmToggle();
  };

  const search = (key) => {
    key ? history.push(`/company-addresses/search=${key.replace(/\//g, "spsl")}`) : history.push(`/company-addresses/page=1`)
  };

  const deleteAddress = (id) => {
    fetchCompanyAddressDelete(id)
      .then((res) => {
        if (res.ok) {
          toastify("success", t("Address deleted"));
          history.push(pars?.search ? `/company-addresses/search=${pars.search.replace(/\//g, "spsl")}` : `/company-addresses/${parseInt(pars.page || 1)}`)
        } else {
          toastify("error", t("Произошла ошибка удаления адреса"));
        }
      })
      .catch((err) => toastify("error", t("Произошла ошибка, проверьте интернет")))
      .finally(onConfirmToggle);
  };

  const filterAddresses = (addresses) => {
    let latOrLngCityNull = []
    let latOrLngNull = []
    let districtCityNull = []
    let districtNull = []
    let cityNull = []
    let other = []
    addresses.forEach(address => {
      if (address.lng === null && address.city === null){
        latOrLngCityNull.push(address)
      } else if (address.lng === null) {
        latOrLngNull.push(address)
      } else if (address.district === null && address.city === null) {
        districtCityNull.push(address)
      }else if (address.district === null) {
        districtNull.push(address)
      }  else if (address.city === null) {
        cityNull.push(address)
      }else if (address.city === null) {
        cityNull.push(address)
      } else {
        other.push(address)
      }
    })

  let filterAddresses = [...latOrLngCityNull,...latOrLngNull, ...districtCityNull, ...districtNull, ...cityNull, ...other]
    return filterAddresses
  }

  const promiseDistrictOptions = (inputValue) => {
    let params = inputValue;
    params += selectedCity ? "&city_id="+ selectedCity.id : ""
    return new Promise(resolve => {
        params.includes("&city_id=") ?
        fetchDistrictSearch(params).then(res => res.ok && res.json().then(d=> {
            resolve(d.map(dd => {return {label: tr(dd.name_ru, dd.name_kg), value: dd.id, ...dd}}))
        })) : resolve([])
    });
  }

  const promiseCityOptions = (inputValue) => {
      return new Promise(resolve => {
          fetchSearchedCities(inputValue).then(res => 
              res.ok && res.json().then(d => {
              console.log(d);
              resolve(d.map(dd => {return {label: tr(dd.name_ru, dd.name_kg), value: dd.id, ...dd}}))
          }))
      });
  }


  return (
    <CCard className="m-2">
      <CCardHeader>
          <CButton
            className="float-right"
            variant="outline"
            color="success"
            onClick={() => {
              history.push("/newcompanyaddress");
            }}
          >
            {t("Create")}
          </CButton>
      </CCardHeader>
      <CCardBody>
        <CRow className="d-none">
          <CCol sm="4">
            <AsyncSelect
              // defaultOptions={cities.map(city => { return { value: city.id,label: tr(city.name_ru, city.name_kg), ...city}})}
              loadOptions={promiseCityOptions}
              isClearable
              placeholder={tr("Город/Район","Шаар/Район")}
              className="mb-2"  
              onChange={res => setSelectedCity(res)} 
              value={selectedCity}
            />
          </CCol>
          <CCol sm="4">
            <AsyncSelect 
              // defaultOptions={districts.map(d => { return { value: d.id,label: tr(d.name_ru, d.name_kg), ...d}})}
              loadOptions={promiseDistrictOptions}
              isClearable
              placeholder={tr("Мкрн/Село","Кичирайон/Айыл")}
              className="mb-2"
              onChange={res => setSelectedDistrict(res)} 
              value={selectedDistrict}
              />
          </CCol>
        </CRow>
            <CForm className="d-flex mb-2" onSubmit={e => {
                e.preventDefault(); 
                search(key)
              }}>
              <CInput
                value={key}
                placeholder={t("Поиск адреса")}
                className="mr-2"
                onChange={(e) => {
                  setKey(e.target.value);
                }}
              />
              <CButton color="success" type="submit">{t("Search")}</CButton>
            </CForm>
          <div className="text-right">
            {page && <div className="d-flex float-right align-items-center">
                {page > 1 && <CButton
                    className='mb-3'
                    variant="outline"
                    color="primary"
                    onClick={onPrev}><CIcon name="cilArrowLeft"></CIcon></CButton> }
                <span
                    className='mb-3 px-2'
                    color="success"
                > Page: {page} </span>
                {addresses.length === limit && <CButton
                    className='mb-3'
                    variant="outline"
                    color="primary"
                    onClick={onNext}><CIcon name="cilArrowRight"></CIcon></CButton>}
            </div>}
          </div>

        <table className="table table-hover">
          <thead>
            <tr>
              <th>{t("Address")}</th>
              <th>{t("City")}</th>
              <th>{t("District")}</th>
              <th>{t("Latitude")}</th>
              <th>{t("Longitude")}</th>
              <th>{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {addresses.length > 0 &&
              filterAddresses(addresses).map((a) => (
                <tr key={a.id}>
                  <td>{tr(a.name_ru, a.name_kg)}</td>
                  <td>
                    {a.city &&
                      tr(a.city.name_ru, a.city.name_kg)}
                  </td>
                  <td>
                    {a.district &&
                      tr(a.district.name_ru, a.district.name_kg)}
                  </td>
                  <td>{a.lat}</td>
                  <td>{a.lng}</td>
                  <td>
                    <CIcon
                      name="cilTrash"
                      className="text-danger mr-2"
                      size="xl"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        showDelete(a);
                        dispatch(
                          setBreadcrumb(["Addresses", "Delete Address"])
                        );
                      }}
                    />
                    <Link to={"/address/edit/" + a.id} target="_blank">
                        <CIcon
                          name="cilPencil"
                          className="text-warning"
                          size="xl"
                          style={{ cursor: 'pointer' }}
                        />
                    </Link>
                  </td>
                </tr>
              ))}

          </tbody>

        </table>
        {page && <div className="d-flex float-right align-items-center">
          {page > 1 && <CButton
              className='mb-3'
              variant="outline"
              color="primary"
              onClick={onPrev}><CIcon name="cilArrowLeft"></CIcon></CButton> }
          <span
              className='mb-3 px-2'
              color="success"
          > Page: {page} </span>
          {addresses.length === limit && <CButton
              className='mb-3'
              variant="outline"
              color="primary"
              onClick={onNext}><CIcon name="cilArrowRight"></CIcon></CButton>}
        </div>}
        <CModal show={isConfirmOpen} onClose={onConfirmToggle}>
          <CModalHeader closeButton>{modalTitle}</CModalHeader>
          <CModalBody>
            {selectedAddress && (
              <CForm>
                <CFormGroup>
                  <CLabel htmlFor="nf-client">
                    {t("Delete this address? ")} {selectedAddress.name_ru}
                  </CLabel>
                </CFormGroup>
              </CForm>
            )}
          </CModalBody>
          <CModalFooter>
            {selectedAddress && (
              <CRow>
                <CButton
                  color="danger"
                  onClick={() => deleteAddress(selectedAddress.id)}
                  className="mr-2"
                >
                  {t("Delete")}
                </CButton>
              </CRow>
            )}
          </CModalFooter>
        </CModal>
      </CCardBody>
    </CCard>
  );
}
