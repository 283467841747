import React from "react";
import { CRow, CCol, CLabel, CInput } from "@coreui/react";
import { useTranslation } from "react-i18next";
import CIcon from "@coreui/icons-react";
import CityField from "./../components/CityField";
import DistrictField from "./../components/DistrictField";
import { onUploadPhoto } from "../helpers/helpers";

const PassportDataTab = ({
  onUploadPhotoSuccess,
  updateField,
  updateFieldCheck,
  fieldChecked,
  initForm,
  cities,
  handleCityChange,
  city,
  districts,
  district,
  setDistrict,
}) => {
  const { t } = useTranslation();

  const onUploadPassport = (e, imgName) => {
    if (imgName === "passport_front") {
      updateFieldCheck('passport_front', e.target?.files.length >= 1)
    } else {
      updateFieldCheck('passport_back', e.target?.files.length >= 1)
    }
    onUploadPhoto(e, imgName, onUploadPhotoSuccess);
  };

  const handleDistrictChange = (district) => {
    setDistrict(district);
    updateField("district_id", district?.value);
  };

  return (
    <CRow className="ml-2">
      <CRow style={{ width: "100%" }}>
        <CCol xs={10}>
          <CityField
            cities={cities}
            city={city}
            handleCityChange={handleCityChange}
          />
        </CCol>

        <CCol className="mt-3" xs={10}>
          <DistrictField
            district={district}
            city={city}
            districts={districts}
            handleDistrictChange={handleDistrictChange}
          />
        </CCol>

        <CCol className="mt-3" xs={10}>
          <CLabel>{t("Personal Number")}</CLabel>
          <CInput
            style={{ width: "92%" }}
            type="text"
            placeholder={t("Personal Number")}
            name="personal_number"
            value={initForm.personal_number}
            onChange={(e) => updateField(e.target.name, e.target.value)}
          />
        </CCol>

        <CCol className="mt-3" xs={10}>
          <CLabel>{t("Passport front image")}*</CLabel>
          <div className="d-flex align-items-center">
            <CInput
              type="file"
              onChange={(e) => onUploadPassport(e, "passport_front")}
            />
            <CIcon
              name={
                fieldChecked.passport_front ? "cilCheckCircle" : "cilWarning"
              }
              className={
                "ml-2 " +
                (fieldChecked.passport_front ? "text-success" : "text-danger")
              }
              size="xl"
            />
          </div>
        </CCol>
      </CRow>
      <CRow style={{ width: "100%" }} className="mt-3">
        <CCol xs={10}>
          <CLabel>{t("Passport back image")}*</CLabel>
          <div className="d-flex align-items-center">
            <CInput
              type="file"
              onChange={(e) => onUploadPassport(e, "passport_back")}
            />
            <CIcon
              name={fieldChecked.passport_back ? "cilCheckCircle" : "cilWarning"}
              className={
                "ml-2 " +
                (fieldChecked.passport_back ? "text-success" : "text-danger")
              }
              size="xl"
            />
          </div>
        </CCol>
      </CRow>
    </CRow>
  );
};

export default PassportDataTab;
