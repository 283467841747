import {
    ORDER_SUCCESS,
    ORDER_LOADING,
    ORDER_FAIL,
    ORDER_SELECT,
    NEW_ORDER
    } from "../types/orderTypes";
  
  import {ordersFetchCurrent, ordersFetchForPartners} from '../../services/OrderService'
  import audio from "src/assets/sounds/accept.mp3";
  
  export const getOrders = (params) => async (dispatch, getState) => {
    dispatch(orderLoading());

    if(params.isPartner === "partner") {
      ordersFetchForPartners().then(res => {
        if(res.ok) {
          res.json().then(data => 
            dispatch(orderSuccess(data))
          )
        } else {
          dispatch(orderFail("response status is not ok"))
        }
      }).catch(err => {
        dispatch(orderFail(err));
      })
    }else{
      // ordersFetch()
      ordersFetchCurrent(params)
      .then(res => {
        if(res.ok) {
          res.json().then(data => 
            dispatch(orderSuccess(data))
          )
        } else {
          dispatch(orderFail("response status is not ok"))
        }
      }).catch(err => {
        dispatch(orderFail(err));
      })
    }

  }

  export const addOrderToCart = (order) => async(dispatch, getState) => {
    const selectedOrders = getState().order.selectedOrders;
    if(!selectedOrders.some(or => or.id === order.id)) {
      dispatch({type: ORDER_SELECT, payload: [...selectedOrders, order]})
    } else {
      dispatch({type: ORDER_SELECT, payload: [...selectedOrders].filter(a => a.id!==order.id)})
    }
  }
  
  export const orderLoading = () => ({ type: ORDER_LOADING })
  export const orderSuccess = (order) => ({ type: ORDER_SUCCESS, payload: order})
  export const orderFail = error => ({ type: ORDER_FAIL, payload: error })

  export const orderListUpdate = (order) => async(dispatch, getState) => {
    let orderList = getState().order.orders?.items?.filter(o => o.id !== order.id) || [];
    let page = getState().order.orders.page;
    const data = 
    {
      ...order, 
      sender_name: order.sender?.fullname, 
      receiver_name: order.receiver?.fullname, 
      tariff_name: order.tariff?.name, 
      package_type_photo: order.package_type?.photo
    }
    if(order.status === "cancelled" || order.status === "completed" || order.status === "rejected") {
      orderList.filter(l=> l.id === data.id);
    } else {
      page===1 && orderList.unshift(data);
    }
    const isNew = order.status === "created" || order.status === "approved"
    if(isNew && getState().settings.isSoundOn) {
      new Audio(audio).play()
    }
    dispatch(orderSuccess({ 
      ...getState().order.orders, 
      items: [...orderList]
    }));
  }

  export const removeOrder = (ids) => async(dispatch, getState) => {
    dispatch(orderSuccess(getState().order.orders?.items?.filter(o => !(ids?.order_ids?.includes(o.id)))))
  }

  export const updateNewOrder = (field, value) => async(dispatch, getState) => {
    let oldData = getState().order.newOrder
    dispatch({type: NEW_ORDER, payload: {...oldData, [field]: value}})
  }

  export const newOrderData = (order) => async(dispatch, getState) => {
    dispatch({type: NEW_ORDER, payload: order})
  }

