import {
  TARIFF_SUCCESS,
  TARIFF_LOADING,
  TARIFF_FAIL,
} from "../types/tariffTypes";

import { fetchTariffs } from "../../services/TariffService";

export const getTariffs = () => async (dispatch, getState) => {
  dispatch(tariffLoading());

  fetchTariffs()
    .then((res) =>
      {
        if(res.ok) {
          res.json().then((data) => {
            dispatch(tariffSuccess(data));
          })
        } else {
          dispatch(tariffFail(res.statusText))
        }

      }
    )
    .catch((err) => {
      dispatch(tariffFail(err));
    });
};

export const tariffLoading = () => ({ type: TARIFF_LOADING });
export const tariffSuccess = (tariffs) => ({
  type: TARIFF_SUCCESS,
  payload: tariffs,
});
export const tariffFail = (error) => ({ type: TARIFF_FAIL, payload: error });
