import React, { useEffect, useState } from "react";
import MiniSpinner from "src/components/spinners/MiniSpinner";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchReportsWithParams,
  // fetchReports,
} from "src/services/ReportsService";
import {
  getDates,
  months,
  yearsList,
  redemptionTypes,
  orderTypes,
} from "src/pages/reports/constants.js";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CInput,
  CRow,
  CCol,
  CButton,
} from "@coreui/react";
import { setBreadcrumb } from "src/redux/actions/settingsActions";
import { useTranslation } from "react-i18next";
import { useTranslate } from "src/helpers/useTranslate";
import { getOrganizations } from "src/redux/actions/organizationActions";
import { getAllRegions } from "src/redux/actions/locationActions.js";
import { fetchClientsSearh } from "src/services/ClientService.js";
import { getClients } from "src/redux/actions/clientActions.js";
import OrderDetails from "src/pages/orders/Order-Details/OrderDetails.js";
import { useModalState } from "src/helpers/useModal.js";
import CIcon from "@coreui/icons-react";
import ReportsTable from "src/pages/reports/ReportsTable.js";
import {dateDifference} from "src/helpers/date";

export const dateParse = (date) => {
  let day = new Date(Date.parse(date)).getUTCDate();
  let month = new Date(Date.parse(date)).toLocaleString("RU", {
    month: "long",
  });
  let year = new Date(Date.parse(date)).getFullYear();
  let hours = new Date(Date.parse(date)).getHours();
  let minutes = new Date(Date.parse(date)).getMinutes();

  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  let time12 = hours + ":" + minutes + " " + ampm;
  let time24 = "";
  const [time, modifier] = time12.split(" ");
  let [hour, minute] = time.split(":");
  if (hour === "12") {
    hour = "00";
  }
  if (modifier === "pm") {
    hour = parseInt(hour, 10) + 12;
  }
  time24 = `${hour}:${minute}`;

  return `${day} ${month.toString().substring(0, 3)} ${year} ${time24}`;
};

export default function ReportsForPartners() {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { isOpen, onToggle } = useModalState();

  const { tr } = useTranslate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [reports, setReports] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [params, setParams] = useState({
    date_from: new Date().toJSON().slice(0, 10),
    date_to: new Date().toJSON().slice(0, 10),
  });
  const [sums, setSums] = useState(0);
  const [clearSum, setClearSum] = useState(0);
  const couriers = useSelector((state) => state.courier.couriers);
  const profile = useSelector((state) => state.auth.company);
  const [year, setYear] = useState(new Date().getFullYear());
  const [isLoading, setIsLoading] = useState(true);
  const [organization, setOrganization] = useState({});
  const [activeBtn, setActiveBtn] = useState("today");

  const [redType, setRedType] = useState({
    value: 0,
    label: tr("Тип выкупа", "Сатып алуу түрү"),
  });
  const [ordType, setOrdType] = useState({
    value: 0,
    label: tr("Тип заказа", "Буйрук түрү"),
  });
  const [courier, setCourier] = useState({
    value: 0,
    label: tr("Выбрать курьера", "Курьерди тандоо"),
  });
  const [selectedMonth, setSelectedMonth] = useState({
    value: 0,
    label: tr("Выбрать месяц", "Айды тандоо"),
  });
  const [selectedYear, setSelectedYear] = useState({
    value: 0,
    label: tr("Выбрать год", "Жылды тандоо"),
  });
  const organizations = useSelector(
    (state) => state.organizations.organizations
  );
  const [selectedOrg, setSelectedOrg] = useState({
    value: 0,
    label: tr("Выбрать организацию", "Мекемени тандоо"),
  });

  const [selectedRegion, setSelectedRegion] = useState({
    label: tr("Выбрать регион", "Регион тандоо"),
  });

  const [selectedClient, setSelectedClient] = useState({
    label: tr("Выбрать клиента", "Кардар тандоо"),
  });

  const regions = useSelector((state) => state.locations.regions);
  const clients = useSelector((state) => state.clients.clients);

  // it needs to be changed then
  useEffect(() => {
    dispatch(getAllRegions());
    dispatch(getClients());
  }, [dispatch]);
  // it needs to be changed then

  useEffect(() => {
    //При загрузке страницы показываю отчеты за неделю
    setFromDate(new Date().toJSON().slice(0, 10));
    setToDate(new Date().toJSON().slice(0, 10));

    // setParams({
    //   date_from: new Date().toJSON().slice(0, 10),
    //   date_to: new Date().toJSON().slice(0, 10),
    // });

    fetchReportsWithParams({
      date_from: new Date().toJSON().slice(0, 10),
      date_to: new Date().toJSON().slice(0, 10),
    }).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          setIsLoading(false);
          console.log(data);
          setReports(data.data);
        });
      } else {
        console.log(res.statusText);
      }
    });
    //показываю все отчеты

    // setClearSum(0);
    setSelectedOrg({
      value: 0,
      label: i18n.language === "ru" ? "Выбрать организацию" : "Мекемени тандоо",
    });
    setSelectedRegion({
      label: i18n.language === "ru" ? "Выбрать регион" : "Регион тандоо",
    });
    setRedType({
      value: 0,
      label: i18n.language === "ru" ? "Тип выкупа" : "Сатып алуу түрү",
    });
    setOrdType({
      value: 0,
      label: i18n.language === "ru" ? "Тип заказа" : "Буйрук түрү",
    });
    setCourier({
      value: 0,
      label: i18n.language === "ru" ? "Выбрать курера" : "Курьерди тандоо",
    });
    setSelectedMonth({
      value: 0,
      label: i18n.language === "ru" ? "Выбрать месяц" : "Айды тандоо",
    });
    setSelectedYear({
      value: 0,
      label: i18n.language === "ru" ? "Выбрать год" : "Жылды тандоо",
    });

    dispatch(setBreadcrumb(["Reports"]));
    dispatch(getOrganizations());
  }, [dispatch, i18n.language]);

  const fromFunc = (e) => {
    setFromDate(e.target.value);
    setParams({ ...params, date_from: e.target.value });
  };

  const toFunc = (e) => {
    setToDate(e.target.value);
    setParams({ ...params, date_to: e.target.value });
  };

  //запрос отчетов с параметрами
  const getReportsWithParams = (params) => {
    setIsLoading(true);
    return fetchReportsWithParams(params).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          setIsLoading(false);
          console.log("d", data);
          setReports(data.data);
          data.organization && setOrganization(data.organization);
        });
      } else {
        console.log(res.statusText);
      }
    });
  };

  const reportsWithDates = (id, y) => {
    setActiveBtn(id);
    if (id === "allmonths") {
      delete params.date_from;
      delete params.date_to;
      setFromDate("");
      setToDate("");
    } else {
      let dates = getDates(year);
      if (y) {
        dates = getDates(y);
      }
      let selectedDate = dates.filter((d) => d.id === id)[0];
      setFromDate(selectedDate.date_from);
      setToDate(selectedDate.date_to);
      setParams({
        ...params,
        date_from: selectedDate.date_from,
        date_to: selectedDate.date_to,
      });
    }
  };

  const redemptionParam = (e) => {
    let redemption = {};
    if (e.value === "with") {
      redemption = { redemption: true };
    } else if (e.value === "without") {
      redemption = { redemption: false };
    } else if (e.value === "all") {
      delete params.redemption;
    }
    setParams({ ...params, ...redemption });
  };

  const orderTypeParam = (e) => {
    let type = {};
    if (e.value === "city") {
      type = { order_type: "intra_city" };
    } else if (e.value === "regional") {
      type = { order_type: "regional" };
    } else if (e.value === "all") {
      delete params.order_type;
    }
    setParams({ ...params, ...type });
  };

  const courierParam = (e) => {
    let courier_id = {};
    if (e.label === "Все курьеры" || e.label === "Баары") {
      delete params.courier_id;
    } else courier_id = { courier_id: e.value };
    setParams({ ...params, ...courier_id });
  };

  const organParam = (e) => {
    let organization_id = {};
    if (e.label === "Все организации" || e.label === "Баары") {
      delete params.organization_id;
    } else {
      organization_id = { organization_id: e.value };
      setParams({ ...params, ...organization_id });
    }
  };

  const currentReports = (cur) => {
    setYear(new Date().getFullYear());
    setSelectedMonth({
      label: tr("Выбрать месяц", "Айды тандоо"),
      value: 0,
    });
    setSelectedYear({
      value: 0,
      label: new Date().getFullYear(),
    });
    reportsWithDates(cur);
  };

  useEffect(() => {
    getReportsWithParams(params);
  }, [dispatch, params, params.date_to]);

  // const clearSumCalc = useCallback(() => {
  //
  //   if (profile?.percent) {
  //     setClearSum(
  //       (reports.reduce((s, r) => s + r.redemption_amount))
  //     );
  //   }
  //   if (profile?.constant_fee) {
  //     setClearSum(profile?.constant_fee * reports.length);
  //   }
  //   if (!profile?.percent && !profile?.constant_fee) {
  //     setClearSum(reports.reduce((s, r) => s + r.final_price, 0));
  //   }
  // }, [profile, reports]);

  // const clearSumShowing = (profile) => {
  //   if (profile?.percent > 0) {
  //     return profile?.percent + "%";
  //   } else if (profile?.constant_fee) {
  //     return profile?.constant_fee + "c";
  //   } else {
  //     return 0;
  //   }
  // };

  useEffect(() => {
    setSums(reports && reports.reduce((s, r) => s + r.final_price, 0));
    setClearSum(reports && reports.reduce((s, r) => s + r.redemption_amount, 0));
    // clearSumCalc();
  }, [reports, profile]);

  const promiseClientOptions = (inputValue) => {
    return new Promise((resolve) => {
      fetchClientsSearh(inputValue).then(
        (res) =>
          res.ok &&
          res.json().then((d) => {
            resolve(
              d.map((cl) => {
                return {
                  value: cl.id,
                  label: cl.fullname + " " + cl.phone,
                  ...cl,
                };
              })
            );
          })
      );
    });
  };

  return (
    <CCard className="m-2">
      <CCardHeader className="pb-0">
        <CRow>
          <div className="mb-2 d-flex align-items-center justify-content-start">
            {t("From")}
            <CInput
              className="mx-2"
              type="date"
              placeholder={t("From")}
              name="fromDate"
              value={fromDate}
              onChange={(e) => {
                fromFunc(e);
              }}
            />
            {t("To")}
            <CInput
              className="ml-2"
              type="date"
              placeholder={t("To")}
              name="toDate"
              value={toDate}
              onChange={(e) => {
                toFunc(e);
              }}
            />
          </div>
          <div className="mw-50 justify-content-center">
            <CButton
              className={`ml-2 mb-2 ${activeBtn === "today" ? "active" : ""}`}
              type="button"
              color="dark"
              variant="outline"
              onClick={() => {
                currentReports("today");
              }}
            >
              {t("Today")}
            </CButton>
            <CButton
              className={`ml-2 mb-2 ${
                activeBtn === "yesterday" ? "active" : ""
              }`}
              type="button"
              color="dark"
              variant="outline"
              onClick={() => {
                currentReports("yesterday");
              }}
            >
              {t("Yesterday")}
            </CButton>
            <CButton
              className={`ml-2 mb-2 ${activeBtn === "week" ? "active" : ""}`}
              type="button"
              color="dark"
              variant="outline"
              onClick={() => {
                currentReports("week");
              }}
            >
              {t("Week")}
            </CButton>
            <CButton
              className={`mx-2 mb-2 ${activeBtn === "month" ? "active" : ""}`}
              type="button"
              color="dark"
              variant="outline"
              onClick={() => {
                currentReports("month");
              }}
            >
              {t("Month")}
            </CButton>
            <CButton
              className={`mr-2 mb-2 ${activeBtn === "first" ? "active" : ""}`}
              type="button"
              color="dark"
              variant="outline"
              onClick={() => reportsWithDates("first")}
            >
              {t("I")}
            </CButton>
            <CButton
              className={`mr-2 mb-2 ${activeBtn === "second" ? "active" : ""}`}
              type="button"
              color="dark"
              variant="outline"
              onClick={() => reportsWithDates("second")}
            >
              {t("II")}
            </CButton>
            <CButton
              className={`mr-2 mb-2 ${activeBtn === "third" ? "active" : ""}`}
              type="button"
              color="dark"
              variant="outline"
              onClick={() => reportsWithDates("third")}
            >
              {t("III")}
            </CButton>
            <CButton
              className={`mr-2 mb-2 ${activeBtn === "fourth" ? "active" : ""}`}
              type="button"
              color="dark"
              variant="outline"
              onClick={() => {
                reportsWithDates("fourth");
              }}
            >
              {t("IV")}
            </CButton>
          </div>

          <CCol className="px-1 col-md-4 col-sm-6 col-lg-2 mb-2">
            <Select
                value={selectedMonth}
                options={
                  months &&
                  [
                    {
                      name: tr("Выбрать месяц", "Айды тандоо"),
                      id: "allmonths",
                    },
                    ...months,
                  ].map((c) => {
                    return { value: c.id, label: c.name };
                  })
                }
                onChange={(e) => {
                  setSelectedMonth(e);
                  reportsWithDates(e.value);
                }}
            />
          </CCol>
          <CCol className="px-1 col-md-4 col-sm-6 col-lg-2 mb-2">
            <Select
                value={selectedYear}
                options={
                  yearsList &&
                  [
                    {
                      name: tr("Выбрать год", "Жылды тандоо"),
                      id: "allyears",
                    },
                    ...yearsList,
                  ].map((c) => {
                    return { value: c.id, label: c.name };
                  })
                }
                onChange={(e) => {
                  if (e.value === "allyears") {
                    setYear(new Date().getFullYear());
                    setSelectedYear(e);
                    delete params.date_from;
                    delete params.date_to;
                    setFromDate("");
                    setToDate("");
                  } else {
                    setYear(e.label);
                    setSelectedYear(e);
                    reportsWithDates(e.value, e.label);
                  }
                }}
            />
          </CCol>
          <div>
            <CButton
              type="button"
              color="danger"
              className="float-right"
              onClick={() => {
                setFromDate(new Date().toJSON().slice(0, 10));
                setToDate(new Date().toJSON().slice(0, 10));

                setParams({
                  date_from: new Date().toJSON().slice(0, 10),
                  date_to: new Date().toJSON().slice(0, 10),
                });
                setActiveBtn("today");
                setRedType({
                  value: 0,
                  label: tr("Тип выкупа", "Сатып алуу түрү"),
                });
                setOrdType({
                  value: 0,
                  label: tr("Тип заказа", "Буйрук түрү"),
                });
                setCourier({
                  value: 0,
                  label: tr("Выбрать курьера", "Курьерди тандоо"),
                });
                setSelectedMonth({
                  value: 0,
                  label: tr("Выбрать месяц", "Айды тандоо"),
                });
                setSelectedYear({
                  value: 0,
                  label: tr("Выбрать год", "Жылды тандоо"),
                });
                setSelectedOrg({
                  value: 0,
                  label:
                    i18n.language === "ru"
                      ? "Выбрать организацию"
                      : "Мекемени тандоо",
                });
                setSelectedClient(null);
                setSelectedRegion(null);
              }}
              variant="outline"
            >
              {t("Reset")}
            </CButton>
          </div>
        </CRow>

        <CRow>
          <CCol className="px-1 col-md-4 col-sm-6 col-lg-2 mb-2">
            <Select
              value={redType}
              options={redemptionTypes.map((r) => {
                return { value: r.id, label: tr(r.type, r.typekg) };
              })}
              onChange={(e) => {
                setRedType(e);
                redemptionParam(e);
              }}
            />
          </CCol>

          <CCol className="px-1 col-md-4 col-sm-6 col-lg-2 mb-2">
            <Select
              value={courier}
              options={
                couriers &&
                [
                  { firstname: tr("Все курьеры", "Баары"), id: 99999 },
                  ...couriers,
                ].map((c) => {
                  return { value: c.id, label: c.firstname };
                })
              }
              onChange={(e) => {
                setCourier(e);
                courierParam(e);
              }}
            />
          </CCol>

          <CCol className="px-1 col-md-4 col-sm-6 col-lg-2 mb-2">
            <Select
              value={selectedOrg}
              options={
                organizations &&
                [
                  { name: tr("Все организации", "Баары"), id: 99999 },
                  ...organizations,
                ].map((c) => {
                  return { value: c.id, label: c.name };
                })
              }
              onChange={(e) => {
                setSelectedOrg(e);
                organParam(e);
              }}
            />
          </CCol>
          <CCol className="px-1 col-md-4 col-sm-6 col-lg-2 mb-2">
            <AsyncSelect
              defaultOptions={clients.map((client) => {
                return { value: client.id, label: client.fullname, ...client };
              })}
              loadOptions={promiseClientOptions}
              isClearable
              placeholder={tr("Клиент", "Кардар")}
              value={selectedClient}
              onChange={(res) => {
                setSelectedClient(res);
                if (res?.id) {
                  setParams({ ...params, client_id: res.id });
                } else {
                  let p = params;
                  delete p.client_id;
                  setParams({ ...p });
                }
              }}
            />
          </CCol>
          <CCol className="px-1 col-md-4 col-sm-6 col-lg-2 mb-2">
            <Select
              value={ordType}
              options={orderTypes.map((r) => {
                return { value: r.id, label: tr(r.type, r.typekg) };
              })}
              onChange={(e) => {
                setOrdType(e);
                orderTypeParam(e);
              }}
            />
          </CCol>
          <CCol className="px-1 col-md-4 col-sm-6 col-lg-2 mb-2">
            <Select
              value={selectedRegion}
              options={
                regions &&
                [
                  {
                    name_ru: "Все регионы",
                    name_kg: "Баары",
                    id: "allRegions",
                  },
                  ...regions,
                ].map((r) => {
                  return { value: r.id, label: tr(r.name_ru, r.name_kg), ...r };
                })
              }
              onChange={(e) => {
                setSelectedRegion(e);
                if (e.id === "allRegions") {
                  let p = params;
                  delete p.region_id;
                  setParams({ ...p });
                } else {
                  setParams({ ...params, region_id: e.id });
                }
              }}
            />
          </CCol>
        </CRow>
        <CRow className="mt-2">
          <CCol className="col-3">
            <h5>
              {tr("Количество заказов: ", "Буйрутмалардын саны: ") +
                `${reports.length < 1 ? 0 : reports.length} `}
            </h5>
          </CCol>
          <CCol className="col-3">
            <h5>{tr("За доставку: ", "Жеткирүү үчүн: ") + Math.floor(sums)} <u>c</u></h5>
          </CCol>
          <CCol className="col-3">
            <h5>
              {tr("Сумма выкупов: ", "Сатып алуу суммасы: ") + Math.floor(clearSum)} <u>c</u>
            </h5>
          </CCol>
          <CCol className="col-3 d-flex justify-content-end align-items-center">
            <ReactHTMLTableToExcel
              className="btn sp-report"
              table="excel-reports"
              filename="Report"
              sheet="Sheet"
              // buttonText={t('Report')}
              buttonText=""
            />
            Отчёт
            <ReactHTMLTableToExcel
              className="btn sp-act"
              table="excel-act"
              filename="Act"
              sheet="Sheet"
              buttonText=""
              // buttonText={t('Act')}
            />
            Акт
          </CCol>
        </CRow>
      </CCardHeader>

      <CCardBody className="py-0">
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <MiniSpinner />
          </div>
        ) : (
          <>
            <ReportsTable forDownload={false} reports={reports} setSelectedOrder={setSelectedOrder} onToggle={onToggle} dateParse={dateParse} dateDifference={dateDifference}/>
            <div className="d-none">
              <ReportsTable forDownload={true} reports={reports} setSelectedOrder={setSelectedOrder} onToggle={onToggle} dateParse={dateParse} dateDifference={dateDifference}/>
            </div>
          </>
        )}

        {/* акт */}
        <table
          style={{ display: "none" }}
          className="table-bordered hover"
          id="excel-act"
        >
          <thead>
            <tr>
              <th colSpan="9" className="text-center">
                Приложение №2 <br />
                <br /> к Договору №1 на оказание услуг по доставке от{" "}
                {new Date(organization?.contract_date).toLocaleDateString(
                  "ru-RU",
                  {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  }
                )}{" "}
                <br /> АКТ ВЫПОЛНЕННЫХ РАБОТ
                <br />
                За период с{" "}
                {new Date(fromDate).toLocaleDateString("ru-RU", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}{" "}
                по{" "}
                {new Date(toDate).toLocaleDateString("ru-RU", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
                <br />
                между {organization?.name} и {profile?.name}
                <br />
                <br />
              </th>
            </tr>
            <tr>
              <th colSpan="4" className="text-left">
                г.Бишкек
              </th>
              <th colSpan="5" className="text-right">
                {new Date().toLocaleDateString("ru-RU", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </th>
            </tr>
            <tr>
              <th className="px-1" style={{ width: "3%" }}>
                {t("№")}
              </th>
              <th className="px-1" style={{ width: "4%" }}>
                {t("Order number")}
              </th>
              <th className="px-1" style={{ width: "20%" }}>
                {t("From Address")}
              </th>
              <th className="px-1" style={{ width: "20%" }}>
                {t("To Address")}
              </th>
              <th className="px-1" style={{ width: "10%" }}>
                {t("Comments")}
              </th>
              <th className="px-1" style={{ width: "10%" }}>
                {t("Created")}
              </th>
              <th className="px-1" style={{ width: "10%" }}>
                {t("Delivered")}
              </th>
              <th className="px-1" style={{ width: "10%" }}>
                <CIcon name="cilClock"/>
              </th>
              <th className="px-1" style={{ width: "7%" }}>
                {t("км")}
              </th>
              <th className="px-1" style={{ width: "10%" }}>
                {t("Courier")}
              </th>
              <th className="px-1" style={{ width: "5%" }}>
                {t("Price")}
              </th>
            </tr>
          </thead>
          <tbody>
            {reports &&
              reports.map((r, i) => {
                return (
                  <tr key={r.id}>
                    <td className="px-1">{i + 1}</td>
                    <td className="px-1">{r.id}</td>
                    <td className="px-1">
                      {r.sender?.fullname},{" "}
                      {tr(r.location_from?.name_ru, r.location_from?.name_kg)}
                    </td>
                    <td className="px-1">
                      {r.receiver?.fullname},{" "}
                      {tr(r.location_to?.name_ru, r.location_to?.name_kg)}
                    </td>
                    <td className="px-1">{r.description}</td>
                    <td className="px-1">{dateParse(r.created_at)}</td>
                    <td className="px-1">{dateParse(r.delivery_end_time)}</td>
                    <td className="px-1">{dateDifference(r.created_at, r.delivery_end_time)}</td>
                    <td className="px-1">{r.distance / 1000}</td>
                    <td className="px-1">
                      {r.courier ? r.courier.user.lastname : ""}{" "}
                      {r.courier ? r.courier.user.firstname : ""}
                    </td>
                    <td className="px-1">{r.final_price}</td>
                  </tr>
                );
              })}
            <tr>
              <th colSpan="9" className="text-right">
                Итого: {sums}
              </th>
            </tr>
            <tr>
              <th colSpan="4" className="text-left">
                Клиет: {organization?.name}
              </th>
              <th colSpan="5" className="text-right">
                Исполнитель: {profile?.name}
              </th>
            </tr>
          </tbody>
        </table>
      </CCardBody>
      {selectedOrder && (
        <OrderDetails
          isOpen={isOpen}
          onToggle={onToggle}
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
        />
      )}
    </CCard>
  );
}
