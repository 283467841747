import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCol,
  CListGroup,
  CListGroupItem,
  CRow,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import MiniSpinner from "src/components/spinners/MiniSpinner";
import { sideBarPages } from "../helpers/constants";
import CourierContractTab from "./CourierContractTab";
import PassportDataTab from "./PassportDataTab";
import PersonalDataTab from "./PersonalDataTab";
import TransportAndRoutesTab from "./TransportAndRoutesTab";
import useCourierFormPage from "./useCourierFormPage";
import useSelectedCourier from "./useSelectedCourier";

export default function CourierFormPage() {
  const { t } = useTranslation();
  const { selectedCourier, loading } = useSelectedCourier();
  const {
    btnState,
    initForm,
    office,
    city,
    district,
    selectedTransport,
    cities,
    offices,
    transports,
    districts,
    routes,
    route,
    roles,
    error,
    fieldChecked,
    activePage,
    setActivePage,
    setSelectedTransport,
    setDistrict,
    setRoles,
    setOffice,
    setRoute,
    onGoToNextPage,
    handleInputChange,
    handleUserNameChange,
    handleCityChange,
    updateField,
    updateFieldCheck,
    onUploadPhotoSuccess,
  } = useCourierFormPage({ selectedCourier });
  return (
    <div>
      {loading ? (
        <div className="text-center">
          <MiniSpinner />
        </div>
      ) : (
        <CCard className="m-3">
          <CCardBody>
            {error && <CAlert color="danger">{error}</CAlert>}
            <CRow>
              <CCol xs={4} className="border-right">
                <CListGroup>
                  {sideBarPages.map((page) => (
                    <CListGroupItem
                      key={page.id}
                      style={{ cursor: "pointer" }}
                      active={activePage === page.id}
                      onClick={() => setActivePage(page.id)}
                    >
                      {page.title}
                    </CListGroupItem>
                  ))}
                </CListGroup>
              </CCol>
              <CCol xs={8} className="border-left">
                {activePage === sideBarPages[0].id && (
                  <>
                    <PersonalDataTab
                      initForm={initForm}
                      updateField={updateField}
                      handleUserNameChange={handleUserNameChange}
                      handleInputChange={handleInputChange}
                      fieldChecked={fieldChecked}
                      onUploadPhotoSuccess={onUploadPhotoSuccess}
                    />
                  </>
                )}
                {activePage === sideBarPages[1].id && (
                  <>
                    <PassportDataTab
                      updateField={updateField}
                      updateFieldCheck={updateFieldCheck}
                      fieldChecked={fieldChecked}
                      initForm={initForm}
                      cities={cities}
                      handleCityChange={handleCityChange}
                      city={city}
                      district={district}
                      districts={districts}
                      setDistrict={setDistrict}
                      onUploadPhotoSuccess={onUploadPhotoSuccess}
                    />
                  </>
                )}
                {activePage === sideBarPages[2].id && (
                  <>
                    <TransportAndRoutesTab
                      updateField={updateField}
                      updateFieldCheck={updateFieldCheck}
                      fieldChecked={fieldChecked}
                      //   selectedCourier={selectedCourier}
                      transports={transports}
                      routes={routes}
                      onUploadPhotoSuccess={onUploadPhotoSuccess}
                      initForm={initForm}
                      selectedTransport={selectedTransport}
                      setSelectedTransport={setSelectedTransport}
                      setRoute={setRoute}
                      route={route}
                    />
                  </>
                )}
                {activePage === sideBarPages[3].id && (
                  <>
                    <CourierContractTab
                      updateField={updateField}
                      updateFieldCheck={updateFieldCheck}
                      fieldChecked={fieldChecked}
                      //   selectedCourier={selectedCourier}
                      offices={offices}
                      onUploadPhotoSuccess={onUploadPhotoSuccess}
                      initForm={initForm}
                      setOffice={setOffice}
                      office={office}
                      setRoles={setRoles}
                      roles={roles}
                    />
                  </>
                )}
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter className="d-flex justify-content-end">
            <CButton
              className="btn-outline-primary"
              disabled={btnState}
              onClick={(e) => onGoToNextPage(e)}
            >
              {btnState ? (
                <MiniSpinner />
              ) : activePage === sideBarPages[3].id ? (
                selectedCourier?.id ? (
                  t("Update")
                ) : (
                  t("Create")
                )
              ) : (
                t("Next")
              )}
            </CButton>
          </CCardFooter>
        </CCard>
      )}
    </div>
  );
}
