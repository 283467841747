import { CCol, CImg, CRow } from "@coreui/react";
import { useTranslate } from "src/helpers/useTranslate";

export default function ProfileTab({ courier }) {
  const { tr } = useTranslate();
  return (
    <div className="m-3">
      {courier && (
        <CRow>
          <CCol sm="6">
            <TextField
              label={"Имя пользователя"}
              value={courier.account_number}
            />
            <TextField label={"Номер телефона"} value={courier.username} />
            <TextField label={"Имя"} value={courier.firstname} />
            <TextField label={"Фамилия"} value={courier.lastname} />
            <TextField label={"Номер машины"} value={courier.auto_number} />
            <TextField label={"Статус"} value={courier.status} />
            <TextField label={"Баланс"} value={courier.balance} />
            <TextField label={"Роли"} value={courier.roles?.toString()} />
            <TextField
              label={"Маршруты"}
              value={courier.routes
                ?.map((route) => tr(route.name_ru, route.name_kg))
                ?.toString()}
            />
          </CCol>
          <CCol sm="6">
            <div
              style={{
                width: "95px",
                height: "95px",
                borderRadius: "50%",
              }}
            >
              <img
                src={courier.photo}
                alt="phot"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            </div>
            <CImg
              src={courier.passport_front}
              thumbnail
              height={100}
              width={100}
            />
            <CImg
              src={courier.passport_back}
              thumbnail
              height={100}
              width={100}
            />
          </CCol>
        </CRow>
      )}
    </div>
  );
}

function TextField({ label, value }) {
  return (
    <CRow>
      <CCol xs="6">
        <b>{label}:</b>
      </CCol>
      <CCol xs="6">{value}</CCol>
    </CRow>
  );
}
