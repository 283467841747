import {
  OPERATOR_SUCCESS,
  OPERATOR_LOADING,
  OPERATOR_FAIL,
} from "../types/operatorTypes";

import { fetchOperators } from "../../services/OperatorService";

export const getOperators = () => async (dispatch, getState) => {
  dispatch(operatorLoading());

  fetchOperators()
    .then((res) =>
      {
        if(res.ok) {
          res.json().then((data) => {
            console.log(data);
            dispatch(operatorSuccess(data));
          })
        } else {
          dispatch(operatorFail(res.statusText))
        }

      }
    )
    .catch((err) => {
      dispatch(operatorFail(err));
    });
};

export const operatorLoading = () => ({ type: OPERATOR_LOADING });

export const operatorSuccess = (operators) => ({
  type: OPERATOR_SUCCESS,
  payload: operators,
});
export const operatorFail = (error) => ({ type: OPERATOR_FAIL, payload: error });
