import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import { useTranslation } from "react-i18next";
import paid from 'src/assets/paid.svg'
import not_paid from 'src/assets/not_paid.svg'
import { useTranslate } from "src/helpers/useTranslate";
import { paymentList } from "src/helpers/paymentsDict";

const paymentFields = {
    sender: (obj) => {
        let type;
        if(obj.sender_payment_type === "invoice") {
            type = "Invoice"
          } else if(obj.sender_payment_type === "service") {
            type = "Instant"
          }
        return {
            label: "Sender",
            paymentType: type,
            amount: obj.sender_payment_amount,
            method: paymentList.filter(payment => payment.value === obj.sender_payment_method)[0]?.label,
            is_paid: obj.sender_payment_is_paid
        }
    },
    receiver: (obj) => {
        let type;
        if(obj.receiver_payment_type === "invoice") {
            type = "Invoice"
          } else if(obj.receiver_payment_type === "service") {
            type = "Instant"
          }
        return {
            label: "Receiver",
            paymentType: type,
            amount: obj.receiver_payment_amount,
            method: paymentList.filter(payment => payment.value === obj.receiver_payment_method)[0]?.label,
            is_paid: obj.receiver_payment_is_paid
        }
    },
}

const PayerRow = ({payer, order, t, both}) => {
    return (
        <>
            {both && <b><span className="mr-1">{t(paymentFields[payer](order)?.label)}: </span></b>}
            <span className="mx-1">{t(paymentFields[payer](order).paymentType)}</span>
            <span className="mx-1">{t(paymentFields[payer](order).method)}</span>
            <span className="mx-1">{t(paymentFields[payer](order).amount)} ⊆</span>
            <span className="mx-1">
                <IsPaid status={paymentFields[payer](order).is_paid}/>
            </span>
        </> 
    )
}

const IsPaid = ({status}) => {
    return (
        <img alt="status" className="mx-1" src={status ? paid : not_paid} />
    )
}

export default function PaymentsCard({orderDetails}) {
    const { t } = useTranslation()
    const { tr } = useTranslate()

    return (
        <CCard>
            <CCardBody>
                <h6><b>{t("Payments")}</b></h6>
                <hr />
                <CRow>
                <CCol sm="8">
                    <div>
                    <b>{t("For delivery")}: </b>
                    <i className="text-danger">{orderDetails.final_price} ⊆</i>
                    {orderDetails.paying_side === "jointly" && <IsPaid status={orderDetails.is_paid}/>}
                    </div>
                    {orderDetails.paying_side === "jointly" ? "" : 
                        <div>
                            <b>{t("Payment type")}: </b>
                            {t(paymentFields[orderDetails.paying_side](orderDetails).paymentType)}
                        </div>
                    }
                    <div>
                        <b>{t("Pays")}: </b> 
                        {
                            orderDetails.paying_side === "jointly" ? tr("Совместно", "Бөлүп төлөшөт") 
                            : 
                            t(paymentFields[orderDetails.paying_side](orderDetails)?.label)
                        }
                    </div>
                    
                    {orderDetails.paying_side === "jointly" ? 
                        <div>
                            <PayerRow order={orderDetails} payer="sender" t={t} both/>
                            <br />
                            <PayerRow order={orderDetails} payer="receiver" t={t} both/>
                        </div> : 
                        <div>
                            <PayerRow order={orderDetails} payer={orderDetails.paying_side} t={t}/>
                        </div>
                    }
                    <hr/>
                    {orderDetails.redemption_amount > 0 && 
                    <>
                    <div>
                        <b>{t("Redemption")}: </b> {orderDetails.redemption_amount} ⊆
                    </div>
                    <div>
                        <b>{t("Статус выкупа")}: </b> 
                        {orderDetails.redemption_payment_type === "imposed" ? t("Imposed") : t("From cash register")} 
                        ({orderDetails.redemption_is_paid ? t("Paid") : t("Not paid")}) 
                    </div>
                    </>}
                </CCol>
                <CCol sm="4" className="text-right">
                    <b>{t("Extra fee")}</b>
                    <p>
                    {t("redemption fee")}: <span className="ml-2">{orderDetails.extra_fees.redemption_fee || 0}</span><br />
                    {t("weight fee")}: <span className="ml-2">{orderDetails.extra_fees.weight_fee || 0}</span><br />
                    {t("zone fee")}: <span className="ml-2">{orderDetails.extra_fees.zone_fee || 0}</span>
                    </p>
                </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    )
}