import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchCourier } from "src/services/CourierService";

export default function useSelectedCourier() {
  const params = useParams();

  const [selectedCourier, setSelectedCourier] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (params.id) {
      setLoading(true);
      fetchCourier(params.id)
        .then((res) => {
          res.ok && res.json().then((d) => setSelectedCourier(d));
        })
        .finally(() => setLoading(false));
    }

    return () => {
      setLoading(false);
      setSelectedCourier(null);
    };
  }, [params.id]);

  return { selectedCourier, loading };
}
