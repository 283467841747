import {
  ADDRESSES_LOADING,
  ADDRESSES_SUCCESS,
  ADDRESSES_FAIL,
  PACKAGESTYPES_LOADING,
  PACKAGESTYPES_SUCCESS,
  PACKAGESTYPES_FAIL,
} from "../types/addressTypes";

import {
  fetchCompanyPackageTypes,
  fetchCompanyAddresses,
  // fetchCompanyAddressesOwn,
} from "../../services/CompanyService";

export const getAddresses = () => async (dispatch, getState) => {
  dispatch(addressesLoading());

  fetchCompanyAddresses()
    .then((res) => 
    {
      if(res.ok) {
        res.json().then((data) => {
          dispatch(addressesSuccess(data));
        })
      } else {
        dispatch(addressesFail(res.statusText))
      }
    }).catch((err) => dispatch(addressesFail(err)));
};

// export const getAddressesOwn = () => async (dispatch, getState) => {
//   dispatch(addressesLoading());

//   fetchCompanyAddressesOwn()
//     .then((res) => 
//     {
//       if(res.ok) {
//         res.json().then((data) => {
//           console.log(data);
//           dispatch(addressesSuccess(data));
//         })
//       } else {
//         dispatch(addressesFail(res.statusText))
//       }
//     }).catch((err) => dispatch(addressesFail(err)));
// };


export const getPackagesTypes = () => async (dispatch, getState) => {
  dispatch(packagesTypesLoading());

  fetchCompanyPackageTypes()
    .then((res) => 
    {
      if(res.ok) {
        res.json().then((data) => {
          dispatch(packagesTypesSuccess(data));
        })
      } else {
        dispatch(packagesTypesFail(res.statusText))
      }
    })
    .catch((err) => dispatch(packagesTypesFail(err)));
};

export const addressesLoading = () => ({ type: ADDRESSES_LOADING });
export const addressesSuccess = (addresses) => ({
  type: ADDRESSES_SUCCESS,
  addresses,
});
export const addressesFail = (err) => ({ type: ADDRESSES_FAIL, payload: err });


export const packagesTypesLoading = () => ({ type: PACKAGESTYPES_LOADING });
export const packagesTypesSuccess = (packagesTypes) => ({
  type: PACKAGESTYPES_SUCCESS,
  packagesTypes: packagesTypes,
});
export const packagesTypesFail = (err) => ({
  type: PACKAGESTYPES_FAIL,
  payload: err,
});
