import {
    EXTRA_FEE_SUCCESS,
    EXTRA_FEE_LOADING,
    EXTRA_FEE_FAIL
  } from '../types/extraFeeTypes'
  
  const initialState = {
    fees: [],
    error: null,
    isLoading: true
  }
  
  export default function extraFeeReducer(state = initialState, action) {
    switch (action.type) {
      case EXTRA_FEE_LOADING:
        return {
          ...state,
          isLoading: true,
          error: null
        }
      case EXTRA_FEE_SUCCESS:
        return {
          ...state,
          fees: action.payload,
          isLoading: false,
          error: null
        }
      case EXTRA_FEE_FAIL:
        return {
          ...state,
          fees: [],
          isLoading: false,
          error: action.payload || null
        }
      default:
        return {
          ...state
        }
    }
  }
  