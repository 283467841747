import {
    SET, SETBREADCRUMB, 
    SET_CONNECTION_STATUS, 
    SET_ATS_CARD, 
    SET_CALLING_CLIENT, 
    SET_SOUND,
    SET_SEARCH
} from "../types/settingsTypes"

export const set = sidebarShow => ({ type: SET, payload: sidebarShow })

export const setBreadcrumb = title => ({ type: SETBREADCRUMB, payload: title })
export const setConectionStatus = status => ({ type: SET_CONNECTION_STATUS, payload: status })
export const setCallingClient = status => ({ type: SET_CALLING_CLIENT, payload: status })
export const setAtsCard = status => ({ type: SET_ATS_CARD, payload: status })
export const setSound = status => ({ type: SET_SOUND, payload: status })
export const setSearch = key => ({ type: SET_SEARCH, payload: key })
