import React, {useCallback, useState} from 'react';
import {CRow, CCol, CInput} from "@coreui/react"
import {useSelector} from 'react-redux'
import { useTranslation } from 'react-i18next';
import { useTranslate } from 'src/helpers/useTranslate';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { fetchClientsSearh } from 'src/services/ClientService';
import FilterButton from 'src/components/FilterButton';
import { archiveDateFilters } from 'src/helpers/date';


const orderTypeOptions = [
    {
        name: "All",
        value: undefined,
    },
    {
        name: "regional",
        value: "regional",
    },
    {
        name: "intra_city",
        value: "intra_city",
    }
]
const isRedemptionOptions = [
    {
        name: "All",
        value: undefined,
    },
    {
        name: "With redemption",
        value: "true",
    },
    {
        name: "Without redemption",
        value: "false",
    }
]

export default function FilterBar({statuses, regions, organizations, applyFilters, filterParams}){
    const [selectedStatusList, setSelectedStatusList] = useState([])
    const [selectedFilters, setSelectedFilters] = useState({})

    const { t } = useTranslation();
    const { tr } = useTranslate();
    
    const couriers = useSelector(state => state.courier.couriers);

    const statusSelectChange = useCallback((list) => {
        setSelectedStatusList(list)
        const newParams = {...filterParams, status: list.map(l => l.value)}
        applyFilters(newParams)
    }, [filterParams, applyFilters])

    const filterOrdersByDate = useCallback((date_from, date_to, name) => {
        const newParams = {...filterParams, date_from: date_from, date_to: date_to, dateName: name}
        applyFilters(newParams);
    }, [filterParams, applyFilters]);
    
    const courierListCompose = useCallback((list) => {
        return list.map(l => ({value: l.id, label: l.firstname, ...l}))
    }, [])

    const regionlistCompose = useCallback((list) => {
        return list.map(l => ({value: l.id, label: tr(l.name_ru, l.name_kg), ...l}))
    }, [tr])

    const organizationlistCompose = useCallback((list) => {
        return list.map(l => ({value: l.id, label: l.name, ...l}))
    }, [])

    const statusListCompose = useCallback((list) => {
        return list.map(l => ({value: l.value, label: tr(l.name_ru, l.name_kg), ...l}))
    }, [tr])

    const handleFilterChange = useCallback((filterName, selectedOption) => {
        setSelectedFilters({...selectedFilters, [filterName]: selectedOption})
        const newParams = {...filterParams, [filterName]: selectedOption?.value}
        applyFilters(newParams);
    }, [selectedFilters, filterParams, applyFilters])


    const promiseClientOptions = (inputValue) => {
        return new Promise(resolve => {
            fetchClientsSearh(inputValue).then(res => 
                res.ok && res.json().then(d => {
                    resolve(d.map(cl => 
                        ({ value: cl.id,label: cl.fullname + " " + cl.phone, ...cl})
                    ))
                })
            )
        });
    }

    return (
        <div className='px-2'>
            <CRow>
                <CCol className="py-1" sm="6">
                    <div style={{display: "inline"}}>
                        {archiveDateFilters.map((df, i) => 
                            <FilterButton
                                key={'dat' + i}
                                onClick={() => 
                                    filterOrdersByDate(df.date_from.toISOString().slice(0, 10), df.date_to.toISOString().slice(0, 10), df.name)}
                                borderColor={'dark'}
                                isActive={filterParams.dateName === df.name}
                                text={t(df.name)}
                            />
                        )}
                    </div>
                </CCol>
                <CCol className="py-1" sm="6">
                    <CRow>
                        <CCol>
                            <CInput type="date" 
                                onChange={e => filterOrdersByDate(e.target.value, filterParams.date_to)} 
                                value={filterParams?.date_from}
                            />
                        </CCol>
                        <CCol>
                            <CInput type="date" 
                                onChange={e => filterOrdersByDate(filterParams.date_from, e.target.value)} 
                                value={filterParams?.date_to}
                            />
                        </CCol>
                    </CRow>
                </CCol>
                <CCol className="py-1" sm='3'>
                    <Select 
                        // isMulti
                        isClearable
                        options={courierListCompose(couriers)}  
                        onChange={res => handleFilterChange('courier_id', res)} 
                        value={selectedFilters.courier_id}
                        placeholder={t("Courier")}
                    />
                </CCol>
                <CCol className="py-1" sm='3'>
                    <Select
                        isClearable
                        options={orderTypeOptions.map(o => ({label: t(o.name), ...o}))}  
                        onChange={res => handleFilterChange('order_type', res)} 
                        value={selectedFilters.order_type}
                        placeholder={t("Order Type")}
                    />
                </CCol>
                <CCol className="py-1" sm='3'>
                    <Select 
                        isMulti
                        isClearable
                        options={statusListCompose(statuses)}  
                        onChange={res => statusSelectChange(res)} 
                        value={statusListCompose(selectedStatusList)}
                        placeholder={t("Status")}
                    />
                </CCol>
                <CCol className="py-1 d-none" sm='3'>
                    <Select
                        isClearable
                        options={isRedemptionOptions.map(o => ({label: t(o.name), ...o}))}
                        onChange={res => handleFilterChange('redemption', res)} 
                        value={selectedFilters.redemption}
                        placeholder={t("Redemption")}
                    />
                </CCol>
                <CCol className="py-1" sm='3'>
                    <Select
                        isClearable
                        options={regionlistCompose(regions)}  
                        onChange={res => handleFilterChange('region_id', res)} 
                        value={selectedFilters.region_id}
                        placeholder={t("Region")}
                    />
                </CCol>
                <CCol className="py-1" sm='3'>
                    <Select
                        isClearable
                        options={organizationlistCompose(organizations)}  
                        onChange={res => handleFilterChange('organization_id', res)} 
                        value={selectedFilters.organization_id}
                        placeholder={t("Organization")}
                    />
                </CCol>
                <CCol className="py-1" sm='3'>
                    <AsyncSelect
                        isClearable
                        loadOptions={res => promiseClientOptions(res)}
                        onChange={res => handleFilterChange('client_id', res)} 
                        value={selectedFilters.client_id}
                        placeholder={t("Client")}
                    />
                </CCol>
            </CRow>
        </div>
    )
}

