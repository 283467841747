import {
  CLIENT_SUCCESS,
  CLIENT_LOADING,
  CLIENT_FAIL,
  CLIENT_ORDERS_LOADING,
  CLIENT_ORDERS_SUCCESS,
  CLIENT_ORDERS_FAIL
} from "../types/clientTypes";

const initialState = {
  clients: [],
  isLoading: true,
  error: null,
  clientOrders: []
};

export default function clientReducer(state = initialState, action) {
  switch (action.type) {
    case CLIENT_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case CLIENT_SUCCESS:
      return {
        ...state,
        clients: action.payload,
        isLoading: false,
        error: null,
      };
    case CLIENT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload || null,
      };
    case CLIENT_ORDERS_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case CLIENT_ORDERS_SUCCESS:
      return {
        ...state,
        clientOrders: action.payload,
        isLoading: false,
        error: null,
      };
    case CLIENT_ORDERS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload || null,
      };
    default:
      return {
        ...state,
      };
  }
}
