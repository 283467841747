import {
  TRANSPORT_SUCCESS,
  TRANSPORT_LOADING,
  TRANSPORT_FAIL,
} from "../types/transportTypes";

import { fetchTransports } from "../../services/TransportService";

export const getTransports = () => async (dispatch, getState) => {
  dispatch(transportLoading());

  fetchTransports()
    .then((res) =>
      {
        if(res.ok) {
          res.json().then((data) => {
            dispatch(transportSuccess(data));
          })
        } else {
          dispatch(transportFail(res.statusText))
        }

      }
    )
    .catch((err) => {
      dispatch(transportFail(err));
    });
};

export const transportLoading = () => ({ type: TRANSPORT_LOADING });
export const transportSuccess = (transports) => ({
  type: TRANSPORT_SUCCESS,
  payload: transports,
});
export const transportFail = (error) => ({ type: TRANSPORT_FAIL, payload: error });
