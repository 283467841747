import {
    CForm,
    CLabel,
    CButton,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CInput,
    CSelect,
    CRow,
    CCol,
    CTabs,
    CNavLink,
    CTabContent,
    CTabPane,
    CNav,
    CNavItem,
  } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useTranslate } from "src/helpers/useTranslate";
import { getCities, getDistricts } from "src/redux/actions/locationActions";
import { toastify } from "src/helpers/toast";
import { fetchAdmins, fetchCreateCompanies, fetchUpdateCompanies } from "src/services/CompanyService";
import { fetchCreateImg } from "src/services/ServerService";
  
  const defaultFields = {
    name: "",
    logo: "",
    navbar_logo: "",
    mini_logo: "",
    status: "demo",
    identifier: "",
    balance: 0,
    percent: 0,
    constant_fee: 0,
    shift_fee: 0,
    admin_phone: "",
    admin_id: undefined,
    send_sms: false,
    sms_balance: 0,
    in_pool: false,
    sms_login: "",
    sms_password: "",
    sms_sender_name: "",
    package_type_id: null,
    pbx_login: "",
    pbx_password: ""
  }
  export default function CompanyForm(props) {
  
    const dispatch = useDispatch();
  
    const { t } = useTranslation();
    const { tr } = useTranslate();
  
    const cities = useSelector(state => state.locations.cities)
    const districts = useSelector(state => state.locations.districts)
    const packagesTypes = useSelector(state => state.addresses.packagesTypes);

  
    const [companyForm, setCompanyForm] = useState(defaultFields)

    const [district, setDistrict] = useState("");
    const [packageType, setPackageType] = useState(null);
    const [city, setCity] = useState("");

    const [adminList, setAdminList] = useState([])
    const [activeTab, setActiveTab] = useState("main");

    useEffect(() => {
      dispatch(getCities());
      fetchAdmins().then(res => {
        res.ok && res.json().then(data => {
          setAdminList(data)
        })
      })
      if(props.selectedCompany) {
        console.log(props.selectedCompany)
        setCompanyForm({...props.selectedCompany})
          if(props.selectedCompany.city) {
              setCity({value: props.selectedCompany.city.id, label: props.selectedCompany.city.name_kg})
          }
          if(props.selectedCompany.district) {
              setDistrict({value: props.selectedCompany.district.id, label: props.selectedCompany.district.name_kg})
          }
          if(props.selectedCompany.package_type) {
              setPackageType({value: props.selectedCompany.package_type.id, label: props.selectedCompany.package_type.name_kg})
          }
      } else {
        setCompanyForm(defaultFields)
      }
    }, [dispatch, props.selectedCompany]);
  
    const handleCityChange = (city) => {
      setCompanyForm({...companyForm, city_id: city.id});
      setCity(city);
      dispatch(getDistricts(city.id));
    }

    const handleChange = e => {
      setCompanyForm({...companyForm, [e.target.name]: e.target.value})
    }

    const sendPhoto = (image) => {
      let formData = new FormData();
      formData.append("image", image);
      return fetchCreateImg(formData) 
    }

    const handleUpload = (e) => {
      sendPhoto(e.target.files[0]).then(res => {
          if(e.target.name === "logo") {
            setCompanyForm({...companyForm, logo: res.data})
          } else if (e.target.name === "navbar_logo") {
            setCompanyForm({...companyForm, navbar_logo: res.data})
          } else if (e.target.name === "mini_logo") {
            setCompanyForm({...companyForm, mini_logo: res.data})
          }
      })
    }
  
    const submitForm = () => {
        (props.selectedCompany ? fetchUpdateCompanies(companyForm, props.selectedCompany.id) : fetchCreateCompanies(companyForm)).then(res => {
          if (res.ok) {
            toastify("success", t("Operation succeeded"));
            props.onSuccess();
          } else {
            toastify("error", t("Operation failed"))
          }
        }).catch(err => console.log(err));
    }
  
    return (
        <CModal show={props.isOpen} onClose={props.onToggle} size="lg">
          <CModalHeader closeButton>{props.modalTitle}</CModalHeader>
          <CModalBody>
            {companyForm && <CForm>
            <CTabs activeTab={activeTab}>
              <CNav variant="tabs">
                <CNavItem>
                  <CNavLink data-tab="main" onClick={() => setActiveTab("main")}>
                    {t("Main")}
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink data-tab="sms" onClick={() => setActiveTab("sms")}>
                    {t("SMS credentials")}
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink data-tab="ats" onClick={() => setActiveTab("ats")}>
                    {t("ATS credentials")}
                  </CNavLink>
                </CNavItem>
              </CNav>
                
              <CTabContent>
                <CTabPane data-tab="main" className="p-2">     
                  <CRow>
                    <CCol sm="6">
                      <CLabel>{t("Name")}</CLabel>
                      <CInput placeholder={t("Name")} value={companyForm.name || ""} onChange={handleChange} name="name" />
                      <CLabel>{t("Sidebar Logo")}</CLabel>
                      <CInput type="file" onChange={e => handleUpload(e)} name="logo" />
                      <CLabel>{t("Navbar Logo")}</CLabel>
                      <CInput type="file" onChange={e => handleUpload(e)} name="navbar_logo" />
                      <CLabel>{t("Mini Logo")}</CLabel>
                      <CInput type="file" onChange={e => handleUpload(e)} name="mini_logo" />
      
      
                      <CLabel>{t("Percent")}</CLabel>
                      <CInput placeholder="percent" type="number" onChange={handleChange} value={companyForm.percent || ""} name="percent" />
                      <CLabel>{t("Constant fee")}</CLabel>
                      <CInput placeholder="field" type="number" onChange={handleChange} value={companyForm.constant_fee || ""} name="constant_fee" />
                      <CLabel>{t("Shift fee")}</CLabel>
                      <CInput placeholder="field" type="number" onChange={handleChange} value={companyForm.shift_fee || ""} name="shift_fee" />
                      <CLabel>{t("Identifier")}</CLabel>
                      <CInput placeholder="identifier" type="text" onChange={handleChange} value={companyForm.identifier || ""} name="identifier"/>
                    </CCol>
      
                    <CCol sm="6">
                    <CLabel>{t("Status")}</CLabel>
                      <CSelect onChange={handleChange} value={companyForm.status || ""} name="status">
                        <option value="demo">{t("Demo")}</option>
                        <option value="working">{t("Working")}</option>
                        <option value="not working">{t("Not working")}</option>
                      </CSelect>
                      
                      {props.selectedCompany && <>
                        <CLabel>{t("Admin")}</CLabel>
                        <CSelect onChange={handleChange} value={companyForm.admin_id || ""} name="admin_id">
                          {adminList.map((admin, i) => 
                            <option value={admin.id} key={i}>{admin.firstname + " " + admin.lastname}</option>)}
                        </CSelect>
                      </>}
                      <CLabel>{t("Admin phone")}</CLabel>
                      <CInput placeholder={t("Admin phone")} onChange={handleChange} value={companyForm.admin_phone || ""} name="admin_phone"/>
      
                      <CLabel>{t("City")}</CLabel>
                      <Select 
                        onChange={e => handleCityChange(e)} 
                        isClearable
                        value={city}
                        options={cities && cities.map(c => {
                          return {
                            value: c.id,
                            label: tr(c.name_ru, c.name_kg),
                            ...c
                          }
                        })}
                      />
                      <CLabel>{t("District")}</CLabel>
                      <Select 
                        onChange={e => { setCompanyForm({...companyForm, district_id: e.id}); setDistrict(e) }} 
                        isClearable
                        value={district}
                        options={districts.map(d => {
                          return {
                            value: d.id,
                            label: tr(d.name_ru, d.name_kg),
                            ...d
                          }
                        })}
                      />
                      <CLabel>{t("Тип посылки (по умолчанию")}</CLabel>
                      <Select 
                        onChange={e => { setCompanyForm({...companyForm, package_type_id: e?.id}); setPackageType(e) }} 
                        isClearable
                        value={packageType}
                        options={packagesTypes.map(d => {
                          return {
                            value: d.id,
                            label: tr(d.name_ru, d.name_kg),
                            ...d
                          }
                        })}
                      />
      
                      <CLabel>{t("Balance")}</CLabel>
                      <CInput placeholder="field" type="number" onChange={handleChange} value={companyForm.balance || ""} name="balance" />
                      <CLabel>{t("SMS Balance")}</CLabel>
                      <CInput placeholder="field" type="number" onChange={handleChange} value={companyForm.sms_balance || ""} name="sms_balance" />
                      <CLabel>{t("In Pool")}
                      <CInput type="checkbox" size="sm" onChange={() => setCompanyForm({...companyForm, in_pool: !companyForm.in_pool})} 
                        value={companyForm.in_pool} checked={companyForm.in_pool}/>
                      </CLabel>
                    </CCol>
                  </CRow>
                </CTabPane>
                <CTabPane data-tab="sms" className="p-2">

                <CRow className="my-2">
                        <CCol sm="auto">
                          <CLabel>
                            {t("Send sms")}
                          </CLabel>
                        </CCol>
                        <CCol className="text-right">
                          <input type="checkbox" className="ml-1"
                            checked={companyForm.send_sms || false}
                            onChange={() => setCompanyForm({...companyForm, send_sms: !companyForm.send_sms})} />
                        </CCol>
                      </CRow>
                  <CLabel>{t("Sender name")}</CLabel>
                  <CInput placeholder={t("Sender name")} value={companyForm.sms_sender_name || ""} onChange={handleChange} name="sms_sender_name" />
                  <CLabel>{t("Login")}</CLabel>
                  <CInput placeholder={t("login")} value={companyForm.sms_login || ""} onChange={handleChange} name="sms_login" />
                  <CLabel>{t("Password")}</CLabel>
                  <CInput placeholder={t("password")} value={companyForm.sms_password || ""} onChange={handleChange} name="sms_password" />
                </CTabPane>
                <CTabPane data-tab="ats" className="p-2">
                  <CLabel>{t("Login")}</CLabel>
                  <CInput placeholder={t("login")} value={companyForm.pbx_login || ""} onChange={handleChange} name="pbx_login" />
                  <CLabel>{t("Password")}</CLabel>
                  <CInput placeholder={t("password")} value={companyForm.pbx_password || ""} onChange={handleChange} name="pbx_password" />
                </CTabPane>
              </CTabContent>
              </CTabs>
            </CForm>}
          </CModalBody>
          <CModalFooter>
           
            <CButton
                color="success"
                onClick={() => submitForm()}
                className="mr-2"
            >
                    {t(!props.selectedCompany ? "Create" : "Update")}
            </CButton>
          </CModalFooter>
        </CModal>
    );
  }
  