import React, { Component } from 'react'
import './scss/style.scss'
import Router from "./pages/Router"
import {loadUser} from './redux/actions/authActions'
import {connect} from 'react-redux'
import FullSpinner from "./components/spinners/FullSpinner"


class App extends Component {

  componentDidMount() {
    this.props.loadUser();
  }

  render() {
    if(this.props.isUserLoading) {
      return <FullSpinner/>
    } else {
      return (
        <Router/>
      )
    }
  }
}

const mapStateToProps = state => ({
  isUserLoading: state.auth.isUserLoading
});

const mapDispatchToProps = dispatch => ({
  loadUser: () => dispatch(loadUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(App)
