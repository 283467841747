import { createApi, queryParamComposer } from './ServerService';

export const ordersFetch = () => {
  return createApi("/api/v1/orders/")
};

export const ordersFetchForMap = () => {
  return createApi("/api/v1/orders/map/")
};

export const ordersFetchCurrent = (params) => {
  return createApi("/api/v1/orders/current2/?" + queryParamComposer(params))
};

export const ordersFetchArchive = (params) => {
  return createApi("/api/v1/orders/archive/?" + queryParamComposer(params))
};

export const ordersFetchArchiveExport = (params) => {
  return createApi("/api/v1/orders/archive/export/?" + queryParamComposer(params))
};

export const orderFetchShareText = (order) => {
  return createApi("/api/v1/orders/" + order.id + "/share_text/")
};
export const orderSearchFetch = (params) => {
  return createApi("/api/v1/orders/search/?" + queryParamComposer(params))
};

export const ordersFetchForPartners = () => {
  return createApi("/api/v1/partners/orders/");
};

export const ordersFetchForRevision = (params) => {
  return createApi("/api/v1/orders/revise/?" + queryParamComposer(params))
};

export const ordersFetchExportForRevision = (params) => {
  return createApi("/api/v1/orders/revise/export/?" + queryParamComposer(params))
};

export const createOrderFetch = (createOrderForm) => {
  return createApi("/api/v1/orders/", {method: "POST", body: createOrderForm});
};

export const createOrderFetchPartner = (createOrderForm) => {
  return createApi("/api/v1/partners/orders/", {method: "POST", body: createOrderForm});
};

export const updateOrderFetch = (id, createOrderForm) => {
  return createApi("/api/v1/orders/" + id + "/", {method: "PATCH", body: createOrderForm} )
};

export const ordersArchiveFetch = (params) => {
  let url = "/api/v1/orders/archive/"
  if(params) {
    url = url + "?" + params
  }
  return createApi(url);
};

export const orderFetch = (order_id) => {
  return createApi("/api/v1/orders/" + order_id + "/")
};

export const orderStatusesFetch = () => {
  return createApi("/api/v1/orders/statuses/");
};

export const orderStatusChangeFetch = (id, status) => {
  return createApi("/api/v1/orders/" + id + "/status/", {method: "PATCH", body: {status: status}})
};

export const orderCourierChangeFetch = (id, courierId) => {
  return createApi("/api/v1/orders/" + id + "/courier/", {method: "PATCH", body: {courier_id: courierId}})
};

export const orderGroupFetch = (orderForm) => {
  return createApi("/api/v1/orders/group/", {method: "POST", body: orderForm})
};

export const orderUngroupFetch = (id) => {
  return createApi("api/v1/orders/" + id + "/ungroup/", {method: "POST"})
};

export const orderDeleteFetch = (id) => {
  return createApi("/api/v1/orders/" + id + "/", {method: "DELETE"})
};

export const orderDeleteFetchForPartners = (id) => {
  return createApi("/api/v1/partners/orders/" + id + "/", {method: "DELETE"})
};

// pool

export const orderTransferToPool = (id) => {
  return createApi("/api/v1/orders/"+id + "/transfer_pool/", {method: "POST"})
};


export const orderRemoveFromPool = (id) => {
  return createApi("/api/v1/orders/"+id + "/cancel/", {method: "POST"})
};


export const orderProcessPool = (id) => {
  return createApi("/api/v1/orders/"+id + "/process/", {method: "POST"})
};


export const orderReturnToPool = (id) => {
  return createApi("/api/v1/orders/"+id + "/return/", {method: "POST"})
};


export const orderConfirmPool = (id) => {
  return createApi("/api/v1/orders/"+id + "/confirm/", {method: "POST"})
};

export const orderConfirmFetch = (id, body) => {
  return createApi("/api/v1/orders/"+id + "/confirm/", {method: "POST", body: body})
};
