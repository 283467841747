import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CCol, CRow } from "@coreui/react";
import order_mark_orange from "src/assets/icons/order_mark_orange.svg";
import order_mark_red from "src/assets/icons/order_mark_red.svg";
import order_mark_gray from "src/assets/icons/order_mark_gray.svg";
import order_mark_blue from "src/assets/icons/order_mark_blue.svg";
import man_default from "src/assets/icons/man_default.svg";
import { newStatuses, oncourierStatuses, onpickupStatuses, onwaitStatuses } from "./Dict";


const toggleListFilters = (currentList, selectedList, value) => {
    return value ?
    [...currentList, ...selectedList]
     : 
    [...currentList.filter(l => !selectedList.includes(l))]
}

export default function FilterBar({filters, setFilters, filteredStatuses, setFilteredStatuses}) {
    const {t} = useTranslation();

    const handleNewsCheck = useCallback((value) => {
        setFilters({...filters, newOrders: value})
        setFilteredStatuses(toggleListFilters(filteredStatuses, newStatuses, value))
      }, [filters, filteredStatuses, setFilteredStatuses, setFilters])
    
      const handleOnpickupCheck = useCallback((value) => {
        setFilters({...filters, onpickup: value})
        setFilteredStatuses(toggleListFilters(filteredStatuses, onpickupStatuses, value))
      }, [filters, filteredStatuses, setFilteredStatuses, setFilters])
    
      const handleOnCourierCheck = useCallback((value) => {
        setFilters({...filters, oncourier: value})
        setFilteredStatuses(toggleListFilters(filteredStatuses, oncourierStatuses, value))
      }, [filters, filteredStatuses, setFilteredStatuses, setFilters])
    
      const handleOnWaitCheck = useCallback((value) => {
        setFilters({...filters, onwait: value})
        setFilteredStatuses(toggleListFilters(filteredStatuses, onwaitStatuses, value))
      }, [filters, filteredStatuses, setFilteredStatuses, setFilters])

    return (
        <div>
          {t("Filter by status")}:
          <div className="mx-auto my-2">
            <CRow>
              <CCol>
                <label className="font-lg">
                  <input type="checkbox" className="mr-1" checked={filters.newOrders} onChange={e => handleNewsCheck(e.target.checked)}/>
                  {t("Надо забрать")}
                  <img alt="created icon" src={order_mark_red} style={{height: "25px"}}/>
                </label>
              </CCol>
              <CCol>
                <label className="font-lg">
                  <input type="checkbox" className="mr-1" checked={filters.onpickup} onChange={e => handleOnpickupCheck(e.target.checked)}/>
                  {t("Курьер назначен или в пути")}
                  <img alt="created icon" src={order_mark_orange} style={{height: "25px"}}/>
                </label>
              </CCol>
              <CCol>
                <label className="font-lg">
                  <input type="checkbox" className="mr-1" checked={filters.oncourier} onChange={e => handleOnCourierCheck(e.target.checked)}/>
                  {t("Надо доставить")}
                  <img alt="created icon" src={order_mark_blue} style={{height: "25px"}}/>
                </label>
              </CCol>
              <CCol>
                <label className="font-lg">
                  <input type="checkbox" className="mr-1" checked={filters.onwait} onChange={e => handleOnWaitCheck(e.target.checked)}/>
                  {t("В ожидании")}
                  <img alt="created icon" src={order_mark_gray} style={{height: "25px"}}/>
                </label>
              </CCol>
              <CCol>
                <label className="font-lg">
                  <input type="checkbox" className="mr-1" checked={filters.courierShow} onChange={e => setFilters({...filters, courierShow: e.target.checked})}/>
                  {t("Couriers")}
                  <img alt="created icon" src={man_default} style={{height: "25px"}}/>
                </label>
              </CCol>
            </CRow>
          </div>
        </div>
    )
}