import React, {useRef, useState, useEffect, useCallback} from 'react';
import ReactDOM from "react-dom";
import { useTranslation } from 'react-i18next';
import { YMaps, Map, Polygon, Placemark} from "react-yandex-maps";
import {useSelector, useDispatch} from 'react-redux';
import { setBreadcrumb } from 'src/redux/actions/settingsActions';
import { getExtraFees } from 'src/redux/actions/extraFeesActions';
import man_online from "src/assets/icons/man_online.svg";
import { CCard, CRow, CCol, CButton, CAlert } from '@coreui/react';
import {getPackagesTypes } from 'src/redux/actions/addressActions';
import { getTransports } from 'src/redux/actions/transportActions';
import { getTariffs } from 'src/redux/actions/tariffActions';
import { toastify } from 'src/helpers/toast';
import { createOrderFetch, createOrderFetchPartner, orderConfirmFetch, orderFetch, updateOrderFetch} from 'src/services/OrderService';
import { fetchCompanyAddressCreate,fetchCompanyAddressesSearch } from 'src/services/CompanyService';
import { fetchDistricts, fetchDistrictSearch, fetchSearchedCities } from 'src/services/CityService';
import { fetchClient, fetchClientOrders, fetchClients, fetchClientsSearh, fetchCreateClient } from 'src/services/ClientService';
import { useTranslate } from 'src/helpers/useTranslate';
import { getCities } from 'src/redux/actions/locationActions';
import MiniSpinner from 'src/components/spinners/MiniSpinner';
import { newOrderData, updateNewOrder } from 'src/redux/actions/orderActions';
import { useHistory } from 'react-router-dom';
import { ClientFields } from 'src/pages/orders/New-Order/ClientFields';
import { ParcelFields } from 'src/pages/orders/New-Order/ParcelFields';
import { TariffFields } from 'src/pages/orders/New-Order/TariffFields';
import { CourierFields } from 'src/pages/orders/New-Order/CourierFields';
import { useParams } from 'react-router-dom';
import { CommentFields } from 'src/pages/orders/New-Order/CommentFields';

const apikey = "14b7fac2-2096-4a85-9aa1-ed9ac48250ff";

export function OrderFormForPartner(props) {
    
    const sidebarShow = useSelector(state => state.settings.sidebarShow);
    const courierList = useSelector(state => state.courier.couriers);
    const packagesTypes = useSelector(state => state.addresses.packagesTypes);
    const transports = useSelector(state => state.transport.transports);
    const tariffs = useSelector(state => state.tariff.tariffs);
    const cities = useSelector(state => state.locations.cities);
    const allFees = useSelector(state => state.extraFees.fees);
    const company = useSelector(state => state.auth.company)
    const profile = useSelector(state => state.auth.profile)
    const orderData = useSelector(state => state.order.newOrder);
    
    const {t, i18n} = useTranslation();
    const {tr} = useTranslate();
    const dispatch = useDispatch();
    const map = useRef(null);
    const params = useParams();
    const history = useHistory();
    
    const [mapApi, setMapApi] = useState(null);
    const [open, setOpen] = useState(false);
    const [center, setCenter] = useState([41.12, 74.99]);
    const [zoom, setZoom] = useState(7);
    const [allAddressesSender, setAllAddressesSender] = useState([]);
    const [allAddressesReceiver, setAllAddressesReceiver] = useState([]);
    const [allClients, setAllClients] = useState([])
    const [districtsSender, setDistrictsSender] = useState([])
    const [districtsReceiver, setDistrictsReceiver] = useState([])
    const [buttonClickable, setButtonClickable] = useState(true);
    const [cCourier, setCCourier] = useState(null);
    const [errorField, setErrorField] = useState(null);
    const [sortedCourierList, setSortedCourierList] = useState(null);
    const [edit, setEdit] = useState(false)
    const [pointSender, setPointSender] = useState(false)
    const [pointReceiver, setPointReceiver] = useState(false)
    const [emptySender, setEmptySender] = useState(true)
    const [emptyAddressSender, setEmptyAddressSender] = useState(true)
    
    useEffect(() => {
        dispatch(setBreadcrumb(params.orderId ? [t("Editing") + " " + orderData?.oid || params.orderId] : [t("Create Order")]));
    }, [params, dispatch, t, orderData])

    useEffect(() => {
        dispatch(getPackagesTypes());
        dispatch(getTransports());
        dispatch(getExtraFees());
        dispatch(getTariffs());
        dispatch(getCities());
        
        fetchClients().then(res => {
            res.ok && res.json().then(d => setAllClients(d))
        })
        
    }, [dispatch, i18n, t]);
    
    const setOrderObjects = useCallback(
        (o) => {
            let order = o
            if(order.sender) {
                setEmptySender(false)
                order.selectedClientSender = {value: order.sender.id, label: order.sender.phone, ...order.sender}
                order.senderFullname = order.sender.fullname
            }
            if(order.receiver) {
                order.selectedClientReceiver = {value: order.receiver.id, label: order.receiver.phone, ...order.receiver}
                order.receiverFullname = order.receiver.fullname
            }
            if(order.location_from) {
                setEmptyAddressSender(false)
                order.selectedAddressSender = {value: order.location_from.id, label: tr(order.location_from.name_ru, order.location_from.name_kg), ...order.location_from}
                order.selectedCitySender = 
                order.location_from.city ?
                {
                    label: tr(order.location_from.city.name_ru, order.location_from.city?.name_kg),
                    value: order.location_from.city.id,
                    ...order.location_from.city
                } : null
                order.selectedDistrictSender = 
                order.location_from.district ? {
                        label: tr(order.location_from.district.name_ru, order.location_from.district?.name_kg),
                        value: order.location_from.district.id,
                        ...order.location_from.district
                } : null
            }
            if(order.location_to) {
                order.selectedAddressReceiver = {value: order.location_to.id, label: tr(order.location_to.name_ru, order.location_to.name_kg), ...order.location_to}
                order.selectedCityReceiver = 
                order.location_to.city ?
                {
                    label: tr(order.location_to.city.name_ru, order.location_to.city?.name_kg),
                    value: order.location_to.city.id,
                    ...order.location_to.city
                } : null
                order.selectedDistrictReceiver = 
                order.location_to.district ? {
                        label: tr(order.location_to.district.name_ru, order.location_to.district?.name_kg),
                        value: order.location_to.district.id,
                        ...order.location_to.district
                } : null
            }
            if(order.package_type) {
                order.selectedPack = {value: order.package_type.id , label: tr(order.package_type.name_ru, order.package_type.name_kg), ...order.package_type};
            }
            if(order.tariff) {
                order.selectedTariff = {value: order.tariff?.id, label: order.tariff?.name, ...order.tariff};
            }
            if(order.selectedTransport) {
                order.selectedTransport = order.transports?.map(tt => {return {value: tt.id, label: tt.name_kg, ...tt}})
            }
            if(order.courier) {
                order.selectedCourier = {value: order.courier?.id, label: order.courier?.user?.firstname, ...order.courier}
            }
            return order
        },
        [tr],
    )

    const updateOrderData = useCallback((field, value) => {
        dispatch(updateNewOrder(field, value))
    }, [dispatch])

    useEffect(() => {
        if(!params.orderId) {
            profile?.delivery_office?.location &&
                updateOrderData("selectedCitySender", {
                    label: profile.delivery_office.location.city?.name_ru,
                    value: profile.delivery_office.location.city?.id, 
                    ...profile.delivery_office.location.city});
            updateOrderData("final_price", tariffs[0]?.price)
            updateOrderData("weight", packagesTypes[0]?.max_weight)
            updateOrderData("width", packagesTypes[0]?.width)
            updateOrderData("length", packagesTypes[0]?.length)
            updateOrderData("height", packagesTypes[0]?.height)
            updateOrderData("amount", 1)
        }
    }, [params, updateOrderData, profile, tariffs, packagesTypes])

    useEffect(() => {
        if(params.orderId) {
            orderFetch(params.orderId).then(res => res.ok && res.json()).then(data => {
                setEdit(true);
                let order = setOrderObjects(data)
                dispatch(newOrderData({...order}))
            })
        } else {
            setEdit(false);
        }
            
    }, [dispatch, params, setOrderObjects])

    const addRoute = useCallback((ymaps, pointA, pointB) => {

        const multiRoute = new ymaps.multiRouter.MultiRoute(
          {
            referencePoints: [pointA, pointB],
          },
          {
            boundsAutoApply: true
          }
        );
        orderData.currentRoute && map.current.geoObjects.remove(orderData.currentRoute);
        updateOrderData("currentRoute", multiRoute);

        map.current.geoObjects.add(multiRoute);
        
        multiRoute.model.events.add('requestsuccess', function() {
          var activeRoute = multiRoute.getActiveRoute();
          if(activeRoute) {
            updateOrderData("distanceBetween", activeRoute.properties.get("distance").text);
            updateOrderData("estimation", activeRoute.properties.get("duration").text);
          }
          });
    }, [orderData, updateOrderData])

    const checkCoors = useCallback((zones, locationTo) => {
        let detectedZone = zones.filter(z => {
            var myPolygon = new mapApi.Polygon([z.fee.coors], {
                hintContent: "Polygon"
            }, {
                visible: false,
                fillColor: "#ffffff"
            });      
            map.current.geoObjects.add(myPolygon);
            if(myPolygon.geometry.contains(locationTo)) {
                return true;
            } else {
                return false;
            }
        })
        if(detectedZone.length > 0) {
            updateOrderData("zoneFee", detectedZone[0].amount);
            updateOrderData("destinationZone", detectedZone[0]);
        } else {
            updateOrderData("destinationZone", null);
            updateOrderData("zoneFee", 0);
        }
    }, [mapApi, updateOrderData])

    const detectZoneFee = useCallback((locationTo) => {
        checkCoors(allFees.filter(fee=> fee.fee_type==="zone")?.filter(fee => fee.fee.coors.length>0), locationTo)
    }, [allFees, checkCoors])
    
    const detectRedemptionFee = useCallback((amount) => {
        let autoFee = allFees.filter(fee => fee.fee_type === "redemption").filter(fee => {
          return parseInt(fee.fee.max) >= amount && amount >= parseInt(fee.fee.min)
        });
        autoFee.length > 0 ? updateOrderData("redemptionFee", autoFee[0].amount) : updateOrderData("redemptionFee", 0);
      }, [allFees, updateOrderData])
      
    const detectWeightFee = useCallback((amount) => {
        let autoFee = [];
        if(company?.id === 21 && parseInt(amount) > 5) {
            autoFee[0] = {amount: parseInt(amount-5) * 50}
            console.log(autoFee)
        } else {
            autoFee = allFees.filter(fee => fee.fee_type === "weight").filter(fee => {
              return parseInt(fee.fee.max) >= amount && amount >= parseInt(fee.fee.min)
            });
        }
        autoFee.length > 0 ? updateOrderData("weightFee", autoFee[0].amount) : updateOrderData("weightFee", 0);
      }, [allFees, updateOrderData, company])

    const handleSenderAddress = useCallback((res) => {
        updateOrderData("selectedAddressSender", res);
        if(res) {
            res?.city?.id && updateOrderData("selectedCitySender", {label: tr(res.city.name_ru, res.city.name_kg), value: res.city.id, ...res.city})
            res?.district?.id && updateOrderData("selectedDistrictSender", {label: tr(res.district.name_ru, res.district.name_kg), value: res.district.id, ...res.district})
            setEmptyAddressSender(false)
        } else {
            setEmptyAddressSender(true)
            updateOrderData("senderGisLink", null)
        }
        if(res && res.lat) {
            if(orderData.selectedAddressReceiver) {
                addRoute(mapApi, [res.lat, res.lng], [orderData.selectedAddressReceiver.lat, orderData.selectedAddressReceiver.lng]);
            } else {
                setCenter([res.lat, res.lng]);
                setZoom(15);
            }
            const sortedCList = courierList.map(c => {
                let a = c.lat-res.lat;
                let b = c.lng-res.lng;
                return {...c, dist: Math.sqrt((a * a) + (b * b))}
            }).sort((a,b) => a.dist < b.dist ? -1 : 1)
            setSortedCourierList(sortedCList);
            // setSelectedCourier({...sortedCList[0], label: sortedCList[0].firstname, value: sortedCList[0].id});
        } else {
            orderData.currentRoute && map.current.geoObjects.remove(orderData.currentRoute);
            updateOrderData("currentRoute", null)
        }
        updateOrderData("location_from_flat", res?.flat || "");
        updateOrderData("location_from_floor", res?.floor || "");
        console.log(res)
    }, [orderData, updateOrderData, courierList, mapApi, addRoute, tr])
    
    const handleReceiverAddress = useCallback((res) => {
        updateOrderData("selectedAddressReceiver", res)
        if(res) {
            res.city?.id && updateOrderData("selectedCityReceiver", {label: tr(res.city.name_ru, res.city.name_kg), value: res.city.id, ...res.city})
            res.district?.id && updateOrderData("selectedDistrictReceiver", {label: tr(res.district.name_ru, res.district.name_kg), value: res.district.id, ...res.district})
        } else {
            updateOrderData("receiverGisLink", null)
        }
        if(res && res.lat) {
            detectZoneFee([res.lat, res.lng]);
            if(orderData.selectedAddressSender) {
                addRoute(mapApi, [orderData.selectedAddressSender.lat, orderData.selectedAddressSender.lng], [res.lat, res.lng]);
            }
        } else {
            orderData.currentRoute && map.current.geoObjects.remove(orderData.currentRoute);
            updateOrderData("currentRoute", null)
        }
        updateOrderData("location_to_flat", res?.flat || "");
        updateOrderData("location_to_floor", res?.floor || "");
    }, [updateOrderData, addRoute, orderData, mapApi, detectZoneFee, tr])

    const selectClient = useCallback((res, isSender) => {
        let selected = res
        if(!res) {
            updateOrderData(isSender ? "senderFullname" : "receiverFullname", "")
            isSender && setEmptySender(true)
        }else{
            isSender && setEmptySender(false)
        }
        if(selected?.phone) {
            selected.label = res.phone
            updateOrderData(isSender ? "senderFullname" : "receiverFullname", selected?.fullname)
        }
        updateOrderData(isSender ? "selectedClientSender" : "selectedClientReceiver", selected)

        if(selected && selected.id) {
            fetchClient(selected.id).then(resp => resp.json().then(d => {
                updateOrderData(isSender ? "send_sms_sender" : "send_sms_receiver", d.send_sms)
                if(d.default_address) {
                    if(isSender) {
                        handleSenderAddress({
                            ...d.default_address.location_from,
                            label: tr(d.default_address.location_from.name_ru, d.default_address.location_from.name_kg),
                            value: d.default_address.location_from?.id,
                            flat: d.default_address.location_from_flat,
                            floor: d.default_address.location_from_floor
                        })
                    } else {
                        handleReceiverAddress({
                            ...d.default_address.location_to,
                            label: tr(d.default_address.location_to.name_ru, d.default_address.location_to.name_kg),
                            value: d.default_address.location_to?.id,
                            flat: d.default_address.location_to_flat,
                            floor: d.default_address.location_to_floor
                        })
                    }
                } else {
                    fetchClientOrders(selected.id, isSender ? "sender" : "receiver").then(res => res.ok && res.json().then(d => {
                    if(d.length > 0) {
                        const addrList = [...d]
                        console.log(addrList)
                        let firstAddr = addrList[0]
                        if(isSender) {
                            firstAddr.location_from && handleSenderAddress({
                                ...firstAddr.location_from,
                                label: tr(firstAddr.location_from.name_ru, firstAddr.location_from.name_kg),
                                value: firstAddr.location_from?.id,
                                flat: firstAddr.location_from_flat,
                                floor: firstAddr.location_from_floor
                            });
                            setAllAddressesSender([...addrList])
                            updateOrderData("location_from_flat", firstAddr.location_from_flat || "")
                            updateOrderData("location_from_floor", firstAddr.location_from_floor || "")
                        } else {
                            firstAddr.location_to && handleReceiverAddress({
                                ...firstAddr.location_to,
                                label: tr(firstAddr.location_to.name_ru, firstAddr.location_to.name_kg),
                                value: firstAddr.location_to?.id,
                                flat: firstAddr.location_to_flat,
                                floor: firstAddr.location_to_floor
                            });
                            setAllAddressesReceiver([...addrList])
                            updateOrderData("location_to_flat", firstAddr.location_to_flat || "")
                            updateOrderData("location_to_floor", firstAddr.location_to_floor || "")
                        }
                        updateOrderData("packageType", {value: d[0].package_type.id , label: tr(d[0].package_type.name_ru, d[0].package_type.name_kg), ...d[0].package_type})
                        updateOrderData("tariff", {value: d[0].tariff?.id, label: d[0].tariff?.name, ...d[0].tariff})
                    }
                })).catch(err => console.log(err))
            }
            })).catch(err => console.log(err))
        }
    }, [tr, handleSenderAddress, handleReceiverAddress, updateOrderData])

    const tariffSelect = useCallback((res) => {
        updateOrderData("zoneFeeCheck", false);
        updateOrderData("redemptionFeeCheck", false);
        updateOrderData("weightFeeCheck", false);
        updateOrderData("selectedTariff", res);
        updateOrderData("final_price", res.price);

        if(res.name.includes("Из офиса")) {
            const loc = profile.delivery_office?.location
            if (loc) {
                let senderAddr = {
                    label: tr(loc.name_ru, loc.name_kg), 
                    value: loc.id, ...loc}
                handleSenderAddress(senderAddr)
                if(loc.city) {
                    updateOrderData("selectedCitySender", {
                        label: tr(loc.city.name_ru, loc.city.name_kg), 
                        value: loc.city.id, 
                        ...loc.city})
                }
                if(loc.district) {
                    updateOrderData("selectedDistrictSender", {
                        label: tr(loc.district.name_ru, loc.district.name_kg), 
                        value: loc.district.id, 
                        ...loc.district})
                }
            }
        }
    }, [updateOrderData, handleSenderAddress, profile, tr])
    
    const transportSelect = useCallback((res) => {
        if(res.length < 1 || !res[res.length-1].value){
            updateOrderData("selectedTransport", [])
        } else {
            updateOrderData("selectedTransport", res.filter(r => r.value))
        }
    }, [updateOrderData])

    const clearFields = useCallback(() => {
        dispatch(newOrderData({}))
    }, [dispatch])
    
    const getDistrictsFor = (city, isSender) => {
        if(city) {
            fetchDistricts(city.id).then(res => 
                res.ok && res.json().then(d => {
                    isSender ? setDistrictsSender(d) : setDistrictsReceiver(d)
            }))
        } else {
            isSender ? setDistrictsSender([]) : setDistrictsReceiver([])
        }
    }

    
    const promiseAddressOptions = (inputValue, role) => {
        let addrParams = inputValue;
        if(role === "Sender") {
            addrParams += orderData.selectedCitySender ? "&city_id="+ orderData.selectedCitySender.id : "" 
            addrParams += orderData.selectedDistrictSender ? "&district_id="+ orderData.selectedDistrictSender.id : "" 
        } else {
            addrParams += orderData.selectedCityReceiver ? "&city_id="+ orderData.selectedCityReceiver.id : "" 
            addrParams += orderData.selectedDistrictReceiver ? "&district_id="+ orderData.selectedDistrictReceiver.id : "" 
        }
        return new Promise(resolve => {
            fetchCompanyAddressesSearch(addrParams).then(res => res.ok && res.json().then(d => {
                resolve(d.map(dd => {return {label: tr(dd.name_ru, dd.name_kg), value: dd.id, ...dd}}))
            }))
        });
    }
    const promiseDistrictOptions = (inputValue, role) => {
        let params = inputValue;
        if(role === "Sender") {
            params += orderData.selectedCitySender ? "&city_id="+ orderData.selectedCitySender.id : "" 
        } else {
            params += orderData.selectedCityReceiver ? "&city_id="+ orderData.selectedCityReceiver.id : "" 
        }
        return new Promise(resolve => {
            params.includes("&city_id=") ?
            fetchDistrictSearch(params).then(res => res.ok && res.json().then(d=> {
                resolve(d.map(dd => {return {label: tr(dd.name_ru, dd.name_kg), value: dd.id, ...dd}}))
            })) : resolve([])
        });
    }

    const promiseCityOptions = (inputValue) => {
        return new Promise(resolve => {
            fetchSearchedCities(inputValue).then(res => 
                res.ok && res.json().then(d => {
                console.log(d);
                resolve(d.map(dd => {return {label: tr(dd.name_ru, dd.name_kg), value: dd.id, ...dd}}))
            }))
        });
    }

    const promiseClientOptions = (inputValue) => {
        let key = inputValue;
        // if(inputValue.replace(/[^\d]/g, '').length > 8) {
        //     key = key.toString().split(' ').join('');
        // }
        if(key[0] === "+") {
            key = key.substring(1,key.length);
        }
        return new Promise(resolve => {
            fetchClientsSearh(key).then(res => res.ok && res.json().then(d => {
                setAllClients(d)
                resolve(d.map(cl => {return { value: cl.id,label: cl.fullname + " " + cl.phone, ...cl}}))
            }))
        });
    }

    const checkFieldsBeforeConfirm = (orderData) => {
        return new Promise((resolve, reject) => {
            if(!orderData.selectedClientSender) {
                reject({title: "Sender client", reason: t("No sender")})
            } else if(!orderData.selectedAddressSender) {
                reject({title: "Sender address", reason: t("No sender address")})
            } else if(!orderData.selectedClientSender.id && orderData.selectedClientSender.label?.length > 14) {
                reject({title: "Sender phone", reason: t("Incorrect sender phone number")})
            } else if(orderData.selectedClientReceiver && orderData.selectedClientReceiver.label?.length > 14) {
                reject({title: "Receiver phone", reason: t("Incorrect receiver phone number")})
            } else {
                resolve("No errors");
            }
        })
    }
    
    const submitCreate = () => {
        setButtonClickable(false);
        setLoading(true)
        console.log(orderData);

        orderData.selectedClientSender ? setEmptySender(false) : setEmptySender(true)
        orderData.selectedAddressSender ? setEmptyAddressSender(false) : setEmptyAddressSender(true)
        
        checkFieldsBeforeConfirm(orderData).then(res => {
            if(orderData.pickUpDay === "tomorrow"){
                orderData.pickUpTimeStamp = new Date((orderData.pickUpTimeStamp.setDate(orderData.pickUpTimeStamp.getDate()+1)))
            }
            if(orderData.deliveryDay === "tomorrow"){
                orderData.deliveryTimeStamp = new Date((orderData.deliveryTimeStamp.setDate(orderData.deliveryTimeStamp.getDate()+1)))
            }
            let orderFormBody = {
                "sender_id": orderData.selectedClientSender.id,
                "location_from_id": orderData.selectedAddressSender.id,
                "location_from_flat": orderData.location_from_flat,
                "location_from_floor": orderData.location_from_floor,
                "location_to_flat": orderData.location_to_flat,
                "location_to_floor": orderData.location_to_floor,
                "receiver_id": orderData.selectedClientReceiver?.id,
                "location_to_id": orderData.selectedAddressReceiver?.id,
                "package_type_id": orderData.selectedPack ? orderData.selectedPack.id : packagesTypes[0]?.id,
                "redemption_amount": orderData.redemption_amount || 0,
                "currency_id": 1,
                "courier_id": orderData.selectedCourier ? orderData.selectedCourier.id : null,
                "transports": orderData.selectedTransport?.map(tr => (tr.label!=="Все" && tr.label!=="Баары") && tr.id) || [],
                "tariff_id": orderData.selectedTariff ? orderData.selectedTariff.id : tariffs[0]?.id,
                "extra_fees": {
                    "zone_fee": orderData.zoneFee,
                    "weight_fee": orderData.weightFee,
                    "redemption_fee": orderData.redemptionFee
                },
                "calculated_price": 0 + (orderData.selectedTariff ? orderData.selectedTariff.price : tariffs[0]?.price) || 0 + (orderData.zoneFee || 0) + (orderData.weightFee || 0) + (orderData.redemptionFee || 0),
                "final_price": orderData.final_price || 0,
                "description": orderData.description || "",
                "source": "operator web",
                "pays_receiver": orderData.pays!=="sender" && orderData.pays!=="together",
                "paying_side": orderData.paying_side,
                "payment_method": "cash",
                "weight": orderData.weight,
                "height": orderData.height,
                "width": orderData.width,
                "length": orderData.length,
                "volume_weight": (orderData.height * orderData.width * orderData.length / 4000),
                "pickup_time": orderData.pickUpTimeStamp?.toISOString() || undefined,
                "delivery_time": orderData.deliveryTimeStamp?.toISOString() || undefined,
                "distance": parseFloat(orderData.distanceBetween) * 1000, 
                "send_sms_sender": orderData.send_sms_sender,
                "send_sms_receiver": orderData.send_sms_receiver,
                "redemption_payment_method": orderData.redemption_amount > 0 ? orderData.redemption_payment_method || "cash" : orderData.redemption_payment_method,
                "redemption_payment_type": orderData.redemption_amount > 0 ? orderData.redemption_payment_type || "redemption" : orderData.redemption_payment_type,
                "sender_payment_amount": orderData.sender_payment_amount || 0,
                "sender_payment_method": orderData.sender_payment_method || "cash",
                "sender_payment_is_payed": orderData.sender_payment_is_payed || false,
                "sender_payment_type": orderData.sender_payment_type || "service",
                "receiver_payment_amount": orderData.receiver_payment_amount || 0,
                "receiver_payment_method": orderData.receiver_payment_method || "cash",
                "receiver_payment_is_payed": orderData.receiver_payment_is_payed || false,
                "receiver_payment_type": orderData.receiver_payment_type || "service",
                "delivery_service_id": orderData.deliveryService?.id,
                "invoice_organization_sender": orderData.invoice_organization_sender?.id,
                "invoice_organization_receiver": orderData.invoice_organization_receiver?.id,
            }
            
            let createFetchs = [[], [], [], []];

            if(!orderData.selectedClientSender?.id) {
                let senderPhone = orderData.selectedClientSender.label
                if(senderPhone.substring(0, 1) === "0") {
                    senderPhone = "+996" + senderPhone.substring(1,senderPhone.length)
                }else if(senderPhone.substring(0, 3) === "996") {
                    senderPhone = "+" + senderPhone
                }
                else if(senderPhone.substring(0, 1) !== "+") {
                    senderPhone = "+996" + senderPhone
                }
                createFetchs[0] = fetchCreateClient({fullname: orderData.selectedClientSender.fullname || orderData.senderFullname ||  senderPhone, phone: senderPhone})
            }
            if(orderData.selectedClientReceiver && !orderData.selectedClientReceiver?.id) {
                let receiverPhone = orderData.selectedClientReceiver.label
                if(receiverPhone.substring(0, 1) === "0") {
                    receiverPhone = "+996" + receiverPhone.substring(1,receiverPhone.length)
                }else if(receiverPhone.substring(0, 3) === "996") {
                    receiverPhone = "+" + receiverPhone
                }
                else if(receiverPhone.substring(0, 1) !== "+") {
                    receiverPhone = "+996" + receiverPhone
                }
                createFetchs[1] = fetchCreateClient({fullname: orderData.selectedClientReceiver.fullname || orderData.receiverFullname || receiverPhone, phone: receiverPhone})
            }
            if(!orderData.selectedAddressSender?.id) {
                createFetchs[2] = fetchCompanyAddressCreate({
                    name_kg: orderData.selectedAddressSender.label, 
                    name_ru: orderData.selectedAddressSender.label, 
                    city_id: orderData.selectedCitySender ? orderData.selectedCitySender.id : company?.city ? company.city.id : 3, 
                    district_id: orderData.selectedDistrictSender?.id,
                    lat: orderData.selectedAddressSender.lat,
                    lng: orderData.selectedAddressSender.lng
                })
            }
            if(orderData.selectedAddressReceiver && !orderData.selectedAddressReceiver?.id) {
                createFetchs[3] = fetchCompanyAddressCreate({
                    name_kg: orderData.selectedAddressReceiver.label, 
                    name_ru: orderData.selectedAddressReceiver.label, 
                    city_id: orderData.selectedCityReceiver ? orderData.selectedCityReceiver.id : company?.city ? company.city.id : 3, 
                    district_id: orderData.selectedDistrictReceiver?.id,
                    lat: orderData.selectedAddressReceiver.lat,
                    lng: orderData.selectedAddressReceiver.lng
                })
            }
            Promise.all(createFetchs).then(values => {
                console.log(values);
                Promise.all(values.map(v => v.toString().length > 0 ? v.json() : v)).then(d => {
                    if(d[0].toString().length > 0) {
                        orderFormBody.sender_id = d[0]?.id
                    }
                    if(d[1].toString().length > 0) {
                        orderFormBody.receiver_id = d[1]?.id
                    }
                    if(d[2].toString().length > 0) {
                        orderFormBody.location_from_id = d[2]?.id
                    }
                    if(d[3].toString().length > 0) {
                        orderFormBody.location_to_id = d[3]?.id
                    }
                    console.log(orderFormBody);
                    submitOrder(orderFormBody);
                })
            }).catch(err => {
                toastify("error", err.toString());
                setButtonClickable(true)
                setLoading(false)
                console.log(err)
            })
        }).catch(err => {
            console.log(err)
            toastify("error", err.reason);
            setErrorField(err);
            setButtonClickable(true)
            setLoading(false)
        })
        
    }

    const fetchReq = (form, orderId) => {
        if(edit) {
            return props.confirm ? orderConfirmFetch(orderId, form) : updateOrderFetch(orderId, form) 
        } else {
            return profile.roles.includes("partner") ? createOrderFetchPartner(form) : createOrderFetch(form)
        }
    }

    const submitOrder = (orderForm) => {
        fetchReq(orderForm, params.orderId).then(res => {
            console.log(res)
            if(res.ok) {
                toastify("success", t("Operation succeeded"));
                let emptyArr = [{}]
                dispatch(newOrderData(...emptyArr))
                history.push(profile?.roles.includes("partner") ? "/current-orders-partners" : "/current-orders")
                caches.keys().then((names) => {
                    names.forEach((name) => {
                      caches.delete(name);
                    });
                });
            } else {
                toastify("error", t("Operation failed"));
                setButtonClickable(true);
                setLoading(false)
                res.json().then(res => {
                    console.log(res)
                })
                if(res.status === 401) {
                    history.push("/login")
                }
            }
        }).catch(err => {
            console.log(err);
            toastify("error", err?.detail)
            toastify("error", t("Error occured. " + err.toString()))
            setLoading(false)
        })
    }

      const Button = (props) => (
          <div className="text-center">
               <img alt="profile avatar" style={{borderRadius: "50%", width: "30px", height: "30px", objectFit: "cover"}}
                  src="https://www.pngfind.com/pngs/m/676-6764065_default-profile-picture-transparent-hd-png-download.png"/>
                {props.cCourier?.firstname}<br/>
                {props.cCourier?.status}
               <button onClick={() => { updateOrderData("selectedCourier", {value: props.cCourier?.id, label: props.cCourier?.firstname})}} className="btn btn-primary btn-sm">
                {t("Assign")}
                </button>
          </div>
      );
    
      const BaloonContent = (props) => {
        const portalRoot = document.querySelector(".portal");
        const portalContainer = document.createElement("div");
        useEffect(() => {
          portalRoot.appendChild(portalContainer);
        }, [portalContainer, portalRoot]);
    
        return ReactDOM.createPortal(
          <Button cCourier={props.cCourier}/>,
          portalContainer
        );
      };


    const geoSelect = (res, isSender) => {
        if(res?.lat) {
            if(isSender) {
                if(!orderData.selectedAddressSender?.lat && res.lat) {
                    setCenter([res.lat, res.lng])
                    setZoom(14)
                    if(orderData.selectedAddressReceiver?.lat) {
                        addRoute(mapApi, [res.lat, res.lng], [orderData.selectedAddressReceiver.lat, orderData.selectedAddressReceiver.lng])
                    } else if(orderData.selectedDistrictReceiver?.lat) {
                        addRoute(mapApi, [res.lat, res.lng], [orderData.selectedDistrictReceiver.lat, orderData.selectedDistrictReceiver.lng])
                    } else if(orderData.selectedCityReceiver?.lat) {
                        addRoute(mapApi, [res.lat, res.lng], [orderData.selectedCityReceiver.lat, orderData.selectedCityReceiver.lng])
                    }
                }
            } else {
                if(!orderData.selectedAddressReceiver?.lat && res.lat) {
                    if(orderData.selectedAddressSender?.lat) {
                        addRoute(mapApi, [orderData.selectedAddressSender.lat, orderData.selectedAddressSender.lng], [res.lat, res.lng])
                    } else if(orderData.selectedDistrictSender?.lat) {
                        addRoute(mapApi, [orderData.selectedDistrictSender.lat, orderData.selectedDistrictSender.lng], [res.lat, res.lng])
                    } else if(orderData.selectedCitySender?.lat) {
                        addRoute(mapApi, [orderData.selectedCitySender.lat, orderData.selectedCitySender.lng], [res.lat, res.lng])
                    }
                } 
            }
        }
    }

    const [loading, setLoading] = useState(false);

    return (
        <CCard className="pb-0 mb-0">
        {loading ? 
            <div className="text-center py-5">
                <MiniSpinner size="lg"/>
            </div> 
            : 
            <div className="m-2 px-1">
                {errorField && 
                    <CAlert color="danger">
                        Error on {errorField.title}. {errorField.reason}
                    </CAlert>
                }
                <CRow>
                    <CCol className="mx-n2">
                        <ClientFields role={"Sender"} allClients={allClients} emptyClient={emptySender} emptyAddress={emptyAddressSender}
                            selectClient={selectClient} orderData={orderData} setPoint={setPointSender}
                            updateOrderData={updateOrderData} cities={cities} getDistrictsFor={getDistrictsFor}
                            geoSelect={geoSelect} districts={districtsSender} promiseAddressOptions={promiseAddressOptions}
                            promiseCityOptions={promiseCityOptions} promiseDistrictOptions={promiseDistrictOptions}
                            allAddresses={allAddressesSender} handleAddress={handleSenderAddress} promiseClientOptions={promiseClientOptions}/>
                    </CCol>
                    <CCol  className="mx-n2">
                        <ClientFields role={"Receiver"} allClients={allClients}
                                      // emptyClient={emptyReceiver} emptyAddress={emptyAddressReceiver}
                            selectClient={selectClient} orderData={orderData} setPoint={setPointReceiver}
                            updateOrderData={updateOrderData} cities={cities} getDistrictsFor={getDistrictsFor}
                            geoSelect={geoSelect} districts={districtsReceiver} promiseAddressOptions={promiseAddressOptions}
                            promiseCityOptions={promiseCityOptions} promiseDistrictOptions={promiseDistrictOptions}
                            allAddresses={allAddressesReceiver} handleAddress={handleReceiverAddress} promiseClientOptions={promiseClientOptions}/>
                    </CCol>
                    <div className='w-100 d-lg-none my-2'></div>
                    {!profile?.roles?.includes("partner") && 
                        <CCol  className="mx-n2">
                            <CourierFields transports={transports} transportSelect={transportSelect} 
                                orderData={orderData} updateOrderData={updateOrderData}
                                sortedCourierList={sortedCourierList} courierList={courierList}/>
                        </CCol>}
                    <CCol  className="mx-n2">
                        <CommentFields orderData={orderData} updateOrderData={updateOrderData} profile={profile}/>
                    </CCol>
                    <div className='w-100 d-lg-none my-2'></div>
                    <CCol  className="mx-n2">
                        <ParcelFields packagesTypes={packagesTypes} updateOrderData={updateOrderData} profile={profile}
                            orderData={orderData} detectRedemptionFee={detectRedemptionFee} detectWeightFee={detectWeightFee}/>
                            {profile?.roles?.includes("partner") && <>
                                <CButton color="primary" className="btn-block mt-2" disabled={!buttonClickable} onClick={submitCreate}>
                                    {!buttonClickable ? <MiniSpinner /> : edit ? t("Save") : t("Submit")}
                                </CButton>
                                <CButton color="secondary" className="btn-block mt-2" onClick={clearFields}>{t("Clear")}</CButton>
                                {edit && <CButton color="danger" className="btn-block mt-2" onClick={() => {
                                        clearFields();
                                        history.push("/current-orders")
                                    }}>{t("Back")}</CButton>}
                            </>}
                    </CCol>
                    {!profile?.roles?.includes("partner") && <CCol className="mx-n2">
                        <TariffFields tariffs={tariffs} tariffSelect={tariffSelect} profile={profile} setButtonClickable={setButtonClickable}
                            orderData={orderData} updateOrderData={updateOrderData}/>
                            <CButton color="primary" className="btn-block mt-2" disabled={!buttonClickable} onClick={submitCreate}>
                                {!buttonClickable ? <MiniSpinner /> : edit ? t("Save") : t("Submit")}
                            </CButton>
                            <CButton color="secondary" className="btn-block mt-2" onClick={clearFields}>{t("Clear")}</CButton>
                            {edit && <CButton color="danger" className="btn-block mt-2" onClick={() => {
                                    clearFields();
                                    history.push("/current-orders")
                                }}>{t("Back")}</CButton>}
                    </CCol>}
                </CRow>
            </div>
        }
            {/* map component */}
            <YMaps className={!sidebarShow && "w-100vw"} query={{ apikey }}>
                <Map
                    onLoad={(ymaps) => {setMapApi(ymaps)}}
                    onClick={e => {
                            if(pointSender) {
                                handleSenderAddress({...orderData.selectedAddressSender, lat: e.get("coords")[0], lng: e.get("coords")[1]})
                                setPointSender(false)
                            } 
                            if (pointReceiver) {
                                handleReceiverAddress({...orderData.selectedAddressReceiver, lat: e.get("coords")[0], lng: e.get("coords")[1]})
                                setPointReceiver(false)
                            }
                        }
                    }
                    instanceRef={map}
                    modules={["multiRouter.MultiRoute"]}                       
                    width="100%"
                    height="70vh"
                    state={{center: center, zoom: zoom}}
                    defaultState={{ center: center, zoom: zoom }}>
                    {orderData.selectedAddressSender && !orderData.currentRoute && 
                        <Placemark
                            onDragEnd={res => {
                                let coords = res.originalEvent.target.geometry._coordinates
                                !orderData.selectedAddressSender.id && handleSenderAddress({...orderData.selectedAddressSender, lat: coords[0], lng: coords[1]})
                            }}
                            geometry={[orderData.selectedAddressSender.lat, orderData.selectedAddressSender.lng]}
                            options={{ iconColor: "red", draggable: !orderData.selectedAddressSender.id && true }}/>
                    }
                    {orderData.selectedAddressReceiver && !orderData.currentRoute && 
                        <Placemark
                            onDragEnd={res => {
                                let coords = res.originalEvent.target.geometry._coordinates
                                !orderData.selectedAddressSender.id && handleReceiverAddress({...orderData.selectedAddressReceiver, lat: coords[0], lng: coords[1]})
                            }}
                            geometry={[orderData.selectedAddressReceiver.lat, orderData.selectedAddressReceiver.lng]}
                            options={{ iconColor: "SteelBlue", draggable: !orderData.selectedAddressSender?.id && true }}/>
                    }

                {courierList.map((c, i) => (
                    <Placemark
                        properties={{
                            hintContent: c.firstname,
                            balloonContent: `<div class="portal" style="height: 100px;"></div>`
                        }}
                        onBalloonOpen={() => {
                            setOpen(true)
                            setCCourier(c)
                            }}
                        onBalloonClose={() => setOpen(false)}
                        geometry={[c.lat, c.lng]}
                        options={{  iconLayout: 'default#image', 
                        iconImageHref: c.transport?.photo || man_online }}
                        modules = {
                            ['geoObject.addon.balloon', 'geoObject.addon.hint']
                        }
                        key={i} 
                    />
                ))}
                <Polygon geometry={allFees} options={{visible: false, opacity: 1}}/>
            </Map>
        </YMaps>
        {open && (
        <BaloonContent cCourier={cCourier}/>
      )}
        </CCard>
    )
}
