import {
  ADDRESSES_LOADING,
  ADDRESSES_SUCCESS,
  ADDRESSES_FAIL,
  PACKAGESTYPES_LOADING,
  PACKAGESTYPES_SUCCESS,
  PACKAGESTYPES_FAIL,
} from "../types/addressTypes";

const initialState = {
  addresses: [],
  packagesTypes: [],
  errors: null,
  isAddressesLoading: true,
  isPackagesTypesLoading: true,
};

export default function locationsReducer(state = initialState, action) {
  switch (action.type) {
    case ADDRESSES_SUCCESS:
      return {
        ...state,
        addresses: action.addresses,
        isAddressesLoading: false,
        error: null,
      };
    case ADDRESSES_FAIL:
      return {
        ...state,
        isAddressesLoading: false,
        error: action.payload || null,
      };
    case ADDRESSES_LOADING:
      return {
        ...state,
        isAddressesLoading: true,
        error: null,
      };
      



    case PACKAGESTYPES_SUCCESS:
      return {
        ...state,
        packagesTypes: action.packagesTypes,
        isPackagesTypesLoading: false,
        error: null,
      };
    case PACKAGESTYPES_FAIL:
      return {
        ...state,
        isPackagesTypesLoading: false,
        error: action.payload || null,
      };
    case PACKAGESTYPES_LOADING:
      return {
        ...state,
        isPackagesTypesLoading: true,
        error: null,
      };
    default:
      return {
        ...state,
      };
  }
}
