import {
  CModalHeader,
  CInput,
  CButton,
  CModal,
  CModalBody,
  CForm,
  CFormGroup,
  CModalFooter,
  CLabel,
  CSelect,
} from "@coreui/react";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { fetchCreateImg } from "src/services/ServerService";
import { toastify } from "src/helpers/toast";
import {
  fetchCreateOperator,
  fetchUpdateOperator,
} from "src/services/OperatorService";
import MiniSpinner from "src/components/spinners/MiniSpinner";
import { fetchOffices } from "src/services/CompanyService";
import Select from "react-select";

const OperatorForm = ({
  toggle,
  modal,
  modalTitle,
  selectedOperator,
  reFetchOperators,
}) => {
  const { t } = useTranslation();

  const [btnState, setBtnState] = useState(false);

  const [operator, setOperator] = useState({
    photo: "",
    username: "",
    firstname: "",
    lastname: "",
    middlename: "",
    password: "",
    delivery_office_id: "",
    roles: []
  })

  const [offices, setOffices] = useState([])
  const [roles, setRoles] = useState([])

  useEffect(() => {
    fetchOffices().then(res => res.ok && res.json().then(d=> {
      setOffices(d)
    }))
  }, [])

  useEffect(() => {
    if(selectedOperator) {
      setRoles(selectedOperator.roles?.map(opr => {
        return {
          label: t(opr),
          value: opr,
        }
      }))
      setOperator({...selectedOperator, delivery_office_id: selectedOperator.delivery_office?.id})
    } else {
      setOperator({
        photo: "",
        username: "",
        firstname: "",
        lastname: "",
        middlename: "",
        password: "",
        delivery_office_id: ""
      })
    }
  }, [selectedOperator, t]);

  const handleChange = (e) => {
    let field = e.target.name;
    let val = e.target.value

    setOperator({...operator, [field]: val})
  }

  const takePhoto = (e) => {
    let formData = new FormData();
    formData.append("image", e.target.files[0]);
    fetchCreateImg(formData).then((data) => setOperator({...operator, photo: data.data}));
  };

  const submitOperator = () => {
    console.log(operator)
    setBtnState(true);
    (selectedOperator?.id ? 
      fetchUpdateOperator(
        { ...operator, 
          roles: roles.map(r => r.value),
          delivery_office_id: operator.delivery_office_id || operator.delivery_office?.id || offices[0].id}, 
          operator?.id) : 
      fetchCreateOperator(
        { ...operator, 
          roles: roles.map(r => r.value),
          delivery_office_id: operator.delivery_office_id || operator.delivery_office?.id || offices[0].id}))
      .then((res) => {
        if (res.ok) {
          toastify("success", t("Operation succeded"));
          reFetchOperators();
          setBtnState(false);
          setOperator({})
          toggle();
        } else {
          toastify("error", t("Operation failed"));
          setBtnState(false);
        }
      })
      .catch((err) => {
        toastify("error", t("Network error"));
        setBtnState(false);
      });
  };

  return (
    <>
      <CModal show={modal} onClose={toggle}>
        <CModalHeader closeButton>{modalTitle}</CModalHeader>
        <CModalBody>
            <CForm>
              <CFormGroup>
                <CLabel>{t('Office')}</CLabel>
                <CSelect value={operator.delivery_office_id} onChange={handleChange} name="delivery_office_id">
                  {offices.map(off => <option key={off.id} value={off.id}>{off.name}</option>)}
                </CSelect>
              </CFormGroup>
              <CFormGroup>
                <CLabel>{t('Username')}</CLabel>
                <CInput
                  type="text"
                  placeholder={t("Username")}
                  name="username"
                  value={operator.username || ""}
                  onChange={handleChange}
                  disabled={selectedOperator?.id}
                />
              </CFormGroup>
            <CFormGroup>
              <CLabel>{t("Firstname")}</CLabel>
              <CInput
                type="text"
                placeholder={t("Firstname")}
                name="firstname"
                value={operator.firstname}
                onChange={handleChange}
              />
            </CFormGroup>
            <CFormGroup>
              <CLabel>{t("Lastname")}</CLabel>
              <CInput
                type="text"
                placeholder={t("Lastname")}
                name="lastname"
                value={operator.lastname}
                onChange={handleChange}
              />
            </CFormGroup>
            <CFormGroup>
              <CLabel>{t("Middlename(not required)")}</CLabel>
              <CInput
                type="text"
                placeholder={t("Middlename(not required)")}
                name="middlename"
                value={operator.middlename}
                onChange={handleChange}
              />
            </CFormGroup>
            <CFormGroup>
              <CLabel>{t("Password")}</CLabel>
              <CInput
                type="password"
                placeholder={t("Password")}
                name="password"
                value={operator.password || ""}
                onChange={handleChange}
              />
            </CFormGroup>
            <CFormGroup>
              <CLabel>{t("Role")}</CLabel>
              <Select
                isMulti
                onChange={(e) => setRoles(e)}
                value={roles}
                options={[
                  {label: t("Operator"), value: "operator"}, 
                  {label: t("Cashier"), value: "cashier"}, 
                ]}
              />
            </CFormGroup>
            <CFormGroup>
              <CLabel>{t('Photo')}</CLabel>
              <CInput type="file" name="photo" onChange={(e) => takePhoto(e)} />
            </CFormGroup>
          </CForm>
        </CModalBody>
        <CModalFooter>
            <CButton
              variant="outline"
              color="success"
              disabled={btnState}
              onClick={() => submitOperator()}
            >
              {btnState ? <MiniSpinner /> : (selectedOperator?.id ? t("Update") : t("Create"))}
            </CButton>
          <CButton
            variant="outline"
            color="danger"
            onClick={toggle}>
            {t("Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default OperatorForm;
