import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  fetchCourierCompaniesAddresses,
  fetchCourierCompanyAddresses,
} from "src/services/CityService";
import { fetchCompanies } from "src/services/CompanyService";
import Select from "react-select";
import { CCard, CCardBody, CCardHeader } from "@coreui/react";
export default function CourierCompanyAddresses() {
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");
  const dispatch = useDispatch();
  const [addresses, setAddresses] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState({
    label: lang === "ru" ? "Выбрать компанию" : "Компанияны тандоо",
    value: 0,
  });
  // console.log(addresses && addresses);

  useEffect(() => {
    fetchCourierCompaniesAddresses().then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          // console.log(data);
          setAddresses(data)});
      } else {
        console.log(res.statusText);
      }
    });

    fetchCompanies().then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          // console.log(data);
          setCompanies(data);
        });
      } else {
        console.log(res.statusText);
      }
    });
  }, [dispatch]);

  return (
    <CCard className="m-2">
      <CCardHeader>
        {companies && (
          <Select
            value={company}
            options={[
              {
                name: lang === "ru" ? "Выбрать компанию" : "Компанияны тандоо",
                id: 0,
              },
              ...companies,
            ].map((c) => {
              return { value: c.id, label: c.name };
            })}
            onChange={(e) => {
              setCompany(e);
              console.log(e);
              if (e.value > 0) {
                fetchCourierCompanyAddresses(e.value).then((res) => {
                  if (res.ok) {
                    res.json().then((data) => {
                      setAddresses(data)
                      // console.log('filtered', data);
                    });
                  } else {
                    console.log(res.statusText);
                  }
                });
              } else {
                fetchCourierCompaniesAddresses().then((res) => {
                  if (res.ok) {
                    res.json().then((data) => setAddresses(data));
                  } else {
                    console.log(res.statusText);
                  }
                });
              }
            }}
          />
        )}
      </CCardHeader>
      <CCardBody>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>{t("City")}</th>
              <th>{t("District")}</th>
              <th>{t("Address")}</th>
              <th>{t("Latitude")}</th>
              <th>{t("Longitude")}</th>
              <th>{t("Courier Company")}</th>
            </tr>
          </thead>
          <tbody>
            {addresses &&
              addresses.map((a) => (
                <tr key={a.id}>
                  <td>
                    {a.city &&
                      (lang === "ru" ? a.city.name_ru : a.city.name_kg)}
                  </td>
                  <td>
                    {a.district &&
                      (lang === "ru" ? a.district.name_ru : a.district.name_kg)}
                  </td>
                  <td>{lang === "ru" ? a.name_ru : a.name_kg}</td>
                  <td>{a.lat}</td>
                  <td>{a.lng}</td>
                  <td>{a.delivery_service.name}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </CCardBody>
    </CCard>
  );
}
