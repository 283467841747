import CIcon from '@coreui/icons-react';
import { CButton, CCard, CCardBody, CCol, CInput, CLabel, CNav, 
    CNavItem, CNavLink, CPopover, CRow, CSelect, CTabContent, 
    CTabPane, CTabs } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { toastify } from 'src/helpers/toast';
import { fetchCouriersShifts } from 'src/services/CourierService';


export default function Schedules() {

    const { t } = useTranslation();
    const [numberOrders, setNumberOrders] = useState(null)

    const [activeTab, setActiveTab] = useState("couriers");
    const [courierShifts, setCourierShifts] = useState([])

    const [searchKey, setSearchKey] = useState("")

    
    useEffect(() => {
        fetchCouriersShifts().then(res => {
            res.ok && res.json().then(d => {
                console.log(d)
                let ccs = []
                d.forEach(dd => {
                    if(ccs.filter(c => c.id === dd.user.id).length > 0) {
                        ccs.filter(c => c.id === dd.user.id)[0].data.push(dd)
                    } else {                        
                        let obj = {...dd.user}
                        obj.data = [dd]
                        ccs.push(obj)
                    }
                })
                console.log(ccs)
                setCourierShifts(ccs)
            })
        })

        let nums = [];
        for(let i = 1; i<32; i++) {
            nums.push(i)
        }
        setNumberOrders(nums)
    }, [])

    const searchIn = () => {
        console.log(searchKey)
        toastify("info","Search is under progress " + searchKey)
    }


    const getCurrentDayData = (day, data) => {
        return data.data.filter(cd => day === new Date(cd.start || cd.end).getDate())
    }

    const getCurrentDayStartOrEnd = (currentDayData, type) => {
        if(type === "start" && currentDayData[0]?.start) {
            const d = new Date(currentDayData[0].start)
            return (d.getHours() < 10 ? "0" : "" + d.getHours()) + ":" + (d.getMinutes() < 10 ? "0" : "" + d.getMinutes())
        }
        if(type === "end" && currentDayData[0]?.end) {
            const d = new Date(currentDayData[0].end)
            return (d.getHours() < 10 ? "0" : "" + d.getHours()) + ":" + (d.getMinutes() < 10 ? "0" : "" + d.getMinutes())
        }
    }
    
    return (
        <div>
        <CCard>
            <CCardBody>
                <CRow className="mb-2">
                    <CCol sm="6">
                        <div className="d-flex">
                            <CInput placeholder="Поиск по ID и ФИО" onChange={e => setSearchKey(e.target.value)}/>
                            <CButton color="primary" className="ml-1" onClick={searchIn}>{t("Search")}</CButton> 
                        </div> 
                    </CCol>
                    <CCol sm="2">
                    </CCol>
                    <CCol sm="4">
                        <div className="d-flex">
                            <CLabel className="mt-2 mx-2">Месяц</CLabel>
                            <CSelect defaultValue="0">
                                <option value="0">Сентябрь</option>
                            </CSelect>
                            <CLabel className="mt-2 mx-2">Год</CLabel>
                            <CSelect defaultValue="0">
                                <option value="0">2021</option>
                            </CSelect> 
                        </div>
                    </CCol>
                </CRow>
                <CTabs activeTab={activeTab}>
                    <CNav variant="tabs">
                        <CNavItem>
                            <CNavLink data-tab="couriers" onClick={() => setActiveTab("couriers")}>
                                {t("Couriers")}
                            </CNavLink>
                        </CNavItem>
                        <CNavItem>
                            <CNavLink data-tab="notices" onClick={() => setActiveTab("notices")}>
                                {t("Примечания")}
                            </CNavLink>
                        </CNavItem>
                    </CNav>
                        
                    <CTabContent>
                        <CTabPane data-tab="couriers">
                            <div className="table-responsive-md">
                                <table className="table table-sm table-bordered mt-2" style={{fontSize: "12px"}}>
                                    <thead>
                                        <tr>
                                            <th className="bg-secondary text-dark">ID</th>
                                            <th className="bg-secondary text-dark">ФИО</th>
                                            {numberOrders && numberOrders.map((no, i) =>
                                                <th key={i}className="text-center">{no}</th>                                
                                            )}
                                            <th className="text-center bg-secondary">Итого</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {courierShifts.map((c, i) => 
                                            <tr key={i}>
                                                <td>{c.id}</td>
                                                <td>{c.firstname + " " + c.lastname}</td>
                                                {numberOrders && numberOrders.map((no, i) =>
                                                    <td key={i} className="text-center" style={{backgroundColor: (no % 7 === 0 || (no % 7)-6 === 0) && "#FFEBEB"}}>
                                                        <CPopover
                                                            content={
                                                            <div>
                                                                <b>{t("Начало смены")}:</b> {getCurrentDayStartOrEnd(getCurrentDayData(no,c), "start")} <br/>
                                                                <b>{t("Конец смены")}:</b> {getCurrentDayStartOrEnd(getCurrentDayData(no,c), "end")} <br/>
                                                                <b>{t("Выполнено заказов")}:</b>  <br/>
                                                                {/* <b>{t("Отработано")}:</b> 10ч <br/> */}
                                                                <b>{t("Примечание")}:</b> текст примечания
                                                            </div>}
                                                            placement="right"
                                                            trigger="click"
                                                        >
                                                            <span>
                                                                {new Date().getDate() >= no &&
                                                                    <CIcon name={getCurrentDayData(no, c).length > 0 
                                                                        ? "cilCheckAlt" : "cilX"} size="sm" 
                                                                    color={getCurrentDayData(no, c).length > 0 ? "red" : "green"}/>
                                                                }
                                                            </span>
                                                        </CPopover>
                                                    </td>                                
                                                )}
                                                <td className="text-center">
                                                    <CPopover
                                                        content={<div><b>{t("Всего заказов")}:</b> </div>}
                                                        placement="right"
                                                        trigger="click"
                                                    >
                                                        <span style={{cursor: "pointer"}}> </span>
                                                    </CPopover>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </CTabPane>
                        <CTabPane data-tab="notices">

                        </CTabPane>
                    </CTabContent>
                </CTabs>
                
            </CCardBody>
        </CCard>
        </div>
    )
}