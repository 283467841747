import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { TheHeader } from '.'
import TheSidebar from './TheSidebar'
import {useDispatch, useSelector} from 'react-redux'
import { getOrders } from 'src/redux/actions/orderActions'
import { getCouriers } from 'src/redux/actions/courierActions'
import { getCompany, getProfile } from 'src/redux/actions/authActions'
import { getPackagesTypes } from 'src/redux/actions/addressActions'

export default function DefaultLayout({children}) {

    const history = useHistory()
    const dispatch = useDispatch()

    const profile = useSelector(state => state.auth.profile)
    const isAuth = useSelector(state => state.auth.isAuth)


    useEffect(() => {
        if(!isAuth && !profile) {
            history.push("/login")
        }
    }, [isAuth, history, profile])

    useEffect(() => {
        dispatch(getOrders({isPartner: profile?.roles.includes('partner') ? "partner" : ""}));
    },[dispatch, profile])

    useEffect(() => {
        if(isAuth) {
            dispatch(getCouriers());
            dispatch(getProfile());
            dispatch(getCompany());
            dispatch(getPackagesTypes());
        }
    }, [dispatch, isAuth]);

    return (
        <div className="c-app c-default-layout">
            <TheSidebar />
            <div className="c-wrapper">
            <TheHeader />
            <div className="c-body">{children}</div>
            </div>
        </div>
    )
    
}
