import React, { useState } from 'react';
import {CModal, CModalHeader, CModalBody, CModalFooter, CForm, CFormGroup, CInput, CRow, CButton, CLabel} from "@coreui/react"
import { useTranslation } from 'react-i18next';
import { fetchCourierBalanceReplenish } from 'src/services/CourierService';
import { toastify } from 'src/helpers/toast';
import { useDispatch } from 'react-redux';
import { getCouriers } from 'src/redux/actions/courierActions';

export default function CourierBalanceReplenish (props) {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [amount, setAmount] = useState(0);
    const [description, setDescription] = useState("");

    const submitReplenish = () => {
        fetchCourierBalanceReplenish({amount, comment: description}, props.selectedCourier.id).then(res => {
            if(res.ok) {
                console.log(res);
                toastify("success", t("Balance replenished successfully"));
                dispatch(getCouriers());
                props.onToggle();
            } else {
                console.log("error");
                toastify("error", t("Balance replenish failed"));
            }
        }).catch(err => {
          console.log(err);
          toastify("error", t("Balance replenish failed"));
        })
    }

    return(
        <CModal show={props.isOpen} onClose={props.onToggle}>
          <CModalHeader closeButton>{t(props.modalTitle)}</CModalHeader>
          <CModalBody>
            {props.selectedCourier && (
              <CForm>
                    <h5>
                        {t("Replenish balance for ")} {props.selectedCourier?.firstname}
                    </h5>
                <CFormGroup>
                    <CLabel>{t("Balance amount")}</CLabel>
                    <CInput placeholder={t("Balance amount")} type="number" value={amount} onChange={(e) => setAmount(e.target.value)}/>
                </CFormGroup>
                <CFormGroup>
                    <CLabel>{t("Comments")}</CLabel>
                    <CInput placeholder={t("Comments")}  value={description} onChange={(e) => setDescription(e.target.value)}/>
                </CFormGroup>
              </CForm>
            )}
          </CModalBody>
          <CModalFooter>
            {props.selectedCourier && (
              <CRow>
                <CButton
                  color="info"
                  onClick={() => submitReplenish()}
                  className="mr-2"
                >
                  {t("Пополнить")}
                </CButton>
              </CRow>
            )}
          </CModalFooter>
        </CModal>
    )
}