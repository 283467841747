import { createApi } from './ServerService';
import { DELETE, PATCH, POST } from './types';

// Package Types

// /api/v1/company/packages_types/ GETALL
export const fetchCompanyPackageTypes = () => {
    return createApi("/api/v1/company/package_types/");
}

// /api/v1/company/package_types/{location_id} GET
export const fetchCompanyPackageType = (id) => {
    return createApi("/api/v1/company/package_types/"+id + "/");
}

// /api/v1/company/package_types/{location_id} CREATE 
export const fetchCompanyPackageTypeCreate = (packageForm) => {
    return createApi("/api/v1/company/package_types/", {method: POST, body: packageForm});
}

// /api/v1/company/package_types/{location_id} UPDATE
export const fetchCompanyPackageTypeUpdate = (packageForm, id) => {
    return createApi("/api/v1/company/package_types/"+id + "/", {method: PATCH, body: packageForm});
}

// /api/v1/company/package_types/{location_id} DELETE
export const fetchCompanyPackageTypeDelete = (id) => {
    return createApi("/api/v1/company/package_types/"+id + "/", {method: DELETE});
}


// Adresses

// /api/v1/company/addresses/ GETALL
export const fetchCompanyAddresses = () => {
    return createApi("/api/v1/company/addresses/?limit=200");
}

export const fetchCompanyAddressesOwn = () => {
    return createApi("/api/v1/company/addresses/own/");
}

export const fetchCompanyAddressesByParams = (skipAmount, limit) => {
    return createApi(`/api/v1/company/addresses/own/?skip=${skipAmount}&limit=${limit}`);
}

export const fetchCompanyAddressesOwnSearch = (key, limit) => {
    return createApi(`/api/v1/company/addresses/own/?search=${key}&limit=${limit}`);
}
// /api/v1/company/addresses/{location_id} GET
export const fetchCompanyAddress = (id) => {
    return createApi("/api/v1/company/addresses/" + id + "/");
}

// /api/v1/company/addresses/{location_id} CREATE
export const fetchCompanyAddressCreate = (addressForm) => {
    return createApi("/api/v1/company/addresses/", {method: POST, body: addressForm});
}

// /api/v1/company/addresses/{location_id} DELETE
export const fetchCompanyAddressDelete = (id) => {
    return createApi("/api/v1/company/addresses/" + id + "/", {method: DELETE});
}

// /api/v1/company/addresses/{location_id} UPDATE
export const fetchCompanyAddressUpdate = (addressForm, id) => {
    return createApi("/api/v1/company/addresses/" + id + "/", {method: PATCH, body: addressForm});
}

// /api/v1/company/statuses get statuses
export const fetchCompanyStatuses = () => {
    return createApi("/api/v1/company/statuses/");
}

// /api/v1/company/colors patch colors
export const fetchCompanyColor = (statusColor) => {
    return createApi("/api/v1/company/colors/", {method: PATCH, statusColor});
}

// /api/v1/company/fees get fees
export const fetchCompanyExtraFees = () => {
    return createApi("/api/v1/company/fees/");
}

// /api/v1/company/fees get fees
export const fetchCompanyExtraFeesSearch = (key) => {
    return createApi("/api/v1/company/fees/?search="+ key);
}

// /api/v1/company/fees create fee
export const fetchCompanyExtraFeePost = (body) => {
    return createApi("/api/v1/company/fees/", {method: POST, body: body});
}

// /api/v1/company/fees delete fee
export const fetchCompanyExtraFeeDelete = (id) => {
    return createApi("/api/v1/company/fees/" + id + "/", {method: POST});
}

// /api/v1/company/fees update fee
export const fetchCompanyExtraFeePatch = (body, id) => {
    return createApi("/api/v1/company/fees/" + id + "/", {method: PATCH, body: body});
}

// /api/v1/company/profile/ GETCOMPANYPROFILE
export const fetchCompanyProfile = () => {
    return createApi("/api/v1/company/profile/");
}

// /api/v1/company/profile/ GETCOMPANYPROFILE
export const fetchStaffProfile = () => {
    return createApi("/api/v1/auth/staff/profile/");
}

// /api/v1/companies/ GETCOMPANIES
export const fetchCompanies = () => {
    return createApi("/api/v1/companies/");
}

// /api/v1/companies/ POSTCOMPANIES
export const fetchCreateCompanies = (companyForm) => {
    return createApi("/api/v1/companies/", {method: POST, body: companyForm});
}

// /api/v1/companies/ GETONECOMPANIES
export const fetchCompany = (id) => {
    return createApi("/api/v1/companies/" + id + "/");
}

// /api/v1/companies/ PATCHCOMPANIES
export const fetchUpdateCompanies = (companyForm, id) => {
    return createApi("/api/v1/companies/" + id + "/", {method: PATCH, body: companyForm});
}


// /api/v1/delivery_office/ GETdelivery_office
export const fetchOffices = () => {
    return createApi("/api/v1/delivery_office/");
}

// /api/v1/delivery_office/ GETdelivery_office
export const fetchOfficesAll = () => {
    return createApi("/api/v1/delivery_office/all/");
}


// /api/v1/delivery_office/ POSTdelivery_office
export const fetchCreateOffice = (officeForm) => {
    return createApi("/api/v1/delivery_office/", {method: POST, body: officeForm});
}

// /api/v1/delivery_office/ GETONEdelivery_office
export const fetchOffice = (id) => {
    return createApi("/api/v1/delivery_office/" + id + "/");
}

// /api/v1/delivery_office/ PATCHdelivery_office
export const fetchUpdateOffice = (officeForm, id) => {
    return createApi("/api/v1/delivery_office/" + id + "/", {method: PATCH, body: officeForm});
}

// /api/v1/delivery_office/ PATCHdelivery_office
export const fetchDeleteOffice = (id) => {
    return createApi("/api/v1/delivery_office/" + id + "/", {method: DELETE});
}


// /api/v1/company/addresses/ GETALL
export const fetchCompanyAddressesSearch = (search) => {
    return createApi("/api/v1/company/addresses/?limit=20&search=" + search);
}

// /api/v1/company/addresses/ GETALL
export const fetchAdmins = () => {
    return createApi("/api/v1/users/admin/");
}

export const fetchSmsSettings = () => {
    return createApi("/api/v1/sms-settings/");
}

export const fetchCreateSmsSettings = (body) => {
    return createApi("/api/v1/sms-settings/", {method: POST, body: body});
}

export const fetchDeleteSmsSettings = (id) => {
    return createApi("/api/v1/sms-settings/" + id + "/?sms_setting_id=" +id, {method: DELETE});
}

export const fetchUpdateSmsSettings = (id, body) => {
    return createApi("/api/v1/sms-settings/" + id + "/", {method: PATCH, body: body});}

export const fetchAllOrders = (params) => {
    let url = "/api/v1/orders/all/"
    if(params) {
      url = url + "?" + params
    }
    return createApi(url);
}