import { CBadge, CButton, CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useModalState } from 'src/helpers/useModal';
import PaymentDetails from './PaymentDetails';
import InstantUpdataPayment from './InstantUpdatePayment';
import CIcon from '@coreui/icons-react';
import { useHistory } from "react-router-dom"
import { useTranslate } from 'src/helpers/useTranslate';
import { fetchPaymentUpdate } from 'src/services/ReportsService';
import { toastify } from 'src/helpers/toast';
import paid from 'src/assets/paid.svg'
import no_paid from 'src/assets/no_paid.svg'
import OrderDetails from 'src/pages/orders/Order-Details/OrderDetails';

export default function PaymentsTable({payments, onSuccess}) {

    const history = useHistory()
    const { onOpen, onClose, isOpen } = useModalState()
    
    const [modalType, setModalType] = useState(null)
    const [selectedPayment, setSelectedPayment] = useState(null)
    const [selectedOrder, setSelectedOrder] = useState(null)
    const [orderModalShow, setOrderModalShow] = useState(false)

    const  {t} = useTranslation()
    const {tr} = useTranslate()

    const showPaymentDetails = (payment) => {
        setSelectedPayment(payment)
        setModalType("details"); 
        onOpen()
    }

    const showOrderDetails = (payment) => {
        setSelectedOrder(payment)
        setOrderModalShow(true)
    }

    const instantUpdate = (payment) => {
        setSelectedPayment(payment)
        setModalType("update"); 
        onOpen()
    }

    const submitUpdate = () => {
        fetchPaymentUpdate(selectedPayment?.id, {...selectedPayment, is_paid: true}).then(res => {
            if(res.ok) {
                toastify("success", t("Operation succeeded"))
                onSuccess()
                onClose()
            } else {
                toastify("error", t("Operation failed"))
            }
        })
    }

    const destruct = (order, ordering) => {
        const currentPayment = order?.payments?.filter(p=> p.type==="redemption" || p.type==="imposed")
            .filter(p => p.ordering === ordering)[0]
        if(currentPayment) {
            return {
                oid: order.oid, 
                ...currentPayment, 
                redemption_type: order?.payments?.filter(p=> p.type==="redemption" || p.type==="imposed")
                    .filter(p => p.ordering === 1)[0]?.type}
        } else {
            return false
        }
    }

    const payer = (pay) => {
        switch (pay.ordering) {
            case 1:
                return pay.payer
            case 2:
                return pay.client
            case 3:
                return pay.payer
            default:
                return pay.payer
        }
    }
    
    const payReceiver = (pay) => {
        switch (pay.ordering) {
            case 1:
                return pay.client
            case 2:
                return pay.receiver
            case 3:
                return pay.receiver
            default:
                return pay.receiver
        }
    }

    const setRole = (r) => {
        switch (r) {
            case 'courier':
                return 'Кур.'
            default:
                return 
        }
    }

    return (
        <div className='table-responsive'>
            <table className="table" id="excel-payments">
                    <thead>
                        <tr>
                            <th>{t("Order ID")}</th>
                            <th>{t("Sender")} <CIcon name="cilArrowRight"/> {t("Receiver")}</th>
                            <th>{t("Redemption")}</th>
                            <th colSpan="2">{t("Redemption status")}</th>
                            <th colSpan="2">{t("Payment for product")}</th>
                            <th colSpan="2">{t("Transfer to office")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {payments.map((payment, i) => <tr key={i}>
                            <td className='sp-link' 
                                    onClick={() => showOrderDetails(payment)}>{payment.oid}</td>
                            <td  className='sp-link' 
                                    onClick={() => showOrderDetails(payment)}>
                                {tr(payment.location_from?.name_ru, payment.location_from?.name_kg)}
                                <CIcon name='cilArrowRight'/>
                                {tr(payment.location_to?.name_ru, payment.location_to?.name_kg)}
                                    <br />
                                <span className='text-dark' style={{opacity: "0.9"}}>
                                    {payment.description}
                                </span>
                            </td>
                            <td>
                                <CBadge color={payment.redemption_is_paid ? "success" : "danger"}>{payment.redemption_amount} c</CBadge>
                            </td>
                            {destruct(payment, 1) && <>
                                <td className="sp-link" onClick={() => showPaymentDetails(destruct(payment, 1))}>
                                    {payer(destruct(payment, 1))?.fullname || payer(destruct(payment, 1))?.firstname} <span className="font-weight-bold">({setRole(payer(destruct(payment, 1))?.roles[0])})</span>
                                    <CIcon name="cilArrowRight"/>
                                    {payReceiver(destruct(payment, 1))?.fullname || payReceiver(destruct(payment, 1))?.firstname} (<span className="font-weight-bold">Пол.</span>)
                                    {/* Курьер <CIcon name="cilArrowRight"/> {t("Sender")}  */}
                                    <br />
                                    <span className='text-dark' style={{opacity: "0.9"}}>
                                        {t(destruct(payment, 1)?.type === "redemption" ? "From cash register" : "Imposed")}
                                    </span>
                                </td>
                                <td className="sp-link" onClick={() => instantUpdate(destruct(payment, 1))}>
                                    <img alt="status" src={destruct(payment, 1).is_paid ? paid : no_paid} />
                                </td>
                            </>}
                            
                            {destruct(payment, 2) && <>
                                <td className="sp-link" onClick={() => showPaymentDetails(destruct(payment, 2))}>
                                    {payer(destruct(payment, 2))?.fullname || payer(destruct(payment, 2))?.firstname}
                                    <CIcon name="cilArrowRight"/>
                                    {payReceiver(destruct(payment, 2))?.fullname || payReceiver(destruct(payment, 2))?.firstname}
                                    {/* {t("Receiver")} <CIcon name="cilArrowRight"/> Курьер */}
                                </td>
                                <td className="sp-link" onClick={() => instantUpdate(destruct(payment, 2))}>
                                    <img alt="status" src={destruct(payment, 2).is_paid ? paid : no_paid} />
                                </td>
                            </>}
                            
                            {destruct(payment, 3) && <>
                                <td className="sp-link" onClick={() => showPaymentDetails(destruct(payment, 3))}>
                                    {payer(destruct(payment, 3))?.fullname || payer(destruct(payment, 3))?.firstname}
                                    <CIcon name="cilArrowRight"/>
                                    {payReceiver(destruct(payment, 3))?.fullname || payReceiver(destruct(payment, 3))?.firstname}
                                    {/* Курьер <CIcon name="cilArrowRight"/> Офис */}
                                </td>
                                <td className="sp-link" onClick={() => instantUpdate(destruct(payment, 3))}>
                                    <img alt="status" src={destruct(payment, 3).is_paid ? paid : no_paid} />
                                </td>
                            </>}

                        </tr>)}
                    </tbody>
                </table>
            {selectedPayment && <CModal show={isOpen} onClose={() => onClose()}>
                <CModalHeader className={modalType === "details" ? "bg-light text-dark" : "bg-success text-dark"}>
                    <CModalTitle>{t("Redemption")} {selectedPayment?.oid}</CModalTitle>
                    <div className='d-flex justify-content-end'>
                        {modalType === "details" && 
                            <CButton size="sm" color="dark" variant='outline' className="mr-2" 
                                onClick={() => history.push("/redemption-payments/edit/" + selectedPayment?.id)}>
                                    <CIcon name="cilPencil" size="sm" className='mr-1'/>
                                    {t("Edit")}
                            </CButton>}
                        <CIcon name="cilX" onClick={() => onClose()} size="xl" className="sp-link"/>
                    </div>
                </CModalHeader>
                <CModalBody>
                    {modalType === "details" ? 
                        <PaymentDetails payer={payer} payReceiver={payReceiver} selectedPayment={selectedPayment}/> 
                    : 
                        <InstantUpdataPayment 
                            payer={payer}
                            payReceiver={payReceiver}
                            selectedPayment={selectedPayment} 
                            setSelectedPayment={setSelectedPayment} 
                            submitUpdate={submitUpdate}/>}
                </CModalBody>
            </CModal>}

            {selectedOrder && <OrderDetails
                isOpen={orderModalShow}
                onToggle={() => setOrderModalShow(!orderModalShow)}
                selectedOrder={selectedOrder}
                setSelectedOrder={setSelectedOrder}
            />}
        </div>
    )
}