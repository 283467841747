import { createApi } from './ServerService';
import { DELETE, PATCH, POST } from "./types";

// /api/v1/partners/
export const fetchPartners = () => {
  return createApi("/api/v1/partners/");
}

export const fetchPartner = (id) => {
  return createApi("/api/v1/partners/" + id + "/");
}

export const fetchCreatePartner = (form) => {
  return createApi("/api/v1/partners/", {method: POST, body: form});
}

export const fetchUpdatePartner = (id, form) => {
  return createApi("/api/v1/partners/" + id + "/", {method: PATCH, body: form});
}

export const fetchDeletePartner = (id) => {
  return createApi("/api/v1/partners/" + id + "/", {method: DELETE});
}

export const fetchPartnerOperators = () => {
  return createApi("/api/v1/partner_operators/");
}

export const fetchPartnerOperator = (id) => {
  return createApi("/api/v1/partner_operators/" + id + "/");
}

export const fetchCreatePartnerOperator = (form) => {
  return createApi("/api/v1/partner_operators/", {method: POST, body: form});}

export const fetchUpdatePartnerOperator = (id, form) => {
  return createApi("/api/v1/partner_operators/" + id + "/", {method: PATCH, body: form});
}

export const fetchDeletePartnerOperator = (id) => {
  return createApi("/api/v1/partner_operators/" + id + "/", {method: DELETE});
}