import React, {useCallback, useState} from 'react';
import {CRow, CCol, CInput} from "@coreui/react"
import {useSelector} from 'react-redux'
import { useTranslation } from 'react-i18next';
import { dateFilters } from 'src/helpers/date';
import FilterButton from '../../../components/FilterButton';
import Select from 'react-select';
import { useTranslate } from 'src/helpers/useTranslate';

export default function FilterBar({statuses, cities, regions, routes, applyFilters, filterParams}){

    const [selectedCourierList, setSelectedCourierList] = useState([])
    const [selectedRegionList, setSelectedRegionList] = useState([])
    const [selectedCityList, setSelectedCityList] = useState([])
    const [selectedRoutesList, setSelectedRoutesList] = useState([])
    const [selectedStatusList, setSelectedStatusList] = useState([])

    const { t } = useTranslation();
    const { tr } = useTranslate();
    
    const couriers = useSelector(state => state.courier.couriers);

    const courierSelectChange = useCallback((list) => {
        setSelectedCourierList(list)
        const newParams = {...filterParams, courier_id: list.map(l => l.value)}
        applyFilters(newParams)
    }, [filterParams, applyFilters])

    const regionSelectChange = useCallback((list) => {
        setSelectedRegionList(list)
        const newParams = {
            ...filterParams, 
            region_id: list.map(l => l.value), 
            city_id: selectedCityList.filter(city => list.some(l => city.id ===l.id)).map(c => c.value)}
        applyFilters(newParams)
    }, [filterParams, applyFilters, selectedCityList])

    const citySelectChange = useCallback((list) => {
        setSelectedCityList(list)
        const newParams = {...filterParams, city_id: list.map(l => l.value)}
        applyFilters(newParams)
    }, [filterParams, applyFilters])

    const routeSelectChange = useCallback((list) => {
        setSelectedRoutesList(list)
        const newParams = {...filterParams, route_id: list.map(l => l.value)}
        applyFilters(newParams)
    }, [filterParams, applyFilters])

    const statusSelectChange = useCallback((list) => {
        setSelectedStatusList(list)
        const newParams = {...filterParams, status: list.map(l => l.value)}
        applyFilters(newParams)
    }, [filterParams, applyFilters])

    const filterOrdersByDate = useCallback((date_from, date_to, name) => {
        const newParams = {...filterParams, date_from: date_from, date_to: date_to, dateName: name}
        applyFilters(newParams);
    }, [filterParams, applyFilters]);
    
    const courierListCompose = useCallback((list) => {
        return list.map(l => ({value: l.id, label: l.firstname, ...l}))
    }, [])

    const regionListCompose = useCallback((list) => {
        return list.map(l => ({value: l.id, label: tr(l.name_ru, l.name_kg), ...l}))
    }, [tr])

    const cityListCompose = useCallback((list) => {
        return list.map(l => ({value: l.id, label: tr(l.name_ru, l.name_kg), ...l}))
    }, [tr])

    const routeListCompose = useCallback((list) => {
        return list.map(l => ({value: l.id, label: tr(l.name_ru, l.name_kg), ...l}))
    }, [tr])
    
    const statusListCompose = useCallback((list) => {
        return list.map(l => ({value: l.value, label: tr(l.name_ru, l.name_kg), ...l}))
    }, [tr])

    return (
        <div className='px-2'>
            <CRow className="my-1">
                <CCol sm="5">
                    <div style={{display: "inline"}}>
                        <FilterButton
                            onClick={() => filterOrdersByDate(null, null, null)}
                            borderColor={'dark'}
                            isActive={!filterParams.dateName}
                            text={'Все'}
                        />
                        {dateFilters.map((df, i) => 
                            <FilterButton
                                key={'dat' + i}
                                onClick={() => 
                                    filterOrdersByDate(df.date_from.toISOString().slice(0, 10), df.date_to.toISOString().slice(0, 10), df.name)}
                                borderColor={'dark'}
                                isActive={filterParams.dateName === df.name}
                                text={t(df.name)}
                            />
                        )}
                    </div>
                </CCol>
                <CCol sm="7">
                    <CRow>
                        <CCol>
                            <CInput type="date" 
                                onChange={e => filterOrdersByDate(e.target.value, filterParams.date_to)} 
                                value={filterParams?.date_from}
                            />
                        </CCol>
                        <CCol>
                            <CInput type="date" 
                                onChange={e => filterOrdersByDate(filterParams.date_from, e.target.value)} 
                                value={filterParams?.date_to}
                            />
                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
            <CRow>
                <CCol className='my-1' sm='3'>
                    <Select 
                        isMulti
                        isClearable
                        options={courierListCompose(couriers)}  
                        onChange={res => courierSelectChange(res)} 
                        value={courierListCompose(selectedCourierList)}
                        placeholder={t("Courier")}
                    />
                </CCol>
                <CCol className='my-1' sm='3'>
                    <Select 
                        isMulti
                        isClearable
                        options={regionListCompose(regions)}  
                        onChange={res => regionSelectChange(res)} 
                        value={regionListCompose(selectedRegionList)}
                        placeholder={t("Region")}
                    />
                </CCol>
                <CCol className='my-1 d-none' sm='3'>
                    <Select 
                        isMulti
                        isClearable
                        options={routeListCompose(routes)}  
                        onChange={res => routeSelectChange(res)} 
                        value={routeListCompose(selectedRoutesList)}
                        placeholder={t("Routes")}
                    />
                </CCol>
                <CCol className='my-1' sm='3'>
                    <Select 
                        isMulti
                        isClearable
                        options={cityListCompose(cities.filter(city => selectedRegionList.some(reg => reg.id === city.region?.id)))}  
                        onChange={res => citySelectChange(res)} 
                        value={cityListCompose(selectedCityList.filter(city => selectedRegionList.some(reg => reg.id === city.region?.id)))}
                        placeholder={t("Районы/Города")}
                    />
                </CCol>
                <CCol className='my-1' sm='3'>
                    <Select 
                        isMulti
                        isClearable
                        options={statusListCompose(statuses)}  
                        onChange={res => statusSelectChange(res)} 
                        value={statusListCompose(selectedStatusList)}
                        placeholder={t("Status")}
                    />
                </CCol>
            </CRow>
        </div>
    )
}

