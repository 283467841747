export const emptyFormData = {
  photo: null,
  username: "",
  password: "",
  firstname: "",
  middlename: "",
  lastname: "",
  passport_front: null,
  passport_back: null,
  routes: [],
  transport_id: "",
  transport_model: "",
  auto_number: "",
  transport_images: [],
  max_weight: "",
  max_volume: "",
  city_id: "",
  district_id: "",
  delivery_office_id: "",
  roles: [],
  contract: null,
};

export const statusList = [
  { name: "online", id: 1 },
  { name: "on_delivery", id: 2 },
  { name: "on_wait", id: 3 },
  { name: "emergency", id: 4 },
  { name: "at_lunch", id: 5 },
  { name: "offline", id: 6 },
];

export const sideBarPages = [
  { id: 1, name: "personalData", title: "Personal information" },
  { id: 2, name: "passportData", title: "Passport data" },
  { id: 3, name: "transportAndRoutes", title: "Transport and routes" },
  { id: 4, name: "contractData", title: "Contract with company" },
];

export const requiredFields = [
  "username",
  "firstname",
  "lastname",
  "delivery_office",
  "transport",
  "routes",
  "passport_front",
  "passport_back",
];