import {
  CCard,
  CCardBody,
  CNavItem,
  CTabs,
  CNav,
  CTabPane,
  CNavLink,
  CButton,
  CTabContent,
  CCardHeader,
  CTooltip,
  CInput, CModal, CModalHeader, CModalBody, CForm, CFormGroup, CLabel, CModalFooter, CRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select'
import {
  getCities,
  getAllDistricts,
  getDistricts,
  getSearchedDistricts,
  getSearchedCities, getAllRegions,
} from "src/redux/actions/locationActions";
import { useTranslation } from "react-i18next";
import { setBreadcrumb } from "src/redux/actions/settingsActions";
import {useConfirmModal, useModalState} from "src/helpers/useModal";
import CIcon from "@coreui/icons-react";
import ObjectForm from "./ObjectForm";
import RegionForm from "./RegionForm";
import {useTranslate} from "../../helpers/useTranslate";
import {fetchDeleteCity, fetchDeleteDistrict, fetchDeleteRegion} from "../../services/CityService";
import {toastify} from "../../helpers/toast";

export default function Cities() {
  const {isConfirmOpen, onConfirmToggle} = useConfirmModal();
  const { t } = useTranslation();
  const { tr } = useTranslate()
  const lang = localStorage.getItem("lang");
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("cities");
  const [modalTitle, setModalTitle] = useState("Modal Title");
  const { isOpen, onToggle } = useModalState();
  const [selectedObject, setSelectedObject] = useState({});
  const [selectedRegion, setSelectedRegion] = useState({})
  const [selectedRegionForSearch, setSelectedRegionForSearch] = useState(null)

  const cities = useSelector((state) => state.locations.cities);
  const districts = useSelector((state) => state.locations.districts);
  const regions = useSelector((state) => state.locations.regions)

  const newObjectForm = (key) => {
    if (key === "d") {
      setSelectedObject({ type: "district" });
      setModalTitle(t("Create district"));
    } else if (key === "c") {
      setSelectedObject({ type: "city" });
      setModalTitle(t("Create city"));
    }
    onToggle();
  };

  const newRegionForm = () => {
    setModalTitle(t("Create") + " (" + t("Region") + ")");
    setSelectedObject({ type: "region" });
    setSelectedRegion({});
    onToggle();
  };

  const showRegionEdit = (region) => {
    setSelectedRegion(region);
    setSelectedObject({ type: "region" });
    setModalTitle(t("Edit") + " (" + t("Region") + ")");
    onToggle();
  };

  const showObjectEdit = (object, key) => {
    console.log(object, "clicked city")
    if (key === "d") {
      setSelectedObject({ ...object, type: "district" });
      setModalTitle(t("Edit District"));
    }
    if (key === "c") {
      setSelectedObject({ ...object, type: "city" });
      setModalTitle(t("Edit City"));
    }
    onToggle();
  };

  const showObjectDelete = (object, key) => {
    if(key === "c"){
      dispatch(setBreadcrumb(["Cities", t("Delete city")]));
      setSelectedObject({...object, type: "city"});
      setModalTitle(t("Delete") + " (" + t("City") + ")");
    }
    if(key === "d"){
      dispatch(setBreadcrumb(["Districts", t("Delete district")]));
      setSelectedObject({...object, type: "district"});
      setModalTitle(t("Delete") + " (" + t("District") + ")");
    }
    if(key === "r"){
      dispatch(setBreadcrumb(["Regions", t("Delete region")]));
      setSelectedObject({...object, type: "region"});
      setModalTitle(t("Delete") + " (" + t("Region") + ")");
    }

    onConfirmToggle();
  };


  const onObjectDelete = (selectedObject) => {
    if (selectedObject.type === "region"){
      fetchDeleteRegion(selectedObject.id)
          .then((res) => {
            if (res.ok){
              toastify("success", t("Operation succeeded"));
              dispatch(getAllRegions());
            } else{
              toastify("error", t("Operation failed"));
            }
          })
          .catch((err) => toastify("error", t("Operation failed")))
          .finally(onConfirmToggle)
    }

    if (selectedObject.type === "city"){
      fetchDeleteCity(selectedObject.id)
          .then((res) => {
            if (res.ok){
              toastify("success", t("Operation succeeded"));
              dispatch(getCities());
            } else{
              toastify("error", t("Operation failed"));
            }
          })
          .catch((err) => toastify("error", t("Operation failed")))
          .finally(onConfirmToggle)
    }

    if (selectedObject.type === "district"){
      fetchDeleteDistrict(selectedObject.id)
          .then((res) => {
            if (res.ok){
              toastify("success", t("Operation succeeded"));
              dispatch(getAllDistricts());
            } else{
              toastify("error", t("Operation failed"));
            }
          })
          .catch((err) => toastify("error", t("Operation failed")))
          .finally(onConfirmToggle)
    }
  }



  useEffect(() => {
    dispatch(setBreadcrumb(["Geography List"]));
    dispatch(getCities());
    dispatch(getAllDistricts());
    dispatch(getAllRegions())
  }, [dispatch]);
  
  return (
    <CCard className="m-2">
      <CCardHeader>
        <CButton
            className="float-right ml-2 "
            variant="outline"
            color="success"
            onClick={() => {
              newRegionForm();
              dispatch(setBreadcrumb(["Geography List", "Create Region"]));
            }}
        >
          {t("Create new region")}
        </CButton>
        <CButton
          className="float-right ml-2"
          variant="outline"
          color="success"
          onClick={() => {
            newObjectForm("d");
            dispatch(setBreadcrumb(["Geography List", "Create Area"]));
          }}
        >
          {t("Create new area")}
        </CButton>
        <CButton
          className="float-right "
          variant="outline"
          color="success"
          onClick={() => {
            newObjectForm("c");
            dispatch(setBreadcrumb(["Geography List", "Create City"]));
          }}
        >
          {t("Create new city")}
        </CButton>


      </CCardHeader>
      <CCardBody>
        <CTabs activeTab={activeTab}>
          <CNav variant="tabs">
            <CNavItem>
              <CNavLink
                data-tab="cities"
                onClick={() => {
                  setActiveTab("cities");
                  dispatch(setBreadcrumb(["Geography List", "Cities"]));
                }}
              >
                {t("Cities/Districts")}
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                data-tab="adresses"
                onClick={() =>
                  dispatch(setBreadcrumb(["Geography List", t("Districts")]))
                }
              >
                {t("Villages/Mkrs")}
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                  data-tab="regions"
                  onClick={() =>
                      dispatch(setBreadcrumb(["Geography List", t("Regions")]))
                  }
              >
                {t("Regions")}
              </CNavLink>
            </CNavItem>
          </CNav>

          <CTabContent>
            <CTabPane data-tab="cities">
              <table className="table table-hover table-border">
                <thead>
                  <tr>
                    <th colSpan="3">
                      <CInput
                        placeholder={t("Search")}
                        onChange={(e) =>
                          dispatch(getSearchedCities(e.target.value))
                        }
                      />
                    </th>
                  </tr>
                  <tr>
                    <CTooltip content={t("Serial number")}>
                      <th>{t("S/n")} </th>
                    </CTooltip>
                    <th>{t("City, District Name")} </th>
                    <th>{t("Region")} </th>
                    <th>{t("Coordinates")}</th>
                    <th>{t("Actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {cities &&
                    cities.map((city) => (
                      <tr key={city.id}>
                        <td className="text-center" style={{width: "6%"}}>{city.ordering}</td>
                        <td>{lang === "ru" ? city?.name_ru : city?.name_kg}</td>
                        <td>{tr(city.region?.name_ru, city.region?.name_kg)}</td>
                        <td>
                          {t("Latitude")}: {city.lat}
                          <br />
                          {t("Longitude")}: {city.lng}
                        </td>
                        <td>
                          <CIcon
                              name="cilPencil"
                              className="text-warning float-center mr-2"
                              size="xl"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                showObjectEdit(city, "c");
                              }}
                          />
                          <CIcon
                              name="cilTrash"
                              className="text-danger"
                              size="xl"
                              style={{ cursor: "pointer" }}
                              onClick={() => showObjectDelete(city,"c")}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </CTabPane>
            <CTabPane data-tab="adresses">
              <table className="table table-hover table-border">
                <thead>
                  <tr>
                    <th colSpan="3">
                      <CInput
                        placeholder={t("Search")}
                        onChange={(e) =>
                          dispatch(getSearchedDistricts(e.target.value))
                        }
                      />
                    </th>
                    <th>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            minWidth: "250px"
                          }),
                        }}
                        isClearable
                        placeholder={t('Choose by region/city')}
                        value={selectedRegionForSearch}
                        options={cities?.map((o) => {
                          return {
                            value: o.id,
                            label: o.name_ru
                          };
                        })}
                        onChange={(e) => {
                          setSelectedRegionForSearch(e);
                          e?.value ? dispatch(getDistricts(e.value)) : dispatch(getAllDistricts())                           
                        }}
                    />
                  </th>
                  </tr>
                  <tr>
                    <th>#</th>
                    <th>{t("District, area, village name")}</th>
                    <th>{t("Areas city")}</th>
                    <th>{t("Coordinates")}</th>
                    <th>{t("Actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {districts &&
                    districts.map((d, i) => (
                      <tr key={d.id}>
                        <td>{i + 1}</td>
                        <td>{lang === "ru" ? d?.name_ru : d?.name_kg}</td>
                        <td>
                          {lang === "ru" ? d.city?.name_ru : d.city?.name_kg}
                        </td>
                        <td>
                          {t("Latitude")}: {d.lat}
                          <br />
                          {t("Longitude")}: {d.lng}
                        </td>
                        <td>
                          <CIcon
                              name="cilPencil"
                              className="text-warning float-center mr-2"
                              size="xl"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                showObjectEdit(d, "d");
                              }}
                          />
                          <CIcon
                              name="cilTrash"
                              className="text-danger"
                              size="xl"
                              style={{ cursor: "pointer" }}
                              onClick={() => showObjectDelete(d,"d")}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </CTabPane>

            {/*Regions Table*/}
            <CTabPane data-tab="regions">
              <table className="table table-hover table-border">
                <thead>
                <tr>
                  {/* <th colSpan="3">
                    <CInput
                        placeholder={t("Search")}
                        onChange={(e) =>
                            dispatch(getSearchedCities(e.target.value))
                        }
                    />
                  </th> */}
                </tr>
                <tr>
                  <th>{t("Regions")} </th>
                  <th>{t("Coordinates")}</th>
                  <th>{t("Actions")}</th>
                </tr>
                </thead>
                <tbody>
                {regions &&
                regions.map((region) => (
                    <tr key={region.id}>
                      <td>{lang === "ru" ? region?.name_ru : region?.name_kg}</td>
                      <td>
                        {t("Latitude")}: {region.lat}
                        <br />
                        {t("Longitude")}: {region.lng}
                      </td>
                      <td>

                        <CIcon
                            name="cilPencil"
                            className="text-warning mr-2"
                            size="xl"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              showRegionEdit(region);
                            }}
                        />
                        <CIcon
                            name="cilTrash"
                            className="text-danger"
                            size="xl"
                            style={{ cursor: "pointer" }}
                            onClick={() => showObjectDelete(region,"r")}
                        />
                      </td>
                    </tr>
                ))}
                </tbody>
              </table>
            </CTabPane>

          </CTabContent>
        </CTabs>
      </CCardBody>
      {selectedObject.type !== "region" ? <ObjectForm
        toggle={onToggle}
        modal={isOpen}
        modalTitle={modalTitle}
        selectedObject={selectedObject}
      /> :
      <RegionForm
          toggle={onToggle}
          modal={isOpen}
          modalTitle={modalTitle}
          selectedRegion={selectedRegion}
      />}

      {/*Modal for Delete Action*/}
      <CModal show={isConfirmOpen} onClose={onConfirmToggle}>
        <CModalHeader closeButton>{modalTitle}</CModalHeader>
        <CModalBody>
          {selectedObject && (
              <CForm>
                <CFormGroup>
                  <CLabel htmlFor="nf-username">
                    {t("Confirm delete")}  <b>{tr(selectedObject?.name_ru, selectedObject?.name_kg)}</b> ?
                  </CLabel>
                </CFormGroup>
              </CForm>
          )}
        </CModalBody>
        <CModalFooter>
          {selectedObject && (
              <CRow>
                <CButton
                    onClick={() => onObjectDelete(selectedObject)}
                    color="danger"
                    className="mr-2"
                >
                  {t("Delete")}
                </CButton>
              </CRow>
          )}
        </CModalFooter>
      </CModal>

    </CCard>
  );
}
