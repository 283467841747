import { createApi } from "./ServerService";
import { PATCH } from "./types";

export const fetchReports = () => {
  return createApi("/api/v1/orders/reports/")
};

export const fetchReportsWithParams = (params) => {
  const query = [];
  if (params.courier_id) {
    query.push(`courier_id=${params.courier_id}`);
  }
  if (params.organization_id) {
    query.push(`organization_id=${params.organization_id}`);
  }
  if (params.order_type) {
    query.push(`order_type=${params.order_type}`);
  }
  if (params.redemption !== undefined) {
    //иногда отправляю false, а когда false, он не попадает в if, поэтому делаю так, чтобы он заходил всегда, когда не undefined
    query.push(`redemption=${params.redemption}`);
  }
  if (params.date_from) {
    query.push(`date_from=${params.date_from}`);
  }
  if (params.date_to) {
    query.push(`date_to=${params.date_to}`);
  }
  if (params.region_id) {
    query.push(`region_id=${params.region_id}`);
  }
  if (params.client_id) {
    query.push(`client_id=${params.client_id}`);
  }
  return createApi("/api/v1/orders/reports/?" + query.join("&"))
};


export const fetchPayments = (params) => {
  let url = "/api/v1/payments/"
  if(params) { url = url + "?" + params}
  return createApi(url)
}

export const fetchRedemptionPayments = (params) => {
  let url = "/api/v1/payments/redemptions/"
  if(params) { url = url + "?" + params}
  return createApi(url)
}

export const fetchPayment = (id) => {
  let url = "/api/v1/payments/"+id+"/"
  return createApi(url)
}

export const fetchPaymentUpdate = (id, payment) => {
  let url = "/api/v1/payments/"+id+"/"
  return createApi(url, {method: PATCH, body: payment})
}