import React from 'react';
import "./AmanatPrint.css"
import Phone from 'src/assets/icons/phone.png'
import SMS from 'src/assets/icons/message.png'
import SPLogo from "../../../assets/SmartPostLogo.png"
import {useTranslation} from "react-i18next";
import {useTranslate} from "../../../helpers/useTranslate";


// function numberWithSpaces(value, pattern) {
//     let i = 0,
//         phone = value.toString();
//     return pattern.replace(/#/g, _ => phone[i++]);
// }

const PrintStickerAmanat = ({oid, qrCode, courierCompanyLogo, courierCompanyPhone, orderId, sender, receiver, senderPhone, senderAddress, receiverPhone, receiverAddress, comment, redemption, price, isPaid, redemptionPaymentType, payingSide, senderPaymentType, receiverPaymentType }) => {

    const {t} = useTranslation()
    const {tr} = useTranslate()

    const printByCityOrRegion = (address) => {
        let byCity = ''
        let byRegion = ''
        if ((senderAddress?.city || receiverAddress?.city) && senderAddress?.city?.id === receiverAddress?.city?.id){
            if(address && tr(address?.name_ru, address?.name_kg)?.length > 70){
                byCity = tr(address.name_ru, address.name_kg).substring(0,70) + "...";
            } else{
                byCity = tr(address?.name_ru, address?.name_kg)
            }
            return byCity
        }else{

            byRegion = (address?.city ? tr(address.city?.name_ru, address.city?.name_kg) + ", " : "") + (address?.district ? tr(address?.district?.name_ru, address?.district?.name_kg) + ", " : "")  + (tr(address?.name_ru, address?.name_kg)?.length > 65 ? tr(address?.name_ru, address?.name_kg).substr(0, 65) + "..." : tr(address?.name_ru, address?.name_kg))
            return byRegion
        }
    }


    const paymentType = () => {
        console.log("oioioio")
        let res = ""
            if (receiverPaymentType === "invoice" || senderPaymentType === "invoice"){
                res = "Счет Фактура"
            }else{
                if(isPaid){
                    res = "Опл."
                }else{
                    res = "Не опл."
                }
            }
            return res
    }

    return (
        <div className='print-container'>
            <div className="left-table text-center">
                <img className = "courier-logo" src={courierCompanyLogo || SPLogo} alt="Company Logo"/>
                <p className = "company-number">{courierCompanyPhone}</p>
                <img className = "qr-code" src={qrCode} alt="QR Code"/>
                <p className="check-number">{oid || orderId}</p>
                {/* <img className = "logo" src={SPLogo} alt="SP Logo"/> */}
                <span className='d-flex justify-content-between'>{t("Redemption")}: <b>{redemption}⊆</b> {redemptionPaymentType === "imposed" ? "(НВ)" : ""} </span>
                <span className='d-flex justify-content-between'>{t("For delivery")}: <b>{price}⊆</b></span>
                <span className='d-flex justify-content-end'><b>{paymentType()}</b></span>
            </div>
            <div className="right-table">
                <div className="sender-table">
                    <p className = "info-sender-receiver">{t("Sender")}: {sender?.length > 60 ? sender.substring(0,60) + "..." : sender}</p>
                    <div className = "call">
                        <img src={Phone} alt="Phone"/>
                        <p className = "phone-number ml-2">{senderPhone}</p>
                    </div>

                    <p className="info-address"> {printByCityOrRegion(senderAddress)} </p>
                </div>

                <div className={`receiver-table ${ !comment ? 'border-bottom-0' : '' }`}>
                    <p className = "info-sender-receiver">{t("Receiver")}: {receiver ? (receiver?.length > 60 ? receiver?.substring(0,60) + "..." : receiver) : "Надо уточнить"}</p>
                    <div className = "call">
                        <img src={Phone} alt="Phone"/>
                        <p className = "phone-number ml-2">{receiverPhone}</p>
                    </div>
                    <p className='info-address'> {receiverAddress ? printByCityOrRegion(receiverAddress) : "Надо уточнить"}</p>
                </div>

                {
                    (comment?.length>0) &&
                    <div className ="comment-table">
                        <div className="comments-wrapper">
                            {/* <div className = "prices">
                            <span>{t("Redemption")}: <b>{redemption}⊆</b> </span>
                            <span>{t("For delivery")}: <b>{price}⊆</b></span>
                        </div> */}
                            <div className="comments">
                                <img src={SMS} alt="SMS"/>
                                <p className="comments-text">
                                    {comment?.length>80 ? comment.substring(0,80)+"..." : comment}
                                </p>
                            </div>
                        </div>
                    </div>
                }


            </div>
        </div>
    );
};

export default PrintStickerAmanat;
