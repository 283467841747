import React, {useCallback, useEffect, useState} from 'react';
import {CAlert, CCard, CCardBody} from "@coreui/react"
import OrdersTable from './OrdersTable';
import { useDispatch, useSelector} from 'react-redux'
import { fetchCompanyStatuses } from 'src/services/CompanyService';
import { useTranslation } from 'react-i18next';
import MiniSpinner from 'src/components/spinners/MiniSpinner';
import Pagination from 'src/components/Pagination';
import { orderSearchFetch } from 'src/services/OrderService';
import { useTranslate } from 'src/helpers/useTranslate';

const limit = 20;

let queryParam = {
    search: '',
    size: limit,
    page: 1,
};

export default function SearchResult(){
        
    const [statuses, setStatuses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [orders, setOrders] = useState({
        items: [],
    });
    const [filterParams, setFilterParams] = useState({
        statuses: [],
        dateFrom: null,
        dateTo: null,
        search: '',
        size: limit,
        page: 1,
    })

    const { t } = useTranslation();
    const { tr } = useTranslate();
    
    const searchText = useSelector(state => state.settings.searchText)
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true)
        orderSearchFetch({...queryParam, search: searchText}).then(res => res.ok && res.json().then(d => {
            setOrders(d)
            setIsLoading(false)
        })).catch(err => {
            setIsLoading(false)
            console.log(err)
        })
    }, [searchText])

    useEffect(() => {
        fetchCompanyStatuses().then(res => {
            if(res.ok) {
                res.json().then(data => {
                    let statusList = data.map((d, i) => {
                        if(d.value==="created") {
                            return {...d, percent: 5}
                        } else if(i < data.length - 2) {
                            return {...d, percent: parseInt(i * 10)}
                        } else {
                            return {...d, percent: 100}
                        }
                    })
                    setStatuses(statusList);
                })
            }
        }).catch(err => console.log(err));
    }, [dispatch])

    const applyFilters = useCallback((params) => {
        orderSearchFetch({...queryParam, search: searchText}).then(res => res.ok && res.json().then(d => {
            setOrders(d)
            setIsLoading(false)
        })).catch(err => {
            setIsLoading(false)
            console.log(err)
        })
        setFilterParams(params)
    }, [searchText])

    const onNext = () => {
        const newParams ={...filterParams, page: filterParams.page + 1}
        applyFilters(newParams);
    }

    const onPrev = () => {
        const newParams ={...filterParams, page: filterParams.page - 1}
        applyFilters(newParams);
    }

    return (
        <div>
            <CCard className="m-2">
                <CCardBody className="p-0">
                    <div className="mt-1">
                        {isLoading ? 
                            <div className="text-center">
                                <MiniSpinner />
                            </div>
                        :
                            <div>
                                <div className='my-1 ml-2'>
                                    {t("Количество заказов")}: {orders.total}
                                </div>
                                {orders.items?.length > 0 ? 
                                    <OrdersTable currentOrders={orders.items || []} statusList={statuses}/>
                                :
                                    <CAlert color='secondary'>
                                        {tr("Ничего не найдено", "Эч нерсе табылбады")}
                                    </CAlert>
                                }
                                <Pagination 
                                    currentPage={filterParams.page}
                                    nextPage={onNext}
                                    prevPage={onPrev}
                                    limit={limit}
                                    currentListLength={orders.items?.length}
                                    isLastPage={(orders.total / (orders.page * limit)) <= 1}/>
                            </div>
                        }
                    </div>

                </CCardBody>
            </CCard>
        </div>
    )
}

