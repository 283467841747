import {
  fetchCourierBlock,
  fetchCouriersAll,
  fetchCreateCourier,
  fetchUpdateCourier,
} from "src/services/CourierService";

export function getAll(onSuccess, onError, onFetchError) {
  fetchCouriersAll()
    .then((res) => {
      if (res.ok) {
        res.json().then(d => {
          onSuccess(d);
        })
      } else {
        onError(res);
      }
    })
    .catch((err) => {
      onFetchError();
    });
}

export function create(form, onSuccess, onError, onFetchError) {
  fetchCreateCourier(form)
    .then((res) => {
      if (res.ok) {
        onSuccess();
      } else {
        onError(res);
      }
    })
    .catch((err) => {
      onFetchError();
    });
}

export function update(form, id, onSuccess, onError, onFetchError) {
  fetchUpdateCourier(form, id)
    .then((res) => {
      if (res.ok) {
        onSuccess();
      } else {
        onError();
      }
    })
    .catch((err) => {
      onFetchError();
    });
}

export function blockRequest(id, isBlock, onSuccess, onError, onFetchError) {
  fetchCourierBlock(id, isBlock)
    .then((res) => {
      if (res.ok) {
        onSuccess();
      } else {
        onError();
      }
    })
    .catch((err) => onFetchError(err));
}
