import React from "react";
import { useTranslation } from "react-i18next";
import { CButton, CCol, CRow } from "@coreui/react";
import MiniSpinner from "src/components/spinners/MiniSpinner";
import { useHistory } from "react-router-dom";

export function ButtonBar({
  buttonClickable,
  submitCreate,
  edit,
  clearFields,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  return (
   <>
        <CRow>
            <CCol>
                <CButton
                    color="success"
                    className="btn-block mt-2"
                    disabled={!buttonClickable}
                    onClick={submitCreate}
                >
                    {!buttonClickable ? (
                        <MiniSpinner />
                    ) : edit ? (
                        t("Save")
                    ) : (
                        t("Submit")
                    )}
                </CButton>
            </CCol>
            <CCol>
                <CButton
                    color="secondary"
                    className="btn-block mt-2"
                    onClick={clearFields}
                    >
                    {t("Clear")}
                </CButton>
            </CCol>            
            {edit && (<CCol>
                        <CButton
                                color="danger"
                                className="btn-block mt-2"
                                onClick={() => {
                                clearFields();
                                history.push("/current-orders");
                                }}
                        >
                                {t("Back")}
                        </CButton>
            </CCol>)}
        </CRow>
   </>
  );
}
