import CIcon from '@coreui/icons-react'
import { CButton, CCol, CInput, CLabel, CRow, CSelect, CTextarea } from '@coreui/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { fetchCreateImg } from 'src/services/ServerService'

export default function InstantUpdatePayment({selectedPayment, setSelectedPayment, submitUpdate}) {
    const {t} = useTranslation()
    const [btnDisabled, setBtnDisabled] = useState(false)

    const uploadImage = (e) => {
        let formData = new FormData();
        formData.append("image", e.target.files[0]);
        setBtnDisabled(true)
        fetchCreateImg(formData).then(data => {
            setBtnDisabled(false)
            setSelectedPayment(payment => {
                return {...payment, image: data.data}
            })
        }).catch(err => {
            console.log(err)
            setBtnDisabled(false)
        })
    }
    return <div>
        <div className='d-flex justify-content-between my-2'>
            <div>{t("Sum")}</div>
            <div>{selectedPayment.amount} <u>с</u></div>
        </div>
        <div className='d-flex justify-content-between'>
            <div>{t("Payment method")}</div>
            <div>
                <CSelect value={selectedPayment.method} onChange={e => setSelectedPayment({...selectedPayment, method: e.target.value})}>
                    <option value="cash">{t("Cash")}</option>
                    <option value="invoice">{t("Банковский перевод")}</option>
                    <option value="elsom">{t("Элсом")}</option>
                    <option value="mbank">{t("Мбанк")}</option>
                    <option value="optima">{t("Оптима")}</option>
                    <option value="o_dengi">{t("O! деньги")}</option>
                    <option value="megapay">{t("Мега Pay")}</option>
                    <option value="balance_kg">{t("Balance KG")}</option>
                    <option value="other">{t("Other")}</option>
                </CSelect>
            </div>
        </div>
        <CLabel>{t("Notes")}</CLabel>
        <CTextarea placeholder='кыстырма' value={selectedPayment.comment || ""} onChange={e => setSelectedPayment({...selectedPayment, comment: e.target.value})}/>
        <CRow className="my-2">
            <CCol xs="6">
                <div>Чек</div>
            </CCol>
            <CCol xs="6">
                <CInput type='file' onChange={uploadImage}/>
            </CCol>
        </CRow>
        <CRow className="my-2">
            <CCol xs="6">
                <div className='d-none'>{t("Preview")}</div>
            </CCol>
            <CCol xs="6">
                <img src={selectedPayment.image} 
                    alt="check" style={{maxWidth: "75%"}}/>
            </CCol>
        </CRow>
        <CRow className="my-2">
            <CCol xs="6">
            </CCol>
            <CCol xs="6">
                <CButton className="btn-block" variant="outline" color="success"  disabled={btnDisabled} onClick={submitUpdate}>
                    {t("Paid")}  <CIcon className="ml-1 mt-0" name="cilCheck"/>
                </CButton>
            </CCol>
        </CRow>
    </div>
}