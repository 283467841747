import CIcon from "@coreui/icons-react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { date } from "src/helpers/date";
import { toastify } from "src/helpers/toast";
import { useTranslate } from "src/helpers/useTranslate";

export default function OrdersTable({ orders, redemptionTypeLabel }) {
  const { t } = useTranslation();
  const { tr } = useTranslate();
  const history = useHistory();

  const setLabel = useCallback(
    (obj) => {
      return obj && `${tr(obj.name_ru, obj.name_kg)}, `;
    },
    [tr]
  );

  return (
    <table className="table table-responsive-lg">
      <thead>
        <tr>
          <th>#</th>
          <th>{t("Sender")}</th>
          <th>{t("Receiver")}</th>
          <th>{t("Courier")}</th>
          <th>{t("Status")}</th>
          <th>{t("Date")}</th>
          <th>{t("Tariff")}</th>
          <th>{t("Redemption")}</th>
          <th>{t("Redemption status")}</th>
          <th>{t("Price")}</th>
          <th>{t("Actions")}</th>
        </tr>
      </thead>
      <tbody>
        {orders?.items?.map((o) => (
          <tr key={o.id}>
            <td>{o.oid}</td>
            <td>
              {o.sender?.phone}
              <br />
              {setLabel(o.city_from || o.location_from?.city)}
              {o.location_from?.name_kg}
            </td>
            <td>
              {o.receiver?.phone}
              <br />
              {setLabel(o.city_to || o.location_to?.city)}
              {o.location_to?.name_kg}
            </td>
            <td>
              {o.courier?.user?.firstname}
              <br />
            </td>
            <td>{t(o.status)}</td>
            <td>{date(o.created_at)}</td>
            <td>{o.tariff?.name}</td>
            <td>{o.redemption_amount}</td>
            <td>{redemptionTypeLabel(o.redemption_payment_type)}</td>
            <td>{o.final_price}</td>
            <td>
              <CIcon
                name="cilCopy"
                color="success"
                className="mx-1"
                size="lg"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  let order = o;
                  if (order) {
                    const orderId = order.id;
                    const sender = order.sender;
                    const locationFrom = order.location_from;
                    const locationFromFlat = order.location_from_flat;
                    const locationFromFloor = order.location_from_floor;
                    const redemptionAmount = order.redemption_amount;
                    const receiver = order.receiver;
                    const locationTo = order.location_to;
                    const locationToFlat = order.location_to_flat;
                    const locationToFloor = order.location_to_floor;
                    const paying_side =
                      order.paying_side === "receiver"
                        ? "Получатель"
                        : order.paying_side === "sender"
                        ? "Отправитель"
                        : "Вместе";
                    const finalPrice = order.final_price;
                    const packageType = tr(
                      order.package_type?.name_ru,
                      order.package_type?.name_ru
                    );
                    const description = order.description;

                    navigator.clipboard
                      .writeText(`*Заказ №${orderId}* \nОтправитель: ${
                      sender?.fullname
                    }, ${sender?.phone},\n🅰️Откуда: ${
                      locationFrom?.city &&
                      tr(
                        locationFrom?.city?.name_ru,
                        locationFrom.city?.name_kg
                      ) + ","
                    } ${
                      locationFrom?.district
                        ? tr(
                            locationFrom?.district?.name_ru,
                            locationFrom?.district?.name_kg
                          ) + ", "
                        : ""
                    }${tr(locationFrom?.name_ru, locationFrom?.name_kg)} ${
                      locationFromFlat ? "\nКвартира: " + locationFromFlat : ""
                    } ${
                      locationFromFloor ? " \nЭтаж: " + locationFromFloor : ""
                    } \n${
                      order.package_type
                        ? "\n📦Тип посылки: " + packageType
                        : ""
                    } \nВыкуп: *${redemptionAmount} ⊆* \n\nПолучатель: ${
                      receiver
                        ? receiver?.fullname + "," + receiver?.phone
                        : "нужно уточнить, "
                    } \n🅱️Куда: ${
                      locationTo
                        ? tr(
                            locationTo?.city?.name_ru,
                            locationTo?.city?.name_kg
                          ) +
                          ", " +
                          (locationTo?.district
                            ? tr(
                                locationTo?.district?.name_ru,
                                locationTo?.district?.name_kg
                              ) + ", "
                            : "") +
                          tr(locationTo?.name_ru, locationTo?.name_kg)
                        : "нужно уточнить"
                    }  \n${
                      locationToFlat ? "Квартира: " + locationToFlat : ""
                    } ${
                      locationToFloor ? " Этаж: " + locationToFloor : ""
                    } \n\nОплачивает: ${paying_side} \n💰 За доставку: *${finalPrice} ⊆*  \n\nКомментарий : ${
                      description ? description : ""
                    }
                                    `);
                    toastify("info", "Copied to cipboard");
                  }
                }}
              />
              <CIcon
                name="cilPencil"
                color="success"
                className="mx-1"
                size="lg"
                onClick={() => {
                  history.push("/update-order/" + o.id);
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
