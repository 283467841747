import { createApi } from './ServerService';
import { DELETE } from './types';


export const fetchLocationsByParams = (params) => {
    let query = ""
    if(params.skip) {
        query+="skip=" + params.skip
    }
    if(params.limit) {
        query+="&limit=" + params.limit
    } 
    if(params.search) {
        query+="&search=" + params.search
    }
    if(params.cityId) {
        query+="&cityId=" + params.cityId
    }
    if(params.districtId) {
        query+="&districtId=" + params.districtId
    }
    return createApi(`/api/v1/locations/?${query}`);
}

export const fetchCompanyAddressesByParams = (params) => {
    let query = ""
    if(params.skip) {
        query+="skip=" + params.skip
    }
    if(params.limit) {
        query+="&limit=" + params.limit
    } 
    if(params.cityId) {
        query+="&city_id=" + params.cityId
    }
    if(params.districtId) {
        query+="&district_id=" + params.districtId
    }
    if(params.search) {
        query+="&search=" + params.search
    }
    return createApi(`/api/v1/company/addresses/?${query}`)
}

export const fetchLocationSearch = (key, limit) => {
    return createApi(`/api/v1/locations/?search=${key}&limit=${limit}`)
}

export const fetchCompanyAddressDelete = (id) => {
    return createApi("/api/v1/locations/" + id + "/", {method: DELETE})
}