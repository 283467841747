import {
  CCard,
  CCardHeader,
  CCardBody,
  CForm,
  CFormGroup,
  CLabel,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useModalState, useConfirmModal } from "../../helpers/useModal";
import { useTranslation } from "react-i18next";
import { getPackagesTypes } from "../../redux/actions/addressActions";
import {
  fetchCompanyPackageTypeDelete
} from "src/services/CompanyService";
import PackageTypeForm from "./PackageTypeForm";
import { setBreadcrumb } from "src/redux/actions/settingsActions";
import { toastify } from "src/helpers/toast";

export function CompanyPackageTypes() {
  const dispatch = useDispatch();
  const lang = localStorage.getItem("lang");
  const { t } = useTranslation();
  const { isOpen, onToggle } = useModalState();
  const { isConfirmOpen, onConfirmToggle } = useConfirmModal();
  const reFetchPt = async () => dispatch(getPackagesTypes());
  const [modalTitle, setModalTitle] = useState("Modal Title");
  const [selectedPackageType, setSelectedPackageType] = useState({});

  useEffect(() => {
    dispatch(getPackagesTypes());
    if (isOpen === false && isConfirmOpen === false) {
      dispatch(setBreadcrumb(["Package Types"]));
    }
  }, [dispatch, isOpen, isConfirmOpen]);

  const packageTypes = useSelector((state) => state.addresses.packagesTypes);

  const newPackageTypeForm = () => {
    setSelectedPackageType({});
    setModalTitle(t("Create package type"));
    onToggle();
  };


  const showDelete = (pt) => {
    setSelectedPackageType(pt);
    setModalTitle(t("Delete package type"));
    reFetchPt();
    onConfirmToggle();
  };

  const showEdit = (pt) => {
    setSelectedPackageType(pt);
    setModalTitle(t("Edit package type"));
    reFetchPt();
    onToggle();
  };

  const deletePackageType = (pt) => {
    console.log(pt);
    fetchCompanyPackageTypeDelete(pt.id)
    .then((res) => {
      if (res.ok) {
        toastify('success', t("Package type deleted successfully"));
        reFetchPt();
      } else {
        toastify("error", t("Произошла ошибка удаления типа посылок"));
      }
    })
    .catch((err) => toastify('error', t("Произошла ошибка, проверьте интернет")))
    .finally(onConfirmToggle);
  };

  return (
    <CCard className="m-2">
      <CCardHeader>
        <CButton
          variant="outline"
          color="success"
          className="float-right"
          onClick={() => {
            newPackageTypeForm();
            setSelectedPackageType({});
            dispatch(setBreadcrumb(["Package Types", "Create package type"]))
          }}
        >
          {t("Create new package type")}
        </CButton>
      </CCardHeader>
      <CCardBody>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>{t("Name")}</th>
              <th>{t("Photo")}</th>
              <th>{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {packageTypes.map((pt) => (
              <tr key={pt.id}>
                <td>{lang === "ru" ? pt.name_ru : pt.name_kg}</td>
                <td>
                  <img
                    className="sp-package-mini"
                    src={pt.photo}
                    alt={lang === "ru" ? pt.name_ru : pt.name_kg}
                  />
                </td>
                <td>
                  <CIcon
                    name="cilTrash"
                    className="text-danger mr-2"
                    size="xl"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      // console.log(e);
                      e.preventDefault();
                      showDelete(pt);
                      dispatch(setBreadcrumb(["Package Types", "Delete package type"]))
                    }}
                  />
                  <CIcon
                    name="cilPencil"
                    className="text-warning"
                    size="xl"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      // console.log(e);
                      e.preventDefault();
                      showEdit(pt);
                      dispatch(setBreadcrumb(["Package Types", "Edit package type"]))
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </CCardBody>

      <CModal show={isConfirmOpen} onClose={onConfirmToggle}>
        <CModalHeader closeButton>{modalTitle}</CModalHeader>
        <CModalBody>
          {selectedPackageType && (
            <CForm>
              <CFormGroup>
                <CLabel htmlFor="nf-username">
                  {t("Delete this package type? ")}{" "}
                  {lang === "ru"
                    ? selectedPackageType.name_ru
                    : selectedPackageType.name_kg}
                </CLabel>
              </CFormGroup>
            </CForm>
          )}
        </CModalBody>
        <CModalFooter>
          {selectedPackageType && (
            <CRow>
              <CButton
                color="danger"
                onClick={() => deletePackageType(selectedPackageType)}
                className="mr-2"
              >
                {t("Delete")}
              </CButton>
            </CRow>
          )}
        </CModalFooter>
      </CModal>
      <PackageTypeForm
        toggle={onToggle}
        modal={isOpen}
        modalTitle={modalTitle}
        selectedPackageType={selectedPackageType}
        reFetchPt={reFetchPt}
      />
    </CCard>
  );
}
