import { createApi } from "./ServerService";
import { DELETE, PATCH, POST } from "./types";

export const fetchRoutes = () => {
    return createApi("/api/v1/routes/");
};

export const fetchNodes = () => {
    return createApi("/api/v1/nodes/");
};

export const fetchPatchNodes = (id, nodeBody) => {
    return createApi("/api/v1/nodes/" + id + "/", {method: PATCH, body: nodeBody});
};

export const fetchCreateRoute = (routeForm) => {
    return createApi("/api/v1/routes/", {method: POST, body: routeForm});
};

export const fetchRouteDetail = (id) => {
    return createApi("/api/v1/routes/" + id + "/");
};

export const fetchEditRoute = (routeForm, id) => {
    return createApi("/api/v1/routes/" + id + "/", {method: PATCH, body: routeForm});
}

export const fetchDeleteRoute = (id) => {
    return createApi("/api/v1/routes/" + id + "/", {method: DELETE});
}
