import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchCities, fetchDistricts } from "src/services/CityService";
import { fetchTransports } from "src/services/TransportService";
import { fetchRoutes } from "src/services/ServerService";
import { toastify } from "src/helpers/toast";
import { useTranslate } from "src/helpers/useTranslate";
import { fetchOffices } from "src/services/CompanyService";
import {
  emptyFormData,
  requiredFields,
  sideBarPages,
} from "./../helpers/constants";
import { getDataHelper } from "./../helpers/helpers";
import { create, update } from "./../helpers/api";
import { useHistory } from "react-router-dom";
import useTabs from "./useTabs";

export default function useCourierFormPage({ selectedCourier }) {
  const { tr } = useTranslate();
  const { t } = useTranslation();
  const history = useHistory();
  const redirect = useCallback(() => history.push("/couriers"), [history]);
  const [btnState, setBtnState] = useState(false);
  const [office, setOffice] = useState();
  const [city, setCity] = useState();
  const [district, setDistrict] = useState();
  const [selectedTransport, setSelectedTransport] = useState();
  const [cities, setCities] = useState([]);
  const [offices, setOffices] = useState([]);
  const [transports, setTransports] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [route, setRoute] = useState("");
  const [roles, setRoles] = useState(null);
  const [courierService, setCourierService] = useState(null);
  const [error, setError] = useState(null);
  const [password, setPassword] = useState("");

  const [fieldChecked, setFieldChecked] = useState(
    requiredFields.map((field) => ({ [field]: false }))
  );

  const updateFieldCheck = useCallback((field, value) => {
    setFieldChecked((fields) => ({ ...fields, [field]: value }));
  }, []);

  const [initForm, setInitForm] = useState(emptyFormData);

  const updateField = useCallback((field, value) => {
    setInitForm((form) => ({ ...form, [field]: value }));
  }, []);

  const handleInputChange = useCallback(
    (e) => {
      let { name, value } = e.target;
      updateField(name, value);
      setPassword(value);
      setFieldChecked((state) => ({
        ...state,
        [name]: value.length >= 1,
      }));
    },
    [updateField]
  );

  const handleUserNameChange = useCallback(
    (phoneNum) => {
      updateField("username", "+" + phoneNum);
      setFieldChecked((state) => ({
        ...state,
        username: phoneNum.length >= 4,
      }));
    },
    [updateField]
  );

  const handleCityChange = useCallback(
    (res) => {
      setCity(res);
      updateField("city_id", res?.value);
      const fetchDist = () => fetchDistricts(res?.value);
      getDataHelper(fetchDist, setDistricts);
    },
    [updateField]
  );

  useEffect(() => {
    if (selectedCourier) {
      requiredFields.forEach((field) => {
        if (selectedCourier[field]) {
          updateFieldCheck(field, true);
        }
      });
    }
  }, [selectedCourier, updateFieldCheck]);

  useEffect(() => {
    getDataHelper(fetchOffices, setOffices);
    getDataHelper(fetchCities, setCities);
    getDataHelper(fetchTransports, setTransports);
    getDataHelper(fetchRoutes, setRoutes);

    if (selectedCourier) {
      setInitForm((state) => ({
        ...state,
        photo: selectedCourier.photo || null,
        username: selectedCourier.username || "",
        firstname: selectedCourier.firstname || "",
        lastname: selectedCourier.lastname || "",
        middlename: selectedCourier.middlename || "",
        max_weight: selectedCourier.max_weight || 0,
        max_volume: selectedCourier.max_volume || 0,
        city_id: selectedCourier.city?.id || "",
        district_id: selectedCourier.district?.id || "",
        delivery_office_id: selectedCourier.delivery_office?.id || "",
        transport_id: selectedCourier.transport?.id || "",
        auto_number: selectedCourier.auto_number || "",
        passport_front: selectedCourier.passport_front || "",
        passport_back: selectedCourier.passport_back || "",
        personal_number: selectedCourier.personal_number || "",
        roles: selectedCourier.roles,
      }));
      setCourierService(null);
      if (selectedCourier.routes) {
        updateField(
          "routes",
          selectedCourier.routes.map((r) => r.id)
        );
        setRoute(
          selectedCourier.routes.map((r) => {
            return { value: r.id, label: tr(r.name_ru, r.name_kg) };
          })
        );
      }
      if (selectedCourier.roles) {
        updateField(
          "roles",
          selectedCourier.roles.map((r) => r)
        );
        setRoles(
          selectedCourier.roles.map((r) => {
            return { value: r, label: r };
          })
        );
      }
      selectedCourier.city &&
        handleCityChange({
          value: selectedCourier.city.id,
          label: tr(selectedCourier.city.name_ru, selectedCourier.city.name_kg),
        });
      selectedCourier.district &&
        setDistrict({
          value: selectedCourier.district.id,
          label: tr(
            selectedCourier.district.name_ru,
            selectedCourier.district.name_kg
          ),
        });
      selectedCourier.transport &&
        setSelectedTransport({
          value: selectedCourier.transport.id,
          label: tr(
            selectedCourier.transport.name_ru,
            selectedCourier.transport.name_kg
          ),
        });
      selectedCourier.delivery_office &&
        setOffice({
          value: selectedCourier.delivery_office.id,
          label: selectedCourier.delivery_office.name,
          ...selectedCourier.delivery_office,
        });
    }
  }, [handleCityChange, selectedCourier, t, tr, updateField]);

  const onUploadPhotoSuccess = (data, imgName) =>
    setInitForm((state) => ({
      ...state,
      [imgName]: imgName === "transport_images" ? [data] : data,
    }));

  const submitCourier = () => {
    const courierForm = { ...initForm };
    if (selectedCourier?.id) {
      updateCourier(courierForm);
    } else {
      if (password?.length < 6) {
        toastify("error", t("Password must be 6 digits"));
        setError("Password must be 6 digits");
      }
      createCourier(courierForm);
    }
  };

  const onSuccessFunc = useCallback(
    (toastText) => {
      toastify("success", t(toastText));
      setBtnState(false);
      setError(null);
      redirect();
    },
    [t, redirect]
  );
  const onFetchError = useCallback(() => {
    toastify("error", t("Произошла ошибка, проверьте интернет"));
    setBtnState(false);
  }, [t]);

  const createCourier = (courierForm) => {
    setBtnState(true);
    const onSuccess = () => onSuccessFunc("Новый курьер успешно создан");
    const onError = (res) => {
      res.json().then((d) => {
        toastify("error", d.detail);
        if (typeof d.detail === "string") {
          setError(d.detail);
        } else {
          setError("Error occured");
        }
      });
      toastify("error", "Ошибка при создании курьера");
      setBtnState(false);
    };
    create(courierForm, onSuccess, onError, onFetchError);
  };

  const updateCourier = (courierForm) => {
    let form = courierForm;
    if (courierService) {
      form.delivery_service_id = courierService.id;
    }
    setBtnState(true);
    const onSuccess = () => onSuccessFunc("Курьер успешно отредактирован");
    const onError = () => {
      setError("Произошла ошибка редактирования курьера");
      toastify("error", t("Произошла ошибка редактирования курьера"));
      setBtnState(false);
    };
    update(form, selectedCourier.id, onSuccess, onError, onFetchError);
  };

  const { activePage, onGoToNextPage, setActivePage } = useTabs({
    onLastPage: submitCourier,
  });

  return {
    btnState,
    initForm,
    office,
    city,
    district,
    selectedTransport,
    cities,
    offices,
    transports,
    districts,
    routes,
    route,
    roles,
    sideBarPages,
    error,
    fieldChecked,
    activePage,
    setActivePage,
    setInitForm,
    setSelectedTransport,
    setDistrict,
    setRoles,
    setOffice,
    setRoute,
    onGoToNextPage,
    handleInputChange,
    handleUserNameChange,
    handleCityChange,
    updateField,
    updateFieldCheck,
    onUploadPhotoSuccess,
  };
}
