import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import messages_ky from "./messages_ky";
import messages_ru from "./messages_ru";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  ky: {
    translation: messages_ky,
  },
  ru: {
    translation: messages_ru,
  },
};

let selectedLanguague = localStorage.getItem("lang")
  ? localStorage.getItem("lang")
  : "ky";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: selectedLanguague,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

localStorage.setItem("lang", selectedLanguague);

export default i18n;
