import React from "react";
import { CCol, CInput, CLabel, CRow } from "@coreui/react";
import Select from "react-select";
import CIcon from "@coreui/icons-react";
import { useTranslation } from "react-i18next";
import { onUploadPhoto } from "../helpers/helpers";

const CourierContractTab = ({
  // selectedCourier,
  offices,
  updateField,
  updateFieldCheck,
  fieldChecked,
  setOffice,
  office,
  setRoles,
  roles,
  onUploadPhotoSuccess,
}) => {
  const { t } = useTranslation();

  const onOfficeSelect = (office) => {
    updateFieldCheck('delivery_office', office)
    setOffice(office);
    updateField('delivery_office_id', office?.id)
  };

  const onRoleSelect = (role) => {
    updateField('roles', role.map(r => r.value))
    setRoles(role);
  };

  return (
    <CRow
      className="ml-2"
    >
      <CCol>
        <CLabel>{t("Офис курьерной службы")}*</CLabel>
      </CCol>

      <div style={{ width: "100%" }} className="d-flex align-items-center">
        <CCol xs={10}>
          <Select
            placeholder="Выберите офис курьерной службы"
            isClearable
            value={office}
            options={offices.map((office) => ({
              value: office.id,
              label: office.name,
              ...office,
            }))}
            onChange={(option) => onOfficeSelect(option)}
          />
        </CCol>
        <CIcon
          name={fieldChecked.delivery_office ? "cilCheckCircle" : "cilWarning"}
          className={"ml-2 " + (fieldChecked.delivery_office ? "text-success" : "text-danger")}
          size="xl"
        />
      </div>

      <CCol className="mt-3" xs={10}>
        <CLabel>{t("Role")}</CLabel>
        <Select
          isMulti
          onChange={(e) => onRoleSelect(e)}
          value={roles}
          options={[
            { label: t("Courier"), value: "courier" },
            { label: t("Packer"), value: "packer" },
            { label: t("Carrier"), value: "carrier" },
          ]}
        />
      </CCol>
      <CCol className="mt-3" xs={10}>
        <CLabel>{t("Договор")}</CLabel>
        <CInput
          type="file"
          onChange={(e) => onUploadPhoto(e, "contract", onUploadPhotoSuccess)}
        />
      </CCol>
    </CRow>
  );
};

export default CourierContractTab;
