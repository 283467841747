export const CITIES_LOADING = 'CITIES_LOADING'
export const CITIES_SUCCESS = 'CITIES_SUCCESS'
export const CITIES_FAIL = 'CITIES_FAIL'

export const DISTRICTS_LOADING = 'DISTRICTS_LOADING'
export const DISTRICTS_SUCCESS = 'DISTRICTS_SUCCESS'
export const DISTRICTS_FAIL = 'DISTRICTS_FAIL'

export const REGIONS_LOADING = 'REGIONS_LOADING'
export const REGIONS_SUCCESS = 'REGIONS_SUCCESS'
export const REGIONS_FAIL = 'REGIONS_FAIL'


export const LOCATIONS_LOADING = 'LOCATIONS_LOADING'
export const LOCATIONS_SUCCESS = 'LOCATIONS_SUCCESS'
export const LOCATIONS_FAIL = 'LOCATIONS_FAIL'