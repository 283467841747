import React, { useRef, useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setBreadcrumb } from "src/redux/actions/settingsActions";
import { getExtraFees } from "src/redux/actions/extraFeesActions";
import { getPackagesTypes } from "src/redux/actions/addressActions";
import { getTransports } from "src/redux/actions/transportActions";
import { getTariffs } from "src/redux/actions/tariffActions";
import { toastify } from "src/helpers/toast";
import {
  createOrderFetch,
  createOrderFetchPartner,
  orderConfirmFetch,
  orderFetch,
  updateOrderFetch,
} from "src/services/OrderService";
import {
  fetchCompanyAddressCreate,
  fetchCompanyAddressesSearch,
} from "src/services/CompanyService";
import {
  fetchDistricts,
  fetchDistrictSearch,
  fetchSearchedCities,
} from "src/services/CityService";
import {
  fetchClient,
  fetchClientOrders,
  fetchClients,
  fetchClientsSearh,
  fetchCreateClient,
} from "src/services/ClientService";
import { useTranslate } from "src/helpers/useTranslate";
import { getCities } from "src/redux/actions/locationActions";
import { newOrderData, updateNewOrder } from "src/redux/actions/orderActions";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  buildOrderBody,
  checkFieldsBeforeConfirm,
  uniqualize,
} from "./helpers";
import { FormContainer } from "./FormContainer";

export function OrderForm(props) {
  const sidebarShow = useSelector((state) => state.settings.sidebarShow);
  const courierList = useSelector((state) => state.courier.couriers);
  const packagesTypes = useSelector((state) => state.addresses.packagesTypes);
  const transports = useSelector((state) => state.transport.transports);
  const tariffs = useSelector((state) => state.tariff.tariffs);
  const cities = useSelector((state) => state.locations.cities);
  const allFees = useSelector((state) => state.extraFees.fees);
  const company = useSelector((state) => state.auth.company);
  const profile = useSelector((state) => state.auth.profile);
  const orderData = useSelector((state) => state.order.newOrder);

  const { t, i18n } = useTranslation();
  const { tr } = useTranslate();
  const dispatch = useDispatch();
  const map = useRef(null);
  const params = useParams();
  const history = useHistory();

  const [mapApi, setMapApi] = useState(null);
  const [open, setOpen] = useState(false);
  const [center, setCenter] = useState([41.12, 74.99]);
  const [zoom, setZoom] = useState(7);
  const [allAddressesSender, setAllAddressesSender] = useState([]);
  const [allAddressesReceiver, setAllAddressesReceiver] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [districtsSender, setDistrictsSender] = useState([]);
  const [districtsReceiver, setDistrictsReceiver] = useState([]);
  const [buttonClickable, setButtonClickable] = useState(true);
  const [cCourier, setCCourier] = useState(null);
  const [errorField, setErrorField] = useState(null);
  const [sortedCourierList, setSortedCourierList] = useState(null);
  const [edit, setEdit] = useState(false);
  const [pointSender, setPointSender] = useState(false);
  const [pointReceiver, setPointReceiver] = useState(false);
  const [emptySender, setEmptySender] = useState(true);
  const [emptyAddressSender, setEmptyAddressSender] = useState(true);

  useEffect(() => {
    dispatch(
      setBreadcrumb(
        params.orderId
          ? [t("Editing") + " " + orderData?.oid || params.orderId]
          : [t("Create Order")]
      )
    );
  }, [params, dispatch, t, orderData]);

  useEffect(() => {
    dispatch(getPackagesTypes());
    dispatch(getTransports());
    dispatch(getExtraFees());
    dispatch(getTariffs());
    dispatch(getCities());

    fetchClients().then((res) => {
      res.ok && res.json().then((d) => setAllClients(d));
    });
  }, [dispatch, i18n, t]);

  const setOrderObjects = useCallback(
    (o) => {
      let order = o;
      if (order.sender) {
        setEmptySender(false);
        order.selectedClientSender = {
          value: order.sender.id,
          label: order.sender.phone,
          ...order.sender,
        };
        order.senderFullname = order.sender.fullname;
      }
      if (order.receiver) {
        order.selectedClientReceiver = {
          value: order.receiver.id,
          label: order.receiver.phone,
          ...order.receiver,
        };
        order.receiverFullname = order.receiver.fullname;
      }
      if (order.location_from) {
        setEmptyAddressSender(false);
        order.selectedAddressSender = {
          value: order.location_from.id,
          label: tr(order.location_from.name_ru, order.location_from.name_kg),
          ...order.location_from,
        };
        order.selectedCitySender = order.location_from.city
          ? {
              label: tr(
                order.location_from.city.name_ru,
                order.location_from.city?.name_kg
              ),
              value: order.location_from.city.id,
              ...order.location_from.city,
            }
          : null;
        order.selectedDistrictSender = order.location_from.district
          ? {
              label: tr(
                order.location_from.district.name_ru,
                order.location_from.district?.name_kg
              ),
              value: order.location_from.district.id,
              ...order.location_from.district,
            }
          : null;
      }
      if (order.location_to) {
        order.selectedAddressReceiver = {
          value: order.location_to.id,
          label: tr(order.location_to.name_ru, order.location_to.name_kg),
          ...order.location_to,
        };
        order.selectedCityReceiver = order.location_to.city
          ? {
              label: tr(
                order.location_to.city.name_ru,
                order.location_to.city?.name_kg
              ),
              value: order.location_to.city.id,
              ...order.location_to.city,
            }
          : null;
        order.selectedDistrictReceiver = order.location_to.district
          ? {
              label: tr(
                order.location_to.district.name_ru,
                order.location_to.district?.name_kg
              ),
              value: order.location_to.district.id,
              ...order.location_to.district,
            }
          : null;
      }
      if (order.package_type) {
        order.selectedPack = {
          value: order.package_type.id,
          label: tr(order.package_type.name_ru, order.package_type.name_kg),
          ...order.package_type,
        };
      }
      if (order.tariff) {
        order.selectedTariff = {
          value: order.tariff?.id,
          label: order.tariff?.name,
          ...order.tariff,
        };
      }
      if (order.selectedTransport) {
        order.selectedTransport = order.transports?.map((tt) => {
          return { value: tt.id, label: tt.name_kg, ...tt };
        });
      }
      if (order.courier) {
        order.selectedCourier = {
          value: order.courier?.id,
          label: order.courier?.user?.firstname,
          ...order.courier,
        };
      }
      return order;
    },
    [tr]
  );

  const updateOrderData = useCallback(
    (field, value) => {
      dispatch(updateNewOrder(field, value));
    },
    [dispatch]
  );

  const getDistrictsFor = useCallback((city, isSender) => {
    if (city) {
      fetchDistricts(city.id).then(
        (res) =>
          res.ok &&
          res.json().then((d) => {
            isSender ? setDistrictsSender(d) : setDistrictsReceiver(d);
          })
      );
    } else {
      isSender ? setDistrictsSender([]) : setDistrictsReceiver([]);
    }
  }, []);
  useEffect(() => {
    if (!params.orderId) {
      if (profile?.delivery_office?.location) {
        updateOrderData("selectedCitySender", {
          label: profile.delivery_office.location.city?.name_ru,
          value: profile.delivery_office.location.city?.id,
          ...profile.delivery_office.location.city,
        });
        getDistrictsFor(profile.delivery_office.location.city, true);
      }
      updateOrderData("final_price", tariffs[0]?.price);
      updateOrderData(
        "weight",
        (company?.package_type || packagesTypes[0])?.max_weight
      );
      updateOrderData(
        "width",
        (company?.package_type || packagesTypes[0])?.width
      );
      updateOrderData(
        "length",
        (company?.package_type || packagesTypes[0])?.length
      );
      updateOrderData(
        "height",
        (company?.package_type || packagesTypes[0])?.height
      );
      updateOrderData("amount", 1);
      company?.package_type &&
        updateOrderData("selectedPackageType", {
          label: tr(company.package_type.name_ru, company.package_type.name_kg),
          value: company.package_type.id,
          ...company.package_type,
        });
    }
  }, [
    params,
    updateOrderData,
    profile,
    tariffs,
    packagesTypes,
    company,
    tr,
    getDistrictsFor,
  ]);

  useEffect(() => {
    if (params.orderId) {
      orderFetch(params.orderId)
        .then((res) => res.ok && res.json())
        .then((data) => {
          setEdit(true);
          let order = setOrderObjects(data);
          dispatch(newOrderData({ ...order }));
        });
    } else {
      setEdit(false);
    }
  }, [dispatch, params, setOrderObjects]);

  const addRoute = useCallback(
    (ymaps, pointA, pointB) => {
      const multiRoute = new ymaps.multiRouter.MultiRoute(
        {
          referencePoints: [pointA, pointB],
        },
        {
          boundsAutoApply: true,
        }
      );
      orderData.currentRoute &&
        map.current.geoObjects.remove(orderData.currentRoute);
      updateOrderData("currentRoute", multiRoute);

      map.current.geoObjects.add(multiRoute);

      multiRoute.model.events.add("requestsuccess", function () {
        var activeRoute = multiRoute.getActiveRoute();
        if (activeRoute) {
          updateOrderData(
            "distanceBetween",
            activeRoute.properties.get("distance").text
          );
          updateOrderData(
            "estimation",
            activeRoute.properties.get("duration").text
          );
        }
      });
    },
    [orderData, updateOrderData]
  );

  const checkCoors = useCallback(
    (zones, locationTo) => {
      let detectedZone = zones.filter((z) => {
        var myPolygon = new mapApi.Polygon(
          [z.fee.coors],
          {
            hintContent: "Polygon",
          },
          {
            visible: false,
            fillColor: "#ffffff",
          }
        );
        map.current.geoObjects.add(myPolygon);
        if (myPolygon.geometry.contains(locationTo)) {
          return true;
        } else {
          return false;
        }
      });
      if (detectedZone.length > 0) {
        updateOrderData("zoneFee", detectedZone[0].amount);
        updateOrderData("destinationZone", detectedZone[0]);
      } else {
        updateOrderData("destinationZone", null);
        updateOrderData("zoneFee", 0);
      }
    },
    [mapApi, updateOrderData]
  );

  const detectZoneFee = useCallback(
    (locationTo) => {
      checkCoors(
        allFees
          .filter((fee) => fee.fee_type === "zone")
          ?.filter((fee) => fee.fee.coors.length > 0),
        locationTo
      );
    },
    [allFees, checkCoors]
  );

  const detectRedemptionFee = useCallback(
    (amount) => {
      let autoFee = allFees
        .filter((fee) => fee.fee_type === "redemption")
        .filter((fee) => {
          return (
            parseInt(fee.fee.max) >= amount && amount >= parseInt(fee.fee.min)
          );
        });
      autoFee.length > 0
        ? updateOrderData("redemptionFee", autoFee[0].amount)
        : updateOrderData("redemptionFee", 0);
    },
    [allFees, updateOrderData]
  );

  const detectWeightFee = useCallback(
    (val) => {
      let autoFee = [];
      autoFee = allFees
        .filter((fee) => fee.fee_type === "weight")
        .filter((fee) => {
          return (
            parseInt(fee.fee.max) >= parseInt(val) &&
            parseInt(val) >= parseInt(fee.fee.min)
          );
        });
      if (autoFee.length > 0) {
        updateOrderData("weightFee", autoFee[0].amount);
        orderData.weightFeeCheck &&
          updateOrderData(
            "final_price",
            orderData.final_price + autoFee[0].amount
          );
      } else {
        updateOrderData("weightFee", 0);
      }
    },
    [allFees, updateOrderData, orderData.final_price, orderData.weightFeeCheck]
  );

  const handleSenderAddress = useCallback(
    (res) => {
      updateOrderData("selectedAddressSender", res);
      if (res) {
        res?.city?.id &&
          updateOrderData("selectedCitySender", {
            label: tr(res.city.name_ru, res.city.name_kg),
            value: res.city.id,
            ...res.city,
          });
        res?.district?.id &&
          updateOrderData("selectedDistrictSender", {
            label: tr(res.district.name_ru, res.district.name_kg),
            value: res.district.id,
            ...res.district,
          });
        setEmptyAddressSender(false);
      } else {
        setEmptyAddressSender(true);
        updateOrderData("senderGisLink", null);
      }
      if (res && res.lat) {
        if (orderData.selectedAddressReceiver) {
          addRoute(
            mapApi,
            [res.lat, res.lng],
            [
              orderData.selectedAddressReceiver.lat,
              orderData.selectedAddressReceiver.lng,
            ]
          );
        } else {
          setCenter([res.lat, res.lng]);
          setZoom(15);
        }
        const sortedCList = courierList
          .map((c) => {
            let a = c.lat - res.lat;
            let b = c.lng - res.lng;
            return { ...c, dist: Math.sqrt(a * a + b * b) };
          })
          .sort((a, b) => (a.dist < b.dist ? -1 : 1));
        setSortedCourierList(sortedCList);
        // setSelectedCourier({...sortedCList[0], label: sortedCList[0].firstname, value: sortedCList[0].id});
      } else {
        orderData.currentRoute &&
          map.current.geoObjects.remove(orderData.currentRoute);
        updateOrderData("currentRoute", null);
      }
      updateOrderData("location_from_flat", res?.flat || "");
      updateOrderData("location_from_floor", res?.floor || "");
    },
    [orderData, updateOrderData, courierList, mapApi, addRoute, tr]
  );

  const handleReceiverAddress = useCallback(
    (res) => {
      updateOrderData("selectedAddressReceiver", res);
      if (res) {
        res.city?.id &&
          updateOrderData("selectedCityReceiver", {
            label: tr(res.city.name_ru, res.city.name_kg),
            value: res.city.id,
            ...res.city,
          });
        res.district?.id &&
          updateOrderData("selectedDistrictReceiver", {
            label: tr(res.district.name_ru, res.district.name_kg),
            value: res.district.id,
            ...res.district,
          });
      } else {
        updateOrderData("receiverGisLink", null);
      }
      if (res && res.lat) {
        detectZoneFee([res.lat, res.lng]);
        if (orderData.selectedAddressSender) {
          addRoute(
            mapApi,
            [
              orderData.selectedAddressSender.lat,
              orderData.selectedAddressSender.lng,
            ],
            [res.lat, res.lng]
          );
        }
      } else {
        orderData.currentRoute &&
          map.current.geoObjects.remove(orderData.currentRoute);
        updateOrderData("currentRoute", null);
      }
      updateOrderData("location_to_flat", res?.flat || "");
      updateOrderData("location_to_floor", res?.floor || "");
    },
    [updateOrderData, addRoute, orderData, mapApi, detectZoneFee, tr]
  );

  const selectClient = useCallback(
    (res, isSender) => {
      let selected = res;
      if (!res) {
        updateOrderData(isSender ? "senderFullname" : "receiverFullname", "");
        isSender && setEmptySender(true);
      } else {
        isSender && setEmptySender(false);
      }
      if (selected?.phone) {
        selected.label = res.phone;
        updateOrderData(
          isSender ? "senderFullname" : "receiverFullname",
          selected?.fullname
        );
      }
      updateOrderData(
        isSender ? "selectedClientSender" : "selectedClientReceiver",
        selected
      );

      if (selected && selected.id) {
        fetchClient(selected.id)
          .then(
            (resp) =>
              resp.ok &&
              resp.json().then((d) => {
                updateOrderData(
                  isSender ? "send_sms_sender" : "send_sms_receiver",
                  d.send_sms
                );
                if (d.default_address) {
                  if (isSender) {
                    handleSenderAddress({
                      ...d.default_address.location_from,
                      label: tr(
                        d.default_address.location_from.name_ru,
                        d.default_address.location_from.name_kg
                      ),
                      value: d.default_address.location_from?.id,
                      flat: d.default_address.location_from_flat,
                      floor: d.default_address.location_from_floor,
                    });
                  } else {
                    handleReceiverAddress({
                      ...d.default_address.location_to,
                      label: tr(
                        d.default_address.location_to.name_ru,
                        d.default_address.location_to.name_kg
                      ),
                      value: d.default_address.location_to?.id,
                      flat: d.default_address.location_to_flat,
                      floor: d.default_address.location_to_floor,
                    });
                  }
                } else {
                  fetchClientOrders(
                    selected.id,
                    isSender ? "sender" : "receiver"
                  )
                    .then(
                      (res) =>
                        res.ok &&
                        res.json().then((d) => {
                          const addrList =
                            d.length > 0
                              ? [
                                  ...d.filter((add) =>
                                    isSender
                                      ? add.sender?.id === selected.id
                                      : add.receiver?.id === selected.id
                                  ),
                                ]
                              : [];
                          if (addrList.length > 0) {
                            const addrList = [
                              ...d.filter((add) =>
                                isSender
                                  ? add.sender?.id === selected.id
                                  : add.receiver?.id === selected.id
                              ),
                            ];
                            let firstAddr = addrList[0];
                            if (isSender) {
                              firstAddr.location_from &&
                                handleSenderAddress({
                                  ...firstAddr.location_from,
                                  label: tr(
                                    firstAddr.location_from.name_ru,
                                    firstAddr.location_from.name_kg
                                  ),
                                  value: firstAddr.location_from?.id,
                                  flat: firstAddr.location_from_flat,
                                  floor: firstAddr.location_from_floor,
                                });
                              setAllAddressesSender(
                                uniqualize(
                                  addrList.map((addr) => {
                                    return {
                                      label: tr(
                                        addr.location_from.name_ru,
                                        addr.location_from.name_kg
                                      ),
                                      value: addr.location_from?.id,
                                      flat: addr.location_from_flat,
                                      floor: addr.location_from_floor,
                                      ...addr.location_from,
                                    };
                                  })
                                )
                              );
                              updateOrderData(
                                "location_from_flat",
                                firstAddr.location_from_flat || ""
                              );
                              updateOrderData(
                                "location_from_floor",
                                firstAddr.location_from_floor || ""
                              );
                            } else {
                              firstAddr.location_to &&
                                handleReceiverAddress({
                                  ...firstAddr.location_to,
                                  label: tr(
                                    firstAddr.location_to.name_ru,
                                    firstAddr.location_to.name_kg
                                  ),
                                  value: firstAddr.location_to?.id,
                                  flat: firstAddr.location_to_flat,
                                  floor: firstAddr.location_to_floor,
                                });
                              setAllAddressesReceiver(
                                uniqualize(
                                  addrList.map((addr) => {
                                    return {
                                      label: tr(
                                        addr.location_to.name_ru,
                                        addr.location_to.name_kg
                                      ),
                                      value: addr.location_to?.id,
                                      flat: addr.location_to_flat,
                                      floor: addr.location_to_floor,
                                      ...addr.location_to,
                                    };
                                  })
                                )
                              );
                              updateOrderData(
                                "location_to_flat",
                                firstAddr.location_to_flat || ""
                              );
                              updateOrderData(
                                "location_to_floor",
                                firstAddr.location_to_floor || ""
                              );
                            }
                            updateOrderData("packageType", {
                              value: d[0].package_type.id,
                              label: tr(
                                d[0].package_type.name_ru,
                                d[0].package_type.name_kg
                              ),
                              ...d[0].package_type,
                            });
                            updateOrderData("tariff", {
                              value: d[0].tariff?.id,
                              label: d[0].tariff?.name,
                              ...d[0].tariff,
                            });
                          }
                        })
                    )
                    .catch((err) => console.log(err));
                }
              })
          )
          .catch((err) => console.log(err));
      }
    },
    [
      tr,
      handleSenderAddress,
      handleReceiverAddress,
      updateOrderData,
      setAllAddressesReceiver,
    ]
  );

  const tariffSelect = useCallback(
    (res) => {
      updateOrderData("zoneFeeCheck", false);
      updateOrderData("redemptionFeeCheck", false);
      updateOrderData("weightFeeCheck", false);
      updateOrderData("selectedTariff", res);
      updateOrderData("final_price", res.price);

      if (res.name.includes("Из офиса")) {
        const loc = profile.delivery_office?.location;
        if (loc) {
          let senderAddr = {
            label: tr(loc.name_ru, loc.name_kg),
            value: loc.id,
            ...loc,
          };
          handleSenderAddress(senderAddr);
          if (loc.city) {
            updateOrderData("selectedCitySender", {
              label: tr(loc.city.name_ru, loc.city.name_kg),
              value: loc.city.id,
              ...loc.city,
            });
          }
          if (loc.district) {
            updateOrderData("selectedDistrictSender", {
              label: tr(loc.district.name_ru, loc.district.name_kg),
              value: loc.district.id,
              ...loc.district,
            });
          }
        }
      }
    },
    [updateOrderData, handleSenderAddress, profile, tr]
  );

  const transportSelect = useCallback(
    (res) => {
      if (res.length < 1 || !res[res.length - 1].value) {
        updateOrderData("selectedTransport", []);
      } else {
        updateOrderData(
          "selectedTransport",
          res.filter((r) => r.value)
        );
      }
    },
    [updateOrderData]
  );

  const clearFields = useCallback(() => {
    dispatch(newOrderData({}));
  }, [dispatch]);

  const promiseAddressOptions = (inputValue, role) => {
    let addrParams = inputValue;
    if (role === "Sender") {
      addrParams += orderData.selectedCitySender
        ? "&city_id=" + orderData.selectedCitySender.id
        : "";
      addrParams += orderData.selectedDistrictSender
        ? "&district_id=" + orderData.selectedDistrictSender.id
        : "";
    } else {
      addrParams += orderData.selectedCityReceiver
        ? "&city_id=" + orderData.selectedCityReceiver.id
        : "";
      addrParams += orderData.selectedDistrictReceiver
        ? "&district_id=" + orderData.selectedDistrictReceiver.id
        : "";
    }
    return new Promise((resolve) => {
      fetchCompanyAddressesSearch(addrParams).then(
        (res) =>
          res.ok &&
          res.json().then((d) => {
            resolve(
              d.map((dd) => {
                return {
                  label:
                    tr(dd.name_ru, dd.name_kg) +
                    (dd.district
                      ? ", " + tr(dd.district.name_ru, dd.district.name_kg)
                      : "") +
                    (dd.city
                      ? ", " + tr(dd.city.name_ru, dd.city.name_kg)
                      : ""),
                  value: dd.id,
                  ...dd,
                };
              })
            );
          })
      );
    });
  };
  const promiseDistrictOptions = (inputValue, role) => {
    let params = inputValue;
    if (role === "Sender") {
      params += orderData.selectedCitySender
        ? "&city_id=" + orderData.selectedCitySender.id
        : "";
    } else {
      params += orderData.selectedCityReceiver
        ? "&city_id=" + orderData.selectedCityReceiver.id
        : "";
    }
    return new Promise((resolve) => {
      fetchDistrictSearch(params).then((res) =>
        res.ok
          ? res.json().then((d) => {
              resolve(
                d.map((dd) => {
                  return {
                    label:
                      tr(dd.name_ru, dd.name_kg) +
                      " (" +
                      tr(dd.city.name_ru, dd.city.name_kg) +
                      ")",
                    value: dd.id,
                    ...dd,
                  };
                })
              );
            })
          : resolve([])
      );
    });
  };

  const promiseCityOptions = (inputValue) => {
    return new Promise((resolve) => {
      fetchSearchedCities(inputValue).then(
        (res) =>
          res.ok &&
          res.json().then((d) => {
            resolve(
              d.map((dd) => {
                return {
                  label: tr(dd.name_ru, dd.name_kg),
                  value: dd.id,
                  ...dd,
                };
              })
            );
          })
      );
    });
  };

  const promiseClientOptions = (inputValue) => {
    let key = inputValue;
    if (key[0] === "+") {
      key = key.substring(1, key.length);
    }
    return new Promise((resolve) => {
      fetchClientsSearh(key).then(
        (res) =>
          res.ok &&
          res.json().then((d) => {
            setAllClients(d);
            resolve(
              d.map((cl) => {
                return {
                  value: cl.id,
                  label: cl.fullname + " " + cl.phone,
                  ...cl,
                };
              })
            );
          })
      );
    });
  };

  const submitCreate = () => {
    setButtonClickable(false);
    setLoading(true);

    orderData.selectedClientSender
      ? setEmptySender(false)
      : setEmptySender(true);
    orderData.selectedAddressSender
      ? setEmptyAddressSender(false)
      : setEmptyAddressSender(true);

    checkFieldsBeforeConfirm(orderData)
      .then((res) => {
        if (orderData.pickUpDay === "tomorrow") {
          orderData.pickUpTimeStamp = new Date(
            orderData.pickUpTimeStamp.setDate(
              orderData.pickUpTimeStamp.getDate() + 1
            )
          );
        }
        if (orderData.deliveryDay === "tomorrow") {
          orderData.deliveryTimeStamp = new Date(
            orderData.deliveryTimeStamp.setDate(
              orderData.deliveryTimeStamp.getDate() + 1
            )
          );
        }
        let orderFormBody = buildOrderBody(orderData, packagesTypes, tariffs);

        // if(!orderData.selectedAddressReceiver?.label) {
        //     if(orderData.selectedDistrictReceiver) {
        //         orderFormBody.location_to_id = allAddressesReceiver[0].id
        //     } else if(orderData.selectedCityReceiver) {
        //         orderFormBody.location_to_id = allAddressesReceiver.filter(ad => !ad.district)[0].id
        //     }
        // }

        let createFetchs = [[], [], [], []];

        if (!orderData.selectedClientSender?.id) {
          let senderPhone = orderData.selectedClientSender.label;
          if (senderPhone.substring(0, 1) === "0") {
            senderPhone = "+996" + senderPhone.substring(1, senderPhone.length);
          } else if (senderPhone.substring(0, 3) === "996") {
            senderPhone = "+" + senderPhone;
          } else if (senderPhone.substring(0, 1) !== "+") {
            senderPhone = "+996" + senderPhone;
          }
          createFetchs[0] = fetchCreateClient({
            fullname:
              orderData.selectedClientSender.fullname ||
              orderData.senderFullname ||
              senderPhone,
            phone: senderPhone,
          });
        }
        if (
          orderData.selectedClientReceiver &&
          !orderData.selectedClientReceiver?.id
        ) {
          let receiverPhone = orderData.selectedClientReceiver.label;
          if (receiverPhone.substring(0, 1) === "0") {
            receiverPhone =
              "+996" + receiverPhone.substring(1, receiverPhone.length);
          } else if (receiverPhone.substring(0, 3) === "996") {
            receiverPhone = "+" + receiverPhone;
          } else if (receiverPhone.substring(0, 1) !== "+") {
            receiverPhone = "+996" + receiverPhone;
          }
          createFetchs[1] = fetchCreateClient({
            fullname:
              orderData.selectedClientReceiver.fullname ||
              orderData.receiverFullname ||
              receiverPhone,
            phone: receiverPhone,
          });
        }
        if (!orderFormBody.location_from_id) {
          createFetchs[2] = fetchCompanyAddressCreate({
            name_kg: orderData.selectedAddressSender.label,
            name_ru: orderData.selectedAddressSender.label,
            city_id: orderData.selectedCitySender
              ? orderData.selectedCitySender.id
              : company?.city
              ? company.city.id
              : 3,
            district_id: orderData.selectedDistrictSender?.id,
            lat: orderData.selectedAddressSender.lat,
            lng: orderData.selectedAddressSender.lng,
          });
        }
        if (
          orderData.selectedAddressReceiver &&
          !orderFormBody.location_to_id
        ) {
          createFetchs[3] = fetchCompanyAddressCreate({
            name_kg: orderData.selectedAddressReceiver.label,
            name_ru: orderData.selectedAddressReceiver.label,
            city_id: orderData.selectedCityReceiver
              ? orderData.selectedCityReceiver.id
              : company?.city
              ? company.city.id
              : 3,
            district_id: orderData.selectedDistrictReceiver?.id,
            lat: orderData.selectedAddressReceiver.lat,
            lng: orderData.selectedAddressReceiver.lng,
          });
        }
        Promise.all(createFetchs)
          .then((values) => {
            Promise.all(
              values.map((v) => (v.toString().length > 0 ? v.json() : v))
            ).then((d) => {
              if (d[0].toString().length > 0) {
                orderFormBody.sender_id = d[0]?.id;
              }
              if (d[1].toString().length > 0) {
                orderFormBody.receiver_id = d[1]?.id;
              }
              if (d[2].toString().length > 0) {
                orderFormBody.location_from_id = d[2]?.id;
              }
              if (d[3].toString().length > 0) {
                orderFormBody.location_to_id = d[3]?.id;
              }
              console.log(orderFormBody);
              submitOrder(orderFormBody);
            });
          })
          .catch((err) => {
            toastify("error", err.toString());
            setButtonClickable(true);
            setLoading(false);
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        toastify("error", t(err.reason));
        setErrorField(err);
        setButtonClickable(true);
        setLoading(false);
      });
  };

  const fetchReq = (form, orderId) => {
    if (edit) {
      return props.confirm
        ? orderConfirmFetch(orderId, form)
        : updateOrderFetch(orderId, form);
    } else {
      return profile.roles.includes("partner")
        ? createOrderFetchPartner(form)
        : createOrderFetch(form);
    }
  };

  const submitOrder = (orderForm) => {
    fetchReq(orderForm, params.orderId)
      .then((res) => {
        console.log(res);
        if (res.ok) {
          res.json().then((d) => {
            toastify(
              "success",
              params.orderId
                ? t("Operation succeeded")
                : tr("Создан новый заказ", "Жаңы буйрутма түзүлдү") +
                    ": " +
                    d.oid
            );
            let emptyArr = [{}];
            dispatch(newOrderData(...emptyArr));
            history.push(
              profile?.roles.includes("partner")
                ? "/current-orders-partners"
                : "/current-orders"
            );
            caches.keys().then((names) => {
              names.forEach((name) => {
                caches.delete(name);
              });
            });
          });
        } else {
          toastify("error", t("Operation failed"));
          setButtonClickable(true);
          setLoading(false);
          res.json().then((d) => {
            console.log(d);
            setErrorField(d);
          });
          if (res.status === 401) {
            history.push("/login");
          }
        }
      })
      .catch((err) => {
        console.log(err);
        toastify("error", err?.detail);
        toastify("error", t("Error occured. " + err.toString()));
        setLoading(false);
      });
  };

  const geoSelect = (res, isSender) => {
    if (res?.lat) {
      if (isSender) {
        if (!orderData.selectedAddressSender?.lat && res.lat) {
          setCenter([res.lat, res.lng]);
          setZoom(14);
          if (orderData.selectedAddressReceiver?.lat) {
            addRoute(
              mapApi,
              [res.lat, res.lng],
              [
                orderData.selectedAddressReceiver.lat,
                orderData.selectedAddressReceiver.lng,
              ]
            );
          } else if (orderData.selectedDistrictReceiver?.lat) {
            addRoute(
              mapApi,
              [res.lat, res.lng],
              [
                orderData.selectedDistrictReceiver.lat,
                orderData.selectedDistrictReceiver.lng,
              ]
            );
          } else if (orderData.selectedCityReceiver?.lat) {
            addRoute(
              mapApi,
              [res.lat, res.lng],
              [
                orderData.selectedCityReceiver.lat,
                orderData.selectedCityReceiver.lng,
              ]
            );
          }
        }
      } else {
        if (!orderData.selectedAddressReceiver?.lat && res.lat) {
          if (orderData.selectedAddressSender?.lat) {
            addRoute(
              mapApi,
              [
                orderData.selectedAddressSender.lat,
                orderData.selectedAddressSender.lng,
              ],
              [res.lat, res.lng]
            );
          } else if (orderData.selectedDistrictSender?.lat) {
            addRoute(
              mapApi,
              [
                orderData.selectedDistrictSender.lat,
                orderData.selectedDistrictSender.lng,
              ],
              [res.lat, res.lng]
            );
          } else if (orderData.selectedCitySender?.lat) {
            addRoute(
              mapApi,
              [
                orderData.selectedCitySender.lat,
                orderData.selectedCitySender.lng,
              ],
              [res.lat, res.lng]
            );
          }
        }
      }
    }
  };

  const [loading, setLoading] = useState(false);

  return (
    <FormContainer
      loading={loading}
      errorField={errorField}
      allClients={allClients}
      emptySender={emptySender}
      emptyAddressSender={emptyAddressSender}
      selectClient={selectClient}
      orderData={orderData}
      setPointSender={setPointSender}
      setPointReceiver={setPointReceiver}
      updateOrderData={updateOrderData}
      getDistrictsFor={getDistrictsFor}
      cities={cities}
      geoSelect={geoSelect}
      districtsSender={districtsSender}
      districtsReceiver={districtsReceiver}
      promiseAddressOptions={promiseAddressOptions}
      promiseCityOptions={promiseCityOptions}
      promiseDistrictOptions={promiseDistrictOptions}
      allAddressesSender={allAddressesSender}
      allAddressesReceiver={allAddressesReceiver}
      setAllAddressesReceiver={setAllAddressesReceiver}
      handleSenderAddress={handleSenderAddress}
      handleReceiverAddress={handleReceiverAddress}
      promiseClientOptions={promiseClientOptions}
      profile={profile}
      transports={transports}
      transportSelect={transportSelect}
      sortedCourierList={sortedCourierList}
      courierList={courierList}
      packagesTypes={packagesTypes}
      detectRedemptionFee={detectRedemptionFee}
      detectWeightFee={detectWeightFee}
      buttonClickable={buttonClickable}
      submitCreate={submitCreate}
      edit={edit}
      clearFields={clearFields}
      tariffs={tariffs}
      tariffSelect={tariffSelect}
      setButtonClickable={setButtonClickable}
      sidebarShow={sidebarShow}
      setMapApi={setMapApi}
      pointSender={pointSender}
      pointReceiver={pointReceiver}
      center={center}
      zoom={zoom}
      allFees={allFees}
      open={open}
      setOpen={setOpen}
      cCourier={cCourier}
      setCCourier={setCCourier}
      map={map}
    />
  );
}
