import { createApi, SERVER_URL } from "./ServerService";
import { DELETE, PATCH, POST } from "./types";

export const fetchCities = () => {
  return createApi("/api/v1/cities/");
};

export const fetchSearchedCities = (key) => {
  return createApi("/api/v1/cities/?search=" + key);
};

export const fetchCreateCity = (cityForm) => {
  return createApi("/api/v1/cities/", {method: POST, body: cityForm});
};

export const fetchCreateDistrict = (districtForm) => {
  return createApi("/api/v1/districts/", {method: POST, body: districtForm});
};

export const fetchUpdateCity = (cityForm, id) => {
  return createApi("/api/v1/cities/" + id + "/", {method: PATCH, body: cityForm});
};

export const fetchUpdateDistrict = (districtForm, id) => {
  return createApi("/api/v1/districts/" + id + "/", {method: PATCH, body: districtForm});
};

export const fetchCity = (id) => {
  return createApi("/api/v1/cities/" + id + "/");
};

export const fetchDistricts = (cityId) => {
  return createApi("/api/v1/districts/?city_id=" + cityId);
};

export const fetchDistrictSearch = (param) => {
  return createApi("/api/v1/districts/?search=" + param);
};

export const fetchAllDistricts = () => {
  return createApi("/api/v1/districts/all/?limit=5000");
};

export const fetchSearchDistricts = (key) => {
  return createApi("/api/v1/districts/all/?limit=5000&search=" + key);
};

export const fetchAllDistrictsWithPar = (sc) => {
  return createApi("/api/v1/districts/all/?skip=" + sc);
};

export const fetchDistrict = (id) => {
  return createApi("/api/v1/districts/" + id + "/");
};

export const fetchDeleteDistrict = (id) => {
  return createApi("/api/v1/districts/" + id + "/", {method: DELETE, });
};

export const fetchDeleteCity = (id) => {
  return createApi("/api/v1/cities/" + id + "/", {method: DELETE});
};

// Get Regions List
export const fetchRegions = () => {
  return createApi("/api/v1/regions/");
};
// Create Region
export const fetchCreateRegion = (regionForm) => {
  return createApi("/api/v1/regions/", {method: POST, body: regionForm});
};
// Update Region
export const fetchUpdateRegion = (regionForm, id) => {
  return createApi("/api/v1/regions/" + id + "/", {method: PATCH, body: regionForm});
};
// Delete Region
export const fetchDeleteRegion = (id) => {
  return createApi("/api/v1/regions/" + id + "/", {method: DELETE});
};

//fetch locations by city_id, district_id
export const fetchLocations = (cityId, districtId) => {
  let url = "/api/v1/locations/?"
  if(cityId) {
    url = url + "city_id=" + cityId + "&"
  }
  if(districtId) {
    url = url + "district_id" + districtId + "&"
  }
  return createApi(url + "limit=15000");
};

export const fetchAllBishkekAdr = () => {
  return createApi("/api/v1/locations/?limit=200000");
};
export const fetchAllAdressesForTable = () => {
  return createApi("/api/v1/locations/?limit=500");
};

export const fetchCourierCompaniesAddresses = () => {
  return createApi("/api/v1/locations/companies/");
};

export const fetchCourierCompanyAddresses = (id) => {
  return createApi("/api/v1/locations/companies?limit=100&delivery_service_id="+id);
};

export const fetchLocationDetails = (locationId) => {
  return createApi("/api/v1/locations/" + locationId + "/");
};

export const postLocation = (locationForm) => {
  return createApi("/api/v1/locations/", {method: POST, body: locationForm});
};

export const deleteLocation = (locationId) => {
  return createApi("/api/v1/locations/" + locationId + "/", {method: DELETE});
};

//fetch locations by search Key
export const fetchLocationSearch = (key, cityId) => {
  let url = cityId
    ? SERVER_URL + "/api/v1/locations/?city_id=" + cityId + "&search=" + key
    : SERVER_URL + "/api/v1/locations/?search=" + key;
  return createApi(url);
};
//fetch locations by search Key2
export const fetchSearchByKey = (key) => {
  return createApi("/api/v1/locations/?search=" + key);
};