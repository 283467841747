import { CButton, CInput } from "@coreui/react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { date } from "src/helpers/date";
import PrintStickerAmanat from "src/pages/orders/Order-Details/PrintStickerAmanat";
import { SERVER_URL } from "src/services/ServerService";

export default function OrdersTab({ orders, selectAll, handleOrderSelect }) {
  const { t } = useTranslation();
  const multiHandlePrint = useReactToPrint({
    content: () => multiComponentRef.current,
  });

  const company = useSelector((state) => state.auth.company);

  //   const singleComponentRef = useRef();
  const multiComponentRef = useRef();
  return (
    <div className="my-4">
      <h5>
        {t("Количество")}: {orders.length}
      </h5>
      {orders.length > 0 && (
        <CButton
          color="info"
          className="float-right mb-2"
          onClick={() => {
            multiHandlePrint();
          }}
        >
          {t("Печатать выбранные")}
        </CButton>
      )}
      {orders.length > 0 ? (
        <table className="table table-responsive-lg">
          <thead>
            <tr>
              <th>#</th>
              <th>{t("Sender")}</th>
              <th>{t("Receiver")}</th>
              <th>{t("Status")}</th>
              <th>{t("Date")}</th>
              <th>{t("Tariff")}</th>
              <th>{t("Redemption")}</th>
              <th>{t("Price")}</th>
              {/* <th>{t("Action")}</th> */}
              <th style={{ width: "5%" }}>
                <CInput
                  type="checkbox"
                  onChange={(e) => selectAll(e.target.checked)}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {orders.map((o, i) => (
              <tr key={o.id}>
                <td>{o.oid}</td>
                <td>
                  {o.sender?.phone}
                  <br />
                  {o.location_from?.name_kg}
                </td>
                <td>
                  {o.receiver?.phone}
                  <br />
                  {o.location_to?.name_kg}
                </td>
                <td>{t(o.status)}</td>
                <td>{date(o.created_at)}</td>
                <td>{o.tariff?.name}</td>
                <td>{o.redemption_amount}</td>
                <td>{o.final_price}</td>
                {/* <td className='text-center'>
                                                <CButton color='info' onClick={() => setSelectedOrder({...o})}>{t("Print")}</CButton>
                                            </td> */}
                <td className="text-center">
                  <CInput
                    type="checkbox"
                    onChange={(e) => handleOrderSelect(i, e.target.checked)}
                    checked={o.selected}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div>Нет заказов</div>
      )}
      <div className="d-none">
        {/* <div ref={singleComponentRef}>
          {orders.map((o, i) => (
            <div key={o.id} className="pagebreak">
              <PrintStickerAmanat
                qrCode={
                  "http://api.qrserver.com/v1/create-qr-code/?data=http://partner.smartpost.kg/orders/" +
                  o.pid
                }
                courierCompanyLogo={company?.navbar_logo}
                courierCompanyPhone={""}
                orderId={o.id}
                oid={o.oid}
                smartPost={""}
                sender={o.sender?.fullname}
                receiver={o.receiver?.fullname}
                senderPhone={o.sender?.phone}
                senderAddress={o.location_from}
                receiverPhone={o.receiver?.phone}
                receiverAddress={o.location_to}
                comment={o.description}
                redemption={o.redemption_amount}
                price={o.final_price}
                isPaid={o.is_paid}
              />
            </div>
          ))}
        </div> */}
        <div ref={multiComponentRef}>
          {orders
            .filter((o) => o.selected)
            .map((o, i) => (
              <div key={o.id} className="pagebreak">
                <PrintStickerAmanat
                  qrCode={
                    `http://api.qrserver.com/v1/create-qr-code/?data=${SERVER_URL}/orders/` +
                    o.pid
                  }
                  courierCompanyLogo={company?.navbar_logo}
                  courierCompanyPhone={""}
                  orderId={o.id}
                  oid={o.oid}
                  smartPost={""}
                  sender={o.sender?.fullname}
                  receiver={o.receiver?.fullname}
                  senderPhone={o.sender?.phone}
                  senderAddress={o.location_from}
                  receiverPhone={o.receiver?.phone}
                  receiverAddress={o.location_to}
                  comment={o.description}
                  redemption={o.redemption_amount}
                  price={o.final_price}
                  isPaid={o.is_paid}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
