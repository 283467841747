import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const useTranslate = () => {

  const {i18n} = useTranslation();

  const tr = useCallback((variant, defaultValue) => {
     const resultText = i18n.language=== "ru" ? variant : defaultValue;
     return resultText || '';
  }, [i18n])

  return { tr };
};