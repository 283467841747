import { CCard, CCardBody, CCol, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { setBreadcrumb } from 'src/redux/actions/settingsActions';
import { useDispatch } from 'react-redux';
import MiniSpinner from 'src/components/spinners/MiniSpinner';
import { fetchRedemptionPayments } from 'src/services/ReportsService';
import { fetchClientOrganizations } from 'src/services/ClientService';
import { fetchOffices } from 'src/services/CompanyService';
import PaymentsTable from './PaymentsTable';
import PaymentFilters from './PaymentFilters';
import { useTranslation } from 'react-i18next';


export default function RedemptionPayments() {
    const [loading, setLoading] = useState(null);
    const dispatch = useDispatch();
    const {t} = useTranslation()

    const [payments, setPayments] = useState([])
    const [organizations, setOrganizations] = useState([])
    const [offices, setOffices] = useState([])

    const [params, setParams] = useState(null)

    useEffect(() => {
        setLoading(true)
        dispatch(setBreadcrumb(["Redemption payments"]));
        fetchRedemptionPayments().then(res => res.ok && res.json().then(d =>{
            setPayments(d)
            setLoading(false)
            console.log(d)
        })).catch(err => console.log(err))
        fetchClientOrganizations().then(res => res.ok && res.json().then(d => {
            setOrganizations(d)
        }))
        fetchOffices().then(res => res.ok && res.json().then(d => {
            setOffices(d)
        }))
    }, [dispatch])


    const filterChange = (e, field) => {
        let qParams = {...params}
            qParams = {...params, [field]: e.target.value}
        setParams(qParams)
        filterList(qParams)
    }
    const filterList = (currentParams, clear) => {
        console.log(currentParams)
        let queryParams = "";
        if(currentParams) {
            if(currentParams.search_key) {
                queryParams = queryParams+"search="+currentParams.search_key + "&";
            }
            if(currentParams.organization) {
                queryParams = queryParams+"organization_id="+currentParams.organization + "&";
            }
            if(currentParams.payment_type) {
                queryParams = queryParams+"payment_type="+currentParams.payment_type + "&";
            }
            if(currentParams.payment_status) {
                queryParams = queryParams+"is_paid="+(currentParams.payment_status ==="paid") + "&";
            }
            if(currentParams.payment_method) {
                queryParams = queryParams+"payment_method="+currentParams.payment_method + "&";
            }
            if(currentParams.date_from) {
                queryParams = queryParams+"date_from="+currentParams.date_from + "&";
            }
            if(currentParams.date_to) {
                queryParams = queryParams+"date_to="+currentParams.date_to
            }
            if(currentParams.from_office_id) {
                queryParams = queryParams+"from_office_id="+currentParams.from_office_id
            }
            if(currentParams.to_office_id) {
                queryParams = queryParams+"to_office_id="+currentParams.to_office_id
            }
        }
        if(clear) {
            setParams({search_key: "", payment_type: "", payment_status: "", date_from: "", date_to: ""})
            queryParams=""
        }
        setLoading(true)
        console.log(queryParams)
        fetchRedemptionPayments(queryParams).then(res => 
            res.ok && res.json().then(d => {
            setPayments(d)
            setLoading(false)
            console.log(d)
        })
        )
    }

    const count = (order, paid) => {
        return payments.reduce(
            (acc, pay) => {
                acc = acc + (pay.payments?.filter(p => 
                        p.ordering===order && p.is_paid === paid
                    )[0]?.amount || 0);
                return acc;
            }, 0
            ) 
        || 0
    }

    const onSuccess = () => {
        setLoading(false)
    }

    return (
        <div>
        <CCard>
            <CCardBody className="pt-1">
                <PaymentFilters organizations={organizations} offices={offices} params={params} filterChange={filterChange}/>
                <CRow>
                    <CCol md="2" sm="4">
                        {t("Redemption payments amount")}: <b>{payments.length}</b>
                    </CCol>
                    <CCol md="2" sm="4">
                        {t("Redemptions overall")}: <b>{payments.reduce((acc, pay) => acc += pay.redemption_amount, 0) || 0} с</b>
                    </CCol>
                    <CCol md="2" sm="4">
                        {t("Paid for product")}: <b>{count(2, true)} с</b>
                    </CCol>
                    <CCol md="2" sm="4">
                        {t("Not paid for product")}: <b>{count(2, false)} с</b>
                    </CCol>
                    <CCol md="2" sm="4">
                        {t("Transfered to office")}: <b>{count(3, true)} с</b>
                    </CCol>
                    <CCol md="2" sm="4">
                        {t("Not transfered to office")}: <b>{count(3, false)} с</b>
                    </CCol>
                </CRow>
                {loading ? 
                    <div className="text-center"><MiniSpinner/></div> :
                    <PaymentsTable payments={payments} onSuccess={onSuccess}/>}
            </CCardBody>
        </CCard>
        </div>
    )
}