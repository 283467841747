import React, { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { CBadge, CCol, CInput, CLabel, CRow} from '@coreui/react';
import Select from 'react-select';
import { useTranslate } from 'src/helpers/useTranslate';
import chevronUp from "src/assets/chevron-up.svg"
import chevronDown from "src/assets/chevron-down.svg"
import CIcon from '@coreui/icons-react';
import { fetchCreateImg } from 'src/services/ServerService';
import { compressFile } from 'src/helpers/fileCompresser';

export function ParcelFields({
    packagesTypes, updateOrderData, orderData, detectWeightFee
}) {

    const {t} = useTranslation();
    const {tr} = useTranslate();

    const [showParams, setShowParams] = useState(false)

    const [photoUp, setPhotoUp] = useState(null)
    
    const uploadImages = useCallback((files) => {
        Promise.all([...files].map(f => {
            let formData = new FormData();
            formData.append("image", f);        
            return fetchCreateImg(formData)
        })).then((values) => {
            setPhotoUp("Фотографии загружены")
            const linkList = values.map(v => v.data)
            updateOrderData("sender_photos", orderData.sender_photos ? [...orderData.sender_photos, ...linkList] : linkList)
          }).catch(err => {
              setPhotoUp("Ошибка при загрузке фотографии")
          });
    }, [updateOrderData, orderData])

    const handleFileChange = useCallback((e) => {
        const files = e.target.files;
        setPhotoUp("Загружается")
        Promise.all([...files].map(file => {
            return compressFile(file)
        })).then((values) => {
            uploadImages(values)
        })
    }, [uploadImages])
    
    return (
        <div>
            <h6 className="text-dark text-uppercase my-n1 font-xs">{t('Parcel')}</h6>
            <hr className="mt-0 my-2" />
            <Select 
                options={packagesTypes.map(pack => { return { value: pack.id,label: tr(pack.name_ru, pack.name_kg), ...pack}})}  
                onChange={res => {
                    updateOrderData("selectedPack", res)
                    updateOrderData("weight", res.max_weight)
                    updateOrderData("height", res.height)
                    updateOrderData("width", res.width)
                    updateOrderData("length", res.length)
                }} 
                value={orderData.selectedPack || { value: packagesTypes[0]?.id,label: tr(packagesTypes[0]?.name_ru, packagesTypes[0]?.name_kg), ...packagesTypes[0]}}/>
                <CLabel className="mt-1 w-100 border-bottom rounded py-1" onClick={() => setShowParams(!showParams)}>
                    <span>{t("Dimensions")}</span>
                    <span className="float-right">
                        <img src={showParams ? chevronUp : chevronDown} alt="toggle"/>
                    </span>    
                </CLabel>
            {showParams && <div>
            <CRow className="px-0">
                <CCol className="pr-1" sm="6">
                    <CLabel className="font-xs my-0">{t("Weight") + "(кг)"}</CLabel>
                    <CInput size="sm" placeholder={t("Weight")} className="mb-2" type="number" value={orderData.weight || ""} 
                        onChange={e => {
                            updateOrderData("weight", e.target.value)
                            detectWeightFee(orderData.amount * Math.max(e.target.value, orderData.height * orderData.width * orderData.length / 4000))
                        }}/>
                </CCol>
                <CCol className="pl-1" sm="6">
                    <CLabel className="font-xs my-0">
                        {t("Amount")}:
                    </CLabel>
                    <CInput size="sm" placeholder={t("Amount")} className="mb-2" type="number" value={orderData.amount || ""} 
                    onChange={e => {
                        updateOrderData("amount", e.target.value)
                        detectWeightFee(e.target.value * Math.max(orderData.weight, orderData.height * orderData.width * orderData.length / 4000))
                    }}/>
                </CCol>
            </CRow>
            <CRow className="px-0">
                <CCol className="pr-1">
                    <CLabel className="font-xs my-0">{t("Height") + " см"}</CLabel>
                    <CInput size="sm" placeholder={t("Height")} type="number" value={orderData.height || ""} 
                    onChange={e => {
                        updateOrderData("height", e.target.value)
                        detectWeightFee(orderData.amount * Math.max(orderData.weight, e.target.value * orderData.width * orderData.length / 4000))
                    }}/>
                </CCol>
                <CCol className="px-1">
                    <CLabel className="font-xs my-0">{t("Width") + " см"}</CLabel>
                    <CInput size="sm" placeholder={t("Width")} type="number" value={orderData.width || ""}
                    onChange={e => {
                        updateOrderData("width", e.target.value)
                        detectWeightFee(orderData.amount * Math.max(orderData.weight, orderData.height * e.target.value * orderData.length / 4000))
                    }}/>
                </CCol>
                <CCol className="pl-1">
                    <CLabel className="font-xs my-0">{t("Length") + " см"}</CLabel>
                    <CInput size="sm" placeholder={t("Length")} type="number" value={orderData.length || ""}
                    onChange={e => {
                        updateOrderData("length", e.target.value)
                        detectWeightFee(orderData.amount * Math.max(orderData.weight, orderData.height * orderData.width * e.target.value / 4000))
                    }}/>
                </CCol>
            </CRow>
            <CRow>
                <CCol className="pr-1" sm="6">
                    <CLabel className="font-xs my-0">{t("All weight")}</CLabel>
                    <CInput size="sm" placeholder={t("Weight")} className="mb-2" 
                        value={((orderData.amount || 1) * orderData.weight) +" кг"} disabled />
                </CCol>
                <CCol className="pl-1" sm="6">
                    <CLabel className="font-xs my-0">
                        {t("Volume weight")}:
                    </CLabel>
                    <CInput className="mb-2" size="sm" 
                        value={((orderData.amount || 1) * orderData.height * orderData.width * orderData.length / 4000).toFixed(2) +" кг"} disabled/>
                </CCol>
            </CRow>
            </div>}
            
            <CLabel>{t("Upload a photo")}</CLabel>
            <CInput type="file" multiple onChange={handleFileChange}/>
            <CBadge color="primary">{photoUp}</CBadge>
            {orderData.sender_photos && 
                orderData.sender_photos.map((photo, i) => 
                    <div key={i} className="my-1 pb-1 d-flex justify-content-between" style={{borderBottom: "1px dotted gray"}}>
                        <img alt="s" src={photo} style={{maxHeight: "30px"}}/>
                        <CIcon name="cilX" className='sp-link' 
                            onClick={() => updateOrderData("sender_photos", 
                                orderData.sender_photos.filter(ph => ph !== photo))}/>
                    </div>
            )}
            <br/>
            <div className="font-xs">
                <div>{t("Distance")}: {orderData.distanceBetween && orderData.distanceBetween}</div>
                <div>{t("Duration")}: {orderData.estimation && orderData.estimation}</div>
                <div>{orderData.destinationZone && t("Fee zone") +": " + orderData.destinationZone.name}</div>
            </div>
        </div>
    )
}
