import { createApi } from './ServerService';
import { DELETE, PATCH, POST } from './types';

export const fetchTransports = () => {
    return createApi("/api/v1/transports/");
}

export const fetchTransport = (id) => {
    return createApi("/api/v1/transports/" + id + "/");
}

export const fetchCreateTransport = (transportForm) => {
    return createApi("/api/v1/transports/", {method: POST, body: transportForm});
}

export const fetchDeleteTransport = (id) => {
    return createApi("/api/v1/transports/" + id + "/", {method: DELETE});
}

export const fetchUpdateTransport = (transportForm, id) => {
    return createApi("/api/v1/transports/" + id + "/", {method: PATCH, body: transportForm});
}

export const fetchTransportTypes = () => {
    return createApi("/api/v1/transports/types/");
}

export const fetchTransportType = (id) => {
    return createApi("/api/v1/transports/types/" + id + "/");
}