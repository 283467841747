import { CButton, CCard, CCardBody, CCol, CInput, CLabel, CRow, CSelect, CTextarea } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select'
import { useTranslate } from 'src/helpers/useTranslate';
import { fetchCompanyPackageTypes, fetchOffices } from 'src/services/CompanyService';
import { fetchCouriers } from 'src/services/CourierService';

export function GroupOrders() {

    const {tr} = useTranslate()
    const {t} = useTranslation()

    const [offices, setOffices] = useState([])
    const [couriers, setCouriers] = useState([])
    const [packagesTypes, setPackageTypes] = useState([])

    const [pack, setPack] = useState(null)
    const [courier, setCourier] = useState(null)
    const [officeFrom, setOfficeFrom] = useState(null)
    const [officeTo, setOfficeTo] = useState(null)

    const [groupInfo, setGroupInfo] = useState({
        "orders": [
            0
          ],
          "location_from_id": 0,
          "location_to_id": 0,
          "amount": 1,
          "unit": "cm/kg",
          "length": 0,
          "width": 0,
          "height": 0,
          "weight": 0,
          "volume_weight": 0,
          "from_office_id": 0,
          "to_office_id": 0,
          "description": "",
          "courier_id": 0,
          "source": "packer web",
          "pickup_time": "2021-12-21T08:14:54.713Z",
          "delivery_time": "2021-12-21T08:14:54.713Z",
          "package_type_id": 0
    })

    useEffect(() => {
        fetchOffices().then(res => res.ok && res.json().then(d => {
            setOffices(d)
        }))
        fetchCompanyPackageTypes().then(res => res.ok && res.json().then(d => {
            setPackageTypes(d)
        }))
        fetchCouriers().then(res => res.ok && res.json().then(d => {
            setCouriers(d)
        }))
    }, [])

    const submitForm = () => {
        console.log(groupInfo)
    }

    return (
        <CCard>
            <CCardBody>
            <div className="m-2 px-1">
                <CRow>
                    <CCol className="mx-n2">
                        <Select options={offices.map(o => { 
                            return {label: o.name, value: o.id, ...o}
                        })} isCliearable
                        placeholder="Office from" onChange={res => setOfficeFrom(res)} value={officeFrom}/>
                    </CCol>
                    <CCol  className="mx-n2">
                        <Select options={offices.map(o => { 
                            return {label: o.name, value: o.id, ...o}
                        })} isCliearable
                        placeholder="Office from" onChange={res => setOfficeTo(res)} value={officeTo}/>
                    </CCol>
                    <div className='w-100 d-lg-none my-2'></div>
                    <CCol  className="mx-n2">
                        <Select 
                            options={packagesTypes.map(p => { return { value: p.id,label: tr(p.name_ru, p.name_kg), ...p}})}  
                            onChange={res => setPack(res)} 
                            value={pack}/>
                        <CRow className="px-0">
                            <CCol className="pr-1" sm="6">
                                <CLabel className="font-xs my-0">{t("Weight") + "(кг)"}</CLabel>
                                <CInput size="sm" placeholder={t("Weight")} className="mb-2" type="number" value={groupInfo.weight || ""} 
                                    onChange={e => setGroupInfo({...groupInfo, weight: e.target.value})}/>
                            </CCol>
                            <CCol className="pl-1" sm="6">
                                <CLabel className="font-xs my-0">
                                    {t("Volume weight")}:
                                </CLabel>
                                <CInput className="mb-2" size="sm" value={(groupInfo.height * groupInfo.width * groupInfo.length / 4000) +" кг"} disabled/>
                            </CCol>
                        </CRow>
                        <CRow className="px-0">
                            <CCol className="pr-1">
                                <CLabel className="font-xs my-0">{t("Height") + " см"}</CLabel>
                                <CInput size="sm" placeholder={t("Height")} type="number" value={groupInfo.height || ""} 
                                onChange={e => setGroupInfo({...groupInfo, height: e.target.value})}/>
                            </CCol>
                            <CCol className="px-1">
                                <CLabel className="font-xs my-0">{t("Width") + " см"}</CLabel>
                                <CInput size="sm" placeholder={t("Width")} type="number" value={groupInfo.width || ""}
                                onChange={e => setGroupInfo({...groupInfo, width: e.target.value})}/>
                            </CCol>
                            <CCol className="pl-1">
                                <CLabel className="font-xs my-0">{t("Length") + " см"}</CLabel>
                                <CInput size="sm" placeholder={t("Length")} type="number" value={groupInfo.length || ""}
                                onChange={e => setGroupInfo({...groupInfo, length: e.target.value})}/>
                            </CCol>
                        </CRow>
                    </CCol>
                    <CCol  className="mx-n2">
                        <CTextarea rows="3" placeholder={t("Comments")} className="mb-2" value={groupInfo.description || ""} 
                            onChange={e => setGroupInfo({...groupInfo, description: e.target.value})}/>
                        <span className="font-xs">
                            {t("Pickup time")}
                        </span>
                        <CRow>
                            <CCol className="pr-1">
                                <CSelect size="sm" onChange={e => setGroupInfo({...groupInfo, pickUpDay: e.target.value})}>
                                    <option value="today">{t("Today")}</option>
                                    <option value="tomorrow">{t("Tomorrow")}</option>
                                </CSelect>
                            </CCol>
                            <CCol className="pl-1">
                                <CInput size="sm" type="time" id="appt" name="appt" min="00:00" max="23:59" 
                                    onChange={e => setGroupInfo({...groupInfo, pickUpTime: e.target.value})}/>
                            </CCol>
                        </CRow>
                        <span className="font-xs">
                            {t("Delivery time")}
                        </span>
                        <CRow>
                            <CCol className="pr-1">
                                <CSelect size="sm" onChange={e => setGroupInfo({...groupInfo, delivery_day: e.target.value})}>
                                    <option value="today">{t("Today")}</option>
                                    <option value="tomorrow">{t("Tomorrow")}</option>
                                </CSelect>
                            </CCol>
                            <CCol className="pl-1">
                                <CInput size="sm" type="time" id="appt" name="appt" min="00:00" max="23:59" 
                                    onChange={e => setGroupInfo({...groupInfo, delivery_time: e.target.value})}/>
                            </CCol>
                        </CRow>
                    </CCol>
                    <div className='w-100 d-lg-none my-2'></div>
                    <CCol className="mx-n2">
                        <Select options={couriers.map(c => { 
                            return {label: c.firstname, value: c.id, ...c}
                        })} isCliearable
                        placeholder="Courier" onChange={res => setCourier(res)} value={courier}/>
                        <CButton color="primary" className="btn-block mt-2" disabled={!true} onClick={submitForm}>
                            {t("Submit")}
                        </CButton>
                    </CCol>
                </CRow>
            </div>    
            </CCardBody>
        </CCard>
    )
}