import { CButton, CCard, CCardBody, CCardHeader, CModal, CModalBody, CModalHeader } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import MiniSpinner from 'src/components/spinners/MiniSpinner'
import { setBreadcrumb } from 'src/redux/actions/settingsActions'
import { fetchCarriers } from 'src/services/CourierService'
import CarrierForm from './CarrierForm'

export default function Carriers () {

    const {t} = useTranslation()
    const dispatch = useDispatch()

    const [carriers, setCarriers] = useState([])
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        dispatch(setBreadcrumb(["Магистральные перевозчики"]))
        setLoading(true)
        fetchCarriers().then(res => res.ok ? res.json().then(d => {
            setLoading(false)
            setCarriers(d)
        }) : setLoading(false)).catch(err => setLoading(false))
    }, [dispatch])

    const onSuccess = () => {
        setShow(false)
        fetchCarriers().then(res => res.ok && res.json().then(d => {
            console.log(d)
            setCarriers(d)
        }))
    }

    return (
        <CCard className="m-1">
            <CCardHeader>
                <CButton color="success" className="float-right" onClick={() => setShow(true)}>{t("Create")}</CButton>
            </CCardHeader>
            <CCardBody>
                {loading ? 
                    <div className='text-center'>
                        <MiniSpinner/>
                    </div> 
                :   <table className='table table-responsive-lg'>
                        <thead>
                            <tr>
                                <th>{t("Username")}</th>
                                <th>{t("Firstname")}</th>
                                <th>{t("Lastname")}</th>
                                <th>{t("Middlename")}</th>
                                <th>{t("Auto Number")}</th>
                                <th>{t("Routes")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {carriers.map(c => 
                                    <tr key={c.id}>
                                        <td>{c.username}</td>
                                        <td>{c.firstname}</td>
                                        <td>{c.lastname}</td>
                                        <td>{c.middlename}</td>
                                        <td>{c.auto_number}</td>
                                        <td>{c.routes?.map((r, i) => (i>0 ? ", " : "") + r.name_kg)}</td>
                                    </tr>
                                )}
                        </tbody>
                    </table>}
            </CCardBody>

            <CModal show={show} onClose={() => setShow(false)} size="lg">
                <CModalHeader closeButton>
                    {t("Create")}
                </CModalHeader>
                <CModalBody>
                    <CarrierForm onSuccess={onSuccess}/>
                </CModalBody>
            </CModal>
        </CCard>
    )
}