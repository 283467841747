import React, {useEffect, useState} from 'react';
import {CButton, CFormGroup, CInput} from "@coreui/react";
import { fetchCompanyExtraFeePatch, fetchCompanyExtraFeePost } from "src/services/CompanyService";
import { toastify } from "src/helpers/toast";
import { getExtraFees } from 'src/redux/actions/extraFeesActions';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

export default function ExtraFeeForm(props) {

    const dispatch = useDispatch()
    const { t } = useTranslation();

    const [name, setName] = useState("");
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [amount, setAmount] = useState(0);

    useEffect(() => {
        if(props.selectedFee) {
            setName(props.selectedFee.name)
            setAmount(props.selectedFee.amount)
            setMin(props.selectedFee.fee.min)
            setMax(props.selectedFee.fee.max)
        } else {
            setName("")
            setAmount(0)
            setMin(0)
            setMax(0)
        }
    }, [props])

    const submitFee = () => {
        let feeForm = {name, amount, fee_type: props.openForm, fee: {min, max}};
        console.log(feeForm);
        fetchCompanyExtraFeePost(feeForm).then(res => {
            if(res.ok) {
                toastify("success", t("Created new fee successfully"));
                dispatch(getExtraFees());
                props.onToggle();
            } else {
                toastify("error", t("Произошла ошибка создания доплаты"))
            }
        }).catch(err=> toastify("error", t("Произошла ошибка создания, проверьте интернет")))
    }


    const updateSubmit = () => {
        let feeForm = {name, amount, fee: {min, max}};
        console.log(feeForm);
        fetchCompanyExtraFeePatch(feeForm, props.selectedFee.id).then(res => {
            if(res.ok) {
                toastify("success", t("Updated fee successfully"));
                dispatch(getExtraFees());
                props.onToggle();
            } else {
                toastify("error", t("Произошла ошибка изменения доплаты"))
            }
        }).catch(err=> toastify("error", t("Произошла ошибка создания, проверьте интернет")))
    }

    return (
        <div>
            <CFormGroup>
                <label>{t("Name")}</label>
                <CInput type="text" placeholder="Name" value={name} onChange={e => setName(e.target.value)}/>
            </CFormGroup>
            <CFormGroup>
                <label>{props.openForm === "weight" ? t("Min Weight") : t("Min Sum")}</label>
                <CInput type="number" placeholder="min" value={min} onChange={e => setMin(e.target.value)}/>
            </CFormGroup>
            <CFormGroup>
                <label>{props.openForm === "weight" ? t("Max Weight") : t("Max Sum")}</label>
                <CInput type="number" placeholder="max" value={max} onChange={e => setMax(e.target.value)}/>
            </CFormGroup>
            <CFormGroup>
                <label>{t("Amount Sum")}</label>
                <CInput type="number" placeholder="Fee amount" value={amount} onChange={e => setAmount(e.target.value)}/>
            </CFormGroup>
            <CFormGroup className="text-right">
                {props.selectedFee ? 
                    <CButton variant="outline" color="primary" className="mx-2" onClick={() => updateSubmit()}>{t("Update")}</CButton>
                :
                    <CButton variant="outline" color="success" className="mx-2" onClick={() => submitFee()}>{t("Create")}</CButton>
                }
                <CButton variant="outline" color="danger" onClick={() => props.onToggle()}>{t("Cancel")}</CButton>
            </CFormGroup>
        </div>
    )
}