import { CButton } from "@coreui/react";

export default function  FilterButton({text, onClick, borderColor, isActive}){
    return (
        <CButton 
            className="btn-outline-light btn-sm m-1 text-capitalize text-dark"
            onClick={onClick} 
            style={{border: "Solid 1px "+ borderColor, backgroundColor: isActive && "aqua"}}>
                {text}
        </CButton>
    )
}