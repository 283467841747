 import React, {useEffect, useState} from 'react';
import {
    CButton,
    CForm,
    CFormGroup,
    CInput,
    CLabel,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader
} from "@coreui/react";
import MiniSpinner from "../../components/spinners/MiniSpinner";
import {useTranslation} from "react-i18next";
import Select from 'react-select';
import {fetchCreateRoute, fetchEditRoute, fetchRoutes} from "../../services/RouteService";
import {toastify} from "../../helpers/toast";
import {useTranslate} from "../../helpers/useTranslate";


const RouteForm = ({setRoutes,   toggle, modal, selectedRoute, modalTitle, nodes}) => {
    const {t} = useTranslation();
    const {tr} = useTranslate()
    const [btnState, setBtnState] = useState(false)
    const [duration, setDuration] = useState("")
    const [distance, setDistance] = useState("")
    const [durationUnit, setDurationUnit] = useState("h")
    const [id, setId] = useState("")
    const [routeName, setRouteName] = useState("")
    const [selectFrom, setSelectFrom] = useState({
        value: t('From Where'),
        label: t('From Where')
    })
    const [selectTo, setSelectTo] = useState({
        value: t('To Where'),
        label: t('To Where')
    })


    useEffect(() => {
        setSelectFrom({
            label: tr(selectedRoute.from_node?.name_ru, selectedRoute.from_node?.name_kg) || t('From Where'),
            value: selectedRoute.from_node?.id || t('From Where'),
            ...selectedRoute.from_node
        })

        setSelectTo({
            label: tr(selectedRoute.to_node?.name_ru, selectedRoute.to_node?.name_kg) || t('To Where'),
            value: selectedRoute.to_node?.id || t('To Where'),
            ...selectedRoute.to_node
        })
        setRouteName(selectedRoute ? tr(selectedRoute?.name_ru, selectedRoute?.name_kg) : t('From Where') - t('To Where'))
        setDistance(selectedRoute.distance)
        setDuration(selectedRoute.duration)
        setId(selectedRoute.id)
        setDurationUnit(selectedRoute.duration ? selectedRoute?.duration : 'h')
    }, [selectedRoute, t, tr])

    const onSelectFromChange = (name) => {
        setSelectFrom(name)
        setRouteName(
            `${tr(name.name_ru || t('From Where') , name.name_kg || t('From Where') )} - ${tr(selectTo.name_ru || t('To Where'), selectTo.name_kg || t('To Where'))}`
        )
    }

    const onSelectToChange = (name) => {
        setSelectTo(name)
        setRouteName(
            `${tr(selectFrom.name_ru || t('From Where'), selectFrom.name_kg || t('From Where'))} - ${tr(name.name_ru || t('To Where'), name.name_kg || t('To Where'))}`
        )
    }

    const onCreate = () => {
        submitRoute('create')
    }

    const onEdit = () => {
        submitRoute('edit')
    }

    const submitRoute = (mode = 'create') => {
        setBtnState(true)
        const routeForm = {
            name_kg: routeName,
            name_ru: routeName,
            from_node_id: selectFrom.id,
            to_node_id: selectTo.id,
            distance: distance,
            duration: duration,
            duration_unit: durationUnit,
        }

        if (mode === 'create') {
            fetchCreateRoute(routeForm)
                .then((res) => {
                    setBtnState(false)
                    if (res.ok) {
                        toastify("success", t("Новый маршрут успешно создан"));
                        toggle();
                        fetchRoutes()
                            .then(res => res.ok && res.json()
                                .then(data => setRoutes(data)));
                        setSelectFrom({
                            label: t('From Where')
                        });
                        setSelectTo({
                            label: t('To Where')
                        });
                        setDuration('');
                        setDistance('');
                    } else {
                        toastify("error", t("Произошла ошибка создания маршрута"))
                    }
                })
                .catch((err) => {
                    setBtnState(false)
                    toastify("error", t("Произошла ошибка создания маршрута"));
                    console.log(err, 'error happened')
                })
        } else {
            fetchEditRoute(routeForm, id)
                .then((res) => {
                    setBtnState(false)
                    if (res.ok) {
                        toastify("success", t("Данные маршрута изменены"));
                        toggle();
                        fetchRoutes()
                            .then(res => res.ok && res.json()
                                .then(data => setRoutes(data)));
                        setSelectFrom({
                            label: t('From Where'),
                            value: "From Where"
                        });
                        setSelectTo({
                            label: t('To Where')
                        });
                        setDuration('');
                        setDistance('');
                    } else {
                        toastify("error", t("Произошла ошибка изменения маршрута"))
                    }
                })
                .catch((err) => {
                    setBtnState(false)
                    toastify("error", t("Произошла ошибка изменения маршрута"));
                    console.log(err, 'error happened')
                })
        }

    }



    return (
        <>
            <CModal show={modal} onClose={toggle} size="lg">
                <CModalHeader closeButton>{modalTitle}</CModalHeader>
                <CModalBody>

                    <CForm>
                        <CFormGroup>
                            <CLabel>{t('From Where')}</CLabel>
                            <Select
                                onChange={onSelectFromChange}
                                value={selectFrom}
                                options={nodes && nodes.map((node) => {
                                    return {value: node.id, label: tr(node.name_ru, node.name_kg), ...node}
                                })}
                            />
                        </CFormGroup>
                    </CForm>

                    <CForm>
                        <CFormGroup>
                            <CLabel>{t("To Where")}</CLabel>
                            <Select
                                onChange={onSelectToChange}
                                value={selectTo}
                                options={nodes && nodes.map((node) => {
                                    return {value: node.id, label: tr(node.name_ru, node.name_kg), ...node}
                                })}
                            />
                        </CFormGroup>
                    </CForm>

                    <CForm>
                        <CFormGroup>
                            <CLabel>{t("Route Name")}</CLabel>
                            <CInput
                                type="text"
                                placeholder={t("Route Name")}
                                name="name"
                                value={routeName}
                                onChange={(e) => setRouteName(e.target.value)}
                            />
                        </CFormGroup>
                    </CForm>

                    <CForm>
                        <CFormGroup>
                            <CLabel>{t("Distance")}</CLabel>
                            <CInput
                                type="number"
                                placeholder={t("Distance")}
                                name="distance"
                                value={distance}
                                onChange={(e) => setDistance(e.target.value)}
                            />
                        </CFormGroup>
                    </CForm>

                    <CForm>
                        <CFormGroup>
                            <CLabel>{t("Duration")}</CLabel>
                            <CInput
                                type="number"
                                placeholder={t("Duration")}
                                name="duration"
                                value={duration}
                                onChange={(e) => setDuration(e.target.value)}
                            />
                        </CFormGroup>
                    </CForm>

                    <CForm>
                        <CFormGroup className="d-flex">
                            <div className="mr-5 d-flex align-items-center">
                                <CLabel htmlFor='timeChoice1' className="mr-2 mt-1">{t("hour")}</CLabel>
                                <input
                                    id='timeChoice1'
                                    type="radio"
                                    name='duration_unit'
                                    value='h'
                                    checked={durationUnit === 'h'}
                                    onChange={(e) => setDurationUnit(e.target.value)}
                                />
                            </div>
                            <div className="d-flex align-items-center">
                                <CLabel htmlFor='timeChoice2' className="mr-2 mt-1">{t("minute")}</CLabel>
                                <input
                                    id='timeChoice2'
                                    type="radio"
                                    name="duration_unit"
                                    value='min'
                                    checked={durationUnit === 'min'}
                                    onChange={(e) => setDurationUnit(e.target.value)}
                                />
                            </div>
                        </CFormGroup>
                    </CForm>
                </CModalBody>

                <CModalFooter>

                    {id ?
                        <CButton
                            type="submit"
                            variant="outline"
                            color="success"
                            disabled={btnState}
                            onClick={onEdit}
                        >
                            {btnState ? <MiniSpinner/> : t("Update")}
                        </CButton>
                        :
                        <CButton
                            type="submit"
                            variant="outline"
                            color="success"
                            disabled={btnState}
                            onClick={onCreate}
                        >
                            {btnState ? <MiniSpinner/> : t("Create")}
                        </CButton>
                    }

                    <CButton
                        variant="outline"
                        color="danger"
                        onClick={() => {
                            toggle();
                        }}
                    >
                        {t("Cancel")}
                    </CButton>
                </CModalFooter>
            </CModal>
        </>
    );
};

export default RouteForm;