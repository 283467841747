import { CButton, CCard, CCardBody, CCol, CInput, CLabel, CRow, CSelect, CTextarea } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { date } from 'src/helpers/date';
import { setBreadcrumb } from 'src/redux/actions/settingsActions';
import { fetchCourierBalanceHistory, fetchCourierBalanceHistoryUpdate, fetchCouriers } from 'src/services/CourierService';
import { useDispatch } from 'react-redux';
import MiniSpinner from 'src/components/spinners/MiniSpinner';
import { useTranslation } from "react-i18next";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import CIcon from '@coreui/icons-react';
import { toastify } from 'src/helpers/toast';


export default function CourierPayments() {

    const { t } = useTranslation();
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(null);
    const dispatch = useDispatch();
    const [couriers, setCouriers] = useState([])
    const [balance, setBalance] = useState(0)
    const [overAll, setOverAll] = useState(0)

    const [params, setParams] = useState(null)
    const [selectedMonth, setSelectedMonth] = useState(null)

    const months = ["Январь", "Февраль", "Март", "Апрель",
        "Май", "Июнь", "Июль", "Август", "Сентябрь",
        "Октябрь", "Ноябрь", "Декабрь"]
    
    

    useEffect(() => {
        setLoading(true)
        dispatch(setBreadcrumb(["Courier payments"]));
        fetchCouriers().then(res => {
            res.ok && res.json().then(d => {
                setCouriers(d)
            })
        })
        fetchCourierBalanceHistory().then(res => res.ok && res.json().then(d => {
            setHistory(d);
            setBalance(d.reduce((s, r) => s + r.amount, 0))
            setOverAll(d.length)
            setLoading(false)
            console.log(d)
        }))
    }, [dispatch])


    const filterChange = (e, field) => {
        let qParams = {...params}
        if(field==="month") {
            let dt = new Date()
            dt.setMonth(e.target.value)
            setSelectedMonth(e.target.value)
            console.log(dt.toISOString().split('T')[0])
            dt.setDate(1)
            qParams.date_from = dt.toISOString().split('T')[0]
            dt.setDate(31)
            qParams.date_to = dt.toISOString().split('T')[0]
        } else {
            qParams = {...params, [field]: e.target.value}
        }
        setParams(qParams)
        filterList(qParams)
    }
    const filterList = (currentParams, clear) => {
        console.log(currentParams)
        let queryParams = "";
        if(currentParams) {
            if(currentParams.courier_id) {
                queryParams = queryParams+"courier_id="+currentParams.courier_id + "&";
            }
            if(currentParams.payment_type) {
                queryParams = queryParams+"payment_type="+currentParams.payment_type + "&";
            }
            if(currentParams.date_from) {
                queryParams = queryParams+"date_from="+currentParams.date_from + "&";
            }
            if(currentParams.date_to) {
                queryParams = queryParams+"date_to="+currentParams.date_to
            }
        }
        if(clear) {
            setParams({courier_id: "", payment_type: "", date_from: "", date_to: ""})
            queryParams=""
        }
        setLoading(true)
        fetchCourierBalanceHistory(queryParams).then(res => res.ok && res.json().then(d => {
            setHistory(d)
            setBalance(d.reduce((s, r) => s + r.amount, 0))
            setOverAll(d.length)
            setLoading(false)
        }));
    }

    const [editItem, setEditItem] = useState(null)


    const submitNewComment = () => {
        let allData = [...history];
        allData.filter(d => d.id === editItem.id)[0].comment= editItem.comment
        setHistory([...allData])
        fetchCourierBalanceHistoryUpdate(editItem.id, {comment: editItem.comment}).then(res => {
            if(res.ok) {
                toastify("success", t("Operation succeeded"))
                setEditItem(null)
            } else {
                toastify("error", t("Operation failed"))
            }
        })
    }
    return (
        <div>
        <CCard>
            <CCardBody>
                <CRow className="my-2">
                    <CCol sm="2">
                        <CLabel>{t("Courier")}</CLabel>
                        <CSelect onChange={e => filterChange(e, "courier_id")} value={params?.courier_id}>
                        <option value="">{t("All")}</option>
                            {couriers.map((c,i) =>
                                <option value={c.id} key={i}>{c.firstname + " " + c.username}</option>
                            )}
                        </CSelect>
                    </CCol>
                    <CCol sm="2">
                        <CLabel>{t("Payment type")}</CLabel>
                        <CSelect onChange={e => filterChange(e, "payment_type")} value={params?.payment_type}>
                        <option value="">{t("All")}</option>
                            <option value="replenish">{t("replenish")}</option>
                            <option value="write-off">{t("write-off")}</option>
                            <option value="shift-fee">{t("shift-fee")}</option>
                        </CSelect>
                    </CCol>
                    <CCol sm="6">
                        <CRow>
                            <CCol>
                                <CLabel>{t("From")}</CLabel>
                                <CInput type="date" onChange={e => filterChange(e, "date_from")} value={params?.date_from}/>
                            </CCol>
                            <CCol>
                                <CLabel>{t("To")}</CLabel>
                                <CInput type="date" onChange={e => filterChange(e, "date_to")} value={params?.date_to}/>
                            </CCol>
                        </CRow>
                    </CCol>
                    <CCol sm="2" className="float-right">
                        {/* <CButton className="btn btn-outline-primary mx-1" onClick={() => filterList()}>{t("Filter")}</CButton> */}
                        {/* <CButton className="btn btn-outline-danger mx-1" onClick={() => filterList({}, true)}>{t("Clear")}</CButton>    */}
                    </CCol>
                </CRow>
                <CRow className="pl-3 mb-2">
                    {t("По месяцам")}: 
                    {months.map((m, i) => 
                        <CButton className={(i===selectedMonth ? "btn-dark" : "btn-outline-dark") + " btn btn-sm mx-1 mb-1"} 
                        value={i} onClick={e => filterChange(e, "month")}>{m}</CButton>
                        )}
                </CRow>
                <CRow>
                    <CCol sm="8">
                        <CRow>
                            <CCol>
                                <h4>{t("Number of operations")} : {overAll}</h4>
                            </CCol>
                            <CCol>
                                <h4>{t("Sum")} : {balance}</h4>
                            </CCol>
                        </CRow>

                    </CCol>
                    <CCol sm="4" className="text-right my-2">
                        <div>
                            <CButton className="btn btn-outline-danger mx-1" onClick={() => filterList({}, true)}>{t("Clear filters")}</CButton>   
                            <ReactHTMLTableToExcel
                                className="btn btn-outline-success mx-1"
                                table="excel-payments"
                                filename="courier_paymens"
                                sheet="Sheet"
                                buttonText={t('Download')}
                        />
                        </div>
                    </CCol>
                </CRow>
                {loading ? <div className="text-center"><MiniSpinner/></div> :
                <table className="table table-sm-responsive" id="excel-payments">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Курьер</th>
                            <th>{t("Пользователь")}</th>
                            <th>{t("Дата и время")}</th>
                            <th>{t("Тип платежа")}</th>
                            <th>{t("Order ID")}</th>
                            <th>{t("Примечание")}</th>
                            <th>{t("Сумма")}</th>

                        </tr>
                    </thead>
                    <tbody>
                        {history.map((h, i) => 
                            <tr key={i}>
                                <td>{h.id}</td>
                                <td>{h.courier.user.firstname}</td>
                                <td>{h.author?.firstname}</td>
                                <td>{date(h.created_at)}</td>
                                <td>{t(h.type)}</td>
                                <td>{h.order_id}</td>
                                <td>
                                    {editItem?.id === h.id ?
                                        <>
                                            <CTextarea value={editItem.comment} onChange={e => 
                                                setEditItem({...editItem, comment: e.target.value})}/>
                                                <div className="d-flex mt-1 float-right">
                                                    <CButton className="btn btn-sm btn-outline-success mx-1" onClick={submitNewComment}>{t("Save")}</CButton>
                                                    <CButton className="btn btn-sm btn-outline-danger mx-1" onClick={() => setEditItem(null)}>{t("Cancel")}</CButton>
                                                </div>
                                        </> :
                                        <>
                                            {h.comment}
                                            {h.comment && <CIcon name="cilPencil" style={{cursor: "pointer"}} onClick={() => setEditItem(h)}/>}   
                                        </>
                                    }
                                </td>
                                <td>{h.amount}</td>
                            </tr>
                        )}
                    </tbody>
                </table>}
            </CCardBody>
        </CCard>
        </div>
    )
}