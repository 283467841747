import {
  CCard,
  CCardHeader,
  CCardBody,
  CForm,
  CFormGroup,
  CLabel,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CRow,
  CInput,
} from "@coreui/react";
import Select from "react-select";
import React from "react";
import VerifyCourierForm from "./components/VerifyCourierForm";
import { useTranslation } from "react-i18next";
import CourierBalanceReplenish from "./components/CourierBalanceReplenish";
import MiniSpinner from "src/components/spinners/MiniSpinner";
import CourierPassword from "./components/CourierPassword";
import CouriersTable from "./CouriersTable";
import ConfirmDeleteModal from "./components/ConfirmDelete";
import useCourierList from "./useCourierList";
import { statusList } from "./helpers/constants";

export default function CouriersContainer({ all }) {
  const { t } = useTranslation();
  const {
    couriers,
    onStatusSelect,
    onOfficeSelect,
    selectCourier,
    blockCourier,
    verifyForm,
    modalForm,
    setModalForm,
    onToggle,
    selectedCourier,
    modalTitle,
    setModalTitle,
    reFetchCouriers,
    isOpen,
    isLoading,
    offices,
    search,
    setSearch,
    filterList,
    onCreateClick,
    onEditClick,
  } = useCourierList({ isAll: all });

  return (
    <CCard className="m-2">
      <CCardHeader>
        <CRow>
          <CInput
            className="col-lg-3 col-md-8 col-sm-12"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder={t("Search")}
          />

          <Select
            isClearable
            className="col-lg-3 col-md-8 col-sm-12"
            placeholder={t("Choose office")}
            options={offices?.map((o) => ({ label: o.name, value: o.id })) || []}
            onChange={(office) => onOfficeSelect(office)}
          />
          <Select
            isClearable
            className="col-lg-3 col-md-8 col-sm-12"
            placeholder={t("Status")}
            options={statusList.map((status) => ({
              label: t(status.name),
              value: status.id,
            }))}
            onChange={(status) => onStatusSelect(status)}
          />

          <CButton
            variant="outline"
            color="success"
            className="float-right"
            onClick={onCreateClick}
          >
            {t("Create new Courier")}
          </CButton>
        </CRow>
      </CCardHeader>
      <CCardBody>
        {isLoading ? (
          <div className="text-center">
            <MiniSpinner />
          </div>
        ) : (
          <CouriersTable
            couriers={filterList(couriers)}
            setModalForm={setModalForm}
            selectCourier={selectCourier}
            onToggle={onToggle}
            setModalTitle={setModalTitle}
            verifyForm={verifyForm}
            search={search}
            setSearch={setSearch}
            onEditClick={onEditClick}
          />
        )}
      </CCardBody>

      {/** confirm modal */}
      {modalForm === "block" && (
        <CModal show={isOpen} onClose={onToggle}>
          <CModalHeader closeButton>{modalTitle}</CModalHeader>
          <CModalBody>
            {selectedCourier && (
              <CForm>
                <CFormGroup>
                  <CLabel htmlFor="nf-username">
                    {selectedCourier.username}
                  </CLabel>
                </CFormGroup>
              </CForm>
            )}
          </CModalBody>
          <CModalFooter>
            {selectedCourier && (
              <CRow>
                <CButton
                  color="danger"
                  onClick={() =>
                    blockCourier(
                      selectedCourier.id,
                      !selectedCourier.is_blocked
                    )
                  }
                  className="mr-2"
                >
                  {selectedCourier.is_blocked ? t("Unblock") : t("Block")}
                </CButton>
              </CRow>
            )}
          </CModalFooter>
        </CModal>
      )}

      {modalForm === "verify" && (
        <VerifyCourierForm
          selectedCourier={selectedCourier}
          toggle={onToggle}
          modal={isOpen}
          modalTitle={modalTitle}
          reFetchCouriers={reFetchCouriers}
        />
      )}
      {modalForm === "password" && (
        <CourierPassword
          onToggle={onToggle}
          isOpen={isOpen}
          modalTitle={modalTitle}
          reFetchCouriers={reFetchCouriers}
          selectedCourier={selectedCourier}
        />
      )}
      {modalForm === "depositBalance" && (
        <CourierBalanceReplenish
          onToggle={onToggle}
          isOpen={isOpen}
          modalTitle={modalTitle}
          selectedCourier={selectedCourier}
        />
      )}
      {modalForm === "delete" && (
        <ConfirmDeleteModal
          onToggle={onToggle}
          isOpen={isOpen}
          modalTitle={modalTitle}
          selectedCourier={selectedCourier}
        />
      )}
    </CCard>
  );
}
