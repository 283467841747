import { CButton, CCol, CForm, CInput, CLabel, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import Select from "react-select";
import { toastify } from 'src/helpers/toast';
import { useTranslate } from 'src/helpers/useTranslate';
import { fetchCreateCarrier } from 'src/services/CourierService';
import { fetchRoutes } from 'src/services/ServerService';

export default function CarrierForm ({onSuccess}) {

    const {t} = useTranslation()
    const {tr} = useTranslate()
    
    const [routes, setRoutes] = useState([])

    const [carrierForm, setCarrierForm] = useState({
        "username": "",
        "firstname": "",
        "middlename": "",
        "lastname": "",
        "auto_number": "",
        "routelist": []
    })

    useEffect(() => {
        fetchRoutes().then(res => res.ok && res.json().then(d => {
            setRoutes(d)
        }))
    }, [])

    const submitForm = () => {
        console.log({...carrierForm, routes: carrierForm.routelist?.map(r => r.value)})
        fetchCreateCarrier({...carrierForm, routes: carrierForm.routelist?.map(r => r.value)})
            .then(res => {
                if(res.ok) {
                    toastify("success", t("Operation succeded"))
                    setCarrierForm({
                        "username": "",
                        "firstname": "",
                        "middlename": "",
                        "lastname": "",
                        "auto_number": "",
                        "routelist": []
                    })
                    onSuccess()
                } else {
                    res.json().then(d => console.log(d))
                    toastify("error", t("Operation failed"))
                }
            }).catch(err => {
                console.log(err)
                toastify("error", t("Operation failed"))
            })
    }

    return (
        <CForm>
            <CRow>
                <CCol sm="6">
                    <CLabel>{t("Username")}*</CLabel>
                    <PhoneInput
                        inputStyle={{ width: "100%" }}
                        containerStyle={{ width: "100%"}}
                        onlyCountries={["kg"]}
                        country={"kg"}
                        value={carrierForm.username}
                        onChange={res => setCarrierForm({...carrierForm, username: res})}
                        placeholder="+996 xxx xxx xxx"
                        containerClass="my-2"
                        className="my-2"
                        masks={{ kg: "(...) ..-..-.." }}
                    />
                    <CLabel>{t("Firstname")}</CLabel>
                    <CInput placeholder='first name' value={carrierForm.firstname}
                        onChange={e => setCarrierForm({...carrierForm, firstname: e.target.value})}/>
                    <CLabel>{t("Lastname")}</CLabel>
                    <CInput placeholder='last name' value={carrierForm.lastname}
                        onChange={e => setCarrierForm({...carrierForm, lastname: e.target.value})}/>
                    <CLabel>{t("Middlename")}</CLabel>
                    <CInput placeholder='middle name' value={carrierForm.middlename}
                        onChange={e => setCarrierForm({...carrierForm, middlename: e.target.value})}/>
                </CCol>
                <CCol sm="6">
                    <CLabel>{t("Auto Number")}</CLabel>
                    <CInput placeholder='auto number' value={carrierForm.auto_number}
                        onChange={e => setCarrierForm({...carrierForm, auto_number: e.target.value})}/>
                    
                    <CLabel>{t("Routes")}</CLabel>
                    <Select
                        isMulti
                        onChange={(e) => {
                            setCarrierForm({...carrierForm, routelist: e})
                        }}
                        value={carrierForm.routelist}
                        options={routes.map((r) => {
                            return {
                                value: r.id,
                                label: tr(r.name_ru, r.name_kg),
                            };
                        })}
                    />
                </CCol>
            </CRow>
            <CButton className="float-right" color="success" onClick={submitForm}>{t("Submit")}</CButton>
        </CForm>
    )
}