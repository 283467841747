import { CButton, CCard, CCardBody, CCardHeader, CCardTitle } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toastify } from 'src/helpers/toast';
import { fetchCompanyStatuses, fetchSmsSettings, fetchUpdateSmsSettings } from 'src/services/CompanyService';

export default function CompanyNotificationSettings() {

    const {t} = useTranslation();

    // const [statuses, setStatuses] = useState([])
    const [textList, setTextList] = useState([])

    useEffect(() => {
        fetchSmsSettings().then(res => res.ok && res.json().then(d => {
            setTextList(d)
            console.log(d)
        }))
        fetchCompanyStatuses().then(res => res.ok && res.json().then(d => {
            // setStatuses(d);
        }))
    }, [])

    const handleTextArea = (val, field, i) => {
        let tList = [...textList]
        tList[i][field] = val
        console.log(tList)
        setTextList(tList)
    }

    const updateText = (i) => {
        let textBody = textList[i]

        fetchUpdateSmsSettings(textBody.id, textBody).then(res => {
            if(res.ok) {
                toastify("success", t("Operation succeeded"))
            } else {
                toastify("error", "Operation failed")
            }
        }).catch(err => {
            toastify("error", t("Operation failed"))
            console.log(err)
        })
    }
    
    return (
        <CCard className="m-2">
            <CCardHeader>
                <CCardTitle>
                    {t("Notification Settings")}
                    {/* <CButton className="btn btn-outline-success float-right" onClick={() => {
                        // statuses.map(s => fetchCreateSmsSettings({
                        //     "sender_sms_text": "test",
                        //     "receiver_sms_text": "test",
                        //     "sender_push_text": "test",
                        //     "receiver_push_text": "test",
                        //     "status": s.value,
                        //     "send_sms_sender": true,
                        //     "send_sms_receiver": true,
                        //     "send_push_sender": true,
                        //     "send_push_receiver": true
                        // }).then(res=> console.log(res)))
                    }}>Save</CButton> */}
                </CCardTitle>
            </CCardHeader>
            <CCardBody>
                <div className="table-responsive">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th rowSpan="2" className="text-center">{t("Status")}</th>
                            <th colSpan="2" className="text-center">SMS</th>
                            <th colSpan="2" className="text-center">{t("PUSH")}</th>
                        </tr>
                        <tr>
                            <th>{t("Sender")}</th>
                            <th>{t("Receiver")}</th>
                            <th>{t("Sender")}</th>
                            <th>{t("Receiver")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {textList.map((s, i) => 
                            <tr key={i}>
                                {/* <td>{tr(s.name_ru, s.name_kg)}</td> */}
                                <td>{s.status}</td>
                                <td>
                                    <textarea rows="2" value={s.sender_sms_text} 
                                        onChange={e => handleTextArea(e.target.value, "sender_sms_text", i)}
                                    /><br/>
                                    <label>
                                        <input type="checkbox" checked={s.send_sms_sender}
                                            onChange={e => handleTextArea(!s.send_sms_sender, "send_sms_sender", i)}
                                        /> {t("Send")}
                                    </label> <br/>
                                    <CButton className="ml-1 btn btn-sm btn-outline-success"
                                        onClick={() => updateText(i)}
                                    >
                                        {t("Save Changes")}
                                    </CButton>
                                </td>
                                <td>
                                    <textarea rows="2" value={s.receiver_sms_text}
                                        onChange={e => handleTextArea(e.target.value, "receiver_sms_text", i)}
                                    /><br/>
                                    <label>
                                        <input type="checkbox" checked={s.send_sms_receiver}
                                            onChange={e => handleTextArea(!s.send_sms_receiver, "send_sms_receiver", i)}
                                        /> {t("Send")}
                                    </label> <br/>
                                    <CButton className="ml-1 btn btn-sm btn-outline-success"
                                        onClick={() => updateText(i)}
                                    >
                                        {t("Save Changes")}
                                    </CButton>
                                </td>
                                <td>
                                    <textarea rows="2" value={s.sender_push_text}
                                        onChange={e => handleTextArea(e.target.value, "sender_push_text", i)}
                                    /><br/>
                                    <label>
                                        <input type="checkbox" checked={s.send_push_sender}
                                            onChange={e => handleTextArea(!s.send_push_sender, "send_push_sender", i)}
                                        /> {t("Send")}
                                    </label> <br/>
                                    <CButton className="ml-1 btn btn-sm btn-outline-success"
                                        onClick={() => updateText(i)}
                                    >
                                        {t("Save Changes")}
                                    </CButton>
                                </td>
                                <td>
                                    <textarea rows="2" value={s.receiver_push_text}
                                        onChange={e => handleTextArea(e.target.value, "receiver_push_text", i)}
                                    /><br/>
                                    <label>
                                        <input type="checkbox" checked={s.send_push_receiver}
                                            onChange={e => handleTextArea(!s.send_push_receiver, "send_push_receiver", i)}
                                        /> {t("Send")}
                                    </label> <br/>
                                    <CButton className="ml-1 btn btn-sm btn-outline-success"
                                        onClick={() => updateText(i)}
                                    >
                                        {t("Save Changes")}
                                    </CButton>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                </div>
            </CCardBody>
        </CCard>
    )
}