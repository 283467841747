import {
  ORGANIZATION_SUCCESS,
  ORGANIZATION_LOADING,
  ORGANIZATION_FAIL
} from '../types/organizationTypes'

const initialState = {
  organizations: [],
  error: null,
  isLoading: true
}

export default function organizationReducer(state = initialState, action) {
  switch (action.type) {
    case ORGANIZATION_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case ORGANIZATION_SUCCESS:
      return {
        ...state,
        organizations: action.payload,
        isLoading: false,
        error: null
      }
    case ORGANIZATION_FAIL:
      return {
        ...state,
        organizations: [],
        isLoading: false,
        error: action.payload || null
      }
    default:
      return {
        ...state
      }
  }
}